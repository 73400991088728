import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

function Icon({ color, className }: Props): JSX.Element {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="pin-02">
        <path
          id="Icon"
          d="M7.26641 13.0133L2.55237 17.7274M10.0312 5.53458L8.73041 6.83532C8.62431 6.94142 8.57126 6.99447 8.51082 7.03663C8.45717 7.07405 8.3993 7.10502 8.3384 7.1289C8.2698 7.15581 8.19623 7.17052 8.04909 7.19995L4.99537 7.8107C4.20179 7.96941 3.80499 8.04877 3.61936 8.25798C3.45764 8.44024 3.38379 8.68415 3.41725 8.92551C3.45566 9.20255 3.74179 9.48868 4.31405 10.0609L10.2188 15.9657C10.7911 16.538 11.0772 16.8241 11.3543 16.8625C11.5956 16.896 11.8395 16.8221 12.0218 16.6604C12.231 16.4748 12.3104 16.078 12.4691 15.2844L13.0798 12.2307C13.1093 12.0835 13.124 12.01 13.1509 11.9414C13.1748 11.8805 13.2057 11.8226 13.2431 11.769C13.2853 11.7085 13.3384 11.6555 13.4445 11.5494L14.7452 10.2486C14.813 10.1808 14.847 10.1469 14.8842 10.1173C14.9174 10.0909 14.9524 10.0672 14.9892 10.0462C15.0305 10.0226 15.0746 10.0037 15.1628 9.96591L17.2414 9.07507C17.8478 8.81517 18.151 8.68523 18.2888 8.47524C18.4092 8.29161 18.4523 8.06786 18.4087 7.85263C18.3588 7.60651 18.1255 7.37325 17.659 6.90673L13.373 2.62078C12.9065 2.15426 12.6733 1.921 12.4271 1.87111C12.2119 1.82748 11.9882 1.87057 11.8045 1.99101C11.5946 2.12874 11.4646 2.43195 11.2047 3.03837L10.3139 5.117C10.2761 5.20518 10.2572 5.24927 10.2336 5.29061C10.2126 5.32734 10.1888 5.36242 10.1625 5.39554C10.1329 5.43282 10.099 5.46674 10.0312 5.53458Z"
          stroke={color ?? '#667085'}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default Icon;
