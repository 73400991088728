import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Set } from 'Models/Scenario';
import {
  QueueActionType,
  QueueCreateUpdateSetLinePayloadType,
} from 'Models/Queue';
import { DELETED, NEW, SYNC_SETLINE_KEY_PREFIX } from 'utils/variables';
import '../../style.scss';

export const copySetLine = async (
  selectedSet: Set,
  setLineKey: string,
  onEnqueue: (data: QueueActionType) => void,
  syncSetLineToStore: (data: QueueCreateUpdateSetLinePayloadType) => void,
  localSolutionKey: string,
  solutionKey: string,
) => {
  if (!selectedSet) return;

  const toBeCopiedSetLine = _.cloneDeep(
    selectedSet.setLines.find((x) => x.setLineKey === setLineKey),
  );

  const toBeSyncedKey = `${SYNC_SETLINE_KEY_PREFIX}${uuidv4()}`;

  if (!toBeCopiedSetLine) return;

  toBeCopiedSetLine.setLineKey = toBeSyncedKey;
  syncSetLineToStore({
    data: {
      ...toBeCopiedSetLine,
      setKey: selectedSet.setKey,
      solutionKey: localSolutionKey,
    },
    toBeSyncedKey,
    solutionKey: localSolutionKey,
    setKey: selectedSet.setKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_SETLINE',
    payload: {
      data: {
        ...toBeCopiedSetLine,
        setLineStatus: NEW,
        setKey: selectedSet.setKey,
        solutionKey,
      },
      toBeSyncedKey,
      solutionKey: localSolutionKey,
    },
  });
};

export const deleteSetLine = async (
  selectedSet: Set,
  setLineKey: string,
  onEnqueue: (data: QueueActionType) => void,
  syncSetLineToStore: (data: QueueCreateUpdateSetLinePayloadType) => void,
  localSolutionKey: string,
  solutionKey: string,
) => {
  if (!selectedSet) return;

  const toBeDeletedSetLine = _.cloneDeep(
    selectedSet.setLines.find((x) => x.setLineKey === setLineKey),
  );

  const toBeSyncedKey = `${SYNC_SETLINE_KEY_PREFIX}${uuidv4()}`;

  if (!toBeDeletedSetLine) return;

  toBeDeletedSetLine.setLineStatus = DELETED;

  syncSetLineToStore({
    data: {
      ...toBeDeletedSetLine,
      setKey: selectedSet.setKey,
      solutionKey: localSolutionKey,
    },
    toBeSyncedKey,
    solutionKey: localSolutionKey,
    setKey: selectedSet.setKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_SETLINE',
    payload: {
      data: {
        ...toBeDeletedSetLine,
        setKey: selectedSet.setKey,
        solutionKey,
      },
      toBeSyncedKey,
      solutionKey: localSolutionKey,
    },
  });
};
