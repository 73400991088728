import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { RoundTemplate } from 'Models/Scenario';
import { Edit, Close } from 'Components/Elements/Icons';
import RoundDeletePopover from './RoundDeletePopover';
import RoundEditPopover from './RoundEditPopover';

interface Props {
  title: string;
  roundTemplateKey: string;
  templates: RoundTemplate[];
}

export default function RoundEvent({
  title,
  roundTemplateKey,
  templates,
}: Props): JSX.Element {
  const [isDeletePopoverOpen, setDeletePopoverOpen] = useState(false);
  const [isEditPopoverOpen, setEditPopoverOpen] = useState(false);
  const [selectedRound, setSelectedRound] = useState<RoundTemplate | null>(
    null,
  );

  const closePopovers = () => {
    setDeletePopoverOpen(false);
    setEditPopoverOpen(false);
    setSelectedRound(null);
  };

  const selectRound = () => {
    setSelectedRound(
      templates.find((item) => item.roundTemplateKey === roundTemplateKey)!,
    );
  };

  const openDeletePopover = () => {
    selectRound();
    setDeletePopoverOpen(true);
  };

  const openEditPopover = () => {
    selectRound();
    setEditPopoverOpen(true);
  };

  const createDeletePopover = () => (
    <RoundDeletePopover onClose={closePopovers} selectedRound={selectedRound} />
  );

  const createEditPopover = () => (
    <RoundEditPopover onClose={closePopovers} selectedRound={selectedRound} />
  );

  return (
    <div className="flex justify-between items-center">
      <span>{title}</span>
      <div className="event-actions">
        <Dropdown
          menu={{ items: [] }}
          trigger={['click']}
          dropdownRender={createEditPopover}
          open={isEditPopoverOpen}
          destroyPopupOnHide
        >
          <span onClick={openEditPopover} data-testid="event-edit">
            <Edit className="w-3 h-3" />
          </span>
        </Dropdown>

        <Dropdown
          menu={{ items: [] }}
          trigger={['click']}
          dropdownRender={createDeletePopover}
          open={isDeletePopoverOpen}
          destroyPopupOnHide
        >
          <span onClick={openDeletePopover} data-testid="event-delete">
            <Close className="w-3 h-3" />
          </span>
        </Dropdown>
      </div>
    </div>
  );
}
