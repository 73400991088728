import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  SolutionMetaResponsePromiseType,
  ScenarioResponsePromiseType,
  ScenarioSolveResponsePromiseType,
  ScenarioConstraintResponsePromiseType,
  ScenarioSetLineResponsePromiseType,
  ScenarioSetResponsePromiseType,
  Constraint,
  Set,
  Solution,
  ScenarioMetaData,
  ScenarioSolveStopResponsePromiseType,
  SolveData,
  SolutionResponsePromiseType,
  ScenarioKey,
  SolutionKey,
  NetworkKey,
  SetLine,
} from 'Models/Scenario';
import { getScenarioKey } from 'utils/ui-helper';
import {
  selectScenarioMetas,
  selectScenario,
  selectCurrentScenarioKey,
  selectSelectedSolution,
  getScenariosMeta,
  getScenario,
  getScenarioWithoutSync,
  getSolution,
  updateScenario as update,
  createScenario as create,
  cloneScenario as clone,
  solveScenario as solve,
  solveScenarioStop as solveStop,
  saveNetwork,
  saveConstraint,
  saveSet,
  setScenarioKey,
  setSolutionKey,
  addSolution,
  deleteScenario,
  selectSolveDataKey,
  setSolveDataKey,
  selectSolutionKey,
  selectSeason,
  setSeason,
  updateSolveData,
  getScenarioMetaData,
  saveNetworkCategory,
  saveSetLine,
  syncSolutionId,
  selectPrevSolution,
  clearSolutionAndSolveDataKey,
} from 'store/slices/scenarioSlice';
import { updateQueueSolution } from 'store/slices/queueSlice';
import { useAppDispatch } from './hooks';
import { SolveType } from 'Models/SolveTypes';
import { SolverParameterBase } from 'Models/SolverParameter';
import {
  Network,
  NetworkCategory,
  NetworkCategoryResponsePromiseType,
  NetworkResponsePromiseType,
} from 'Models/Network';

export const useScenario = () => {
  const dispatch = useAppDispatch();
  const selectedSeason =
    useSelector(selectSeason) ?? new Date().getUTCFullYear();
  const scenarioMetas = useSelector(selectScenarioMetas);
  const scenario = useSelector(selectScenario);
  const selectedSolution = useSelector(selectSelectedSolution) as Solution;
  const prevSolution = useSelector(selectPrevSolution) as Solution;
  const solveDataKey = useSelector(selectSolveDataKey);
  const solutionKey = useSelector(selectSolutionKey);
  const currentScenarioKey = useSelector(selectCurrentScenarioKey);
  const stateData = selectedSolution ? selectedSolution.stateData : null;
  const selectedSolutionRef = useRef(selectedSolution);
  const scenarioMetasRef = useRef(scenarioMetas);
  const solveData =
    selectedSolution && solveDataKey
      ? selectedSolution.solveDataList.find(
          (item) => item.solveDataKey === solveDataKey,
        )
      : null;

  useEffect(() => {
    selectedSolutionRef.current = selectedSolution;
  }, [selectedSolution]);

  useEffect(() => {
    scenarioMetasRef.current = scenarioMetas;
  }, [scenarioMetas]);

  const getMetas = (season: number) => dispatch(getScenariosMeta(season));

  const getScenarioMeta = (
    scenarioKey: string,
  ): Promise<SolutionMetaResponsePromiseType> =>
    dispatch(
      getScenarioMetaData(scenarioKey),
    ) as Promise<SolutionMetaResponsePromiseType>;

  const fetchScenario = (
    season: number,
    scenarioKey: string,
  ): Promise<ScenarioResponsePromiseType> =>
    dispatch(
      getScenario({ season, scenarioKey }),
    ) as Promise<ScenarioResponsePromiseType>;

  const fetchScenarioWithoutSync = (
    season: number,
    scenarioKey: string,
  ): Promise<ScenarioResponsePromiseType> =>
    dispatch(
      getScenarioWithoutSync({ season, scenarioKey }),
    ) as Promise<ScenarioResponsePromiseType>;

  const fetchSolution = (
    scenarioKey: string,
    targetSolutionKey: string,
  ): Promise<SolutionResponsePromiseType> =>
    dispatch(
      getSolution({ scenarioKey, solutionKey: targetSolutionKey }),
    ) as Promise<SolutionResponsePromiseType>;

  const updateScenario = (
    payload: ScenarioMetaData,
  ): Promise<ScenarioResponsePromiseType> =>
    dispatch(
      update({
        scenario: payload,
      }),
    ) as Promise<ScenarioResponsePromiseType>;

  const createScenario = (
    payload: ScenarioMetaData,
  ): Promise<ScenarioResponsePromiseType> =>
    dispatch(create(payload)) as Promise<ScenarioResponsePromiseType>;

  const cloneScenario = (
    payload: ScenarioMetaData,
  ): Promise<ScenarioResponsePromiseType> =>
    dispatch(clone(payload)) as Promise<ScenarioResponsePromiseType>;

  const onDeleteScenario = (
    scenarioKey: string,
  ): Promise<ScenarioResponsePromiseType> =>
    dispatch(
      deleteScenario(scenarioKey),
    ) as Promise<ScenarioResponsePromiseType>;

  const solveScenario = (
    key: string | null,
    solveType: SolveType,
    solverParameters: SolverParameterBase[],
  ): Promise<ScenarioSolveResponsePromiseType> =>
    dispatch(
      solve({
        solutionKey: key,
        solveType,
        solverParameters,
      }),
    ) as Promise<ScenarioSolveResponsePromiseType>;

  const solveScenarioStop = (
    key: string | null,
  ): Promise<ScenarioSolveStopResponsePromiseType> =>
    dispatch(
      solveStop({
        solutionKey: key,
      }),
    ) as Promise<ScenarioSolveStopResponsePromiseType>;

  const createOrUpdateConstraint = (
    payload: Constraint,
    clonedSolution?: Solution | null,
  ): Promise<ScenarioConstraintResponsePromiseType> =>
    dispatch(
      saveConstraint({
        constraint: {
          ...payload,
          solutionKey: clonedSolution?.solutionKey ?? payload.solutionKey,
        },
        solutionStatus:
          clonedSolution?.solutionStatus ?? selectedSolution.solutionStatus,
      }),
    ) as Promise<ScenarioConstraintResponsePromiseType>;

  const createOrUpdateNetwork = (
    scenarioKey: ScenarioKey,
    _solutionKey: SolutionKey,
    payload: Network,
    clonedSolution: Solution | null,
  ): Promise<NetworkResponsePromiseType> =>
    dispatch(
      saveNetwork({
        scenarioKey,
        solutionKey: _solutionKey,
        network: {
          ...payload,
        },
        solutionStatus:
          clonedSolution?.solutionStatus ?? selectedSolution.solutionStatus,
      }),
    ) as Promise<NetworkResponsePromiseType>;

  const createOrUpdateNetworkCategory = (
    scenarioKey: ScenarioKey,
    _solutionKey: SolutionKey,
    networkKey: NetworkKey,
    payload: NetworkCategory,
    clonedSolution: Solution | null,
  ): Promise<NetworkCategoryResponsePromiseType> =>
    dispatch(
      saveNetworkCategory({
        scenarioKey,
        solutionKey: _solutionKey,
        networkKey,
        networkCategory: {
          ...payload,
        },
        solutionStatus:
          clonedSolution?.solutionStatus ?? selectedSolution!.solutionStatus,
      }),
    ) as Promise<NetworkCategoryResponsePromiseType>;

  const createOrUpdateSet = (
    payload: Set,
    clonedSolution?: Solution | null,
  ): Promise<ScenarioSetResponsePromiseType> =>
    dispatch(
      saveSet({
        set: {
          ...payload,
          solutionKey: clonedSolution?.solutionKey ?? payload.solutionKey,
        },
        solutionStatus:
          clonedSolution?.solutionStatus ??
          selectedSolutionRef.current.solutionStatus,
      }),
    ) as Promise<ScenarioSetResponsePromiseType>;

  const createOrUpdateSetLine = (
    payload: SetLine,
    clonedSolution?: Solution | null,
  ): Promise<ScenarioSetLineResponsePromiseType> =>
    dispatch(
      saveSetLine({
        payload: {
          setLine: {
            ...payload,
          },
          solutionKey:
            clonedSolution?.solutionKey ?? selectedSolution.solutionKey,
          scenarioKey: scenario?.scenarioKey ?? '',
        },
        solutionStatus:
          clonedSolution?.solutionStatus ?? selectedSolution.solutionStatus,
      }),
    ) as Promise<ScenarioSetLineResponsePromiseType>;

  const setCurrentScenarioKey = (scenarioKey: string) =>
    dispatch(setScenarioKey(scenarioKey));

  const setSelectedSeason = (season: number) => dispatch(setSeason(season));

  const setCurrentSolutionKey = (key: string) => dispatch(setSolutionKey(key));

  const addSolutionToScenario = (
    solution: Solution,
    toBeUpdatedKey?: string,
  ) => {
    dispatch(
      addSolution({
        solution,
        toBeUpdatedKey,
      }),
    );
  };

  const setCurrentSolveDataKey = (key: string) =>
    dispatch(setSolveDataKey(key));

  const setEmptySolutionAndSolveDataKey = () =>
    dispatch(clearSolutionAndSolveDataKey());

  const onUpdateSolveData = (payload: SolveData) =>
    dispatch(updateSolveData(payload));

  const setClonedSolutionToQueue = (
    oldSolutionKey: string,
    newSolutionKey: string,
  ) =>
    dispatch(
      updateQueueSolution({
        solutionKey: oldSolutionKey,
        newSolutionKey,
      }),
    );

  const syncSolution = (solution: Solution, toBeUpdatedKey: string) => {
    dispatch(
      syncSolutionId({
        solution,
        toBeUpdatedKey,
      }),
    );
  };

  const getSolutionByKey = (targetSolutionKey: string) => {
    const calculatedScenarioKey = getScenarioKey(targetSolutionKey);
    const currentScenario = scenarioMetasRef.current.find(
      (item) => item.scenarioKey === calculatedScenarioKey,
    )?.scenario;

    if (currentScenario) {
      const targetSolution =
        currentScenario.optimizationEnvelop?.solutions.find(
          (solution) => solution.solutionKey === targetSolutionKey,
        );

      return targetSolution ?? null;
    }

    return null;
  };

  return {
    selectedSeason,
    scenarioMetas,
    scenario,
    currentScenarioKey,
    selectedSolution,
    solveDataKey,
    solutionKey,
    stateData,
    solveData,
    getMetas,
    fetchScenario,
    fetchScenarioWithoutSync,
    fetchSolution,
    updateScenario,
    solveScenario,
    solveScenarioStop,
    createOrUpdateConstraint,
    createOrUpdateSet,
    createOrUpdateNetwork,
    createOrUpdateNetworkCategory,
    setCurrentScenarioKey,
    setCurrentSolutionKey,
    setSelectedSeason,
    addSolutionToScenario,
    createScenario,
    onDeleteScenario,
    cloneScenario,
    setCurrentSolveDataKey,
    onUpdateSolveData,
    setClonedSolutionToQueue,
    getScenarioMeta,
    createOrUpdateSetLine,
    syncSolution,
    prevSolution,
    getSolutionByKey,
    setEmptySolutionAndSolveDataKey,
  };
};

export default useScenario;
