import _ from 'lodash';
import {
  ScenarioSlice,
  Scenario,
  StateData,
  Solution,
  Constraint,
  Set,
  SetLine,
  RoundTemplate,
  RoundDay,
  TimeSlot,
} from 'Models/Scenario';
import { VenueTeam } from 'Models/VenueTeam';
import { getStateData } from 'Models/ScenarioUtils';
import {
  DELETED,
  SYNC_TEAM_KEY_PREFIX,
  SYNC_VENUE_KEY_PREFIX,
} from 'utils/variables';
import { Opponents } from 'Models/Opponent';
import { getSolutionKey, replaceStringInObject } from 'utils/ui-helper';
import { Venue } from 'Models/Venue';
import { Team } from 'Models/Team';
import { SlotType } from 'Models/Slot';
import { Network, NetworkCategory } from 'Models/Network';

export const updateScenriosScenario = (
  state: ScenarioSlice,
  scenarioKey: string,
  scenario: Scenario,
) => {
  state.scenarios = state.scenarios.map((scenarioData) => ({
    ...scenarioData,
    scenario:
      scenarioData.scenarioKey === scenarioKey
        ? scenario
        : scenarioData.scenario,
  }));
};

export const updateSolution = (
  state: ScenarioSlice,
  scenarioKey: string,
  solutionKey: string,
  updatedSolution: Solution,
) => {
  // @ts-expect-error
  state.scenarios = state.scenarios.map((scenarioData) => {
    if (scenarioData.scenarioKey !== scenarioKey) {
      return scenarioData;
    }

    const solutions: Solution[] =
      scenarioData.scenario?.optimizationEnvelop.solutions ?? [];
    const index = solutions.findIndex((x) => x.solutionKey === solutionKey);
    let updatedSolutions: Solution[] = [...solutions];

    if (index >= 0) {
      updatedSolutions[index] = updatedSolution;
    } else {
      updatedSolutions.push(updatedSolution);
    }

    return {
      ...scenarioData,
      scenario: {
        ...scenarioData.scenario,
        optimizationEnvelop: {
          solutions: updatedSolutions,
        },
      },
    };
  });
};

export const getScenarioByKey = (
  state: ScenarioSlice,
  scenarioKey: string | null,
) =>
  _.cloneDeep(
    state.scenarios.find((item) => item.scenarioKey === scenarioKey)?.scenario,
  );

export const clearTeamFromVenueTeams: (
  venueTeams: VenueTeam[],
  teamId: string,
) => VenueTeam[] = (venueTeams, teamId) =>
  venueTeams.map((item) => {
    item.teamIds = item.teamIds.filter((id) => id !== teamId);
    return item;
  });

export const updateSolutionStateData = (
  scenario: Scenario,
  solutionKey: string,
  stateData: StateData,
) => {
  const clonedScenario = _.cloneDeep(scenario);
  const solutionIndex = scenario.optimizationEnvelop.solutions.findIndex(
    (solution) => solution.solutionKey === solutionKey,
  );

  if (solutionIndex !== -1) {
    clonedScenario.optimizationEnvelop.solutions[solutionIndex].stateData =
      stateData;
  }

  return clonedScenario;
};

export const updateConstraint = (
  scenarioKey: string,
  updatedConstraint: Constraint,
  state: ScenarioSlice,
  toBeSyncedKey?: string,
) => {
  if (toBeSyncedKey && updatedConstraint.constraintStatus === DELETED) return;

  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = updatedConstraint.solutionKey;
  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  const operationKey = toBeSyncedKey ? 'toBeSyncedKey' : 'constraintKey';
  const operationValue = toBeSyncedKey
    ? toBeSyncedKey
    : updatedConstraint.constraintKey;

  let constraintInd = -1;

  if (!toBeSyncedKey) {
    constraintInd = stateData.constraintsEnvelop.constraints.findIndex(
      (x) => x.constraintKey === updatedConstraint.constraintKey,
    );
  } else {
    constraintInd = stateData.constraintsEnvelop.constraints.findIndex(
      (x) =>
        x.toBeSyncedKey === toBeSyncedKey || x.constraintKey === toBeSyncedKey,
    );
  }

  if (constraintInd !== -1) {
    if (updatedConstraint.constraintStatus === DELETED) {
      stateData.constraintsEnvelop.constraints =
        stateData.constraintsEnvelop.constraints.filter(
          (x) => x[operationKey] !== operationValue,
        );
    } else {
      if (toBeSyncedKey) {
        stateData.constraintsEnvelop.constraints[constraintInd].constraintKey =
          updatedConstraint.constraintKey;
        stateData.constraintsEnvelop.constraints[constraintInd].solutionKey =
          updatedConstraint.solutionKey;
      } else {
        stateData.constraintsEnvelop.constraints[constraintInd] =
          updatedConstraint;
      }
    }
  } else if (updatedConstraint.constraintStatus !== DELETED && !toBeSyncedKey) {
    stateData.constraintsEnvelop.constraints.push(updatedConstraint);
  }

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateSet = (
  scenarioKey: string,
  updatedSet: Set,
  state: ScenarioSlice,
  toBeSyncedKey?: string,
) => {
  if (toBeSyncedKey && updatedSet.setStatus === DELETED) return;

  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = updatedSet.solutionKey;
  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  const operationKey = toBeSyncedKey ? 'toBeSyncedKey' : 'setKey';
  const operationValue = toBeSyncedKey ? toBeSyncedKey : updatedSet.setKey;

  let setIndex = -1;

  if (!toBeSyncedKey) {
    setIndex = stateData.constraintsEnvelop.sets.findIndex(
      (x) => x.setKey === updatedSet.setKey,
    );
  } else {
    setIndex = stateData.constraintsEnvelop.sets.findIndex(
      (x) => x.toBeSyncedKey === toBeSyncedKey || x.setKey === toBeSyncedKey,
    );
  }

  if (setIndex !== -1) {
    if (updatedSet.setStatus === DELETED) {
      stateData.constraintsEnvelop.sets =
        stateData.constraintsEnvelop.sets.filter(
          (x) => x[operationKey] !== operationValue,
        );
    } else {
      if (toBeSyncedKey) {
        stateData.constraintsEnvelop.sets[setIndex].setKey = updatedSet.setKey;
        stateData.constraintsEnvelop.sets[setIndex].solutionKey =
          updatedSet.solutionKey;
        stateData.constraintsEnvelop.sets[setIndex].setId = updatedSet.setId;
      } else {
        stateData.constraintsEnvelop.sets[setIndex] = updatedSet;
      }
    }
  } else if (updatedSet.setStatus !== DELETED && !toBeSyncedKey) {
    stateData.constraintsEnvelop.sets.push(updatedSet);
  }

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateSetLine = (
  scenarioKey: string,
  setKey: string,
  solutionKey: string,
  updatedSetline: SetLine,
  state: ScenarioSlice,
  toBeSyncedKey?: string,
) => {
  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;

  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  const operationKey = toBeSyncedKey ? 'toBeSyncedKey' : 'setLineKey';
  const operationValue = toBeSyncedKey
    ? toBeSyncedKey
    : updatedSetline.setLineKey;

  const setInd = stateData.constraintsEnvelop.sets.findIndex(
    (x) => x.setKey === setKey,
  );

  if (setInd !== -1) {
    let checkActualValue = false;
    let setLineIndex = stateData.constraintsEnvelop.sets[
      setInd
    ].setLines.findIndex((x) => x[operationKey] === operationValue);

    if (
      setLineIndex === -1 &&
      updatedSetline.setLineStatus === DELETED &&
      !updatedSetline.toBeSyncedKey
    ) {
      setLineIndex = stateData.constraintsEnvelop.sets[
        setInd
      ].setLines.findIndex((x) => x.setLineKey === updatedSetline.setLineKey);

      if (setLineIndex !== -1) {
        checkActualValue = true;
      }
    }

    if (setLineIndex !== -1) {
      if (updatedSetline.setLineStatus === DELETED) {
        stateData.constraintsEnvelop.sets[setInd].setLines =
          stateData.constraintsEnvelop.sets[setInd].setLines.filter((x) =>
            checkActualValue
              ? x.setLineKey !== updatedSetline.setLineKey
              : x[operationKey] !== operationValue,
          );
      } else {
        if (toBeSyncedKey) {
          stateData.constraintsEnvelop.sets[setInd].setLines[
            setLineIndex
          ].setLineKey = updatedSetline.setLineKey;

          stateData.constraintsEnvelop.sets[setInd].setLines[
            setLineIndex
          ].solutionKey = solutionKey;
        } else {
          stateData.constraintsEnvelop.sets[setInd].setLines[setLineIndex] =
            updatedSetline;
        }
      }
    } else if (updatedSetline.setLineStatus !== DELETED && !toBeSyncedKey) {
      stateData.constraintsEnvelop.sets[setInd].setLines.push(updatedSetline);
    }

    updateScenriosScenario(
      state,
      scenarioKey,
      updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
    );
  }
};

export const updateOpponent = (
  scenarioKey: string,
  solutionKey: string,
  updatedOpponent: Opponents,
  opponentsPenalty: number,
  state: ScenarioSlice,
) => {
  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;

  const stateData = getStateData(targetSolutionKey, targetScenario);

  const targetSolutionIndex =
    targetScenario.optimizationEnvelop.solutions.findIndex(
      (solution) => solution.solutionKey === targetSolutionKey,
    );

  if (targetSolutionIndex === -1) return;

  const targetSolution =
    targetScenario.optimizationEnvelop.solutions[targetSolutionIndex];

  targetSolution.stateData.opponents = updatedOpponent;
  targetSolution.stateData.opponentsPenalty = opponentsPenalty;

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateVenue = (
  scenarioKey: string,
  solutionKey: string,
  updatedVenues: Venue[],
  state: ScenarioSlice,
  toBeSyncedKey?: string[],
) => {
  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;

  const stateData = getStateData(targetSolutionKey, targetScenario);

  const targetSolutionIndex =
    targetScenario.optimizationEnvelop.solutions.findIndex(
      (solution) => solution.solutionKey === targetSolutionKey,
    );

  if (targetSolutionIndex === -1) return;

  updatedVenues.forEach((updatedVenue, i) => {
    let venueIndex = -1;

    const savedVenueTeam = updatedVenue.venueTeam;
    delete updatedVenue.venueTeam;

    const operationKey = toBeSyncedKey ? 'toBeSyncedKey' : 'venueKey';
    const operationValue = toBeSyncedKey
      ? toBeSyncedKey[i]
      : updatedVenue.venueKey;

    if (!toBeSyncedKey) {
      venueIndex = stateData.venues.findIndex(
        (x) => x.venueKey === updatedVenue.venueKey,
      );
    } else {
      venueIndex = stateData.venues.findIndex(
        (x) =>
          x.toBeSyncedKey === toBeSyncedKey[i] ||
          x.venueKey === toBeSyncedKey[i],
      );
    }

    if (venueIndex !== -1) {
      if (updatedVenue.venueStatus === DELETED) {
        stateData.venues = stateData.venues.filter(
          (x) => x[operationKey] !== operationValue,
        );
      } else {
        if (toBeSyncedKey) {
          stateData.venues[venueIndex].venueKey = updatedVenue.venueKey;
          stateData.venues[venueIndex].solutionKey = updatedVenue.solutionKey;
          stateData.venues[venueIndex].venueId = updatedVenue.venueId;
          const targetVenueTeamIndex = stateData.venueTeams.findIndex(
            (item) => item.venueId === toBeSyncedKey[i],
          );
          if (targetVenueTeamIndex !== -1) {
            stateData.venueTeams[targetVenueTeamIndex].venueId =
              updatedVenue.venueId;
          }

          // update set venueId
          stateData.constraintsEnvelop.sets = replaceStringInObject(
            stateData.constraintsEnvelop.sets,
            toBeSyncedKey[i],
            updatedVenue.venueId,
          );
        } else {
          stateData.venues[venueIndex] = updatedVenue;
        }
      }
    } else if (updatedVenue.venueStatus !== DELETED && !toBeSyncedKey) {
      stateData.venues.push(updatedVenue);
      if (savedVenueTeam) stateData.venueTeams.push(savedVenueTeam);
    }
  });

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateTeamVenueMap = (
  scenarioKey: string,
  solutionKey: string,
  data: {
    venueKey: string;
    teamKey: string;
    type: 'update' | 'delete';
  },
  state: ScenarioSlice,
) => {
  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;

  const stateData = getStateData(targetSolutionKey, targetScenario);

  const targetSolutionIndex =
    targetScenario.optimizationEnvelop.solutions.findIndex(
      (solution) => solution.solutionKey === targetSolutionKey,
    );

  if (targetSolutionIndex === -1) return;

  const { venueKey, teamKey, type } = data;

  let venueId = venueKey;
  let teamId = teamKey;

  if (!venueKey.includes(SYNC_VENUE_KEY_PREFIX)) {
    // real venue key. extract the venueId
    const allIds = venueKey.split('-');
    const targetVenueId = allIds[3];

    if (targetVenueId) {
      venueId = targetVenueId;
    }
  }

  if (!teamKey.includes(SYNC_TEAM_KEY_PREFIX)) {
    // real team key. extract the teamId
    const allIds = teamKey.split('-');
    const targetTeamId = allIds[3];

    if (targetTeamId) {
      teamId = targetTeamId;
    }
  }

  const venueTeamIndex = stateData.venueTeams.findIndex(
    (venueTeam) => venueTeam.venueId === venueId,
  );

  if (venueTeamIndex === -1) return;

  let teamIds = stateData.venueTeams[venueTeamIndex].teamIds;

  if (type === 'delete') {
    teamIds = teamIds.filter((item) => item !== teamId);
  } else if (!teamIds.includes(teamId)) {
    teamIds.push(teamId);
  }

  stateData.venueTeams[venueTeamIndex].teamIds = teamIds;

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateTeam = (
  scenarioKey: string,
  solutionKey: string,
  updatedTeam: Team,
  state: ScenarioSlice,
  toBeSyncedKey?: string,
  opponents?: Opponents,
) => {
  if (toBeSyncedKey && updatedTeam.teamStatus === DELETED) return;

  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;
  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  const operationKey = toBeSyncedKey ? 'toBeSyncedKey' : 'teamKey';
  const operationValue = toBeSyncedKey ? toBeSyncedKey : updatedTeam.teamKey;

  let teamIndex = -1;

  if (!toBeSyncedKey) {
    teamIndex = stateData.teams.findIndex(
      (x) => x.teamKey === updatedTeam.teamKey,
    );
  } else {
    teamIndex = stateData.teams.findIndex(
      (x) => x.toBeSyncedKey === toBeSyncedKey || x.teamKey === toBeSyncedKey,
    );
  }

  if (teamIndex !== -1) {
    if (updatedTeam.teamStatus === DELETED) {
      // remove all this team id from venueTeams
      const { teamId: toBeDeletedTeamId } = updatedTeam;

      stateData.venueTeams = stateData.venueTeams.map((item) => {
        item.teamIds = item.teamIds.filter(
          (teamId) => teamId !== toBeDeletedTeamId,
        );

        return item;
      });

      stateData.teams = stateData.teams.filter(
        (x) => x[operationKey] !== operationValue,
      );
    } else {
      if (toBeSyncedKey) {
        stateData.teams[teamIndex].teamKey = updatedTeam.teamKey;
        // update constraint teamIds
        stateData.constraintsEnvelop.constraints = replaceStringInObject(
          stateData.constraintsEnvelop.constraints,
          toBeSyncedKey,
          updatedTeam.teamId,
        );
        // update set teamId
        stateData.constraintsEnvelop.sets = replaceStringInObject(
          stateData.constraintsEnvelop.sets,
          toBeSyncedKey,
          updatedTeam.teamId,
        );
      } else {
        stateData.teams[teamIndex] = updatedTeam;
      }
    }
  } else if (updatedTeam.teamStatus !== DELETED && !toBeSyncedKey) {
    stateData.teams.push(updatedTeam);
  }

  if (opponents) stateData.opponents = opponents;

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateNewTeam = (
  scenarioKey: string,
  solutionKey: string,
  team: Team,
  venue: Venue,
  state: ScenarioSlice,
  venueTeam?: VenueTeam,
  opponents?: Opponents,
  toBeSyncedTeamKey?: string,
  toBeSyncedVenueKey?: string,
) => {
  if (toBeSyncedTeamKey && team.teamStatus === DELETED) return;

  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;
  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  let teamIndex = -1;
  let venueIndex = -1;

  if (!toBeSyncedTeamKey) {
    teamIndex = stateData.teams.findIndex((x) => x.teamKey === team.teamKey);
  } else {
    teamIndex = stateData.teams.findIndex(
      (x) =>
        x.toBeSyncedKey === toBeSyncedTeamKey ||
        x.teamKey === toBeSyncedTeamKey,
    );
  }

  if (!toBeSyncedVenueKey) {
    venueIndex = stateData.venues.findIndex(
      (x) => x.venueKey === venue.venueKey,
    );
  } else {
    venueIndex = stateData.venues.findIndex(
      (x) =>
        x.toBeSyncedKey === toBeSyncedVenueKey ||
        x.venueKey === toBeSyncedVenueKey,
    );
  }

  if (teamIndex !== -1) {
    if (toBeSyncedTeamKey) {
      stateData.teams[teamIndex].teamKey = team.teamKey;

      // replace all the local TeamId with actual teamId in venueTeams
      stateData.venueTeams = stateData.venueTeams.map((item) => {
        item.teamIds = item.teamIds.map((item) =>
          item === toBeSyncedTeamKey ? team.teamId : item,
        );

        return item;
      });

      stateData.teams[teamIndex].teamId = team.teamId;

      // update constraint teamIds
      stateData.constraintsEnvelop.constraints = replaceStringInObject(
        stateData.constraintsEnvelop.constraints,
        toBeSyncedTeamKey,
        team.teamId,
      );
      // update set teamId
      stateData.constraintsEnvelop.sets = replaceStringInObject(
        stateData.constraintsEnvelop.sets,
        toBeSyncedTeamKey,
        team.teamId,
      );
    } else {
      stateData.teams[teamIndex] = team;
    }
  } else if (team.teamStatus !== DELETED && !toBeSyncedTeamKey) {
    stateData.teams.push(team);
  }

  if (venueIndex !== -1) {
    if (toBeSyncedVenueKey) {
      stateData.venues[venueIndex].venueKey = venue.venueKey;

      // replace all the local VenueId with actual venueId in venueTeams
      stateData.venueTeams = stateData.venueTeams.map((item) => {
        if (item.venueId === toBeSyncedVenueKey) {
          item.venueId = venue.venueId;
        }

        return item;
      });

      stateData.venues[venueIndex].venueId = venue.venueId;

      // update set venueId
      stateData.constraintsEnvelop.sets = replaceStringInObject(
        stateData.constraintsEnvelop.sets,
        toBeSyncedVenueKey,
        venue.venueId,
      );
    } else {
      stateData.venues[venueIndex] = venue;
    }
  } else if (venue.venueStatus !== DELETED && !toBeSyncedVenueKey) {
    stateData.venues.push(venue);
  }

  if (opponents) stateData.opponents = opponents;
  if (venueTeam && !toBeSyncedTeamKey) {
    stateData.venueTeams.push(venueTeam);
  }

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateSlotType = (
  scenarioKey: string,
  solutionKey: string,
  updatedSlotType: SlotType,
  state: ScenarioSlice,
  toBeSyncedKey?: string,
) => {
  if (toBeSyncedKey && updatedSlotType.slotTypeStatus === DELETED) return;

  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;
  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  const operationKey = toBeSyncedKey ? 'toBeSyncedKey' : 'slotTypeKey';
  const operationValue = toBeSyncedKey
    ? toBeSyncedKey
    : updatedSlotType.slotTypeKey;

  let slotTypeIndex = -1;

  if (!toBeSyncedKey) {
    slotTypeIndex = stateData.roundsDictionaries.slotTypes.findIndex(
      (x) => x.slotTypeKey === updatedSlotType.slotTypeKey,
    );
  } else {
    slotTypeIndex = stateData.roundsDictionaries.slotTypes.findIndex(
      (x) =>
        x.toBeSyncedKey === toBeSyncedKey || x.slotTypeKey === toBeSyncedKey,
    );
  }

  if (slotTypeIndex !== -1) {
    if (updatedSlotType.slotTypeStatus === DELETED) {
      stateData.roundsDictionaries.slotTypes =
        stateData.roundsDictionaries.slotTypes.filter(
          (x) => x[operationKey] !== operationValue,
        );
    } else {
      if (toBeSyncedKey) {
        stateData.roundsDictionaries.slotTypes[slotTypeIndex].slotTypeKey =
          updatedSlotType.slotTypeKey;
        stateData.roundsDictionaries.slotTypes[slotTypeIndex].slotTypeId =
          updatedSlotType.slotTypeId;

        // update networks slotTypeId where we have local slot type id
        stateData.roundsDictionaries.networks = replaceStringInObject(
          stateData.roundsDictionaries.networks,
          toBeSyncedKey,
          updatedSlotType.slotTypeId,
        );

        // update set slotTypeId
        stateData.constraintsEnvelop.sets = replaceStringInObject(
          stateData.constraintsEnvelop.sets,
          toBeSyncedKey,
          updatedSlotType.slotTypeId,
        );
      } else {
        stateData.roundsDictionaries.slotTypes[slotTypeIndex] = updatedSlotType;
      }
    }
  } else if (updatedSlotType.slotTypeStatus !== DELETED && !toBeSyncedKey) {
    stateData.roundsDictionaries.slotTypes.push(updatedSlotType);
  }

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateNetwork = (
  scenarioKey: string,
  solutionKey: string,
  updatedNetwork: Network,
  state: ScenarioSlice,
  toBeSyncedKey?: string,
) => {
  if (toBeSyncedKey && updatedNetwork.networkStatus === DELETED) return;

  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;
  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  const operationKey = toBeSyncedKey ? 'toBeSyncedKey' : 'networkKey';
  const operationValue = toBeSyncedKey
    ? toBeSyncedKey
    : updatedNetwork.networkKey;

  let networkIndex = -1;

  if (!toBeSyncedKey) {
    networkIndex = stateData.roundsDictionaries.networks.findIndex(
      (x) => x.networkKey === updatedNetwork.networkKey,
    );
  } else {
    networkIndex = stateData.roundsDictionaries.networks.findIndex(
      (x) =>
        x.toBeSyncedKey === toBeSyncedKey || x.networkKey === toBeSyncedKey,
    );
  }

  if (networkIndex !== -1) {
    if (updatedNetwork.networkStatus === DELETED) {
      stateData.roundsDictionaries.networks =
        stateData.roundsDictionaries.networks.filter(
          (x) => x[operationKey] !== operationValue,
        );
    } else {
      if (toBeSyncedKey) {
        stateData.roundsDictionaries.networks[networkIndex].networkKey =
          updatedNetwork.networkKey;
        stateData.roundsDictionaries.networks[networkIndex].networkId =
          updatedNetwork.networkId;

        // update set networkId
        stateData.constraintsEnvelop.sets = replaceStringInObject(
          stateData.constraintsEnvelop.sets,
          toBeSyncedKey,
          updatedNetwork.networkId,
        );
      } else {
        stateData.roundsDictionaries.networks[networkIndex] = updatedNetwork;
      }
    }
  } else if (updatedNetwork.networkStatus !== DELETED && !toBeSyncedKey) {
    stateData.roundsDictionaries.networks.push(updatedNetwork);
  }

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateNetworkCategory = (
  scenarioKey: string,
  solutionKey: string,
  networkKey: string,
  updatedNetworkCategory: NetworkCategory,
  state: ScenarioSlice,
  toBeSyncedKey?: string,
) => {
  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;

  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  const operationKey = toBeSyncedKey ? 'toBeSyncedKey' : 'networkCategoryKey';
  const operationValue = toBeSyncedKey
    ? toBeSyncedKey
    : updatedNetworkCategory.networkCategoryKey;

  const networkIndex = stateData.roundsDictionaries.networks.findIndex(
    (x) => x.networkKey === networkKey,
  );

  if (networkIndex !== -1) {
    let checkActualValue = false;
    let setLineIndex = stateData.roundsDictionaries.networks[
      networkIndex
    ].networkCategories.findIndex((x) => x[operationKey] === operationValue);

    if (
      setLineIndex === -1 &&
      updatedNetworkCategory.networkCategoryStatus === DELETED &&
      !updatedNetworkCategory.toBeSyncedKey
    ) {
      setLineIndex = stateData.roundsDictionaries.networks[
        networkIndex
      ].networkCategories.findIndex(
        (x) =>
          x.networkCategoryKey === updatedNetworkCategory.networkCategoryKey,
      );

      if (setLineIndex !== -1) {
        checkActualValue = true;
      }
    }

    if (setLineIndex !== -1) {
      if (updatedNetworkCategory.networkCategoryStatus === DELETED) {
        stateData.roundsDictionaries.networks[networkIndex].networkCategories =
          stateData.roundsDictionaries.networks[
            networkIndex
          ].networkCategories.filter((x) =>
            checkActualValue
              ? x.networkCategoryKey !==
                updatedNetworkCategory.networkCategoryKey
              : x[operationKey] !== operationValue,
          );
      } else {
        if (toBeSyncedKey) {
          stateData.roundsDictionaries.networks[networkIndex].networkCategories[
            setLineIndex
          ].networkCategoryKey = updatedNetworkCategory.networkCategoryKey;

          stateData.roundsDictionaries.networks[networkIndex].networkCategories[
            setLineIndex
          ].networkCategoryId = updatedNetworkCategory.networkCategoryId;

          // update set networkcategoryId
          stateData.constraintsEnvelop.sets = replaceStringInObject(
            stateData.constraintsEnvelop.sets,
            toBeSyncedKey,
            updatedNetworkCategory.networkCategoryId,
          );
        } else {
          stateData.roundsDictionaries.networks[networkIndex].networkCategories[
            setLineIndex
          ] = updatedNetworkCategory;
        }
      }
    } else if (
      updatedNetworkCategory.networkCategoryStatus !== DELETED &&
      !toBeSyncedKey
    ) {
      stateData.roundsDictionaries.networks[
        networkIndex
      ].networkCategories.push(updatedNetworkCategory);
    }

    updateScenriosScenario(
      state,
      scenarioKey,
      updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
    );
  }
};

export const updateRoundTemplate = (
  scenarioKey: string,
  solutionKey: string,
  updatedRound: RoundTemplate,
  state: ScenarioSlice,
  toBeSyncedKey?: string,
) => {
  if (toBeSyncedKey && updatedRound.roundTemplateStatus === DELETED) return;

  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;
  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  const operationKey = toBeSyncedKey ? 'toBeSyncedKey' : 'roundTemplateKey';
  const operationValue = toBeSyncedKey
    ? toBeSyncedKey
    : updatedRound.roundTemplateKey;

  let roundIndex = -1;

  if (!toBeSyncedKey) {
    roundIndex = stateData.roundTemplates.findIndex(
      (x) => x.roundTemplateKey === updatedRound.roundTemplateKey,
    );
  } else {
    roundIndex = stateData.roundTemplates.findIndex(
      (x) =>
        x.toBeSyncedKey === toBeSyncedKey ||
        x.roundTemplateKey === toBeSyncedKey,
    );
  }

  if (roundIndex !== -1) {
    if (updatedRound.roundTemplateStatus === DELETED) {
      stateData.roundTemplates = stateData.roundTemplates.filter(
        (x) => x[operationKey] !== operationValue,
      );
    } else {
      if (toBeSyncedKey) {
        stateData.roundTemplates[roundIndex].roundTemplateKey =
          updatedRound.roundTemplateKey;
        stateData.roundTemplates[roundIndex].startDate = updatedRound.startDate;
        stateData.roundTemplates[roundIndex].endDate = updatedRound.endDate;
      } else {
        let savedRoundDays = stateData.roundTemplates[roundIndex].roundDays;

        if (
          Array.isArray(updatedRound.roundDays) &&
          updatedRound.roundDays.length > 0
        ) {
          savedRoundDays = updatedRound.roundDays;
        }

        stateData.roundTemplates[roundIndex] = updatedRound;
        stateData.roundTemplates[roundIndex].roundDays = savedRoundDays;
      }
    }
  } else if (updatedRound.roundTemplateStatus !== DELETED && !toBeSyncedKey) {
    stateData.roundTemplates.push(updatedRound);
  }

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateRoundDay = (
  scenarioKey: string,
  solutionKey: string,
  roundTemplateKey: string,
  updatedRoundDay: RoundDay,
  state: ScenarioSlice,
  toBeSyncedKey: string,
) => {
  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;
  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  let roundIndex = stateData.roundTemplates.findIndex(
    (x) => x.roundTemplateKey === roundTemplateKey,
  );

  if (roundIndex === -1) return;

  const roundDayIndex = stateData.roundTemplates[
    roundIndex
  ].roundDays.findIndex(
    (rd) =>
      rd.roundDayKey === toBeSyncedKey ||
      rd.toBeSyncedKey === toBeSyncedKey ||
      rd.roundDayKey === updatedRoundDay.roundDayKey,
  );

  if (roundDayIndex === -1) return;

  stateData.roundTemplates[roundIndex].roundDays[roundDayIndex].roundDayKey =
    updatedRoundDay.roundDayKey;

  stateData.roundTemplates[roundIndex].roundDays[roundDayIndex].isActive =
    updatedRoundDay.isActive;

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};

export const updateTimeslot = (
  scenarioKey: string,
  solutionKey: string,
  roundTemplateKey: string,
  roundDayKey: string,
  updatedTimeslot: TimeSlot,
  state: ScenarioSlice,
  toBeSyncedKey?: string,
) => {
  if (toBeSyncedKey && updatedTimeslot.timeSlotStatus === DELETED) return;

  const targetScenario = getScenarioByKey(state, scenarioKey);

  if (!targetScenario) return;

  const targetSolutionKey = solutionKey;
  const stateData = _.cloneDeep(
    getStateData(targetSolutionKey, targetScenario),
  );

  let roundIndex = stateData.roundTemplates.findIndex(
    (x) => x.roundTemplateKey === roundTemplateKey,
  );

  if (roundIndex === -1) return;

  const roundDayIndex = stateData.roundTemplates[
    roundIndex
  ].roundDays.findIndex((rd) => rd.roundDayKey === roundDayKey);

  if (updatedTimeslot.timeSlotStatus === DELETED) {
    stateData.roundTemplates[roundIndex].roundDays[roundDayIndex].timeSlots =
      stateData.roundTemplates[roundIndex].roundDays[
        roundDayIndex
      ].timeSlots.filter(
        (ts) => ts.timeSlotKey !== updatedTimeslot.timeSlotKey,
      );

    if (
      stateData.roundTemplates[roundIndex].roundDays[roundDayIndex].timeSlots
        .length === 0
    ) {
      stateData.roundTemplates[roundIndex].roundDays[roundDayIndex].isActive =
        false;
    }
  } else {
    stateData.roundTemplates[roundIndex].roundDays[roundDayIndex].isActive =
      true;

    if (toBeSyncedKey) {
      const timeSlotIndex = stateData.roundTemplates[roundIndex].roundDays[
        roundDayIndex
      ].timeSlots.findIndex((item) => item.toBeSyncedKey === toBeSyncedKey);

      if (timeSlotIndex === -1) return;
      stateData.roundTemplates[roundIndex].roundDays[roundDayIndex].timeSlots[
        timeSlotIndex
      ] = updatedTimeslot;
    } else {
      stateData.roundTemplates[roundIndex].roundDays[
        roundDayIndex
      ].timeSlots.push(updatedTimeslot);
    }
  }

  updateScenriosScenario(
    state,
    scenarioKey,
    updateSolutionStateData(targetScenario, targetSolutionKey, stateData),
  );
};
