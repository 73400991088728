import { Slice, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SolverParameter, SolverParameterSlice } from 'Models/SolverParameter';
import { FetchSolverParameters } from 'Services/SolverParameterService';

export const getSolverParameters = createAsyncThunk(
  'user/getSolverParameters',
  async () => {
    const res: SolverParameterSlice = await FetchSolverParameters();

    return { data: res.solverParameters };
  },
);

const initialState: SolverParameterSlice = {
  solverParameters: []
};

export const solverParametersReducer = (state: SolverParameterSlice, { payload } : {
  payload: {
    data: SolverParameter[]
  }
}) => {
  state.solverParameters = payload.data;
};

const solverParametersSlice: Slice = createSlice({
  name: 'app/solve-parameters',
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSolverParameters.fulfilled, solverParametersReducer);
  },
});

export default solverParametersSlice.reducer;
