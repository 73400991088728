import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Dropdown, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { decodeHTML } from 'utils/ui-helper';
import { NameMap } from 'Models/Scenario';
import {
  selectTeams,
  selectVenueTeams,
  selectVenues,
} from 'store/slices/scenarioSlice';
import VenueSelectPopover from './VenueSelectPopover';
import { Venue } from 'Models/Venue';

// @ts-expect-error
export default function HomeVenue({ data }) {
  const { t } = useTranslation();
  const allTeams = useSelector(selectTeams);
  const allVenues = useSelector(selectVenues);
  const allVenueTeams = useSelector(selectVenueTeams);

  const venuesMap = useMemo(() => {
    if (allVenues) {
      return allVenues.reduce((acc: NameMap, venue: Venue) => {
        acc[venue.venueId] = decodeHTML(venue.name);

        return acc;
      }, {});
    }
    return {};
  }, [allVenues]);

  const teamHomeVenues = useMemo(() => {
    if (allTeams && allVenueTeams) {
      const teamId = allTeams.find(
        (team) => team.teamKey === data.teamKey,
      )?.teamId;

      return teamId
        ? allVenueTeams.reduce((acc: string[], item) => {
            if (
              item.teamIds.includes(teamId) &&
              !acc.includes(item.venueId) &&
              venuesMap[item.venueId]
            ) {
              acc.push(item.venueId);
            }

            return acc;
          }, [])
        : [];
    }

    return [];
  }, [allTeams, allVenueTeams, venuesMap]);

  const venueTeams = useMemo(
    () =>
      teamHomeVenues.map((venueId) => ({
        label: <span>{venuesMap[venueId]}</span>,
        key: venueId,
      })),

    [teamHomeVenues, venuesMap],
  );

  const createSelectPopOver = () => (
    <VenueSelectPopover teamKey={data.teamKey} venueIds={teamHomeVenues} />
  );

  let innerContent = null;

  if (teamHomeVenues.length === 0) {
    innerContent = (
      <span className="cursor-pointer table-cell">
        {t('GENERAL.VENUE.SELECT')}
      </span>
    );
  } else if (teamHomeVenues.length === 1) {
    innerContent = (
      <span className="cursor-pointer" data-testid="home-venue-count">
        <Tag color="#BDBDBD">{venuesMap[teamHomeVenues[0]]}</Tag>
      </span>
    );
  } else {
    innerContent = (
      <div>
        <Dropdown
          menu={{ items: venueTeams }}
          trigger={['hover']}
          placement="bottomRight"
          destroyPopupOnHide
        >
          <div className="cursor-pointer">
            <Badge
              count={teamHomeVenues.length}
              style={{ backgroundColor: '#BDBDBD' }}
              data-testid="home-venue-count"
            />
          </div>
        </Dropdown>
      </div>
    );
  }

  return (
    <Dropdown
      menu={{ items: [] }}
      trigger={['click']}
      dropdownRender={createSelectPopOver}
      placement="bottomLeft"
      destroyPopupOnHide
    >
      {innerContent}
    </Dropdown>
  );
}
