// @ts-nocheck

import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useTranslation } from 'react-i18next';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';

export default memo(
  forwardRef((props, ref) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
    } = usePlacesAutocomplete();
    const { t } = useTranslation();

    const valueFromKeyStroke =
      props.eventKey?.length === 1 ? props.eventKey : null;

    const location = useRef(valueFromKeyStroke ?? props.value);
    const comboboxRef = useRef(null);

    useEffect(() => {
      // focus on the input
      setValue(valueFromKeyStroke ?? (props.value || ''));

      setTimeout(() => {
        comboboxRef.current?.focus();
      }, 150);
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => ({
      // the final value to send to the grid, on completion of editing
      getValue() {
        // this simple editor doubles any value entered into the input
        return comboboxRef.current ? comboboxRef.current.value : props.value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any value greater than 1000
        return !location;
      },
    }));

    const handleMouseClick = (e: MouseEvent) => {
      let element = e.target;

      if (element.tagName !== 'LI') {
        element = element.parentNode;
      }
      comboboxRef.current.value = element.innerText;
    };

    useEffect(() => {
      let locationItems: HTMLDivElement[] = [];
      if (data.length > 0) {
        setTimeout(() => {
          locationItems = document.querySelectorAll(
            'li[data-reach-combobox-option]',
          );

          for (let i = 0; i < locationItems.length; i++) {
            locationItems[i].addEventListener('mouseover', handleMouseClick);
          }
        }, 700);
      }

      return () => {
        locationItems.forEach((element) => {
          element.removeEventListener('mouseover', handleMouseClick);
        });
      };
    }, [data]);

    return (
      <Combobox>
        <ComboboxInput
          ref={comboboxRef}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="w-full pl-4"
          placeholder={t('GENERAL.SEARCH_ADDRESS')}
          data-testid="search-input"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === 'OK' &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  }),
);
