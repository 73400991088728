import { useRef, useEffect } from 'react';
import _ from 'lodash';
import {
  ColumnTypeBye,
  ColumnTypeMatch,
  ColumnTypeNotSet,
  GamesLockPayload,
  OptimizedColumn,
} from '../../../Models/Scenario.d';
import { Team } from 'Models/Team';
import { RoundInstance } from 'Models/RoundInstance';
import {
  SolverRow,
  SolverColumn,
  SlotInfo,
} from 'Components/Scenarios/EditScenario/Solve/Solve/SolveTypes';
import { LockType } from 'Models/LockTypes';
import useScenario from 'storeHooks/useScenario';
import { useAppDispatch } from 'storeHooks/hooks';
import { syncGamesLock } from 'store/slices/scenarioSlice';
import useQueue from 'storeHooks/useQueue';
import useSolution from 'customHooks/useSolution';
import { CREATED } from 'utils/variables';

export const useSolveTable = () => {
  const dispatch = useAppDispatch();
  const { scenario, selectedSolution, solveData } = useScenario();
  const { onEnqueue } = useQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const selectedSolutionRef = useRef(selectedSolution);
  const getAllScenarioSlotsInfo = (
    roundInstances: RoundInstance[],
  ): SlotInfo[] => {
    try {
      let allScenarioSlotsInfo: SlotInfo[] = roundInstances.flatMap((round) =>
        round.roundDays.flatMap((day) =>
          day.timeSlots.flatMap((slot) => ({
            // slot info object
            slot,
            day,
            round,
          })),
        ),
      );

      allScenarioSlotsInfo = Array.from(
        new Set(allScenarioSlotsInfo.map((item) => item)),
      ); // distinct

      return allScenarioSlotsInfo;
    } catch (err) {
      // @ts-expect-error
      const errMsg = `getAllScenarioSlots error: ${err.message}`;
      throw new Error(errMsg);
    }
  };

  useEffect(() => {
    selectedSolutionRef.current = selectedSolution;
  }, [selectedSolution]);

  const getSelectedSlotsInfo = (
    roundsInstances: RoundInstance[],
  ): SlotInfo[] => {
    try {
      const allScenarioSlotsInfo: SlotInfo[] =
        getAllScenarioSlotsInfo(roundsInstances);

      let selectedSlotsInfo: SlotInfo[] = allScenarioSlotsInfo;

      selectedSlotsInfo = Array.from(
        new Set(selectedSlotsInfo.map((item) => item)),
      ) // distinct slots
        .sort((slotInfo1, slotInfo2) => {
          // sort by date
          const slotDayDate1 = new Date(slotInfo1.day.date);
          const slotDayDate2 = new Date(slotInfo2.day.date);
          return slotDayDate1.getTime() - slotDayDate2.getTime();
        });

      return selectedSlotsInfo;
    } catch (err) {
      // @ts-expect-error
      const errMsg = `getSelectedSlots error: ${err.message}`;
      throw new Error(errMsg);
    }
  };

  const isHomeTeam = (
    timeSlotInstanceKey: string,
    teamId: string,
    optimizedOptions: OptimizedColumn[] | null,
  ): boolean | null => {
    const option = optimizedOptions?.find(
      (opt) =>
        opt.slotKey === timeSlotInstanceKey &&
        (opt.homeTeamId === teamId || opt.awayTeamId === teamId),
    );

    if (option) {
      return option.homeTeamId === teamId;
    }

    return null;
  };

  const getOpponent = (
    timeSlotInstanceKey: string,
    teamId: string,
    optimizedOptions: OptimizedColumn[] | null,
    teams: Team[],
  ): Team | null => {
    const isItHomeTeam = isHomeTeam(
      timeSlotInstanceKey,
      teamId,
      optimizedOptions,
    );

    if (isItHomeTeam === null) {
      return null;
    }

    const option = optimizedOptions?.find(
      (opt) =>
        opt.slotKey === timeSlotInstanceKey &&
        (opt.homeTeamId === teamId || opt.awayTeamId === teamId),
    );

    if (option) {
      const opponentTeamId = isItHomeTeam
        ? option.awayTeamId
        : option.homeTeamId;
      const opponentTeam =
        teams.find((t) => opponentTeamId === t.teamId) ?? null;

      return opponentTeam;
    }

    return null;
  };

  function findOption(
    timeSlotInstanceKey: string,
    homeTeamId: string,
    awayTeamId: string,
    optimizedOptions: OptimizedColumn[] | null,
  ): OptimizedColumn | null {
    const option = optimizedOptions?.find(
      (opt) =>
        opt.slotKey === timeSlotInstanceKey &&
        opt.homeTeamId === homeTeamId &&
        opt.awayTeamId === awayTeamId,
    );
    return option ?? null;
  }

  const tryGetByeOption = (
    timeSlotInstanceKey: string,
    teamId: string,
    optimizedOptions: OptimizedColumn[] | null,
  ): OptimizedColumn | null => {
    const option = optimizedOptions?.find(
      (opt) =>
        opt.columnType === ColumnTypeBye &&
        opt.slotKey === timeSlotInstanceKey &&
        opt.homeTeamId === teamId,
    );

    return option ?? null;
  };

  const getSlotRows = (
    optimizedColumns: OptimizedColumn[] | null,
    teams: Team[],
    roundsInstances: RoundInstance[],
  ): SolverRow[] => {
    try {
      const selectedTeams: Team[] = teams; // lets always show all team now

      const selectedSlotsInfo: SlotInfo[] =
        getSelectedSlotsInfo(roundsInstances);
      const retMatrix: SolverRow[] = [];

      const slotsPerRounds = _.groupBy(selectedSlotsInfo, (n) =>
        n.round.roundNumber.toString(),
      );

      _.forEach(slotsPerRounds, (slots, roundNumberStr) => {
        const roundNumber = Number.parseInt(roundNumberStr, 10);

        const row: SolverRow = {
          round: roundsInstances.find(
            (x) => x.roundNumber === roundNumber,
          ) as RoundInstance,
          columns: [],
        };

        // Columns for the slot
        selectedTeams.forEach((selTeam) => {
          const col: SolverColumn = {
            columnType: ColumnTypeNotSet,
            columnShortId: '',
            team: selTeam,
            opponentTeam: null,
            isHomeGameForOpponentTeam: null,
            slotInfo: null,
            isLocked: false,
          };

          row.columns.push(col);

          // try to find an option by current slot and current team for a cell
          // othrwise a default SolverColumn will be applied for the cell
          slots.forEach((selSlotInfo) => {
            const byeOption = tryGetByeOption(
              selSlotInfo.slot.timeSlotInstanceKey,
              selTeam.teamId,
              optimizedColumns,
            );

            if (byeOption !== null) {
              col.columnType = ColumnTypeBye;
              col.columnShortId = byeOption.columnShortId;
              col.slotInfo = selSlotInfo;
              col.isLocked = byeOption.isLocked;
            } else {
              const opponentTeam = getOpponent(
                selSlotInfo.slot.timeSlotInstanceKey,
                selTeam.teamId,
                optimizedColumns,
                teams,
              );

              const isItHomeTeam = isHomeTeam(
                selSlotInfo.slot.timeSlotInstanceKey,
                selTeam.teamId,
                optimizedColumns,
              );

              const homeTeamId =
                (isItHomeTeam ? selTeam.teamId : opponentTeam?.teamId) ?? '';

              const awayTeamId =
                (!isItHomeTeam ? selTeam.teamId : opponentTeam?.teamId) ?? '';

              const option = findOption(
                selSlotInfo.slot.timeSlotInstanceKey,
                homeTeamId,
                awayTeamId ?? '',
                optimizedColumns,
              );

              if (opponentTeam && option) {
                col.columnType = ColumnTypeMatch;
                col.columnShortId = option.columnShortId;
                col.opponentTeam = opponentTeam;
                col.isHomeGameForOpponentTeam = !isItHomeTeam;
                col.slotInfo = selSlotInfo;
                col.isLocked = option.isLocked;
              }
            }
          });
        });

        retMatrix.push(row);
      });

      return retMatrix;
    } catch (err) {
      // @ts-expect-error
      const errMsg = `getSlotRows error: ${err.message}`;
      throw new Error(errMsg);
    }
  };

  const handleGamesLock = async (
    lockType: LockType,
    columnShortIds: string[],
  ): Promise<string> => {
    if (!scenario || !selectedSolution || !solveData) {
      return Promise.resolve('');
    }

    const chosenSolution = getCurrentOrClonedSolution(false);

    if (!chosenSolution) return Promise.resolve('');

    const { solveDataKey } = solveData;

    const gamesLockPayload: GamesLockPayload = {
      scenarioKey: scenario.scenarioKey,
      solutionKey: chosenSolution.solutionKey,
      solveDataKey,
      lockType,
      columnShortIds,
      solutionStatus: CREATED,
    };

    dispatch(syncGamesLock(gamesLockPayload));

    onEnqueue({
      type: 'UPDATE_LOCK_UNLOCK',
      payload: {
        data: gamesLockPayload,
        solutionKey: chosenSolution.solutionKey, // local solution key
        actualSolutionKey: chosenSolution.stateData.solutionKey,
      },
    });

    return Promise.resolve('');
  };

  return {
    scenario,
    getSlotRows,
    handleGamesLock,
  };
};

export default useSolveTable;
