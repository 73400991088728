import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { persistor, store } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { initKeycloak } from './Services/UserService';
import router from './router/router';
import 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '@reach/combobox/styles.css';
import 'react-contexify/dist/ReactContexify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './plugins/i18next';
import './plugins/sentry';
import './index.scss';

const root = createRoot(document.getElementById('root'));

const renderApp = () => {
  return root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
  );
};

initKeycloak(renderApp);
