import { Slice, createSlice } from '@reduxjs/toolkit';

type StateType = {
  settings: {
    shouldShowDeleteConfirmation: boolean;
  };
};

const initialState: StateType = {
  settings: {
    shouldShowDeleteConfirmation: false,
  },
};

export const selectShoudDeleteConfirmation = ({ user }: { user: StateType }) =>
  user.settings.shouldShowDeleteConfirmation;

export const userSlice: Slice = createSlice({
  name: 'app/user-info',
  initialState: {
    ...initialState,
  },
  reducers: {
    setShoudDeleteConfirmation(state, { payload }) {
      state.settings.shouldShowDeleteConfirmation = payload;
    },
  },
});

export const { setShoudDeleteConfirmation } = userSlice.actions;

export default userSlice.reducer;
