import { Scenario, ScenarioMetaData } from '../Models/Scenario';

const setRoundDate = (scnario: Scenario, scenarioMeta: ScenarioMetaData) => {
  const initialRoundTemplate = scnario.optimizationEnvelop.solutions[0].stateData.roundTemplates[0];

  scenarioMeta.startDate = initialRoundTemplate?.startDate ? initialRoundTemplate.startDate : null;
  scenarioMeta.endDate = initialRoundTemplate?.endDate ? initialRoundTemplate.endDate : null;

  scnario.optimizationEnvelop.solutions.forEach(
    (solution) => {
      solution.stateData.roundTemplates.forEach(
        (roundTemplate) => {
          if (scenarioMeta.startDate) {
            scenarioMeta.startDate = roundTemplate.startDate < scenarioMeta.startDate ? roundTemplate.startDate : scenarioMeta.startDate;
          }
          if (scenarioMeta.endDate) {
            scenarioMeta.endDate = roundTemplate.endDate > scenarioMeta.endDate ? roundTemplate.endDate : scenarioMeta.endDate;
          }
        }
      );
    });
};

export const getScenarioMeta = (scenario: Scenario): ScenarioMetaData => {
  const scenarioMeta = {
    id: scenario.id,
    season: scenario.season,
    scenarioKey: scenario.scenarioKey,
    name: scenario.name,
    logo: scenario.logo,
    scenario: null,
    results: scenario.optimizationEnvelop.solutions.reduce(
      (sum, solution) => solution.solveDataList.length + sum, 0
    ),
    lastModifiedDate: scenario.optimizationEnvelop.solutions[0].createTime,
  };

  setRoundDate(scenario, scenarioMeta);

  return scenarioMeta;
};
