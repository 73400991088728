// @ts-nocheck
import React, { useMemo, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Badge } from 'antd';
import { useLoadScript } from '@react-google-maps/api';
import useScenario from 'storeHooks/useScenario';
import useLocale from 'locales/localeMapGrid';
import { Penalty } from 'Models/Scenario';
import './style.scss';

function PenaltyList(): JSX.Element {
  useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    libraries: ['places'],
  });
  const { solveData } = useScenario();
  const {
    penalty: { number, comment, penaltyHappenedTimes, title },
  } = useLocale();
  const gridRef = useRef();

  const penalties: Penalty[] = useMemo(() => {
    if (solveData) {
      return solveData.penalties;
    }
    return [];
  }, [solveData]);

  const [columnDefs] = useState([
    { field: 'penalty', headerName: number, maxWidth: 100 },
    {
      field: 'penaltyHappenedTimes',
      headerName: penaltyHappenedTimes,
      maxWidth: 150,
    },
    {
      field: 'name',
      headerName: comment,
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
    }),
    [],
  );

  const totalPenalties = penalties.length;

  return (
    <div className="penalty-table">
      <div className="flex justify-between items-center">
        <span className="font-semibold ml-4">
          {title}
          <Badge
            className={totalPenalties ? 'penalty-badge' : ''}
            count={totalPenalties}
            color="var(--Gray-100, #F2F4F7)"
          />
        </span>
      </div>
      <div className="ag-theme-alpine mt-4 h-full">
        <AgGridReact
          ref={gridRef}
          rowData={penalties}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={(params) => params.data.id}
          animateRows
          enterNavigatesVerticallyAfterEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  );
}

export default PenaltyList;
