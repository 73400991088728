/* eslint-disable no-console */
import { isLoggedIn, doLogin } from '../../Services/UserService';

function EnsureAuthenticated({ children }) {
  if (!isLoggedIn()) {
    doLogin();
    return [];
  }

  return children;
}

export default EnsureAuthenticated;
