import { isAdmin, manageAccount, doLogout } from 'Services/UserService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppVersion from './AppVersion';

export default function Header() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<header className="App-header">
			<p>
				{isAdmin() ? (
					<button onClick={() => navigate('/admin-dashboard')}>
						{t('GENERAL.USER.ADMIN_DASHBOARD')}
					</button>
				) : (
					<></>
				)}
				<button onClick={manageAccount}>
					{t('GENERAL.USER.MANAGE_ACCOUNT')}
				</button>
				<button onClick={doLogout}>{t('GENERAL.USER.LOGOUT')}</button>
				<span>Optimal Planning Solutions</span>
				<AppVersion />
			</p>
		</header>
	);
}
