import React, {
  useMemo,
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
} from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import useScenario from 'storeHooks/useScenario';
import useNetwork from 'storeHooks/useNetwork';
import { clearCellsHilighting } from 'Components/Scenarios/EditScenario/Solve/Solve/SelectSolveTableCells';
import ScenarioSelectorSortTrigger from './ScenarioSelectorSortTrigger';
import useQueue from 'storeHooks/useQueue';

interface Props {
  season: string;
  scenarioKey: string;
}

type MyOption = { label: string; value: string };

const toggleClassName = 'show';

const selectStyles = {
  // indicatorSeparator: () => ({ display: 'none' }),
};

function ScenarioSelector({ scenarioKey, season }: Props): JSX.Element {
  const navigate = useNavigate();
  const {
    scenarioMetas,
    setCurrentScenarioKey,
    getScenarioMeta,
    fetchSolution,
    solveDataKey,
    scenario: currentScenario,
    selectedSolution,
    setEmptySolutionAndSolveDataKey,
  } = useScenario();
  const { setIsSaving } = useNetwork();
  const { queue, failedQueueItems, restoreFailedQueueItems, setQueueFree } =
    useQueue();
  const selectScenarioOptions: MyOption[] = useMemo(
    () =>
      scenarioMetas.map((item) => ({
        value: item.scenarioKey,
        label: item.name,
      })),
    [scenarioMetas],
  );
  const [scenario, setScenario] = useState<MyOption | null>(
    selectScenarioOptions.find((item) => item.value === scenarioKey) ?? null,
  );
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const scenarioSelector = useRef<HTMLDivElement>(null);
  const initalLoadDone = useRef(false);
  const isMetaLoading = useRef(false);
  const isFetchingSolution = useRef(false);
  const currentScenarioKeyRef = useRef('');
  const currentSolveDataKeyRef = useRef('');
  const selectedSolutionRef = useRef(selectedSolution);

  const selectedMeta = useMemo(() => {
    const meta = scenarioMetas.find((item) => item.scenarioKey === scenarioKey);

    return meta ? meta.name : '';
  }, [scenarioMetas, scenarioKey]);

  const toggleSelectMenu = () => setIsOpenMenu((prev) => !prev);

  const onChangePerPage = (selected: MyOption | null) => {
    if (selected) {
      currentScenarioKeyRef.current = selected.value;
      setCurrentScenarioKey(selected.value);
      setScenario(selected);

      clearCellsHilighting();
      setEmptySolutionAndSolveDataKey();

      navigate(`/scenario/${season}/${selected.value}`);
    }

    toggleSelectMenu();
  };

  useLayoutEffect(() => {
    const handleClickOutside = (event: Event) => {
      const element = event.target as HTMLElement;

      if (
        scenarioSelector.current &&
        !scenarioSelector.current.contains(element)
      ) {
        scenarioSelector.current?.classList.remove(toggleClassName);
        setIsOpenMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    selectedSolutionRef.current = selectedSolution;
  }, [selectedSolution]);

  const currentSolutionHasPendingQueueItem = () => {
    const currentSolution = selectedSolutionRef.current?.solutionKey;
    if (!currentSolution) return false;

    return JSON.stringify([...queue, ...failedQueueItems]).includes(
      currentSolution,
    );
  };

  useEffect(() => {
    if (scenario && !isMetaLoading.current) {
      if (currentSolutionHasPendingQueueItem()) {
        setQueueFree();
        restoreFailedQueueItems();
        return;
      }

      isMetaLoading.current = true;

      getScenarioMeta(scenario.value).finally(() => {
        isMetaLoading.current = false;
        const solutionKey = currentSolveDataKeyRef.current
          .split('-')
          .slice(0, -1)
          .join('-');
        if (solutionKey) {
          setIsSaving(true);
          fetchSolution(currentScenarioKeyRef.current, solutionKey).finally(
            () => {
              setIsSaving(false);
            },
          );
        }
      });
    }
  }, [scenario]);

  useEffect(() => {
    if (selectScenarioOptions.length > 0 && !initalLoadDone.current) {
      const initialScenario = selectScenarioOptions.find(
        (item) => item.value === scenarioKey,
      );

      if (initialScenario) {
        setScenario(initialScenario);
        initalLoadDone.current = true;
      }
    }

    currentScenarioKeyRef.current = scenarioKey;
    setCurrentScenarioKey(scenarioKey);
  }, [scenarioMetas, scenarioKey]);

  useEffect(() => {
    if (
      currentScenarioKeyRef.current &&
      solveDataKey &&
      !isFetchingSolution.current &&
      currentScenario
    ) {
      const solutionKey = solveDataKey.split('-').slice(0, -1).join('-');

      if (solutionKey) {
        isFetchingSolution.current = true;

        if (!isMetaLoading.current && !selectedSolutionRef.current.stateData) {
          setIsSaving(true);
          fetchSolution(currentScenarioKeyRef.current, solutionKey).finally(
            () => {
              setIsSaving(false);
            },
          );
        }

        setTimeout(() => {
          isFetchingSolution.current = false;
        }, 800);
      }
    }

    currentSolveDataKeyRef.current = solveDataKey;
  }, [solveDataKey, currentScenario]);

  return (
    <div className="scenarios-selector-container">
      <div className="scenario-selected">
        {selectedMeta && (
          <div
            className="selected"
            onClick={toggleSelectMenu}
            data-testid="scenario-selector"
          >
            {selectedMeta}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </div>
        )}

        <div className="scenario-options" ref={scenarioSelector}>
          {isOpenMenu && (
            <Select
              value={scenario}
              options={selectScenarioOptions}
              // @ts-expect-error
              onChange={onChangePerPage}
              className="w-64"
              styles={selectStyles}
              menuIsOpen={isOpenMenu}
              components={{ DropdownIndicator: ScenarioSelectorSortTrigger }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ScenarioSelector;
