import React from 'react';

function OpponentsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_1781_155930)">
        <path d="M4.43057 16.1987C4.93751 15.0044 6.12109 14.1667 7.50033 14.1667H12.5003C13.8796 14.1667 15.0631 15.0044 15.5701 16.1987M13.3337 7.91669C13.3337 9.75764 11.8413 11.25 10.0003 11.25C8.15938 11.25 6.66699 9.75764 6.66699 7.91669C6.66699 6.07574 8.15938 4.58335 10.0003 4.58335C11.8413 4.58335 13.3337 6.07574 13.3337 7.91669ZM18.3337 10C18.3337 14.6024 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6024 1.66699 10C1.66699 5.39765 5.39795 1.66669 10.0003 1.66669C14.6027 1.66669 18.3337 5.39765 18.3337 10Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1781_155930">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OpponentsIcon;
