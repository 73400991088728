import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ScenarioSlotTypeResponsePromiseType } from 'Models/Scenario';
import { QueueCreateUpdateSlotPayloadType } from 'Models/Queue';
import { createOrUpdateSlotType } from 'store/slices/roundSlice';
import { syncSlotType } from 'store/slices/scenarioSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { CREATED } from 'utils/variables';
import { useRef, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { SlotType } from 'Models/Slot';
import { getScenarioKey } from 'utils/ui-helper';

export const useSlotType = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareSlotTypePayload = ({
    data,
    toBeUpdatedFields,
  }: QueueCreateUpdateSlotPayloadType): SlotType => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (queueLocalKeyMapRef.current[newPayload.slotTypeKey]) {
      newPayload.slotTypeKey =
        queueLocalKeyMapRef.current[newPayload.slotTypeKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.slotTypeId]) {
      newPayload.slotTypeId =
        queueLocalKeyMapRef.current[newPayload.slotTypeId];
    }

    return newPayload;
  };

  const syncSlotTypeToStore = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
    solutionKey,
  }: QueueCreateUpdateSlotPayloadType): void => {
    dispatch(
      syncSlotType({
        updatedSlotType: prepareSlotTypePayload({
          data,
          toBeUpdatedFields,
        }),
        solutionKey,
        toBeSyncedKey,
      }),
    );
  };

  const createOrUpdateSlotTypeQueue = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
    actualSolutionKey,
  }: {
    data: SlotType;
    toBeUpdatedFields: {
      [key in keyof SlotType]?: SlotType[key];
    };
    toBeSyncedKey: string;
    actualSolutionKey: string;
  }): Promise<ScenarioSlotTypeResponsePromiseType | null> => {
    const preparedData = prepareSlotTypePayload({
      data,
      toBeUpdatedFields,
    });

    if (preparedData) {
      return dispatch(
        createOrUpdateSlotType({
          slotTypePayload: {
            slotType: {
              ...preparedData,
              toBeSyncedKey,
            },
            scenarioKey: getScenarioKey(actualSolutionKey),
            solutionKey: actualSolutionKey,
          },
          solutionStatus: CREATED,
        }),
      ) as Promise<ScenarioSlotTypeResponsePromiseType>;
    }

    return Promise.resolve(null);
  };

  return {
    prepareSlotTypePayload,
    syncSlotTypeToStore,
    createOrUpdateSlotTypeQueue,
  };
};

export default useSlotType;
