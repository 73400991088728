import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Card, Checkbox, Col, Input, Row } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useTranslation } from 'react-i18next';
import { Venue } from 'Models/Venue';
import useScenario from 'storeHooks/useScenario';
import { Search } from 'Components/Elements/Icons';
import { Solution } from 'Models/Scenario';
import useQueue from 'storeHooks/useQueue';
import { useVenue as useVenueQueue } from 'storeHooks/queue';
import { QueueActionType } from 'Models/Queue';
import useSolution from 'customHooks/useSolution';
import { SyncVenueMapPayload } from 'storeHooks/queue/useVenue';

interface Props {
  teamKey: string;
  venueIds: string[];
}

interface Option {
  label: string;
  value: string;
}

export const handleSelectVenue = async (
  teamKey: string,
  venueIds: string[],
  checkedValues: CheckboxValueType[],
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncTeamVenueMapToStore: (data: SyncVenueMapPayload) => void,
) => {
  if (!selectedSolution) return;

  const { venues } = selectedSolution.stateData;
  const { solutionKey: localSolutionKey } = selectedSolution;
  const { solutionKey } = selectedSolution.stateData;

  const operationType: {
    type: 'update' | 'delete';
    venueKey: string;
  } = {
    type: 'update',
    venueKey: '',
  };

  const currentVenues = venueIds.reduce(
    (acc: Record<string, boolean>, item: string) => {
      acc[item] = true;

      return acc;
    },
    {},
  );

  const newVenues: Record<string, boolean> = checkedValues
    .map((item) => item.toString())
    .reduce((acc: Record<string, boolean>, item: string) => {
      acc[item] = true;

      return acc;
    }, {});

  if (checkedValues.length < venueIds.length) {
    venueIds.forEach((item) => {
      if (!newVenues[item]) {
        operationType.type = 'delete';
        operationType.venueKey = venues.find((venue) => venue.venueId === item)
          ?.venueKey as string;
      }
    });
  } else {
    checkedValues.forEach((item) => {
      if (!currentVenues[item as string]) {
        operationType.type = 'update';
        operationType.venueKey = venues.find((venue) => venue.venueId === item)
          ?.venueKey as string;
      }
    });
  }

  const { venueKey } = operationType;

  if (!venueKey || !teamKey) return;

  syncTeamVenueMapToStore({
    data: {
      venueKey,
      teamKey,
    },
    toBeUpdatedFields: {
      type: operationType.type,
    },
    solutionKey: selectedSolution.solutionKey,
  });

  onEnqueue({
    type: 'UPDATE_TEAM_VENUE',
    payload: {
      data: {
        venueKey,
        teamKey,
      },
      toBeUpdatedFields: {
        type: operationType.type,
      },
      solutionKey: localSolutionKey, // local solution key
      actualSolutionKey: solutionKey,
    },
  });
};

function VenueSelectPopOver({ teamKey, venueIds }: Props): JSX.Element {
  const { stateData } = useScenario();
  const { syncTeamVenueMapToStore } = useVenueQueue();
  const { onEnqueue } = useQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const [value, setValue] = useState<CheckboxValueType[]>([...venueIds]);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');

  const venues: Venue[] = useMemo(() => {
    if (stateData) {
      return stateData.venues;
    }
    return [];
  }, [stateData]);

  const options: Option[] = venues.map((team) => ({
    label: team.name,
    value: team.venueId,
  }));

  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  const onSearch = (e: ChangeEvent) => {
    setSearchText((e.target as HTMLInputElement).value);
  };

  const handleChange = (checkedValues: CheckboxValueType[]) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    handleSelectVenue(
      teamKey,
      venueIds,
      checkedValues,
      chosenSolution,
      onEnqueue,
      syncTeamVenueMapToStore,
    );
  };

  useEffect(() => {
    if (searchText.trim().length > 0) {
      const filtered: Option[] = options.filter((item) =>
        item.label.toLowerCase().includes(searchText.trim().toLowerCase()),
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [searchText]);

  useEffect(() => {
    setValue([...venueIds]);
  }, [venueIds]);

  return (
    <Card className="default-pop-over flat" style={{ width: 250 }}>
      <Input
        className="search-input my-2"
        size="middle"
        placeholder={t('GENERAL.SEARCH')}
        prefix={<Search />}
        value={searchText}
        onChange={onSearch}
        data-testid="search-input"
      />
      <div className="p-1">
        <Checkbox.Group
          style={{ width: '100%' }}
          value={value}
          onChange={handleChange}
        >
          <span style={{ maxHeight: 120, overflowY: 'auto' }}>
            <Row>
              {filteredOptions.map((option) => (
                <Col
                  className="mt-2 venue-item"
                  dats-testid="venue-item"
                  span={24}
                  key={option.value}
                >
                  <Checkbox value={option.value}> {option.label} </Checkbox>
                </Col>
              ))}
              {options.length === 0 && <Col>{t('GENERAL.VENUE.EMPTY')}</Col>}
            </Row>
          </span>
        </Checkbox.Group>
      </div>
    </Card>
  );
}

export default VenueSelectPopOver;
