import React, { useMemo, useEffect, useState, useRef, ElementRef } from 'react';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import { Dropdown, Spin } from 'antd';
import classNames from 'classnames';
import { SwapOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import useTeam from 'storeHooks/useTeam';
import { Team } from 'Models/Team';
import { StateData } from 'Models/Scenario';
import { useSelector } from 'react-redux';
import { CellOpponentIndexMap } from 'Models/Opponent';
import {
  selectTeams,
  selectSelectedSolution,
} from 'store/slices/scenarioSlice';
import {
  initializeTableSelection,
  unInitializeTableSelection,
  getSelectedCells,
  clearCellsHighlighting,
  selectAllCells,
  keyPressed,
  toBeExecutedWhileMultiSelect,
  toBeExecutedPressEnter,
  lastPressedNumber,
  isEditModeEnabled,
} from './OpponentTableSelectCells';
import OpponentActionPopOver from './OpponentActionPopOver';
import OpponentPenalty from './OpponentPenalty';
import './style.scss';
import { toggleDrawerCloseIconVisibility } from 'utils/ui-helper';

const items: MenuProps['items'] = [];

export type OrientationType = 'default' | 'reverse';
export type SelectionType = 'min' | 'max' | 'both';
export type CellRecordMapType = Record<string, number>;

type TeamRowDataType = Record<
  string,
  {
    combinedKey: string;
    teamHomeInd: number;
    teamAwayInd: number;
    itemsList: number[][];
    minGamesAmount: number;
    maxGamesAmount: number;
    gamesAmt: number;
    lastCellText: string | number;
    teamAway: Team;
    cellText: string;
    selectionType: SelectionType;
    orientation: OrientationType;
  }[]
>;

const closeActiveCellsInput = () => {
  const inputs = document.querySelectorAll('.cell-input.active-input');

  for (let i = 0; i < inputs.length; i++) {
    inputs[i].classList.remove('active-input');
    inputs[i].classList.add('inactive-input');
  }
};

const showActiveInputCell = (combinedteamKey: string) => {
  const inputs = document.querySelectorAll(
    `input[data-combinedteamkey="${combinedteamKey}"]`,
  );

  for (let i = 0; i < inputs.length; i++) {
    inputs[i].classList.remove('inactive-input');
    inputs[i].classList.add('active-input');
  }
};

function GamesSpinner(
  teamHome: Team,
  openPopOverId: string,
  setOpenPopOverId: (value: string) => void,
  selectedCellsForOperation: CellOpponentIndexMap,
  rowDataMap: TeamRowDataType,
  getLastCellText: (teamId: string, direction: string) => number | string,
  invalidOperationCell: CellOpponentIndexMap,
): JSX.Element {
  const { formatOpponent } = useTeam();
  const inputRef = useRef<Record<string, ElementRef<'input'>>>({});
  const prevValue = useRef(0);
  const requestHandled = useRef(false);
  const amount = useRef(0);

  const onAmountChange = (e: React.FormEvent<HTMLInputElement>) => {
    amount.current = Number(e.currentTarget.value);
  };

  const onOpenMenu = (
    e: React.MouseEvent,
    combinedKey: string,
    teamHomeInd: number,
    teamAwayInd: number,
  ) => {
    if (keyPressed.meta) {
      e.preventDefault();
      return false;
    }

    setOpenPopOverId(combinedKey);

    const selectionTypeCellCount = getSelectedCells().length;

    if (selectionTypeCellCount === 0) {
      const targetElement = document.querySelector(
        `[data-matrix="${teamHomeInd}-${teamAwayInd}"]`,
      ) as HTMLTableCellElement;

      if (targetElement) {
        targetElement.classList.add('highlighted');
      }
    }

    return true;
  };

  const enableEdit = (
    gamesAmt: number,
    homeTeamKey: string,
    awayTeamKey: string,
  ) => {
    isEditModeEnabled.value = true;

    if (lastPressedNumber.value !== null) {
      amount.current = lastPressedNumber.value;
    } else {
      amount.current = gamesAmt;
    }

    prevValue.current = gamesAmt;

    showActiveInputCell(`${homeTeamKey}-${awayTeamKey}`);

    setTimeout(() => {
      if (inputRef.current[`${homeTeamKey}-${awayTeamKey}`]) {
        // @ts-ignore
        inputRef.current[`${homeTeamKey}-${awayTeamKey}`].value =
          amount.current;
      }

      // @ts-ignore
      inputRef.current[`${homeTeamKey}-${awayTeamKey}`]?.focus();
      // @ts-ignore
      inputRef.current[`${homeTeamKey}-${awayTeamKey}`]?.select();
    });
  };

  const handleClick = (
    event: React.MouseEvent,
    gamesAmt: number,
    homeTeamKey: string,
    awayTeamKey: string,
  ) => {
    if (event.detail === 2) {
      enableEdit(gamesAmt, homeTeamKey, awayTeamKey);
    } else {
      // close all other inputs and context menu
      setOpenPopOverId(new Date().toString());
    }
  };

  const highlightRowColumn = (
    event: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    // @ts-ignore
    if (event.target.classList.contains('opp-disabled')) {
      setOpenPopOverId('');
    }
  };

  const handleKeyDown = async (
    e: React.KeyboardEvent,
    teamHomeInd: number,
    teamAwayInd: number,
    selectionType: SelectionType,
    orientation: string,
  ) => {
    if (e.key === 'Enter') {
      closeActiveCellsInput();
      clearCellsHighlighting();

      if (Number(prevValue.current) !== amount.current) {
        requestHandled.current = true;
        formatOpponent(
          { [`${teamHomeInd}-${teamAwayInd}`]: true },
          amount.current,
          selectionType,
          orientation === 'default',
        );
      }
    }
  };

  const handleOnBlur = async (
    teamHomeInd: number,
    teamAwayInd: number,
    selectionType: SelectionType,
    orientation: string,
  ) => {
    lastPressedNumber.value = null;
    closeActiveCellsInput();

    if (
      Number(prevValue.current) !== amount.current &&
      !requestHandled.current
    ) {
      formatOpponent(
        { [`${teamHomeInd}-${teamAwayInd}`]: true },
        amount.current,
        selectionType,
        orientation === 'default',
      );
    } else {
      requestHandled.current = false;
    }
  };

  useEffect(() => {
    closeActiveCellsInput();
  }, [openPopOverId]);

  useEffect(() => {
    rowDataMap[teamHome.teamKey].forEach((item) => {
      toBeExecutedPressEnter[`${teamHome.teamKey}-${item.teamAway.teamKey}`] =
        () => {
          enableEdit(item.gamesAmt, teamHome.teamKey, item.teamAway.teamKey);
        };
    });

    return () => {
      rowDataMap[teamHome.teamKey].forEach((item) => {
        delete toBeExecutedPressEnter[
          `${teamHome.teamKey}-${item.teamAway.teamKey}`
        ];
      });
    };
  }, [rowDataMap, teamHome]);

  return (
    <tr key={teamHome.teamKey}>
      {rowDataMap[teamHome.teamKey].map((item) => {
        if (teamHome.teamId === item.teamAway.teamId) {
          return (
            <td
              key={`${teamHome.teamId}_${item.teamAway.teamId}`}
              className="opponent opp-disabled"
              onClick={(event: React.MouseEvent<HTMLTableCellElement>) =>
                highlightRowColumn(event)
              }
            />
          );
        }

        return (
          <td
            key={item.teamHomeInd * 10 + item.teamAwayInd}
            className={classNames('opponent', {
              invalid:
                invalidOperationCell[`${item.teamHomeInd}-${item.teamAwayInd}`],
            })}
            data-matrix={`${item.teamHomeInd}-${item.teamAwayInd}`}
            onContextMenu={(e: React.MouseEvent) =>
              onOpenMenu(
                e,
                item.combinedKey,
                item.teamHomeInd,
                item.teamAwayInd,
              )
            }
            onClick={(event: React.MouseEvent<HTMLTableCellElement>) =>
              highlightRowColumn(event)
            }
            tabIndex={0}
          >
            <input
              ref={(el) => {
                // @ts-expect-error
                inputRef.current[
                  `${teamHome.teamKey}-${item.teamAway.teamKey}`
                ] = el;
              }}
              type="number"
              min="0"
              className={classNames('h-full inactive-input cell-input')}
              data-combinedteamkey={`${teamHome.teamKey}-${item.teamAway.teamKey}`}
              onKeyDown={(e: React.KeyboardEvent) =>
                handleKeyDown(
                  e,
                  item.teamHomeInd,
                  item.teamAwayInd,
                  item.selectionType,
                  item.orientation,
                )
              }
              onBlur={() => {
                handleOnBlur(
                  item.teamHomeInd,
                  item.teamAwayInd,
                  item.selectionType,
                  item.orientation,
                );
              }}
              onChange={onAmountChange}
            />
            <div
              className="w-full h-full flex items-center justify-center trigger-container"
              onClick={(event: React.MouseEvent) =>
                handleClick(
                  event,
                  item.gamesAmt,
                  teamHome.teamKey,
                  item.teamAway.teamKey,
                )
              }
              data-hometeamkey={teamHome.teamKey}
              data-awayteamkey={item.teamAway.teamKey}
            >
              <span>
                {selectedCellsForOperation[
                  `${item.teamHomeInd}-${item.teamAwayInd}`
                ] ? (
                  <Spin size="small" />
                ) : (
                  item.cellText
                )}
              </span>
            </div>
          </td>
        );
      })}
      <td className="result-item">
        {getLastCellText(teamHome.teamId, 'right')}
      </td>
    </tr>
  );
}

export default function OpponentMatchTable(): JSX.Element {
  const { t: translate } = useTranslation();
  const selectedSolution = useSelector(selectSelectedSolution);
  const allTeams = useSelector(selectTeams);
  const { formatOpponent, formatOpponentToOppositeSelectionType } = useTeam();
  const [openPopOverId, setOpenPopOverId] = useState('');
  const [orientation, setOrientation] = useState<OrientationType>('reverse');
  const [selectionType, setSelectionType] = useState<SelectionType>('min');
  const [selectedCellsForOperation, setSelectedCellsForOperation] =
    useState<CellOpponentIndexMap>({});
  const invalidCellIndexMap = useRef<CellOpponentIndexMap>({});

  const tableContainerRef = useRef<ElementRef<'table'>>(null);

  const availableTeams: Team[] = useMemo(() => {
    if (allTeams) {
      return [...allTeams].sort((a, b) =>
        a.code.localeCompare(b.code, 'en', { numeric: true }),
      );
    }
    return [];
  }, [allTeams]);

  const handleSelectAllCells = () => {
    const selectionTypeCellCount = getSelectedCells().filter((element) =>
      element.hasAttribute('data-matrix'),
    ).length;

    if (selectionTypeCellCount === 0) {
      selectAllCells();
    } else {
      clearCellsHighlighting();
    }
  };

  const clearAllHighlights = () => {
    clearCellsHighlighting();
  };

  const onSwapOrientation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    clearAllHighlights();
    setOrientation((prev) => (prev === 'default' ? 'reverse' : 'default'));
  };

  const closeDropDown = () => {
    const disabledCell: HTMLDivElement | null = document.querySelector(
      '.opponent.opp-disabled',
    );

    if (disabledCell) {
      disabledCell.click();
    }
  };

  const onSelectValue = async (value: number) => {
    const selectedCellIndexMap: CellOpponentIndexMap = {};

    getSelectedCells()
      .filter((element) => element.hasAttribute('data-matrix'))
      .forEach((element) => {
        if (element.getAttribute('data-matrix')) {
          selectedCellIndexMap[element.getAttribute('data-matrix')!] = true;
        }
      });

    closeDropDown();

    setSelectedCellsForOperation(selectedCellIndexMap);

    formatOpponent(
      selectedCellIndexMap,
      value,
      selectionType,
      orientation === 'default',
    );

    clearCellsHighlighting();

    setOpenPopOverId('');
  };

  const onCopyToOpposite = async () => {
    const selectedCellIndexMap: CellOpponentIndexMap = {};

    getSelectedCells()
      .filter((element) => element.hasAttribute('data-matrix'))
      .forEach((element) => {
        if (element.getAttribute('data-matrix')) {
          selectedCellIndexMap[element.getAttribute('data-matrix')!] = true;
        }
      });

    closeDropDown();

    formatOpponentToOppositeSelectionType(
      selectedCellIndexMap,
      selectionType,
      orientation === 'default',
    );

    clearCellsHighlighting();

    setOpenPopOverId('');
  };

  const createPopover = () => (
    <OpponentActionPopOver
      selectionType={selectionType}
      onSelect={onSelectValue}
      onCopyToOpposite={onCopyToOpposite}
    />
  );

  function FillTeamSums(
    isHome: boolean,
    teams: Team[],
    itemsList: number[][],
  ): CellRecordMapType {
    const teamSums: CellRecordMapType = {};
    const teamsAmount = teams?.length ?? 0;

    for (let i = 0; i < teamsAmount; i++) {
      const curTeamId = teams[i].teamId;

      let teamSum = 0;

      for (let j = 0; j < teamsAmount; j++) {
        teamSum += isHome ? itemsList[i][j] : itemsList[j][i];
      }

      teamSums[curTeamId] = teamSum;
    }

    return teamSums;
  }

  function MakeOpponentsTblBody(
    currentStateData: StateData,
    selection: SelectionType,
  ): JSX.Element {
    let body: JSX.Element;
    const teams = [...(currentStateData.teams ?? [])];

    const sortedTeams = teams.sort((a, b) =>
      a.code.localeCompare(b.code, 'en', { numeric: true }),
    );
    const { opponents } = currentStateData;
    const teamsAmount = sortedTeams.length ?? 0;

    const horizontalTeamSumsForHome: CellRecordMapType = FillTeamSums(
      true,
      sortedTeams,
      selection === 'min' ? opponents.minItemsList : opponents.maxItemsList,
    );
    const verticalTeamSumsForOpp: CellRecordMapType = FillTeamSums(
      false,
      sortedTeams,
      selection === 'min' ? opponents.minItemsList : opponents.maxItemsList,
    );

    const getLastCellText = (
      teamId: string,
      direction: string,
    ): number | string => {
      if (selectionType === 'both') {
        const horizontalTeamMinSumsForHome: CellRecordMapType = FillTeamSums(
          true,
          sortedTeams,
          opponents.minItemsList,
        );
        const horizontalTeamMaxSumsForHome: CellRecordMapType = FillTeamSums(
          true,
          sortedTeams,
          opponents.maxItemsList,
        );
        const verticalTeamMinSumsForOpp: CellRecordMapType = FillTeamSums(
          false,
          sortedTeams,
          opponents.minItemsList,
        );
        const verticalTeamMaxSumsForOpp: CellRecordMapType = FillTeamSums(
          false,
          sortedTeams,
          opponents.maxItemsList,
        );

        if (
          (direction === 'right' && orientation === 'default') ||
          (direction !== 'right' && orientation !== 'default')
        ) {
          return `${horizontalTeamMinSumsForHome[teamId]}/${horizontalTeamMaxSumsForHome[teamId]}`;
        }
        if (
          (direction === 'right' && orientation !== 'default') ||
          (direction !== 'right' && orientation === 'default')
        ) {
          return `${verticalTeamMinSumsForOpp[teamId]}/${verticalTeamMaxSumsForOpp[teamId]}`;
        }
      }

      if (
        (direction === 'right' && orientation === 'reverse') ||
        (direction !== 'right' && orientation !== 'reverse')
      ) {
        return horizontalTeamSumsForHome[teamId];
      }
      if (
        (direction === 'right' && orientation !== 'reverse') ||
        (direction !== 'right' && orientation === 'reverse')
      ) {
        return verticalTeamSumsForOpp[teamId];
      }

      return '';
    };

    const teamsRowData: TeamRowDataType = useMemo(() => {
      const dataMap: TeamRowDataType = {};
      const { minItemsList, maxItemsList } = opponents;
      sortedTeams.forEach((teamHome) => {
        dataMap[teamHome.teamKey] = [];

        sortedTeams.forEach((teamAway) => {
          const combinedKey = `${teamHome.teamKey}-${teamAway.teamKey}`;
          const teamHomeInd = sortedTeams.findIndex(
            (t) => t.teamId === teamHome.teamId,
          );
          const teamAwayInd = sortedTeams.findIndex(
            (t) => t.teamId === teamAway.teamId,
          );

          const itemsList =
            selectionType === 'min' ? minItemsList : maxItemsList;
          let minGamesAmount = 0;
          let maxGamesAmount = 0;

          if (orientation === 'reverse') {
            minGamesAmount = minItemsList[teamHomeInd][teamAwayInd];
            maxGamesAmount = maxItemsList[teamHomeInd][teamAwayInd];
          } else {
            minGamesAmount = minItemsList[teamAwayInd][teamHomeInd];
            maxGamesAmount = maxItemsList[teamAwayInd][teamHomeInd];
          }

          const gamesAmt =
            orientation === 'reverse'
              ? itemsList[teamHomeInd][teamAwayInd]
              : itemsList[teamAwayInd][teamHomeInd];

          let cellText = `${gamesAmt}`;

          if (selectionType === 'both') {
            cellText = `${minGamesAmount} / ${maxGamesAmount}`;
          }

          dataMap[teamHome.teamKey].push({
            combinedKey,
            teamHomeInd,
            teamAwayInd,
            itemsList,
            minGamesAmount,
            maxGamesAmount,
            gamesAmt,
            lastCellText: getLastCellText(teamHome.teamId, 'right'),
            teamAway,
            cellText,
            selectionType,
            orientation,
          });
        });
      });

      const itemsListInState =
        selectionType === 'min' ? minItemsList : maxItemsList;

      let newItemList: number[][] = JSON.parse(
        JSON.stringify(itemsListInState),
      );

      if (orientation === 'default') {
        // @ts-ignore
        newItemList = _.zip(...newItemList);
      }

      invalidCellIndexMap.current = {};

      newItemList.forEach((row, rowIndex) => {
        row.forEach((col, colIndex) => {
          if (selectionType === 'min') {
            if (
              minItemsList[rowIndex][colIndex] >
              maxItemsList[rowIndex][colIndex]
            ) {
              invalidCellIndexMap.current[`${rowIndex}-${colIndex}`] = true;
            }
          } else if (
            maxItemsList[rowIndex][colIndex] < minItemsList[rowIndex][colIndex]
          ) {
            invalidCellIndexMap.current[`${rowIndex}-${colIndex}`] = true;
          }
        });
      });

      if (Object.keys(invalidCellIndexMap.current).length > 0) {
        toggleDrawerCloseIconVisibility('hide');
      } else {
        toggleDrawerCloseIconVisibility('show');
      }

      return dataMap;
    }, [sortedTeams, orientation, selectionType, opponents]);

    useEffect(() => {
      if (selectedSolution) {
        setSelectedCellsForOperation({});
      }
    }, [selectedSolution]);

    if (teamsAmount) {
      body = (
        <Dropdown
          menu={{ items }}
          trigger={['contextMenu']}
          dropdownRender={createPopover}
          destroyPopupOnHide
        >
          <tbody>
            {sortedTeams.map((teamHome) =>
              GamesSpinner(
                teamHome,
                openPopOverId,
                setOpenPopOverId,
                selectedCellsForOperation,
                teamsRowData,
                getLastCellText,
                invalidCellIndexMap.current,
              ),
            )}

            <tr className="result-item" key={3}>
              {sortedTeams.map((team) => (
                <td key={team.teamId} className="result-item">
                  {getLastCellText(team.teamId, 'bottom')}
                </td>
              ))}
              <td className="result-item" />
            </tr>
          </tbody>
        </Dropdown>
      );
    } else {
      body = (
        <tbody>
          <tr key={1}>
            <td>{translate('GENERAL.TEAM.EMPTY')}</td>
          </tr>
        </tbody>
      );
    }

    return body;
  }

  useEffect(() => {
    clearAllHighlights();
  }, [selectionType]);

  useEffect(() => {
    initializeTableSelection();

    return () => {
      unInitializeTableSelection();
      delete toBeExecutedWhileMultiSelect.clear_headers;
    };
  }, [selectionType, orientation]);

  useEffect(() => {
    const tableContainer = document.querySelector('.opponent-table');

    const resizeEelements = (cellElement: HTMLDivElement) => {
      const newWidth = cellElement.getBoundingClientRect().width;

      const headerCells: NodeListOf<HTMLDivElement> = document.querySelectorAll(
        '.team-list-item.top',
      );
      for (let i = 0; i < headerCells.length; i++) {
        headerCells[i].style.width = `${newWidth}px`;
      }
    };

    const handleKeypressInTable = (e: Event) => {
      if (e.type === 'resize' && tableContainer) {
        const cellElement: HTMLDivElement =
          tableContainer.querySelector('.opponent')!;

        resizeEelements(cellElement);
      }
    };

    window.addEventListener('resize', handleKeypressInTable);

    const firstRenderedCell: HTMLDivElement = document.querySelector(
      '.opponent-table .opponent',
    )!;

    if (firstRenderedCell) {
      resizeEelements(firstRenderedCell);
    }

    return () => {
      window.removeEventListener('resize', handleKeypressInTable);
    };
  }, [selectionType, orientation]);

  const hanldleClickOutside = (e: React.MouseEvent) => {
    let targetElement = e.target as HTMLDivElement;
    let clickedOutside = true;

    while (targetElement) {
      if (
        targetElement.classList.contains('opponent-table') ||
        targetElement.classList.contains('empty')
      ) {
        clickedOutside = false;
        break;
      }

      targetElement = targetElement.parentElement as HTMLDivElement;
    }

    if (clickedOutside) {
      isEditModeEnabled.value = false;
      clearCellsHighlighting();
    }
  };

  return (
    <div
      className="opponents-grid flex flex-col h-95"
      onClick={hanldleClickOutside}
    >
      <div className="flex justify-between items-center mb-2">
        <span className="font-semibold ml-3">
          {translate('GENERAL.OPPONENT.TITLE', {
            count: 2,
          })}
        </span>

        <OpponentPenalty
          selectionType={selectionType}
          setSelectionType={setSelectionType}
        />
      </div>

      <div className="opponents-wrapper">
        <div
          className="opponents-container"
          style={{
            minWidth: availableTeams.length * 72 + 170,
          }}
          data-testid="opponents-container"
        >
          <div className="opponents-header">
            <div
              className="empty"
              onClick={handleSelectAllCells}
              data-testid="toggle-highlight"
            >
              <span className="swap" onClick={onSwapOrientation}>
                <SwapOutlined />
              </span>
            </div>
            <div className="teams">
              <div className="teams-wrapper">
                <div className="header">
                  {translate(
                    `GENERAL.GAME.${
                      orientation === 'default' ? 'HOME' : 'AWAY'
                    }`,
                  )}
                </div>
                <div className="team-list">
                  {availableTeams.map((team) => (
                    <div
                      key={`${team.teamId}_${team.name}`}
                      className="team-list-item top"
                      data-tkey={team.teamKey}
                    >
                      {team.code}
                    </div>
                  ))}
                </div>
              </div>
              <div className="total">{translate('GENERAL.TOTAL.TITLE')}</div>
            </div>
          </div>
          <div className="opponents-sidebar">
            <div className="header">
              <span>
                {translate(
                  `GENERAL.GAME.${orientation === 'default' ? 'AWAY' : 'HOME'}`,
                )}
              </span>
            </div>
            <div className="team-list">
              {availableTeams.map((team) => (
                <div
                  key={team.teamId}
                  className="team-list-item"
                  data-lkey={team.teamKey}
                >
                  {team.code}
                </div>
              ))}
            </div>
            <div className="total">{translate('GENERAL.TOTAL.TITLE')}</div>
          </div>
          <div className="opponents-content">
            <Table
              className={classNames('opponent-table', {
                'no-action': selectionType === 'both',
              })}
              bordered
              data-testid="opponent-table"
              ref={tableContainerRef}
            >
              {selectedSolution &&
                MakeOpponentsTblBody(selectedSolution.stateData, selectionType)}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
