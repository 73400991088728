import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ScenarioSetLineResponsePromiseType, SetLine } from 'Models/Scenario';
import { QueueCreateUpdateSetLinePayloadType } from 'Models/Queue';
import { saveSetLine, syncSetline } from 'store/slices/scenarioSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { CREATED } from 'utils/variables';
import { useRef, useEffect } from 'react';
import { getNthElement, getScenarioKey } from 'utils/ui-helper';
import { useAppDispatch } from '../hooks';

export const useSetLine = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareSetLinePayload = ({
    data,
    toBeUpdatedFields,
  }: QueueCreateUpdateSetLinePayloadType): SetLine => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (newPayload.setKey && queueLocalKeyMapRef.current[newPayload.setKey]) {
      newPayload.setKey = queueLocalKeyMapRef.current[newPayload.setKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.setLineKey]) {
      newPayload.setLineKey =
        queueLocalKeyMapRef.current[newPayload.setLineKey];
    }

    if (newPayload.teamId && queueLocalKeyMapRef.current[newPayload.teamId]) {
      newPayload.teamId = getNthElement(
        queueLocalKeyMapRef.current[newPayload.teamId],
        4,
      );
    }

    if (
      newPayload.opponentTeamId &&
      queueLocalKeyMapRef.current[newPayload.opponentTeamId]
    ) {
      newPayload.opponentTeamId = getNthElement(
        queueLocalKeyMapRef.current[newPayload.opponentTeamId],
        4,
      );
    }

    if (newPayload.venueId && queueLocalKeyMapRef.current[newPayload.venueId]) {
      newPayload.venueId = getNthElement(
        queueLocalKeyMapRef.current[newPayload.venueId],
        4,
      );
    }

    if (
      newPayload.slotTypeId &&
      queueLocalKeyMapRef.current[newPayload.slotTypeId]
    ) {
      newPayload.slotTypeId = getNthElement(
        queueLocalKeyMapRef.current[newPayload.slotTypeId],
        4,
      );
    }

    if (
      newPayload.networkId &&
      queueLocalKeyMapRef.current[newPayload.networkId]
    ) {
      newPayload.networkId = getNthElement(
        queueLocalKeyMapRef.current[newPayload.networkId],
        4,
      );
    }

    if (
      newPayload.networkCategoryId &&
      queueLocalKeyMapRef.current[newPayload.networkCategoryId]
    ) {
      newPayload.networkCategoryId = getNthElement(
        queueLocalKeyMapRef.current[newPayload.networkCategoryId],
        5,
      );
    }

    return newPayload;
  };

  const syncSetLineToStore = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
    solutionKey,
  }: QueueCreateUpdateSetLinePayloadType): void => {
    const preparedData = prepareSetLinePayload({
      data,
      toBeUpdatedFields,
      solutionKey,
    });

    dispatch(
      syncSetline({
        data: {
          ...preparedData,
          solutionKey,
          toBeSyncedKey,
        },
        solutionKey,
        toBeSyncedKey,
      }),
    );
  };

  const createOrUpdateSetLineQueue = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
    solutionKey,
  }: {
    data: SetLine;
    toBeUpdatedFields: {
      [key in keyof SetLine]?: SetLine[key];
    };
    toBeSyncedKey: string;
    solutionKey: string;
  }): Promise<ScenarioSetLineResponsePromiseType | null> => {
    const preparedData = prepareSetLinePayload({
      data,
      toBeUpdatedFields,
    });

    const scenarioKey = data.solutionKey
      ? getScenarioKey(data.solutionKey)
      : solutionKey;

    if (!preparedData) return Promise.resolve(null);

    return dispatch(
      saveSetLine({
        payload: {
          setLine: {
            ...preparedData,
            toBeSyncedKey,
          },
          solutionKey: preparedData.solutionKey ?? '',
          scenarioKey,
          toBeSyncedKey,
        },
        solutionStatus: CREATED,
      }),
    ) as Promise<ScenarioSetLineResponsePromiseType>;
  };

  return {
    prepareSetLinePayload,
    syncSetLineToStore,
    createOrUpdateSetLineQueue,
  };
};

export default useSetLine;
