import _ from 'lodash';
import i18n from 'plugins/i18next';
import api from './apiClient';
import { getErrorMsg } from './ServiceHelper';

import {
  Scenario,
  Solution,
  ScenarioMetaData,
  ModelSummary,
  Constraint,
  Set,
  SolveData,
  RoundCreatePayload,
  RoundCreateResponseType,
  RoundUpdatePayload,
  SlotUpdatePayload,
  TimeSlot,
  CreateOrUpdateSlotTypePayload,
  SetLine,
  GamesLockPayload,
  RoundWeekStartDayPayload,
  LogMessagesPayload,
  LogMessagesResponse,
} from 'Models/Scenario';

import { SolveType } from 'Models/SolveTypes';
import { SolverParameterBase } from 'Models/SolverParameter';
import { message } from 'antd';
import { FileExport } from 'Models/FileExport';
import { SlotType } from 'Models/Slot';
import { NEW } from 'utils/variables';

export interface SolveDataApiPayload {
  scenarioKey: string;
  solutionKey: string;
  solveDataKey: string;
  unlockAllCells?: boolean;
}

export interface SolutionApiPayload {
  scenarioKey: string;
  solutionKey: string;
}

interface SetUpdateRequest {
  set: Set;
}

interface SetLineUpdateRequest {
  setLine: SetLine;
}

export const Ping = async (): Promise<string> => {
  try {
    const response = await api.get('ping');

    return response.data as string;
  } catch (err) {
    throw getErrorMsg(err, 'Ping Server');
  }
};

const prepareDateFields = (solution: Solution): void => {
  solution.stateData.roundTemplates.forEach((rt) => {
    rt.startDate = new Date(rt.startDate);
    rt.endDate = new Date(rt.endDate);
  });

  solution.createTime = new Date(solution.createTime);
};

export const FetchScenario = async (
  season: number | null,
  scenarioKey: string | null,
): Promise<Scenario | null> => {
  const uri = `scenario/${season}/${scenarioKey}`;

  if (!season || season.toString() === '[object Object]' || !scenarioKey) {
    /* eslint-disable no-console */
    console.warn('Skip FetchScenario.');
    return null;
  }

  try {
    const response = await api.get(uri);

    const scenario = response.data as Scenario;

    scenario.optimizationEnvelop.solutions.forEach((sol) =>
      prepareDateFields(sol),
    );

    return scenario;
  } catch (err: unknown) {
    throw getErrorMsg(err, 'Get Scenario');
  }
};

export const FetchScenarioMeta = async (
  scenarioKey: string | null,
): Promise<Solution[] | null> => {
  const uri = `scenarios/${scenarioKey}/solution-metadatas`;

  try {
    const response = await api.get(uri);

    const scenarioMeta = response.data.solutions as Solution[];

    return scenarioMeta;
  } catch (err: unknown) {
    throw getErrorMsg(err, 'Get Scenario meta');
  }
};

export const FetchSolution = async (
  scenarioKey: string | null,
  solutionKey: string | null,
): Promise<Solution | null> => {
  const uri = `scenarios/${scenarioKey}/solutions/${solutionKey}`;

  try {
    const response = await api.get(uri);

    const solution = response.data as Solution;
    prepareDateFields(solution);

    return solution;
  } catch (err: unknown) {
    throw getErrorMsg(err, 'Get Solution');
  }
};

export const FetchModelSummary = async (
  solveDataKey: string | null,
): Promise<ModelSummary | null> => {
  const uri = `scenario/solution/${solveDataKey}/modelSummary`;

  if (!solveDataKey) {
    /* eslint-disable no-console */
    console.warn('Skip FetchModelSummary.');
    return null;
  }

  try {
    const response = await api.get(uri);

    const modelSummary = response.data as ModelSummary;

    return modelSummary;
  } catch (err: unknown) {
    throw getErrorMsg(err, 'Get ModelSummary');
  }
};

export const FetchScenariosMetaData = async (
  season: number | null,
): Promise<ScenarioMetaData[]> => {
  try {
    if (!season) {
      /* eslint-disable no-console */
      console.warn('Skip FetchScenariosNames.');
      return [];
    }

    const response = await api.get(`scenario/${season}`);

    const scenarios = response.data.scenarios as ScenarioMetaData[];

    const scenarioMetaDatas = scenarios.map((sc) => sc as ScenarioMetaData);

    return scenarioMetaDatas;
  } catch (err) {
    throw getErrorMsg(err, 'Get Scenarios Names');
  }
};

export const FetchLogMessages = async (
  payload: LogMessagesPayload,
): Promise<LogMessagesResponse | null> => {
  const { solutionKey, logMessageType } = payload;
  try {
    const response = await api.get(
      `scenario/solution/${solutionKey}/log-messages/${logMessageType}`,
    );

    return response.data as LogMessagesResponse;
  } catch (err: any) {
    throw getErrorMsg(err, 'FetchLogMessages');
  }
};

export const PostCreateScenario = async (
  scenario: ScenarioMetaData | null,
): Promise<Scenario | null> => {
  if (
    !scenario ||
    !scenario.season ||
    scenario.season.toString() === '[object Object]'
  ) {
    /* eslint-disable no-console */
    console.warn('Skip postCreateScenario.');
    return null;
  }

  try {
    const response = await api.post('scenario', scenario);

    return response.data;
  } catch (err: any) {
    throw getErrorMsg(err, 'Create Scenario');
  }
};

export const PostGamesLock = async (
  payload: GamesLockPayload,
): Promise<SolveData | null> => {
  const {
    scenarioKey,
    solutionKey,
    solveDataKey,
    lockType,
    columnShortIds,
    solutionStatus,
  } = payload;

  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  if (!columnShortIds.length) {
    console.log('columnShortIds.length = 0. DO NOTHING.');
    return null;
  }

  try {
    const requestBody = { columnShortIds: columnShortIds };
    const response = await api.post(
      `scenario/${scenarioKey}/solution/${solutionKey}/solveDataKey/${solveDataKey}/gamesLock/${lockType}`,
      requestBody,
    );

    return response.data.solveData as SolveData;
  } catch (err: any) {
    throw getErrorMsg(err, 'PostGamesLock');
  }
};

export const PostCreateOrUpdateConstraint = async (
  constraint: Constraint,
  solutionStatus: string | null | undefined,
): Promise<Constraint> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    interface ConstraintUpdateRequest {
      constraint: Constraint;
    }

    const request: ConstraintUpdateRequest = {
      constraint,
    };

    const response = await api.post(
      `scenario/solution/${constraint.solutionKey}/constraint`,
      request,
    );

    return response.data.constraint as Constraint;
  } catch (err: any) {
    throw getErrorMsg(err, 'CreateOrUpdateConstraint');
  }
};

export const PostCreateOrUpdateSet = async (
  set: Set,
  solutionStatus: string | null | undefined,
): Promise<Set> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    const request: SetUpdateRequest = {
      set,
    };

    const response = await api.post(
      `scenario/solution/${set.solutionKey}/set`,
      request,
    );

    return response.data.set as Set;
  } catch (err: any) {
    throw getErrorMsg(err, 'CreateOrUpdateSet');
  }
};

export const PostCreateOrUpdateSetLine = async (
  payload: {
    setLine: SetLine;
    solutionKey: string;
    scenarioKey: string;
  },
  solutionStatus: string | null | undefined,
): Promise<SetLine> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    const request: SetLineUpdateRequest = {
      setLine: payload.setLine,
    };

    let setKey = payload.setLine.setKey;

    if (!setKey && payload.setLine.setLineKey !== NEW) {
      setKey = payload.setLine.setLineKey.split('-').slice(0, -1).join('-');
    }

    const response = await api.post(
      `scenarios/${payload.scenarioKey}/solutions/${payload.solutionKey}/sets/${setKey}/set-lines`,
      request,
    );

    return response.data.setLine as SetLine;
  } catch (err: any) {
    throw getErrorMsg(err, 'CreateOrUpdateSetLine');
  }
};

export const PutUpdateScenario = async (
  scenario: ScenarioMetaData | null,
): Promise<Scenario | null> => {
  if (
    !scenario ||
    !scenario.season ||
    scenario.season.toString() === '[object Object]' ||
    !scenario.scenarioKey
  ) {
    /* eslint-disable no-console */
    console.log('Skip putUpdateScenario.');
    throw Error('PutUpdateScenario received wrong param');
    // return null;
  }

  try {
    const response = await api.put(
      `scenario/${scenario.scenarioKey}`,
      scenario,
    );

    const scenarioData = response.data as Scenario;

    scenarioData.optimizationEnvelop.solutions.forEach((sol) =>
      prepareDateFields(sol),
    );

    return scenarioData;
  } catch (err: any) {
    throw getErrorMsg(err, 'Put Updated Scenario');
  }
};

export const PostCloneScenario = async (
  scenarioMetaData: ScenarioMetaData | null,
): Promise<ScenarioMetaData | null> => {
  if (
    !scenarioMetaData ||
    !scenarioMetaData.season ||
    scenarioMetaData.season.toString() === '[object Object]' ||
    !scenarioMetaData.scenarioKey
  ) {
    /* eslint-disable no-console */
    console.warn('Skip postCloneScenario.');
    return null;
  }

  try {
    const response = await api.post(
      `scenario/${scenarioMetaData.scenarioKey}`,
      scenarioMetaData,
    );

    return response.data;
  } catch (err: any) {
    const error = getErrorMsg(err, 'Clone Scenario');
    throw error;
  }
};

export const CloneScenarioWithSolution = async (
  payload: SolutionApiPayload,
): Promise<Scenario | null> => {
  try {
    const requestDto = {};

    const response = await api.post(
      `scenario/${payload.scenarioKey}/solution/${payload.solutionKey}/clone-scenario`,
      requestDto,
    );

    return response.data as Scenario;
  } catch (err: any) {
    const error = getErrorMsg(err, 'CreateScenarioWithSolution');
    throw error;
  }
};

export const CloneScenarioWithSolveData = async (
  payload: SolveDataApiPayload,
): Promise<Scenario | null> => {
  try {
    const requestDto = {};

    const response = await api.post(
      `scenario/${payload.scenarioKey}/solution/${payload.solutionKey}/solve-data/${payload.solveDataKey}/clone-scenario`,
      requestDto,
    );

    return response.data as Scenario;
  } catch (err: any) {
    throw getErrorMsg(err, 'CreateScenarioWithSolveData');
  }
};

export const CloneSolveDataToNewSolution = async (
  payload: SolveDataApiPayload,
): Promise<Solution | null> => {
  try {
    const requestDto = {};

    const { scenarioKey, solutionKey, solveDataKey, unlockAllCells } = payload;

    let uri = `scenario/${scenarioKey}/solution/${solutionKey}/solve-data/${solveDataKey}/clone`;

    if (unlockAllCells !== undefined) {
      uri += `?unlockAllCells=${unlockAllCells}`;
    }

    const response = await api.post(uri, requestDto);

    const solution = response.data as Solution;
    prepareDateFields(solution);

    return solution;
  } catch (err: any) {
    throw getErrorMsg(err, 'CloneSolveDataToNewSolution');
  }
};

export const CloneSolution = async (
  payload: SolutionApiPayload,
): Promise<Solution | null> => {
  try {
    const requestDto = {};

    const response = await api.post(
      `scenario/${payload.scenarioKey}/solution/${payload.solutionKey}/clone`,
      requestDto,
    );

    const solution = response.data as Solution;
    prepareDateFields(solution);

    return solution;
  } catch (err: any) {
    throw getErrorMsg(err, 'CloneSolution');
  }
};

export const DeleteSolution = async (
  payload: SolutionApiPayload,
): Promise<Solution | null> => {
  try {
    const response = await api.delete(
      `scenario/${payload.scenarioKey}/solution/${payload.solutionKey}`,
    );

    return response.data as Solution;
  } catch (err: any) {
    throw getErrorMsg(err, 'CloneSolution');
  }
};

export const DeleteSolveDataFromSolution = async (
  payload: SolveDataApiPayload,
): Promise<SolveData | null> => {
  try {
    const requestDto = {};

    const response = await api.delete(
      `scenario/${payload.scenarioKey}/solution/${payload.solutionKey}/solve-data/${payload.solveDataKey}`,
      requestDto,
    );

    return response.data.solveData as SolveData;
  } catch (err: any) {
    throw getErrorMsg(err, 'CloneSolveDataToNewSolution');
  }
};

export const PostSolveScenario = async (
  solutionKey: string | null,
  solveType: SolveType,
  solverParameters: SolverParameterBase[],
): Promise<Solution | null> => {
  if (!solutionKey) {
    console.warn('Skip postSolveScenario.');
    return null;
  }

  try {
    const dto = {
      solutionKey: solutionKey,
      solverParameters: solverParameters,
    };

    const response = await api.post(
      `scenario/solution/${solutionKey}/solve/${solveType}`,
      dto,
    );

    const solution = response.data as Solution;
    prepareDateFields(solution);

    return solution;
  } catch (err: any) {
    throw getErrorMsg(err, 'Solve Scenario');
  }
};

export const PostSolveScenarioStop = async (
  solutionKey: string | null,
): Promise<void> => {
  if (!solutionKey) {
    console.warn('Skip postSolveScenarioStop.');
    return;
  }

  try {
    const response = await api.post(
      `scenario/solution/${solutionKey}/solve-stop`,
    );

    return;
  } catch (err: any) {
    throw getErrorMsg(err, 'Solve Scenario stop');
  }
};

export const DeleteScenario = async (
  scenarioKey: string | null,
): Promise<void> => {
  try {
    if (!scenarioKey) {
      /* eslint-disable no-console */
      console.warn('Skip DeleteScenario.');
      return;
    }

    const response = await api.delete(`scenario/${scenarioKey}`);
  } catch (err) {
    throw getErrorMsg(err, 'Delete Scenario');
  }
};

export const ExportSolveData = async (
  scenarioKey: string,
  solutionKey: string,
  solveDataKey: string,
): Promise<FileExport> => {
  try {
    const response = await api.get(
      `scenario/${scenarioKey}/solution/${solutionKey}/solve-data/${solveDataKey}/export`,
    );
    return response.data as FileExport;
  } catch (err) {
    throw getErrorMsg(err, 'Export SolveData');
  }
};

export const ExportModelSummary = async (
  scenarioKey: string,
  solutionKey: string,
  solveDataKey: string,
): Promise<any> => {
  try {
    const response = await api.get(
      `scenario/${scenarioKey}/solution/${solutionKey}/solve-data/${solveDataKey}/model-summary/export`,
    );
    return response.data;
  } catch (err) {
    throw getErrorMsg(err, 'Export ModelSummary');
  }
};

export const PostCreateRound = async (
  payload: RoundCreatePayload,
  solutionStatus: string | null | undefined,
): Promise<RoundCreateResponseType | null> => {
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    const response = await api.post(
      `scenario/${payload.scenarioKey}/solution/${payload.solutionKey}/round-templates`,
      {
        ...payload.data,
        actualData: [],
        toBeSyncedKeys: [],
      },
    );

    const rounds = response.data as RoundCreateResponseType;

    return rounds;
  } catch (err: any) {
    throw getErrorMsg(err, 'CreateRound');
  }
};

export const PutUpdateRound = async (
  payload: RoundUpdatePayload,
  solutionStatus: string | null | undefined,
): Promise<RoundCreateResponseType | null> => {
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    const response = await api.put(
      `scenario/${payload.scenarioKey}/solution/${payload.solutionKey}/round-templates`,
      payload.data,
    );

    const rounds = response.data as RoundCreateResponseType;

    return rounds;
  } catch (err: any) {
    throw getErrorMsg(err, 'Update round');
  }
};

export const CreateOrUpdateSlotType = async (
  payload: CreateOrUpdateSlotTypePayload,
  solutionStatus: string | null | undefined,
): Promise<CreateOrUpdateSlotTypePayload | null> => {
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    const response = await api.post(
      `scenario/${payload.scenarioKey}/solution/${payload.solutionKey}/slot-types`,
      {
        slotType: payload.slotType,
      },
    );

    const slotType = response.data.slotType as SlotType;

    return {
      slotType,
      scenarioKey: payload.scenarioKey,
      solutionKey: payload.solutionKey,
    };
  } catch (err: any) {
    throw getErrorMsg(err, 'CreateOrUpdateSlotType');
  }
};

export const PostUpdateSlot = async (
  payload: SlotUpdatePayload,
  solutionStatus: string | null | undefined,
): Promise<TimeSlot | null> => {
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    const response = await api.post(
      `scenario/${payload.scenarioKey}/solution/${payload.solutionKey}/round-templates/${payload.data.roundTemplateKey}/round-days/${payload.data.roundDayKey}/time-slots`,
      payload.data,
    );

    const slot = response.data.timeSlot as TimeSlot;

    return slot;
  } catch (err: any) {
    throw getErrorMsg(err, 'Update slot');
  }
};

export const PostUpdateRoundWeekStartDay = async (
  payload: RoundWeekStartDayPayload,
  solutionStatus: string | null | undefined,
): Promise<{} | null> => {
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    const response = await api.post(
      `scenario/solution/${payload.solutionKey}/round-template-week-start-day`,
      {
        RoundTemplateWeekStartDay: payload.data.roundTemplateWeekStartDay,
      },
    );

    return response.data as {};
  } catch (err: any) {
    throw getErrorMsg(err, 'Update round');
  }
};
