import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Constraint, Set } from 'Models/Scenario';
import {
  QueueActionType,
  QueueCreateUpdateConstraintPayloadType,
} from 'Models/Queue';
import { useTranslation } from 'react-i18next';
import { ColDef } from 'ag-grid-community';
import { Close } from 'Components/Elements/Icons';
import useQueue from 'storeHooks/useQueue';
import useConstraint from 'storeHooks/queue/useConstraint';
import useSolution from 'customHooks/useSolution';
import { SYNC_CONSTRAINT_KEY_PREFIX } from 'utils/variables';
import { useSelector } from 'react-redux';
import { selectConstraints, selectSets } from 'store/slices/scenarioSlice';
import SetGroup from '../SetGroup/SetGroup';
import SetLabel from '../SetGroup/SetLine/SetLabel';
import '../style.scss';

const NEW_SET = 'trigger-new-set';

interface Props {
  data: Constraint;
  colDef: ColDef;
}

export const handleCreateOrUpdate = async (
  constraint: Constraint,
  field: string,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  onEnqueue: (data: QueueActionType) => void,
  syncConstraintToStore: (data: QueueCreateUpdateConstraintPayloadType) => void,
  localSolutionKey: string,
  solutionKey: string,
) => {
  setValue(value);

  if (constraint) {
    const toBeSyncedKey = `${SYNC_CONSTRAINT_KEY_PREFIX}${uuidv4()}`;

    syncConstraintToStore({
      data: {
        ...constraint,
        solutionKey: localSolutionKey,
      },
      toBeUpdatedFields: {
        [field]: value,
      },
      toBeSyncedKey,
    });
    onEnqueue({
      type: 'CREATE_UPDATE_CONSTRAINT',
      payload: {
        data: {
          ...constraint,
          solutionKey,
        },
        toBeUpdatedFields: {
          [field]: value,
        },
        toBeSyncedKey,
        solutionKey: localSolutionKey,
      },
    });
  }
};

function SelectSetRenderer({ data, colDef }: Props) {
  const { t } = useTranslation();
  const { field } = colDef as { field: string };
  const allConstraints = useSelector(selectConstraints);
  const allSets = useSelector(selectSets);
  const [isSetGroupDrawerOpen, setIsSetGroupDrawerOpen] = useState(false);
  const { onEnqueue } = useQueue();
  const { syncConstraintToStore } = useConstraint();
  const { getCurrentOrClonedSolution } = useSolution();

  const constraints: Constraint[] = useMemo(
    () => allConstraints ?? [],
    [allConstraints],
  );

  const constraint: Constraint | null = useMemo(() => {
    const item = constraints.find(
      (x) => x.constraintKey === data.constraintKey,
    );
    return item || null;
  }, [constraints]);

  const sets: Set[] = useMemo(() => allSets ?? [], [allSets]);

  const toggleSetGroupDrawer = () => {
    setIsSetGroupDrawerOpen((v) => !v);
  };

  const items: MenuProps['items'] = useMemo(
    () => [
      { key: NEW_SET, label: t('GENERAL.SET.NEW') },

      ...sets.map((x) => ({
        key: x.setId,
        label: <SetLabel label={x.name} setKey={x.setKey} />,
      })),
    ],
    [sets],
  );

  // @ts-expect-error
  const [value, setValue] = useState(constraint ? constraint[field] : '');

  const selected = useMemo(() => {
    if (value) {
      const selectedSet = sets.find((item) => item.setId === value);

      if (selectedSet) {
        return selectedSet.name;
      }
    }

    return t('GENERAL.SELECT');
  }, [sets, value]);

  const handleChange = ({ key }: { key: string }) => {
    if (key === NEW_SET) {
      toggleSetGroupDrawer();
      return;
    }

    const selectedSetId = key.includes('sub-') ? key.split('-')[1] : key;

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution || !constraint) return;
    const { solutionKey } = chosenSolution.stateData;

    handleCreateOrUpdate(
      constraint,
      field,
      selectedSetId,
      setValue,
      onEnqueue,
      syncConstraintToStore,
      chosenSolution.solutionKey,
      solutionKey,
    );
  };

  useEffect(() => {
    if (constraint) {
      // @ts-expect-error
      setValue(constraint[field]);
    }
  }, [constraint]);

  if (isSetGroupDrawerOpen) {
    return (
      <Drawer
        title={t('GENERAL.SET.CREATE')}
        placement="bottom"
        closable={false}
        open
        width={680}
        extra={
          <div className="icon" onClick={toggleSetGroupDrawer}>
            <Close />
          </div>
        }
      >
        <SetGroup />
      </Drawer>
    );
  }

  return (
    <div data-testid="select-set-renderer">
      <Dropdown
        menu={{
          items,
          selectable: true,
          onSelect: handleChange,
        }}
        trigger={['click']}
        destroyPopupOnHide
      >
        <div className="flex justify-between items-center pr-3">
          <span className="cursor-pointer">{selected}</span>
          <DownOutlined className="carret-down" />
        </div>
      </Dropdown>
    </div>
  );
}

export default SelectSetRenderer;
