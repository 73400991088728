import { v4 as uuidv4 } from 'uuid';
import React, { useMemo, useRef, useCallback } from 'react';
import { Button, message } from 'antd';
import { Plus } from 'Components/Elements/Icons';
import useLocale from 'locales/localeMapGrid';
import { AgGridReact } from 'ag-grid-react';
import { NameMap, Set, SetLine, Solution } from 'Models/Scenario';
import i18n from 'plugins/i18next';
import showMessage from 'Components/Elements/Common/Message';
import DeleteConfirm from 'Components/Elements/Common/DeleteConfirm';
import useQueue from 'storeHooks/useQueue';
import useSetLine from 'storeHooks/queue/useSetLine';
import useSolution from 'customHooks/useSolution';
import { useSelector } from 'react-redux';
import { decodeHTML } from 'utils/ui-helper';
import {
  selectTeams,
  selectSets,
  selectVenues,
  selectRoundTemplates,
  selectNetworks,
  selectSlotTypes,
} from 'store/slices/scenarioSlice';
import { selectShoudDeleteConfirmation } from 'store/slices/userSlice';
import {
  QueueActionType,
  QueueCreateUpdateSetLinePayloadType,
} from 'Models/Queue';
import {
  ALL,
  AWAY,
  HOME,
  BYE,
  CREATED,
  NEW,
  SYNC_SETLINE_KEY_PREFIX,
} from 'utils/variables';
import { copySetLine, deleteSetLine } from './ActionCellRenderer';
import '../../style.scss';
import { Venue } from 'Models/Venue';
import { Team } from 'Models/Team';

interface Props {
  selectedSetKey: string;
}

interface CellParamType {
  data: SetLine;
}

const validator: Record<
  string,
  { min: number; max: number; required: boolean }
> = {
  seqNo: {
    min: 1,
    max: 50,
    required: true,
  },
};

const validate = (field: string, value: number) => {
  if (!validator[field]) return value;

  return Math.min(
    Math.max(Math.floor(value), validator[field].min),
    validator[field].max,
  );
};

export const handleCreateEmptySetLine = async (
  selectedSolution: Solution | null,
  set: Set | null,
  onEnqueue: (data: QueueActionType) => void,
  syncSetLineToStore: (data: QueueCreateUpdateSetLinePayloadType) => void,
) => {
  if (!selectedSolution) return;

  if (!set) {
    showMessage({
      key: 'GENERAL.SET_LINE.UPDATED',
      type: 'success',
      content: i18n.t('GENERAL.CONSTRAINT.ERRORS.CREATE_A_SET_FIRST'),
    });

    return;
  }

  const { solutionKey } = selectedSolution;

  const toBeSyncedKey = `${SYNC_SETLINE_KEY_PREFIX}${uuidv4()}`;

  const dataPayload = {
    setLineKey: toBeSyncedKey,
    setKey: set.setKey,
    gameType: ALL,
    seqNo: 1,
    roundNumber: null,
    setLineStatus: CREATED,
    teamId: null,
    opponentTeamId: null,
    weekDay: null,
    slotTypeId: null,
    networkId: null,
    networkCategoryId: null,
    venueId: null,
    solutionKey,
  };

  syncSetLineToStore({
    data: dataPayload as SetLine,
    toBeSyncedKey,
    solutionKey,
    setKey: set.setKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_SETLINE',
    payload: {
      data: {
        ...dataPayload,
        setLineStatus: NEW,
        solutionKey: selectedSolution.stateData.solutionKey,
      } as SetLine,
      toBeSyncedKey,
      solutionKey,
    },
  });
};

function SetLineList({ selectedSetKey }: Props): JSX.Element {
  const allTeams = useSelector(selectTeams);
  const allVenues = useSelector(selectVenues);
  const allSets = useSelector(selectSets);
  const roundTemplates = useSelector(selectRoundTemplates);
  const networks = useSelector(selectNetworks);
  const slotTypes = useSelector(selectSlotTypes);
  const showDeleteConfirmation = useSelector(selectShoudDeleteConfirmation);
  const { onEnqueue } = useQueue();
  const { syncSetLineToStore } = useSetLine();
  const { getCurrentOrClonedSolution } = useSolution();
  const setRef = useRef<Set | null>(null);

  const { setLine, all: allOptions } = useLocale();
  const {
    dayOptions: { mon, tue, wed, thu, fri, sat, sun },
  } = setLine;

  const venuesMap = useMemo(() => {
    if (allVenues) {
      return allVenues.reduce((acc: NameMap, venue: Venue) => {
        acc[venue.venueId] = decodeHTML(venue.name);

        return acc;
      }, {});
    }
    return {};
  }, [allVenues]);

  const teamsMap = useMemo(() => {
    if (allTeams) {
      return allTeams.reduce((acc: NameMap, venue: Team) => {
        acc[venue.teamId] = decodeHTML(venue.name);

        return acc;
      }, {});
    }
    return {};
  }, [allTeams]);

  teamsMap['null'] = allOptions;
  venuesMap['null'] = allOptions;

  const {
    title,
    create,
    seqNo,
    gameType,
    teams,
    opponents,
    day,
    slotType,
    network,
    category,
    venue,
    round,
  } = setLine;

  const gameTypeData = {
    all: ALL,
    away: AWAY,
    bye: BYE,
    home: HOME,
  };

  const weekDayData = {
    null: allOptions,
    1: mon,
    2: tue,
    3: wed,
    4: thu,
    5: fri,
    6: sat,
    7: sun,
  };

  const gameTypeDataRef = {
    all: ALL,
    All: ALL,
    away: AWAY,
    Away: AWAY,
    bye: BYE,
    Bye: BYE,
    home: HOME,
    Home: HOME,
  };

  const gridRef = useRef();

  const set = useMemo(() => {
    if (allSets && selectedSetKey) {
      return allSets.find((x) => x.setKey === selectedSetKey) || null;
    }
    return null;
  }, [selectedSetKey, allSets]);

  const setLines: SetLine[] = useMemo(() => {
    if (selectedSetKey && set) {
      setRef.current = set;
      return set.setLines.map((x) => ({ ...x, setKey: selectedSetKey }));
    }
    return [];
  }, [selectedSetKey, set]);

  const roundsMap = useMemo(() => {
    if (roundTemplates) {
      return roundTemplates.reduce(
        (acc, v, i) => {
          acc[(i + 1).toString()] = (i + 1).toString();
          return acc;
        },
        { null: allOptions } as NameMap,
      );
    }
    return {};
  }, [roundTemplates]);

  const networksMap = useMemo(() => {
    if (networks) {
      return networks.reduce(
        (acc, item) => {
          acc[item.networkId] = item.name;
          return acc;
        },
        { null: allOptions } as NameMap,
      );
    }
    return {};
  }, [networks]);

  const slotsMap = useMemo(() => {
    if (slotTypes) {
      return slotTypes.reduce(
        (acc, item) => {
          acc[item.slotTypeId] = item.name;
          return acc;
        },
        {
          null: allOptions,
        } as NameMap,
      );
    }
    return {};
  }, [slotTypes]);

  const networkCategoryMap = useMemo(() => {
    if (networks) {
      return networks
        .flatMap((item) => item.networkCategories)
        .reduce(
          (acc, item) => {
            acc[item.networkCategoryId] = item.name;
            return acc;
          },
          {
            null: allOptions,
          } as NameMap,
        );
    }
    return {};
  }, [networks]);

  const onDeleteSetline = (setlineKey: string) => {
    if (!setRef.current) return;

    if (setLines.length === 1) {
      message.error(i18n.t('GENERAL.SET_LINE.ERRORS.CANNOT_DELETE_ONLY_SET'));
      return;
    }

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;
    const { solutionKey } = chosenSolution.stateData;

    deleteSetLine(
      setRef.current,
      setlineKey,
      onEnqueue,
      syncSetLineToStore,
      chosenSolution.solutionKey,
      solutionKey,
    );
  };

  const columnDefs = useMemo(
    () => [
      {
        field: 'seqNo',
        headerName: seqNo,
        editable: true,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: validator.seqNo.min,
          max: validator.seqNo.max,
        },
        singleClickEdit: true,
        maxWidth: 65,
      },
      {
        field: 'gameType',
        headerName: gameType,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: Object.keys(gameTypeData),
        },
        refData: gameTypeDataRef,
      },
      {
        field: 'teamId',
        headerName: teams,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: Object.keys(teamsMap),
        },
        refData: {
          null: allOptions,
          ...teamsMap,
        },
      },
      {
        field: 'opponentTeamId',
        headerName: opponents,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: Object.keys(teamsMap),
        },
        singleClickEdit: true,
        refData: {
          ...teamsMap,
          null: allOptions,
        },
      },
      {
        field: 'weekDay',
        headerName: day,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: Object.keys(weekDayData),
        },
        singleClickEdit: true,
        refData: weekDayData,
        maxWidth: 65,
      },
      {
        field: 'slotTypeId',
        headerName: slotType,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: Object.keys(slotsMap),
        },
        refData: {
          ...slotsMap,
          null: allOptions,
        },
      },
      {
        field: 'networkId',
        headerName: network,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: Object.keys(networksMap),
        },
        refData: {
          ...networksMap,
          null: allOptions,
        },
      },
      {
        field: 'networkCategoryId',
        headerName: category,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: (params: { data: SetLine }) => {
          const { data } = params;
          let values: string[] = [];

          if (data.networkId && networks) {
            values = networks
              .filter((item) => item.networkId === data.networkId)
              .flatMap((item) => item.networkCategories)
              .map((item) => item.networkCategoryId);
          } else if (networks) {
            values = networks
              .flatMap((item) => item.networkCategories)
              .map((item) => item.networkCategoryId);
          }

          return { values };
        },
        refData: {
          ...networkCategoryMap,
          null: allOptions,
        },
      },
      {
        field: 'venueId',
        headerName: venue,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: Object.keys(venuesMap),
        },
        refData: {
          ...venuesMap,
          null: allOptions,
        },
      },
      {
        field: 'roundNumber',
        headerName: round,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: Object.keys(roundsMap),
        },
        refData: {
          ...roundsMap,
          null: allOptions,
        },
        maxWidth: 80,
      },
      {
        headerName: '',
        headerClass: 'ag-grid-right-header-aligned',
        cellClass: 'clone-icon',
        maxWidth: 40,
        onCellClicked: (event: CellParamType) => {
          const chosenSolution = getCurrentOrClonedSolution();

          if (!chosenSolution || !setRef.current) return;
          const { solutionKey } = chosenSolution.stateData;

          copySetLine(
            setRef.current,
            event.data.setLineKey,
            onEnqueue,
            syncSetLineToStore,
            chosenSolution.solutionKey,
            solutionKey,
          );
        },
      },
      {
        headerName: '',
        headerClass: 'ag-grid-right-header-aligned',
        cellClass: !showDeleteConfirmation ? 'trash-icon' : 'grid-center',
        maxWidth: 40,
        cellRenderer: (event: CellParamType) => {
          if (!showDeleteConfirmation) return null;

          return (
            <DeleteConfirm
              title={i18n.t('GENERAL.FEEDBACK.SETLINE.DELETE_CONFIRM')}
              onConfirm={() => onDeleteSetline(event.data.setLineKey)}
            />
          );
        },
        onCellClicked: (event: CellParamType) => {
          if (showDeleteConfirmation) return;

          onDeleteSetline(event.data.setLineKey);
        },
      },
    ],
    [networks],
  );

  const createEmptySetLine = () => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    handleCreateEmptySetLine(
      chosenSolution,
      set,
      onEnqueue,
      syncSetLineToStore,
    );
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
    }),
    [],
  );

  const onCellEditRequest = useCallback(
    // @ts-expect-error
    async (event) => {
      const oldData = event.data as SetLine;
      const { field } = event.colDef as {
        field: keyof SetLine;
      };
      const { newValue } = event;

      if (!set || String(oldData[field]) === String(newValue)) return;

      const setLineToBeUpdated = set.setLines.find(
        (x) => x.setLineKey === oldData.setLineKey,
      );

      if (newValue !== undefined) {
        const toBeUpdatedFields: QueueCreateUpdateSetLinePayloadType['toBeUpdatedFields'] =
          {};

        if (set && setLineToBeUpdated) {
          const newData = { ...oldData };

          let fieldValue = newValue !== null ? validate(field, newValue) : null;
          if (validator[field]?.required && !fieldValue) {
            fieldValue = validator[field].min;
          }

          // @ts-ignore
          newData[field] = fieldValue;

          if (field === 'networkId') {
            newData['networkCategoryId'] = null;
            toBeUpdatedFields['networkCategoryId'] = null;
          }

          const tx = {
            update: [newData],
          };
          event.api.applyTransaction(tx);

          const toBeSyncedKey = `${SYNC_SETLINE_KEY_PREFIX}${uuidv4()}`;

          // @ts-expect-error
          toBeUpdatedFields[field] = newValue === 'null' ? null : fieldValue;

          const chosenSolution = getCurrentOrClonedSolution();

          if (!chosenSolution) return;

          const { solutionKey } = chosenSolution;

          syncSetLineToStore({
            data: {
              ...setLineToBeUpdated,
              setLineStatus: CREATED,
              setKey: set.setKey,
              solutionKey,
              toBeSyncedKey,
            },
            toBeUpdatedFields,
            toBeSyncedKey,
            solutionKey,
            setKey: set.setKey,
          });

          onEnqueue({
            type: 'CREATE_UPDATE_SETLINE',
            payload: {
              data: {
                ...setLineToBeUpdated,
                setKey: set.setKey,
                solutionKey: chosenSolution.stateData.solutionKey,
                setLineStatus: CREATED,
              },
              toBeUpdatedFields,
              toBeSyncedKey,
              solutionKey: set.solutionKey,
            },
          });
        }
      }
    },
    [set],
  );

  return (
    <div className="set-table">
      <div className="flex justify-between items-center">
        <span className="font-semibold ml-4">{title}</span>
        <Button
          className="create-btn"
          onClick={createEmptySetLine}
          icon={<Plus />}
          data-testid="create-setline-btn"
        >
          {create}
        </Button>
      </div>
      <div
        className="ag-theme-alpine mt-2 ag-grid-curve"
        data-testid="setline-list"
        style={{
          height: 680,
        }}
      >
        {/* @ts-ignore */}
        <AgGridReact
          ref={gridRef}
          rowData={setLines}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellEditRequest={onCellEditRequest}
          getRowId={(params) => params.data.setLineKey}
          animateRows
          readOnlyEdit
          rowSelection="multiple"
          suppressRowClickSelection
          enterNavigatesVerticallyAfterEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  );
}

export default SetLineList;
