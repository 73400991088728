import React, { useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Team } from 'Models/Team';
import useTeamQueue from 'storeHooks/queue/useTeam';
import useSolution from 'customHooks/useSolution';
import useQueue from 'storeHooks/useQueue';
import { SYNC_TEAM_KEY_PREFIX } from 'utils/variables';
import { useSelector } from 'react-redux';
import { selectTeams } from 'store/slices/scenarioSlice';

// @ts-expect-error
export default function TeamLogo({ data }) {
  const allTeams = useSelector(selectTeams);
  const { syncTeamToStore } = useTeamQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const { onEnqueue } = useQueue();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const currentTeam: Team | null = useMemo(() => {
    if (allTeams) {
      return allTeams.find((item) => item.teamKey === data.teamKey)!;
    }

    return null;
  }, [allTeams]);

  const first2Letter = currentTeam
    ? currentTeam.code.substring(0, 2).toUpperCase()
    : 'NA';

  const onOpenUploader = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[e.target.files.length - 1];

      if (selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          if (currentTeam) {
            const toBeSyncedKey = `${SYNC_TEAM_KEY_PREFIX}${uuidv4()}`;

            const chosenSolution = getCurrentOrClonedSolution();

            if (!chosenSolution) return;

            const toBeUpdatedFields = {
              logo: reader.result as string,
            };

            const { solutionKey } = chosenSolution;

            syncTeamToStore({
              data: {
                ...currentTeam,
                solutionKey,
              },
              toBeUpdatedFields,
              toBeSyncedKey,
              solutionKey,
            });

            onEnqueue({
              type: 'CREATE_UPDATE_TEAM',
              payload: {
                data: {
                  ...currentTeam,
                  solutionKey: chosenSolution.stateData.solutionKey,
                  toBeSyncedKey,
                },
                toBeUpdatedFields,
                toBeSyncedKey,
                solutionKey, // local solution key
              },
            });
          }
        };
      }
    }
  };

  return (
    <div>
      {currentTeam && (
        <div className={`${currentTeam.logo ? 'pt-1' : ''}`}>
          {currentTeam.logo ? (
            <img
              className="w-8 h-8 rounded-full cursor-pointer"
              src={currentTeam.logo}
              alt="Rounded avatar"
              onClick={onOpenUploader}
              data-testid="image-holder"
            />
          ) : (
            <div
              className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 cursor-pointer"
              onClick={onOpenUploader}
              data-testid="image-text"
            >
              <span className="font-medium text-gray-600 dark:text-gray-300">
                {first2Letter}
              </span>
            </div>
          )}

          <input
            type="file"
            ref={inputRef}
            className="hidden"
            onChange={onFileSelect}
            accept="image/png, image/jpeg"
            data-testid="input-logo"
          />
        </div>
      )}
    </div>
  );
}
