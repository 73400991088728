import React, { useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Card, Divider, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Team } from 'Models/Team';
import { Solution } from 'Models/Scenario';
import i18n from 'plugins/i18next';
import { QueueActionType } from 'Models/Queue';
import useSolution from 'customHooks/useSolution';
import useQueue from 'storeHooks/useQueue';
import { useTeam as useTeamQueue } from 'storeHooks/queue';
import { SyncNewTeamPayloadType } from 'storeHooks/queue/useTeam';
import {
  CREATED,
  NEW,
  SYNC_TEAM_KEY_PREFIX,
  SYNC_VENUE_KEY_PREFIX,
  SYNC_VENUE_TEAM_KEY_PREFIX,
} from 'utils/variables';
import { Venue } from 'Models/Venue';
import { VenueTeam } from 'Models/VenueTeam';
import './style.scss';

interface Props {
  onClose: () => void;
}

export const handleCreateTeamWithVenues = async (
  count: number,
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncTeamToStore: (data: SyncNewTeamPayloadType) => void,
  syncQueueLocalKeyMap: (key: string, value: string) => void,
) => {
  if (!selectedSolution) return;

  const { teams } = selectedSolution.stateData;
  const { solutionKey: localSolutionKey } = selectedSolution;
  const { solutionKey } = selectedSolution.stateData;
  const usedTeamCodes: string[] = teams.map((team) => team.code.toLowerCase());

  let teamCode = '';
  let teamName = '';
  let venueCode = '';
  let venueName = '';
  let iTeam = 1;

  for (let i = 1; i <= count; i++) {
    do {
      teamCode = `T${iTeam}`;
      teamName = `${i18n.t('GENERAL.TEAM.TITLE')} ${iTeam}`;
      venueCode = `V${iTeam}`;
      venueName = `${i18n.t('GENERAL.VENUE.TITLE')} ${iTeam}`;
      ++iTeam;
    } while (usedTeamCodes.includes(teamCode.toLowerCase()));

    usedTeamCodes.push(teamCode);

    const toBeSyncedTeamKey = `${SYNC_TEAM_KEY_PREFIX}${uuidv4()}`;
    const toBeSyncedVenueKey = `${SYNC_VENUE_KEY_PREFIX}${uuidv4()}`;
    const toBeSyncedVenueTeamKey = `${SYNC_VENUE_TEAM_KEY_PREFIX}${uuidv4()}`;

    syncQueueLocalKeyMap(toBeSyncedTeamKey, '');
    syncQueueLocalKeyMap(toBeSyncedVenueKey, '');

    const newTeam: Team = {
      teamKey: toBeSyncedTeamKey,
      teamId: toBeSyncedTeamKey,
      code: teamCode,
      name: teamName,
      teamStatus: CREATED,
      toBeSyncedVenueKey,
      toBeSyncedKey: toBeSyncedTeamKey,
    };

    const newVenueTeam: VenueTeam = {
      teamIds: [toBeSyncedTeamKey],
      venueId: toBeSyncedVenueKey,
      venueTeamKey: toBeSyncedVenueTeamKey,
      venueTeamStatus: CREATED,
    };

    const newVenue: Venue = {
      venueKey: toBeSyncedVenueKey,
      venueId: toBeSyncedVenueKey,
      code: venueCode,
      name: venueName,
      venueStatus: CREATED,
      toBeSyncedKey: toBeSyncedVenueKey,
    };

    syncTeamToStore({
      team: newTeam,
      venue: newVenue,
      venueTeam: newVenueTeam,
      solutionKey: localSolutionKey,
    });

    onEnqueue({
      type: 'CREATE_UPDATE_TEAM',
      payload: {
        data: {
          ...newTeam,
          teamStatus: NEW,
          solutionKey,
        },
        toBeUpdatedFields: {},
        toBeSyncedKey: toBeSyncedTeamKey,
        solutionKey: localSolutionKey, // local solution key
        toBeSyncedVenueKey,
      },
    });
  }
};

function TeamCreatePopOver({ onClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { syncNewTeamToStore } = useTeamQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const { onEnqueue, syncQueueLocalKeyMap } = useQueue();
  const [count, setCount] = useState(1);

  const enableButton = useMemo(() => {
    if (count > 0) {
      return true;
    }

    return false;
  }, [count]);

  const onCountChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCount(Number(e.currentTarget.value));
  };

  const createTeamsWithVenues = () => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;
    handleCreateTeamWithVenues(
      count,
      chosenSolution,
      onEnqueue,
      syncNewTeamToStore,
      syncQueueLocalKeyMap,
    );
  };

  const onSave = async () => {
    onClose();
    createTeamsWithVenues();
  };

  return (
    <Card
      className="default-pop-over"
      type="inner"
      title={t('GENERAL.TEAM.CREATE', { count: 2 })}
      style={{ width: 400 }}
    >
      <div className="flex justify-between items-center px-4">
        <span>{t('GENERAL.TEAM.TOTAL')}</span>

        <Input
          className="w-40"
          type="number"
          value={count}
          onChange={onCountChange}
          data-testid="team-count"
        />
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-end items-center gap-1 px-4">
        <Button size="small" onClick={onClose}>
          {t('GENERAL.CANCEL')}
        </Button>
        <Button
          size="small"
          type="primary"
          disabled={!enableButton}
          onClick={onSave}
          data-testid="add-team-count"
        >
          {t('GENERAL.TEAM.CREATE', { count: 2 })}
        </Button>
      </div>
    </Card>
  );
}

export default TeamCreatePopOver;
