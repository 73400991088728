import React from 'react';

function ConstraintsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_1781_155938)">
        <path d="M6.25033 3.75002C6.25033 2.59943 7.18307 1.66669 8.33366 1.66669C9.48425 1.66669 10.417 2.59943 10.417 3.75002V5.00002H11.2503C12.4152 5.00002 12.9976 5.00002 13.457 5.19032C14.0696 5.44406 14.5563 5.93074 14.81 6.54331C15.0003 7.00274 15.0003 7.58517 15.0003 8.75002H16.2503C17.4009 8.75002 18.3337 9.68276 18.3337 10.8334C18.3337 11.9839 17.4009 12.9167 16.2503 12.9167H15.0003V14.3334C15.0003 15.7335 15.0003 16.4336 14.7278 16.9683C14.4882 17.4387 14.1057 17.8212 13.6353 18.0609C13.1005 18.3334 12.4005 18.3334 11.0003 18.3334H10.417V16.875C10.417 15.8395 9.57753 15 8.54199 15C7.50646 15 6.66699 15.8395 6.66699 16.875V18.3334H5.66699C4.26686 18.3334 3.5668 18.3334 3.03202 18.0609C2.56161 17.8212 2.17916 17.4387 1.93948 16.9683C1.66699 16.4336 1.66699 15.7335 1.66699 14.3334V12.9167H2.91699C4.06759 12.9167 5.00033 11.9839 5.00033 10.8334C5.00033 9.68276 4.06759 8.75002 2.91699 8.75002H1.66699C1.66699 7.58517 1.66699 7.00274 1.85729 6.54331C2.11103 5.93074 2.59771 5.44406 3.21028 5.19032C3.66971 5.00002 4.25214 5.00002 5.41699 5.00002H6.25033V3.75002Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1781_155938">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ConstraintsIcon;
