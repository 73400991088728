// @ts-nocheck
import React, { useState, useRef } from 'react';
import { Dayjs } from 'dayjs';
import type { DatePickerProps } from 'antd';
import { Card, Divider, Button, DatePicker, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import useRoundCreate from './useRoundCreate';
import './style.scss';

interface Props {
  start?: Dayjs;
  onClose: () => void;
}

const DATE_FORMAT = 'DD MMM YY';
const selectStyle = {
  width: 150,
};

function ScenarioStartDatePopover({ onClose, start }: Props): JSX.Element {
  const { t } = useTranslation();
  const { updateEntireRound } = useRoundCreate();
  const [startDate, setStartDate] = useState(start);
  const [count, setCount] = useState(1);
  const [duration, setDuration] = useState('year');
  const lastChanged = useRef('input');

  const timeOptions = [
    {
      label: t('GENERAL.TIME.YEAR'),
      value: 'year',
    },
    {
      label: t('GENERAL.TIME.MONTH'),
      value: 'months',
    },
    {
      label: t('GENERAL.TIME.WEEK'),
      value: 'weeks',
    },
  ];

  const onStartChange: DatePickerProps['onChange'] = (date) => {
    lastChanged.current = 'datepicker';

    setStartDate(date);
  };

  const onTimeChange = (v: string) => {
    lastChanged.current = 'input';
    setDuration(v);
  };

  const onCountChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCount(Number(e.currentTarget.value));
  };

  const onSave = () => {
    if (lastChanged.current === 'datepicker') {
      updateEntireRound(start, startDate);
    } else {
      updateEntireRound(start, startDate?.add(count, duration));
    }

    onClose();
  };

  return (
    <Card
      className="default-pop-over secondary-text"
      type="inner"
      title={t('GENERAL.ROUND.UPDATE_SCENARIO_START')}
      style={{ width: 400 }}
    >
      <div className="flex justify-between items-center px-4">
        <span>{t('GENERAL.ROUND.INCREASE_START_DATE')}</span>

        <div className="flex items-center">
          <Input
            type="number"
            value={count}
            onChange={onCountChange}
            className="select-wb"
            data-testid="increase-round-start"
            addonAfter={
              <Select
                value={duration}
                options={timeOptions}
                onChange={onTimeChange}
              />
            }
          />
        </div>
      </div>

      <div className="flex justify-between items-center px-4 mt-1">
        <Divider>{t('GENERAL.OR')}</Divider>
      </div>

      <div className="flex justify-between items-center px-4 mt-1">
        <span>{t('GENERAL.ROUND.SELECT_START_DATE')}</span>

        <DatePicker
          defaultValue={startDate}
          onChange={onStartChange}
          format={DATE_FORMAT}
          allowClear={false}
          style={selectStyle}
        />
      </div>

      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-end items-center gap-1 px-4">
        <Button size="small" onClick={onClose}>
          {t('GENERAL.CANCEL')}
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={onSave}
          disabled={count === 0}
          data-testid="update-round-start"
        >
          {t('GENERAL.UPDATE')}
        </Button>
      </div>
    </Card>
  );
}

export default ScenarioStartDatePopover;
