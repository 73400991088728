import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorBoundary from 'Components/Common/ErrorBoundary';
import Header from 'Components/Header/Header.js';
import EnsureAuthenticated from 'Components/Guards/EnsureAuthenticated';

function App() {
  return (
    <>
      <EnsureAuthenticated>
        <Container fluid className="App">
          <Row>
            <Col>
              <ErrorBoundary componentName="ScenariosArea">
                <ScenariosArea />
              </ErrorBoundary>
            </Col>
          </Row>
          <Row>
            <Col>
              <Header />
            </Col>
          </Row>
        </Container>
      </EnsureAuthenticated>
    </>
  );
}

export default App;
