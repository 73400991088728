import React, { useEffect, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { WarningIcon } from 'Components/Elements/Icons';
import { CloseOutlined } from '@ant-design/icons';
import { RoundTemplate, Solution, RoundUpdateUnitType } from 'Models/Scenario';
import { Card, Divider, Button } from 'antd';
import useScenario from 'storeHooks/useScenario';
import { useTranslation } from 'react-i18next';
import useQueue from 'storeHooks/useQueue';
import useSolution from 'customHooks/useSolution';
import useRoundTemplateQueue from 'storeHooks/queue/useRoundTemplate';
import './style.scss';
import { DELETED, ROUND_TEMPLATE_KEY_PREFIX } from 'utils/variables';
import { SolutionStatusStopped } from 'Models/SolutionStatuses';

interface Props {
  onClose: () => void;
  selectedRound: RoundTemplate | null;
}

export const handleRoundDelete = async (
  payload: RoundUpdateUnitType[],
  clonedSolution: Solution | null,
  onDeleteRound: (
    rounds: RoundUpdateUnitType[],
    clonedSolution?: Solution | null,
  ) => Promise<void>,
  setIsSaving: (value: boolean) => void,
) => {
  await onDeleteRound(payload, clonedSolution);
  setIsSaving(false);
};

function RoundCreatePopover({ onClose, selectedRound }: Props): JSX.Element {
  const { t } = useTranslation();
  const { onEnqueue } = useQueue();
  const { syncRoundToStore } = useRoundTemplateQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const { selectedSolution } = useScenario();
  const stateData = useMemo(() => {
    if (selectedSolution) {
      return selectedSolution.stateData;
    }

    return null;
  }, [selectedSolution]);
  const shouldClone = useRef(false);

  useEffect(() => {
    shouldClone.current = selectedSolution?.solutionStatus === SolutionStatusStopped;
  }, [selectedSolution]);

  const onAllRoundsDelete = async () => {
    onClose();

    if (!selectedRound) return;

    if (stateData && selectedRound?.groupBatchId) {
      const { groupBatchId } = selectedRound;

      const chosenSolution = getCurrentOrClonedSolution();

      if (!chosenSolution) return;
      const { solutionKey } = chosenSolution.stateData;

      const toBeletedRounds = chosenSolution.stateData.roundTemplates
        .filter((rt) => rt.groupBatchId === groupBatchId)
        .map((roundTemplate) => {
          const toBeSyncedKey = `${ROUND_TEMPLATE_KEY_PREFIX}${uuidv4()}`;

          const modifiedRT = {
            ...roundTemplate,
            roundTemplateStatus: DELETED,
            toBeSyncedKey,
          };

          syncRoundToStore({
            data: modifiedRT as RoundTemplate,
            toBeSyncedKey,
            solutionKey: chosenSolution.solutionKey,
          });

          return {
            ...roundTemplate,
            roundTemplateStatus: DELETED,
            toBeSyncedKey,
          };
        });

      onEnqueue({
        type: 'UPDATE_ROUND_TEMPLATE',
        payload: {
          data: toBeletedRounds as RoundTemplate[],
          solutionKey: chosenSolution.solutionKey, // local solution key
          actualSolutionKey: solutionKey,
        },
      });
    }
  };

  const onThisRoundDelete = () => {
    onClose();

    if (!selectedRound) return;

    const toBeSyncedKey = `${ROUND_TEMPLATE_KEY_PREFIX}${uuidv4()}`;
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;
    const { solutionKey } = chosenSolution.stateData;

    syncRoundToStore({
      data: {
        ...selectedRound,
        roundTemplateStatus: DELETED,
        toBeSyncedKey,
      },
      toBeSyncedKey,
      solutionKey: chosenSolution.solutionKey,
    });

    onEnqueue({
      type: 'UPDATE_ROUND_TEMPLATE',
      payload: {
        data: [
          {
            ...selectedRound,
            roundTemplateStatus: DELETED,
          },
        ] as RoundTemplate[],
        solutionKey: chosenSolution.solutionKey, // local solution key
        actualSolutionKey: solutionKey,
      },
    });
  };

  return (
    <Card
      className="default-pop-over secondary-text"
      type="inner"
      style={{ width: 380 }}
    >
      <div className="flex justify-end items-center mb-2 pr-1">
        <Button
          size="small"
          type="text"
          icon={<CloseOutlined />}
          onClick={onClose}
          data-testid="close-delete-popup"
        />
      </div>
      <div className="flex justify-center items-center pb-3">
        <div className="warning-background">
          <WarningIcon />
        </div>
      </div>
      <div className="flex flex-column justify-center items-center gap-1">
        <div className="section-title">{t('GENERAL.ROUND.DELETE_WARNING')}</div>
        <div className="secondary-text">{t('GENERAL.ROUND.DELETE_GROUP')}</div>
      </div>

      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-center items-center gap-1 px-4">
        <Button
          size="small"
          onClick={onAllRoundsDelete}
          data-testid="delete-related-round"
        >
          {t('GENERAL.ROUND.ALL')}
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={onThisRoundDelete}
          data-testid="delete-only-this-round"
        >
          {t('GENERAL.ROUND.ONLY')}
        </Button>
      </div>
    </Card>
  );
}

export default RoundCreatePopover;
