import React, { useRef, useLayoutEffect } from 'react';
import { manageAccount, doLogout } from 'Services/UserService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NavigationItem from './NavigationItem';
import './Navigation.scss';
import { persistor } from '../../../../store/store';

const toggleClassName = 'show';

function Navigation(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navMenu = useRef<HTMLDivElement>(null);

  const toggleUserNav = () => {
    if (navMenu.current?.classList.contains(toggleClassName)) {
      navMenu.current?.classList.remove(toggleClassName);
    } else {
      navMenu.current?.classList.add(toggleClassName);
    }
  };

  const closeMenu = () => {
    navMenu.current?.classList.remove(toggleClassName);
  };

  const onLogout = () => {
    closeMenu();
    persistor.purge();
    doLogout();
  };

  const onManageAccount = () => {
    closeMenu();
    manageAccount();
  };

  const onAdminSetting = () => {
    closeMenu();
    navigate('/settings');
  };

  useLayoutEffect(() => {
    const handleClickOutside = (event: Event) => {
      const element = event.target as HTMLElement;

      if (navMenu.current && !navMenu.current.contains(element)) {
        navMenu.current?.classList.remove(toggleClassName);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="app-user">
      <button type="button" className="btn-abbr" onClick={toggleUserNav}>
        U
      </button>

      <div className="navigation-items" ref={navMenu}>
        <NavigationItem
          label={t('GENERAL.USER.MANAGE_ACCOUNT')}
          onClick={onManageAccount}
        />
        <NavigationItem label={t('GENERAL.SETTING')} onClick={onAdminSetting} />
        <NavigationItem label={t('GENERAL.USER.LOGOUT')} onClick={onLogout} />
      </div>
    </div>
  );
}

export default Navigation;
