import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { keycloakApiClient } from './apiClient';
import api from './apiClient';

const config: KeycloakConfig = {
  realm: process.env.REACT_APP_KC_REALM || 'OPS',
  url: process.env.REACT_APP_KC_AUTH_URL || '',
  clientId: process.env.REACT_APP_KC_CLIENT_ID || 'GSS',
};

export const keycloakClient = new Keycloak(config);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
export const initKeycloak = (onAuthenticatedCallback: () => void) => {
  keycloakClient
    .init({
      onLoad: 'check-sso',
      flow: 'standard',
    })
    .then((isAuthenticated) => {
      if (!isAuthenticated) {
        // TODO: use logger if you want to log unauthenticated
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

export const doLogin = keycloakClient.login;

export const manageAccount = keycloakClient.accountManagement;

export const doLogout = keycloakClient.logout;

export const getToken = () => keycloakClient.token;

export const getTokenParsed = () => keycloakClient.tokenParsed;

export const isLoggedIn = () => !!keycloakClient.token;

export const updateToken = () => keycloakClient.updateToken(5);

export const getUsername = () => keycloakClient.tokenParsed?.preferred_username;

export const isAdmin = () => _hasRole('admin');

const _hasRole = (role: string) => {
  const roles: string[] = keycloakClient.tokenParsed?.roles;
  return roles?.find((x) => x == role);
};

export const inviteUser = async (
  email: string,
  firstName: string,
  lastName: string,
) => {
  try {
    await _signupUser(email, firstName, lastName);
    const user = await _getUserByEmail(email);
    await _sendPasswordResetEmail(user.id);
  } catch (error) {
    console.error('Error:', error);
  }
};

const _signupUser = async (
  email: string,
  firstName: string,
  lastName: string,
) => {
  await keycloakApiClient.post(`admin/realms/${config.realm}/users`, {
    email,
    username: email,
    firstName,
    lastName,
    enabled: true,
    emailVerified: true,
  });
};

const _getUserByEmail = async (email: string) => {
  const res = await keycloakApiClient.get(
    `admin/realms/${config.realm}/users?email=${email}`,
  );
  return res.data[0];
};

const _sendPasswordResetEmail = async (userId: string) => {
  await keycloakApiClient.put(
    `admin/realms/${config.realm}/users/${userId}/execute-actions-email`,
    ['UPDATE_PASSWORD'],
  );
};

export const saveUserSettings = async (data: {
  shouldShowDeleteConfirmation: boolean;
  appSettingsStatus: string;
}) => {
  const res = await api.post('appsettings', data);
  return res.data;
};

export const getUserSettings = async () => {
  let res = {
    data: {
      shouldShowDeleteConfirmation: false,
    },
  };

  try {
    res = await api.get('appsettings');
  } catch (e) {}

  return res.data;
};
