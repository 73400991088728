export const SolutionStatusNew = 'New';
export const SolutionStatusCreated = 'Created';
export const SolutionStatusSolveRequested = 'SolveRequested';
export const SolutionStatusStopRequested = 'StopRequested';
export const SolutionStatusSolving = 'Solving';
export const SolutionStatusStopping = 'Stopping';
export const SolutionStatusStopped = 'Stopped';
export const SolutionStatusDeleted = 'Deleted';

export type SolutionStatus =
    typeof SolutionStatusNew
  | typeof SolutionStatusCreated
  | typeof SolutionStatusSolveRequested
  | typeof SolutionStatusSolving
  | typeof SolutionStatusStopRequested
  | typeof SolutionStatusStopping
  | typeof SolutionStatusStopped
  | typeof SolutionStatusDeleted;
