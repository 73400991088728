import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { LinkOutlined } from '@ant-design/icons';

const Integrations = () => {
  const { t } = useTranslation();

  const integrations = [
    {
      title: t('GENERAL.INTIGRATIONS.AZURE.TITLE'),
      subtitle: t('GENERAL.INTIGRATIONS.AZURE.SUBTITLE'),
    },
    {
      title: t('GENERAL.INTIGRATIONS.AWS.TITLE'),
      subtitle: t('GENERAL.INTIGRATIONS.AWS.SUBTITLE'),
    },
    {
      title: t('GENERAL.INTIGRATIONS.GCP.TITLE'),
      subtitle: t('GENERAL.INTIGRATIONS.GCP.SUBTITLE'),
    },
  ];

  return (
    <>
      <div>
        <div className="section-title font-semibold pl-0">
          {t('GENERAL.USER.INTIGRATIONS')}
        </div>
        <div className="mt-1 section-subtitle">
          {t('GENERAL.USER.CONNECT_CLOUD_PROVIDERS')}
        </div>
      </div>

      <Divider style={{ marginBottom: '16px' }} />

      <div>
        <div className="flex flex-column gap-2">
          {integrations.map((item) => (
            <div
              key={item.title}
              className="flex border rounded-md p-3 justify-between items-center"
            >
              <div>
                <div className="section-title font-semibold pl-0">
                  {item.title}
                </div>
                <div className="section-subtitle">{item.subtitle}</div>
              </div>

              <button
                type="button"
                className="text-white bg-blue-500 flex items-center gap-2 bg px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"
              >
                <LinkOutlined rotate={45} />
                {t('GENERAL.CONNECT')}
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Integrations;
