const solveTableQuery = '.solve-table';
const solveTableCellClass = 'team-match';
const solveTableCellQuery = `.${solveTableCellClass}`;
const solveTableHighlightedCellClass = 'highlighted';

let handleMouseDown: EventListenerOrEventListenerObject;
let handleMouseOver: EventListenerOrEventListenerObject;
let handleSelectStart: EventListenerOrEventListenerObject;
let handleMouseUp: EventListenerOrEventListenerObject;

let tableGlobal: HTMLTableElement | null;

export const keyPressed: Record<string, boolean> = {
  meta: false,
};

export const toBeExecutedWhileMultiSelect: Record<string, () => void> = {};

function getTable(): HTMLTableElement | null {
  return document.querySelector(solveTableQuery);
}

let selectedCells: HTMLTableCellElement[] = [];

export function getAllCells(): NodeListOf<HTMLTableCellElement> {
  return getTable()?.querySelectorAll(
    solveTableCellQuery,
  ) as NodeListOf<HTMLTableCellElement>;
}

export function getSelectedCells(): HTMLTableCellElement[] {
  const allCells = getAllCells();
  const result: HTMLTableCellElement[] = [];

  for (let i = 0; i < allCells.length; i++) {
    const cell: HTMLTableCellElement = allCells[i];

    if (cell.classList.contains('highlighted')) {
      result.push(cell);
    }
  }

  return result;
}

export function clearCellsHilighting() {
  const allCells = getAllCells();

  if (allCells) {
    for (let i = 0; i < allCells.length; i++) {
      const cell: Element = allCells[i];
      cell.classList.remove(solveTableHighlightedCellClass);
    }

    Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());
  }
}

export function setHighlight(element: HTMLTableCellElement) {
  element.classList.add(solveTableHighlightedCellClass);
}

export function selectAllCells() {
  const allCells = getAllCells();

  for (let i = 0; i < allCells.length; i++) {
    const cell: HTMLTableCellElement = allCells[i];
    setHighlight(cell);
  }
}

function handleKeyPress(event: KeyboardEvent) {
  if (event.isTrusted && (event.metaKey || event.ctrlKey)) {
    keyPressed.meta = true;
  } else {
    keyPressed.meta = false;
  }
}
function handleKeyUp() {
  keyPressed.meta = false;
}

export function initializeTableSelection() {
  let isMouseDown = false;

  const table = getTable();

  if (!table) return;

  tableGlobal = table;

  let startCell: HTMLTableCellElement | null;

  function tryGetCell(target: EventTarget | null): HTMLTableCellElement | null {
    let element: HTMLTableCellElement | null = null;

    if (
      target &&
      (target as Element)?.classList?.contains(solveTableCellClass)
    ) {
      element = target as HTMLTableCellElement;
    } else if (
      target &&
      (target as Element)?.parentElement?.classList?.contains(
        solveTableCellClass,
      )
    ) {
      element = (target as Element)?.parentElement as HTMLTableCellElement;
    } else {
      element = null;
    }

    return element;
  }

  handleMouseDown = (event: Event) => {
    if (event instanceof MouseEvent && event.button === 2) {
      return; // Do nothing for right-click
    }

    const element: HTMLTableCellElement | null = tryGetCell(event.target);

    if (element) {
      if (!keyPressed.meta) {
        clearCellsHilighting();
      }

      isMouseDown = true;
      startCell = element;
      if (!keyPressed.meta) {
        selectedCells = [];
      } else {
        Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());
        selectedCells = getSelectedCells();
      }

      selectedCells.push(startCell);
      setHighlight(startCell);
      event.preventDefault(); // prevent text selection
    }
  };

  // Helper function to get all cells within a rectangle
  function getCellsInRectangle(
    currentStartCell: HTMLTableCellElement,
    endCell: HTMLTableCellElement,
  ): HTMLTableCellElement[] {
    const retCells: HTMLTableCellElement[] = [];

    if (
      !currentStartCell ||
      !currentStartCell.parentElement ||
      !endCell ||
      !endCell.parentElement
    ) {
      return retCells;
    }

    const rows = table!.querySelectorAll('tr');
    const rowsArr = Array.from(rows);

    const startRowIndex = rowsArr.indexOf(
      currentStartCell.parentElement as HTMLTableRowElement,
    );
    const endRowIndex = rowsArr.indexOf(
      endCell.parentElement as HTMLTableRowElement,
    );

    const startColumnIndex = Array.from(
      currentStartCell.parentElement.children,
    ).indexOf(currentStartCell);
    const endColumnIndex = Array.from(endCell.parentElement.children).indexOf(
      endCell,
    );

    const rectTopRowInd = Math.min(startRowIndex, endRowIndex);
    const rectBottomRowInd = Math.max(startRowIndex, endRowIndex);

    for (let i = rectTopRowInd; i <= rectBottomRowInd; i++) {
      const curRow = rows[i];

      const rectLeftColInd = Math.min(startColumnIndex, endColumnIndex);
      const rectRightColInd = Math.max(startColumnIndex, endColumnIndex);

      for (let j = rectLeftColInd; j <= rectRightColInd; j++) {
        retCells.push(curRow.cells[j] as HTMLTableCellElement);
      }
    }

    return retCells;
  }

  handleMouseOver = (event: Event) => {
    const element: HTMLTableCellElement | null = tryGetCell(event.target);

    if (isMouseDown && startCell && element) {
      const endCell = element;
      const allCells = getAllCells();

      // Clear previous selection
      for (let i = 0; i < allCells.length; i++) {
        const cell = allCells[i];
        if (cell !== startCell && !keyPressed.meta) {
          cell.classList.remove(solveTableHighlightedCellClass);
        }
      }

      selectedCells = getCellsInRectangle(startCell, endCell);
      selectedCells.forEach((cell) => {
        setHighlight(cell);
      });

      selectedCells = getCellsInRectangle(startCell, endCell);

      if (selectedCells.length > 1) {
        Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());
      }
    }
  };

  handleSelectStart = (event: Event) => {
    event.preventDefault();
  };

  handleMouseUp = () => {
    isMouseDown = false;
    startCell = null;
  };

  function AddEventListeners() {
    table!.addEventListener('mousedown', handleMouseDown, true);
    table!.addEventListener('mouseover', handleMouseOver, true);

    table!.addEventListener('selectstart', handleSelectStart, true);
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('keydown', handleKeyPress, true);
    document.addEventListener('keyup', handleKeyUp, true);
    window.addEventListener('blur', handleKeyUp, true);
  }

  AddEventListeners();
}

export function unInitializeTableSelection() {
  if (tableGlobal) {
    if (handleMouseDown) {
      tableGlobal.removeEventListener('mousedown', handleMouseDown, true);
    }
    if (handleMouseOver) {
      tableGlobal!.removeEventListener('mouseover', handleMouseOver, true);
    }

    if (handleSelectStart) {
      tableGlobal.removeEventListener('selectstart', handleSelectStart, true);
    }
  }

  if (handleMouseUp) {
    document.removeEventListener('mouseup', handleMouseUp, true);
  }

  document.removeEventListener('keydown', handleKeyPress, true);

  document.removeEventListener('keyup', handleKeyUp, true);

  window.removeEventListener('blur', handleKeyUp, true);
}
