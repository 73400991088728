import _ from 'lodash';
import { SolutionStatus, SolutionStatusStopped } from 'Models/SolutionStatuses';
import { useEffect, useRef } from 'react';
import { CREATED, SYNC_SOLUTION_KEY_PREFIX } from 'utils/variables';
import {
  syncToBeClonedSolution,
  addClonedSolutionKey,
  setSolutionLocalKeyMap,
  selectSolveDataKey,
  selectSelectedSolution,
  selectScenario,
} from 'store/slices/scenarioSlice';
import { useSelector } from 'react-redux';
import i18n from 'plugins/i18next';
import { useAppDispatch } from '../storeHooks/hooks';
import { message } from 'antd';

export const useSolution = () => {
  const dispatch = useAppDispatch();
  const selectedSolution = useSelector(selectSelectedSolution);
  const selectedScenario = useSelector(selectScenario);
  const solveDataKey = useSelector(selectSolveDataKey);

  const selectedSolutionRef = useRef(selectedSolution);
  const solveDataKeyRef = useRef(solveDataKey);

  useEffect(() => {
    selectedSolutionRef.current = selectedSolution;
  }, [selectedSolution]);

  useEffect(() => {
    solveDataKeyRef.current = solveDataKey;
  }, [solveDataKey]);

  const getCurrentOrClonedSolution = (unlockAllCells = true) => {
    if (!selectedSolutionRef.current) return null;

    if (selectedSolutionRef.current.solutionStatus !== SolutionStatusStopped) {
      return selectedSolutionRef.current;
    }

    const toBeSyncedKey = `${SYNC_SOLUTION_KEY_PREFIX}${new Date().getTime()}`;

    const idList = selectedSolutionRef.current.stateData.solutionKey.split('-');

    dispatch(
      setSolutionLocalKeyMap({
        key: toBeSyncedKey,
        value: selectedSolutionRef.current.solutionKey,
      }),
    );

    const toBeCopiedSolution = _.cloneDeep(selectedSolutionRef.current);

    toBeCopiedSolution.solveDataList = toBeCopiedSolution.solveDataList.filter(
      (item) => item.solveDataKey === solveDataKeyRef.current,
    );

    let lastSolutionIndex = toBeCopiedSolution.solutionIndex;

    if (
      selectedScenario?.optimizationEnvelop.solutions[
        selectedScenario.optimizationEnvelop.solutions.length - 1
      ]
    ) {
      lastSolutionIndex =
        selectedScenario.optimizationEnvelop.solutions[
          selectedScenario.optimizationEnvelop.solutions.length - 1
        ].solutionIndex;
    }

    const newLocalSolution = {
      ...toBeCopiedSolution,
      toBeSyncedKey,
      solutionIndex: lastSolutionIndex + 1,
      solutionKey: toBeSyncedKey,
      solveDataList: !unlockAllCells
        ? toBeCopiedSolution.solveDataList
        : toBeCopiedSolution.solveDataList.map((item) => ({
            ...item,
            optimizedColumns: item.optimizedColumns.map((ol) => ({
              ...ol,
              isLocked: false,
            })),
          })),
      solutionStatus: CREATED as SolutionStatus,
    };

    dispatch(addClonedSolutionKey(toBeSyncedKey));

    dispatch(syncToBeClonedSolution(newLocalSolution));

    // @ts-expect-error
    if (document.appNavigate) {
      // @ts-expect-error
      document.appNavigate(`/scenario/${idList[0]}/${idList[0]}-${idList[1]}`);
    }

    return newLocalSolution;
  };

  const canDeleteVenue = (venueId: string) => {
    if (!selectedSolutionRef.current) return false;

    if (
      JSON.stringify(
        selectedSolutionRef.current.stateData.constraintsEnvelop.sets,
      ).includes(venueId)
    ) {
      message.error(
        i18n.t('GENERAL.FEEDBACK.CAN_NOT_DELETE', {
          entity: i18n.t('GENERAL.SET_LINE.TITLE'),
        }),
      );
      return false;
    }
    return true;
  };

  const canDeleteTeam = (teamId: string) => {
    if (!selectedSolutionRef.current) return false;
    const sets = JSON.stringify(
      selectedSolutionRef.current.stateData.constraintsEnvelop.sets,
    );

    const constraints = JSON.stringify(
      selectedSolutionRef.current.stateData.constraintsEnvelop.constraints,
    );

    if (constraints.includes(teamId)) {
      message.error(
        i18n.t('GENERAL.FEEDBACK.CAN_NOT_DELETE', {
          entity: i18n.t('GENERAL.CONSTRAINT.TITLE'),
        }),
      );
      return false;
    }

    if (sets.includes(teamId)) {
      message.error(
        i18n.t('GENERAL.FEEDBACK.CAN_NOT_DELETE', {
          entity: i18n.t('GENERAL.SET_LINE.TITLE'),
        }),
      );
      return false;
    }
    return true;
  };

  const canDeleteSlotType = (slotTypeId: string) => {
    if (!selectedSolutionRef.current) return false;

    if (
      JSON.stringify(
        selectedSolutionRef.current.stateData.constraintsEnvelop.sets,
      ).includes(slotTypeId)
    ) {
      message.error(
        i18n.t('GENERAL.FEEDBACK.CAN_NOT_DELETE', {
          entity: i18n.t('GENERAL.SET_LINE.TITLE'),
        }),
      );
      return false;
    }

    if (
      JSON.stringify(
        selectedSolutionRef.current.stateData.roundsDictionaries.networks,
      ).includes(slotTypeId)
    ) {
      message.error(
        i18n.t('GENERAL.FEEDBACK.CAN_NOT_DELETE', {
          entity: i18n.t('GENERAL.NETWORK.TITLE'),
        }),
      );
      return false;
    }

    return true;
  };

  const canDeleteNetwork = (networkId: string) => {
    if (!selectedSolutionRef.current) return false;

    if (
      JSON.stringify(
        selectedSolutionRef.current.stateData.constraintsEnvelop.sets,
      ).includes(networkId)
    ) {
      message.error(
        i18n.t('GENERAL.FEEDBACK.CAN_NOT_DELETE', {
          entity: i18n.t('GENERAL.SET_LINE.TITLE'),
        }),
      );
      return false;
    }

    return true;
  };

  const canDeleteNetworkCategory = (networkCategoryId: string) => {
    if (!selectedSolutionRef.current) return false;

    if (
      JSON.stringify(
        selectedSolutionRef.current.stateData.constraintsEnvelop.sets,
      ).includes(networkCategoryId)
    ) {
      message.error(
        i18n.t('GENERAL.FEEDBACK.CAN_NOT_DELETE', {
          entity: i18n.t('GENERAL.SET_LINE.TITLE'),
        }),
      );
      return false;
    }

    return true;
  };

  return {
    getCurrentOrClonedSolution,
    canDeleteVenue,
    canDeleteTeam,
    canDeleteSlotType,
    canDeleteNetwork,
    canDeleteNetworkCategory,
  };
};

export default useSolution;
