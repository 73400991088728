import React from 'react';
import { CodeBlock, tomorrowNightBlue } from 'react-code-blocks';

interface Props {
  message: string;
}

export default function SignalRMessages({ message }: Props): JSX.Element {
  return (
    <CodeBlock
      text={message}
      theme={tomorrowNightBlue}
      language="text"
      codeContainerStyle={{
        fontSize: '13px',
        border: '1px solid black',
        overflow: 'auto',
        color: 'white',
      }}
    />
  );
}
