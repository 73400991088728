import React from 'react';

function VenueIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10.0003 3.33331V16.6666M1.66699 7.49998H3.75033C3.98044 7.49998 4.16699 7.68653 4.16699 7.91665V12.9166C4.16699 13.1468 3.98044 13.3333 3.75033 13.3333H1.66699M18.3337 7.49998H16.2503C16.0202 7.49998 15.8337 7.68653 15.8337 7.91665V12.9166C15.8337 13.1468 16.0202 13.3333 16.2503 13.3333H18.3337M2.50033 16.6666H17.5003C17.9606 16.6666 18.3337 16.2935 18.3337 15.8333V4.16665C18.3337 3.70641 17.9606 3.33331 17.5003 3.33331H2.50033C2.04009 3.33331 1.66699 3.70641 1.66699 4.16665V15.8333C1.66699 16.2935 2.04009 16.6666 2.50033 16.6666ZM13.3337 9.99998C13.3337 11.8409 11.8413 13.3333 10.0003 13.3333C8.15938 13.3333 6.66699 11.8409 6.66699 9.99998C6.66699 8.15903 8.15938 6.66665 10.0003 6.66665C11.8413 6.66665 13.3337 8.15903 13.3337 9.99998Z" stroke="#667085" strokeWidth="1.67" />
    </svg>
  );
}

export default VenueIcon;
