import React, { useState } from 'react';
import { Divider, Button, Input } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { SelectionType } from './OpponentMatchTable';

interface Props {
  selectionType: SelectionType;
  onSelect: (value: number) => void;
  onCopyToOpposite: () => void;
}

const PRE_SELECT_ITEM = Array(4).fill(0);

function OpponentActionPopOver({
  selectionType,
  onSelect,
  onCopyToOpposite,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);

  const onCountChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCount(Number(e.currentTarget.value));
  };

  const onSave = () => {
    onSelect(count);
  };

  return (
    <div className="opponent-popover">
      <div
        onClick={onCopyToOpposite}
        className="opponent-popover-header"
        data-testid="opponent-minmax-value-transfer-option"
      >
        {selectionType === 'min'
          ? t('GENERAL.OPPONENT.COPY_TO_MAX')
          : t('GENERAL.OPPONENT.COPY_TO_MIN')}
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="opponent-popover-options">
        {PRE_SELECT_ITEM.map((item, i) => (
          <div
            key={`select-${item}${i}`}
            onClick={(e) => {
              e.stopPropagation();
              onSelect(i);
            }}
            data-testid={`select-${i}`}
          >
            {t('GENERAL.OPPONENT.SET', { count: i })}
          </div>
        ))}
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="opponent-popover-footer">
        <Input
          className="w-24"
          type="number"
          value={count}
          min={0}
          max={999}
          onChange={onCountChange}
          data-testid="opponent-count"
        />

        <Button
          icon={<CheckOutlined />}
          onClick={onSave}
          data-testid="save-custom-opponent-count"
        />
      </div>
    </div>
  );
}

export default OpponentActionPopOver;
