import { Slice, createSlice } from '@reduxjs/toolkit';

type StateType = {
  saving: boolean;
};

const initialState: StateType = {
  saving: false,
};

export const selectIsSaving = ({ network }: { network: StateType }) =>
  network.saving;

export const networkSlice: Slice = createSlice({
  name: 'app/network-call',
  initialState: {
    ...initialState,
  },
  reducers: {
    setSaving(state, { payload }) {
      state.saving = payload;
    },
  },
});

export const { setSaving } = networkSlice.actions;

export default networkSlice.reducer;
