import React, { useState } from 'react';
import dayjs from 'dayjs';
import type { DatePickerProps } from 'antd';
import { RoundTemplate } from 'Models/Scenario';
import { Card, Divider, Button, DatePicker, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import useRoundCreate from './useRoundCreate';
import './style.scss';

interface Props {
  selectedRound: RoundTemplate | null;
  onClose: () => void;
}

const DATE_FORMAT = 'DD MMM YY';

function RoundEditPopover({ onClose, selectedRound }: Props): JSX.Element {
  const { updateRound } = useRoundCreate();
  const { t } = useTranslation();
  const [data, setData] = useState<{
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  }>({
    start: selectedRound ? dayjs(selectedRound.startDate).utc() : dayjs().utc(),
    end: selectedRound ? dayjs(selectedRound.endDate).utc() : dayjs().utc(),
  });
  const [error, setError] = useState('');

  const onResetError = () => {
    setError('');
  };

  const onStartChange: DatePickerProps['onChange'] = (date) => {
    if (date) {
      setData((prev) => ({
        ...prev,
        start: date,
      }));
    }
  };

  const onEndChange: DatePickerProps['onChange'] = (date) => {
    if (date) {
      setData((prev) => ({
        ...prev,
        end: date,
      }));
    }
  };

  const onSaveRound = () => {
    onResetError();

    if (selectedRound) {
      const errorMessage = updateRound(data.start, data.end, selectedRound);

      if (errorMessage) {
        setError(errorMessage);
      } else {
        onClose();
      }
    }
  };

  return (
    <Card
      className="default-pop-over secondary-text"
      type="inner"
      title={t('GENERAL.ROUND.EDIT')}
      style={{ width: 400 }}
    >
      <div className="flex justify-between items-center px-4">
        <span>{t('GENERAL.STARTS')}</span>

        <DatePicker
          defaultValue={data.start}
          onChange={onStartChange}
          format={DATE_FORMAT}
          allowClear={false}
        />
      </div>
      <div className="flex justify-between items-center px-4 mt-1">
        <span>{t('GENERAL.ENDS')}</span>

        <DatePicker
          defaultValue={data.end}
          onChange={onEndChange}
          format={DATE_FORMAT}
          allowClear={false}
        />
      </div>

      {error && (
        <div className="px-4 mt-1">
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            afterClose={onResetError}
          />
        </div>
      )}

      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-end items-center gap-1 px-4">
        <Button size="small" onClick={onClose}>
          {t('GENERAL.CANCEL')}
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={onSaveRound}
          data-testid="edit-round-button"
        >
          {t('GENERAL.UPDATE')}
        </Button>
      </div>
    </Card>
  );
}

export default RoundEditPopover;
