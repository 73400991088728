/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */

import * as signalR from '@microsoft/signalr';
import { EventEmitter } from 'events';
import { getToken, updateToken } from './UserService';

export type THub = 'log' | 'status';

class SignalRService {
  private connection: signalR.HubConnection | null = null;

  private connectionStatus: string = 'DISCONNECTED';

  private eventsEmitter: EventEmitter;

  private readonly connectionErrorName = 'connectionError';

  private readonly hubName: THub;

  private groupName?: string;

  constructor(hubName: THub) {
    this.eventsEmitter = new EventEmitter();
    this.hubName = hubName;
  }

  public init = async (groupName?: string): Promise<SignalRService> => {
    const apiBaseUrl: string | undefined = new URL(
      process.env.REACT_APP_API_BASE!, // Example: 'http://localhost:7030';
    ).origin;

    this.groupName = groupName;

    let signalRUrl: string = `${apiBaseUrl}/hub/${this.hubName}`;

    if (this.groupName) {
      signalRUrl += `?group_name=${this.groupName}`;
    }

    console.debug(`signalRUrl=${signalRUrl}`);
    console.debug(`initiating ${this.hubName} hub connection...`);

    const reconnectPolicy = {
      nextRetryDelayInMilliseconds: () => 500,
      maxRetryAttempts: 100000,
    };

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(signalRUrl, {
        accessTokenFactory: async () => {
          await updateToken();
          return getToken() || '';
        },
      })
      .withAutomaticReconnect(reconnectPolicy)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    await this.startConnection();
    this.setupConnectionStatusHandling();

    return this;
  };

  private set status(status: string) {
    this.connectionStatus = status;
    this.eventsEmitter.emit(this.connectionErrorName, status);

    const msg = `SignalRService status: ${status}`;

    if (status.toUpperCase().includes('ERROR')) {
      console.error(msg);
    } else {
      console.debug(msg);
    }
  }

  private startConnection = async () => {
    try {
      this.status = 'CONNECTING...';
      if (!this.connection) {
        this.status = 'CONNECTION ERROR: connection is not initialized';
        return;
      }

      await this.connection.start();
      this.status = 'CONNECTED';
    } catch (err) {
      this.status = `CONNECTION ERROR: ${err}`;
      await this.init();
    }
  };

  private setupConnectionStatusHandling = () => {
    this.connection?.onreconnecting((error) => {
      this.status = 'RECONNECTING...';

      if (error?.message.includes('Unauthorized')) {
        this.init();
      }
    });

    this.connection?.onreconnected(() => {
      this.status = 'RECONNECTED';
    });

    this.connection?.onclose(() => {
      this.status = 'DISCONNECTED';
    });
  };

  getHubName = () => this.hubName;

  getConnection = () => this.connection;

  getConnectionStatus = () => this.connectionStatus;

  stop = async () => {
    console.debug(`stopping ${this.getHubName()} hub connection...`);

    await this.connection?.stop();
  };

  onConnectionStatus = (handler: (error: unknown) => void) => {
    this.eventsEmitter.removeAllListeners();
    this.eventsEmitter.on(this.connectionErrorName, handler);
  };
}

export default SignalRService;

const logHub = new SignalRService('log');
const statusHub = new SignalRService('status');

export const connectLogHub = (group?: string) => logHub.init(group);
export const connectStatusHub = (group?: string) => statusHub.init(group);

export const disconnectLogHub = () => logHub.stop();
export const disconnectStatusHub = () => statusHub.stop();
