import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SignalRService, {
  connectLogHub,
  connectStatusHub,
  disconnectLogHub,
  disconnectStatusHub,
} from 'Services/SignalRService';
import {
  selectCurrentScenarioKey,
  selectSolutionKey,
} from 'store/slices/scenarioSlice';

export default function useConnectHubs() {
  const scenarioKey = useSelector(selectCurrentScenarioKey);
  const solutionKey = useSelector(selectSolutionKey);
  const logHubRef = useRef<SignalRService | null>(null);
  const [logHub, setLogHub] = useState(logHubRef.current);
  const statusHubRef = useRef<SignalRService | null>(null);
  const [statusHub, setStatusHub] = useState(statusHubRef.current);
  const isExecuting = useRef<boolean>(false);

  const connectLogHubHandler = (
    currentSolutionKey?: string,
  ): Promise<SignalRService | null> => {
    if (logHubRef.current) return Promise.resolve(logHubRef.current);

    return connectLogHub(currentSolutionKey)
      .then((hub: SignalRService) => {
        logHubRef.current = hub;
        setLogHub(logHubRef.current);
        return Promise.resolve(logHubRef.current);
      })
      .catch((err) => Promise.reject(err));
  };

  useEffect(() => {
    if (!isExecuting.current || !statusHubRef.current) {
      connectStatusHub(scenarioKey)
        .then((hub: SignalRService) => {
          statusHubRef.current = hub;
          setStatusHub(statusHubRef.current);
        })
        .finally(() => {
          isExecuting.current = false;
        });

      isExecuting.current = true;
    }

    return () => {
      if (statusHubRef.current) {
        statusHubRef.current = null;
        setStatusHub(null);
        disconnectStatusHub();
      }
    };
  }, [scenarioKey]);

  useEffect(
    () => () => {
      if (logHubRef.current) {
        logHubRef.current = null;
        setLogHub(null);
        disconnectLogHub();
      }
    },
    [solutionKey],
  );

  return {
    logHub,
    connectLogHubHandler,
    statusHub,
  };
}
