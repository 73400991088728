import i18n from 'plugins/i18next';
import { message } from 'antd';
import { Network, NetworkCategory } from 'Models/Network';
import { NetworkKey, ScenarioKey, SolutionKey } from 'Models/Scenario';
import api from './apiClient';
import { getErrorMsg } from './ServiceHelper';

export const PostCreateOrUpdateNetwork = async (
  scenarioKey: ScenarioKey,
  solutionKey: SolutionKey,
  network: Network,
  solutionStatus: string | null | undefined,
): Promise<Network> => {
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    interface NetworkUpdateRequest {
      network: Network;
    }

    const request: NetworkUpdateRequest = {
      network,
    };

    const response = await api.post(
      `scenario/${scenarioKey}/solution/${solutionKey}/network`,
      request,
    );
    return response.data.network as Network;
  } catch (err: unknown) {
    throw getErrorMsg(err, 'CreateOrUpdateNetwork');
  }
};

export const PostCreateOrUpdateNetworkCategory = async (
  scenarioKey: ScenarioKey,
  solutionKey: SolutionKey,
  networkKey: NetworkKey,
  networkCategory: NetworkCategory,
  solutionStatus: string | null | undefined,
): Promise<NetworkCategory> => {
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }

  try {
    interface NetworkCategoryUpdateRequest {
      networkCategory: NetworkCategory;
    }

    const request: NetworkCategoryUpdateRequest = {
      networkCategory,
    };

    const response = await api.post(
      `scenario/${scenarioKey}/solution/${solutionKey}/network/${networkKey}/network-category`,
      request,
    );

    return response.data.networkCategory as NetworkCategory;
  } catch (err: unknown) {
    throw getErrorMsg(err, 'CreateOrUpdateNetworkCategory');
  }
};
