import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

function Icon({ color, className }: Props): JSX.Element {
  return (
    <svg
      className={className}
      height="24"
      width="24"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke={color ?? '#000000'}
      strokeWidth="1"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <path
          className="cls-1"
          fill={color ?? '#000000'}
          d="M8,6.5A1.5,1.5,0,1,1,6.5,8,1.5,1.5,0,0,1,8,6.5ZM.5,8A1.5,1.5,0,1,0,2,6.5,1.5,1.5,0,0,0,.5,8Zm12,0A1.5,1.5,0,1,0,14,6.5,1.5,1.5,0,0,0,12.5,8Z"
        />
      </g>
    </svg>
  );
}

export default Icon;
