import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

function Icon({ color, className }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path d="M2.39735 15.5964C2.43564 15.2518 2.45478 15.0795 2.50691 14.9185C2.55316 14.7756 2.61851 14.6396 2.70118 14.5143C2.79436 14.373 2.91694 14.2504 3.16209 14.0052L14.1673 3C15.0878 2.07952 16.5802 2.07952 17.5007 3C18.4211 3.92047 18.4211 5.41286 17.5007 6.33333L6.49542 17.3386C6.25027 17.5837 6.1277 17.7063 5.98639 17.7995C5.86102 17.8821 5.72506 17.9475 5.58219 17.9937C5.42115 18.0459 5.24887 18.065 4.90429 18.1033L2.08398 18.4167L2.39735 15.5964Z" stroke={color ?? '#98A2B3'} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default Icon;
