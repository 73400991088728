import { Skeleton } from 'antd';
import React from 'react';

interface Payload {
  loading: boolean;
}

function ScenariosTableLoader({ loading }: Payload): JSX.Element {
  return (
    <div className="px-3 pt-3 pb-1">
      <Skeleton className="mt-4" loading={loading} />
      <Skeleton className="mt-4" loading={loading} />
      <Skeleton className="mt-4" loading={loading} />
    </div>
  );
}

export default ScenariosTableLoader;
