import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Button,
  Divider,
  Typography,
  Radio,
  RadioChangeEvent,
  Input,
} from 'antd';
import useScenario from 'storeHooks/useScenario';
import {
  SolveType,
  SolveTypeDistributed,
  SolveTypeLocalSearch,
  SolveTypeRegular,
  SolveTypeSeeder,
} from 'Models/SolveTypes';
import classNames from 'classnames';
import { useSolverParameter } from 'storeHooks/useSolverParameter';
import { SolverParameter, SolverParameterBase } from 'Models/SolverParameter';
import SignalRService from 'Services/SignalRService';
import showMessage from 'Components/Elements/Common/Message';

const { Text, Title } = Typography;

interface Props {
  onCancel: () => void;
  setIsLogButtonVisible: Dispatch<SetStateAction<boolean>>;
  connectLogHub: (group?: string) => Promise<SignalRService | null>;
}

interface SolveOption {
  label: string;
  value: SolveType;
  testid: string;
  disabled?: boolean;
}

function SolveScenario({
  onCancel,
  setIsLogButtonVisible,
  connectLogHub,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedSolution, solveScenario } = useScenario();
  const { solverParameters: fetchedSolverParameters } = useSolverParameter();
  const [solverParameters, setSolverParameters] = useState<SolverParameter[]>();
  const [selectedSolveType, setSelectedSolveType] =
    useState<SolveType>(SolveTypeRegular);
  const [isSolveClicked, setIsSolveClicked] = useState(false);

  const solveOptions: SolveOption[] = [
    {
      label: t('GENERAL.SOLVE_PATTERN.REGULAR'),
      value: SolveTypeRegular,
      testid: 'regular-solve-button',
    },
    {
      label: t('GENERAL.SOLVE_PATTERN.SEEDER'),
      value: SolveTypeSeeder,
      testid: 'seeder-solve-button',
      disabled: true,
    },
    {
      label: t('GENERAL.SOLVE_PATTERN.LOCAL_SEARCH'),
      value: SolveTypeLocalSearch,
      testid: 'local-search-solve-button',
    },
    {
      label: t('GENERAL.SOLVE_PATTERN.DISTRIBUTED'),
      value: SolveTypeDistributed,
      testid: 'distributed-solve-button',
      disabled: true,
    },
  ];

  useEffect(() => {
    if (fetchedSolverParameters) {
      setSolverParameters(fetchedSolverParameters.map((obj) => ({ ...obj })));
    }
  }, [fetchedSolverParameters]);

  useEffect(() => {
    if (solverParameters) {
      setSolverParameters(solverParameters.map((obj) => ({ ...obj })));
    }
  }, [selectedSolveType]);

  const handleSolveTypeChange = (e: RadioChangeEvent) => {
    setSelectedSolveType(e.target.value);
  };

  function getVisibleSolverParameters(
    selSolveType: SolveType,
  ): SolverParameter[] {
    if (!solverParameters || !solverParameters.length) return [];

    let filteredSolverParameters: SolverParameter[];

    switch (selSolveType) {
      case SolveTypeRegular:
        filteredSolverParameters = solverParameters.filter(
          (x) => x.regularSolveDefault,
        );
        break;
      case SolveTypeSeeder:
        filteredSolverParameters = solverParameters.filter(
          (x) => x.seederDefault,
        );
        break;
      case SolveTypeLocalSearch:
        filteredSolverParameters = solverParameters.filter(
          (x) => x.localSearchDefault,
        );
        break;
      case SolveTypeDistributed:
        filteredSolverParameters = solverParameters.filter(
          (x) => x.distributedSolveDefault,
        );
        break;
      default:
        throw Error(`Unknown solve type '${selSolveType}'.`);
    }

    return filteredSolverParameters;
  }

  const handleSolve = async () => {
    setIsSolveClicked(true);
    const selectedSolverParameters = getVisibleSolverParameters(
      selectedSolveType,
    ).map((x) => x as SolverParameterBase);

    try {
      await connectLogHub(selectedSolution.solutionKey);

      showMessage({
        key: 'statusChange',
        type: 'success',
        content: t('GENERAL.FEEDBACK.SOLVE.REQUESTED'),
      });

      solveScenario(
        selectedSolution?.solutionKey || null,
        selectedSolveType,
        selectedSolverParameters,
      );

      setIsLogButtonVisible(() => true);
    } catch (err) {
      //
    }

    onCancel();
  };

  const handleSolverParametersChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!solverParameters || !solverParameters.length) {
      return;
    }
    const newParameters = [...solverParameters];

    newParameters[index].value = event.target.value;
    setSolverParameters(newParameters);
  };

  const visibleParameters = getVisibleSolverParameters(selectedSolveType);

  return (
    <Modal
      data-testid="solve-modal"
      title={t('GENERAL.SOLVE')}
      centered
      open
      onCancel={onCancel}
      width={720}
      footer={[
        <Button key="back" onClick={onCancel} data-testid="cancel-button">
          {t('GENERAL.CANCEL')}
        </Button>,
        <Button
          disabled={isSolveClicked}
          key="submit"
          onClick={handleSolve}
          className="btn-submit text-white bg-blue-600"
          data-testid="scenario-solve-button"
        >
          {t('GENERAL.SOLVE')}
        </Button>,
      ]}
    >
      <Divider />
      <div className="d-flex">
        <div className="flex-grow-1">
          <Title className="secondary-text" level={5}>
            {t('GENERAL.SOLVE_PATTERN.SELECT')}
          </Title>
          <Radio.Group
            onChange={handleSolveTypeChange}
            value={selectedSolveType}
          >
            {solveOptions.map((option: SolveOption) => (
              <div
                key={option.value}
                className={classNames('solve-type-box', {
                  disabled: option.disabled,
                  active: option.value === selectedSolveType,
                })}
                onClick={() => setSelectedSolveType(option.value)}
              >
                <Text className="mr-10">{option.label}</Text>
                <Radio
                  disabled={option.disabled}
                  value={option.value}
                  data-testid={option.testid}
                />
              </div>
            ))}
          </Radio.Group>
        </div>

        <div className="flex-grow-1">
          <Title className="secondary-text" level={5}>
            {t('GENERAL.SOLVE_PARAMETERS.BASIC_PARAMETERS')}
          </Title>
          {solverParameters &&
            solverParameters.map(
              (option: SolverParameter, index: number) =>
                visibleParameters.includes(option) && (
                  <div
                    key={`${
                      option.name
                    }_${selectedSolveType}_${index.toString()}`}
                    data-testid={option.name}
                    className="d-flex justify-between mt-2"
                  >
                    <Text
                      className="col-md-5"
                      title={`${option.description ?? ''}`}
                    >
                      {option.name}
                    </Text>

                    <Input
                      key={`${
                        option.name
                      }_${selectedSolveType}_${index.toString()}`}
                      data-testid="basic-parameter-input"
                      className="col-md-7"
                      type={option.dataType === 'String' ? 'text' : 'number'}
                      title={`min: ${option.minValue ?? '<null>'}, max: ${
                        option.maxValue ?? '<null>'
                      }, default: ${option.defaultValue ?? '<null>'}`}
                      value={option.value}
                      min={option.minValue ?? undefined}
                      max={option.maxValue ?? undefined}
                      onChange={(e) => handleSolverParametersChange(index, e)}
                    />
                  </div>
                ),
            )}
        </div>
      </div>
    </Modal>
  );
}

export default SolveScenario;
