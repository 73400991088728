import React from 'react';
import SlotType from './SlotType/SlotType';
import Networks from './Networks/Networks';

export default function NetworksTab(): JSX.Element {
  return (
    <div className="networks-tab">
      <SlotType className="slot-section" />
      <Networks className="network-section" />
    </div>
  );
}
