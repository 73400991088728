import { useSelector } from 'react-redux';
import { Solution } from 'Models/Scenario';
import { CloneSolveDataToNewSolution } from 'Services/ScenarioService';
import { useEffect, useRef } from 'react';
import { updateQueueSolution } from 'store/slices/queueSlice';
import {
  selectClonedSolutionLocalKeys,
  removeFirstClonedSolutionLocalKey,
  selectLocalSolutionKeyMap,
  addSolution,
  setSolutionKey,
  setSolveDataKey,
  syncSolutionId,
  selectSelectedSolution,
  selectSolveDataKey,
} from 'store/slices/scenarioSlice';
import { SYNC_SOLUTION_KEY_PREFIX } from 'utils/variables';
import { useAppDispatch } from '../storeHooks/hooks';

export const useSolveData = () => {
  const dispatch = useAppDispatch();
  const localKeys = useSelector(selectClonedSolutionLocalKeys);
  const localSolutionKeyMap = useSelector(selectLocalSolutionKeyMap);
  const selectedSolution = useSelector(selectSelectedSolution);
  const solveDataKey = useSelector(selectSolveDataKey);

  const selectedSolutionRef = useRef(selectedSolution);
  const solveDataKeyRef = useRef(solveDataKey);
  const localClonedKeys = useRef(localKeys);
  const localSolutionKeyMapRef = useRef(localSolutionKeyMap);

  const addSolutionToScenario = (
    solution: Solution,
    toBeUpdatedKey?: string,
  ) => {
    dispatch(
      addSolution({
        solution,
        toBeUpdatedKey,
      }),
    );
  };

  const setCurrentSolutionKey = (key: string) => dispatch(setSolutionKey(key));

  const setCurrentSolveDataKey = (key: string) =>
    dispatch(setSolveDataKey(key));

  const syncSolution = (solution: Solution, toBeUpdatedKey: string) => {
    dispatch(
      syncSolutionId({
        solution,
        toBeUpdatedKey,
      }),
    );
  };

  const setClonedSolutionToQueue = (
    oldSolutionKey: string,
    newSolutionKey: string,
  ) =>
    dispatch(
      updateQueueSolution({
        solutionKey: oldSolutionKey,
        newSolutionKey,
      }),
    );

  useEffect(() => {
    selectedSolutionRef.current = selectedSolution;
  }, [selectedSolution]);

  useEffect(() => {
    solveDataKeyRef.current = solveDataKey;
  }, [solveDataKey]);

  useEffect(() => {
    localClonedKeys.current = localKeys;
  }, [localKeys]);

  useEffect(() => {
    localSolutionKeyMapRef.current = localSolutionKeyMap;
  }, [localSolutionKeyMap]);

  const cloneToNewInstance = async (
    shouldQueue?: boolean,
    unlockAllCells: boolean = true,
  ) => {
    let clonedSolution: Solution | null = null;

    if (!selectedSolutionRef.current) return clonedSolution;

    const solutionKey = selectedSolutionRef.current.solutionKey.includes(
      SYNC_SOLUTION_KEY_PREFIX,
    )
      ? localSolutionKeyMapRef.current[selectedSolutionRef.current.solutionKey]
      : selectedSolutionRef.current.solutionKey;

    const idList = solutionKey.split('-');

    const toBeSyncedKey = localClonedKeys.current
      ? localClonedKeys.current[0]
      : '';

    clonedSolution = await CloneSolveDataToNewSolution({
      scenarioKey: `${idList[0]}-${idList[1]}`,
      solutionKey,
      solveDataKey: solveDataKeyRef.current,
      unlockAllCells,
    });

    if (clonedSolution) {
      if (shouldQueue && toBeSyncedKey) {
        syncSolution(clonedSolution, toBeSyncedKey);
        dispatch(removeFirstClonedSolutionLocalKey());
      } else {
        addSolutionToScenario(clonedSolution);
      }

      setCurrentSolutionKey(clonedSolution.solutionKey);
      setCurrentSolveDataKey(clonedSolution.solveDataList[0].solveDataKey);
      setClonedSolutionToQueue(
        `${idList[0]}-${idList[1]}-${idList[2]}`,
        clonedSolution.solutionKey,
      );

      // @ts-expect-error
      if (document.appNavigate) {
        // @ts-expect-error
        document.appNavigate(
          `/scenario/${idList[0]}/${idList[0]}-${idList[1]}`,
        );
      }
    }

    return clonedSolution;
  };

  return {
    cloneToNewInstance,
  };
};

export default useSolveData;
