import { RoundInstance } from './RoundInstance';
import { SlotType } from './Slot';
import { Network, NetworkCategory } from './Network';
import { Venue } from './Venue';
import { Team as TeamType } from './Team';
import { SolutionStatus } from './SolutionStatuses';
import { QueueItem } from './QueueBaseItem';
import { LockType } from './LockTypes';
import { EntityStatusType } from './Common';
import { SolveDataOptimizationStatus } from './SolveDataOptimizationStatus';
import { SolveType } from './SolveTypes';

type Key = string;
type Season = number;

type apiErrorType = {
  message: string;
  name: string;
  stack: string;
};

export interface KeyValueOption {
  key: string;
  value: string;
}

export interface ScenarioMetaData {
  id: string;
  season: string;
  scenarioKey: ScenarioKey;
  name: string;
  logo?: string;
  scenario?: Scenario | null;
  startDate?: Date | null;
  endDate?: Date | null;
  results?: number;
  lastModifiedDate?: Date | null;
}

export interface Scenario extends ScenarioMetaData {
  scenarioStatus: string;
  createdBy: string;
  optimizationEnvelop: OptimizationEnvelop;
}

export interface KeyValuePair {
  key: string;
  value: boolean;
}

type ScenarioKey = string;
type SolutionKey = string;
type NetworkKey = string;
type Username = string;

export const Min = 'Min';
export const Max = 'Max';

export type Operator = typeof Min | typeof Max;

export const Matching = 'Matching';
export const ByTeam = 'ByTeam';
export const BySet = 'BySet';

export type FamilyType = typeof Matching | typeof ByTeam | typeof BySet;

export interface PenaltyCap {
  penaltyCapThreshold?: number | null;
  penaltyScore?: number | null;
}

export interface PenaltyScore {
  penaltyScore: number;
  penaltyCap?: PenaltyCap | null;
}

export interface Constraint extends QueueItem {
  constraintKey: string;
  solutionKey: string;
  comment: string;
  isActive: boolean;
  operator: Operator;
  familyType: FamilyType;
  games: number;
  rounds: number;
  start: number;
  end: number;
  teamsIds: string[];
  penalty: PenaltyScore;
  setId: string;
  constraintStatus: EntityStatusType;
}

export const All = 'All';
export const Home = 'Home';
export const Away = 'Away';
export const Bye = 'Bye';

export type GameType = typeof All | typeof Home | typeof Away | typeof Bye;

export interface SetLine extends QueueItem {
  setLineKey: string;
  gameType: GameType;
  seqNo: number;
  teamId: string | null;
  opponentTeamId: string | null;
  weekDay: number | null;
  roundNumber: number | null;
  slotTypeId: string | null;
  networkId: string | null;
  networkCategoryId: string | null;
  venueId: string | null;
  setLineStatus: EntityStatusType;
  setKey?: string;
  solutionKey?: string;
}

export interface Set extends QueueItem {
  setKey: string;
  setId: string;
  solutionKey: string;
  name: string;
  setLines: SetLine[];
  setStatus: EntityStatusType;
  toBesyncedSetId?: string;
}

export interface ConstraintsEnvelop {
  sets: Set[];
  constraints: Constraint[];
}

export interface RoundsDictionaries {
  slotTypes: SlotType[];
  networks: Network[];
}

export interface VenueTeam {
  venueTeamKey: string;
  teamIds: string[];
  venueId: string;
  venueTeamStatus: string;
}

export interface TimeSlot extends QueueItem {
  timeSlotText: string;
  timeSlotKey: string;
  timeSlotId: string;
  slotTypeId: string;
  networkCategoryId: string;
  networkId: string;
  networkCategory?: NetworkCategory;
  slotType?: SlotType;
  timeSlotStatus: string;
}

export interface RoundDay extends QueueItem {
  roundDayKey: string;
  weekDay: number;
  roundDayStatus: string;
  isActive: boolean;
  timeSlots: TimeSlot[];
}

export interface RoundTemplate extends QueueItem {
  roundTemplateKey: string;
  name: string;
  startDate: Date;
  endDate: Date;
  roundTemplateStatus: EntityStatusType;
  roundDays: RoundDay[];
  groupBatchId?: string;
}

export type RoundTemplateResponsePromiseType = {
  type: string;
  payload: {
    data: {
      roundTemplates: RoundTemplate[];
    };
  };
  error?: apiErrorType;
};

export interface OptimizationEnvelop {
  solutions: Solution[];
}

export interface Solution extends QueueItem {
  solutionKey: string;
  solutionIndex: number;
  startedBy?: Username;
  solutionStatus: SolutionStatus;
  solveDataOptimizationStatus: SolveDataOptimizationStatus;
  solveType: SolveType | null;
  createTime: Date;
  stateData: StateData;
  solveDataList: SolveData[];
}

export interface StateData {
  solutionKey: string;
  venues: Venue[];
  venueTeams: VenueTeam[];
  teams: TeamType[];
  constraintsEnvelop: ConstraintsEnvelop;
  roundTemplates: RoundTemplate[];
  opponents: Opponents;
  roundsDictionaries: RoundsDictionaries;
  opponentsPenalty: number;
  roundsInstances: RoundInstance[];
  roundTemplateWeekStartDay?: number;
}

export interface SolveData {
  solutionKey: string;
  solveDataKey: string;
  optimizedOptions: OptimizedOption[];
  optimizedColumns: OptimizedColumn[];
  penalties: Penalty[];
  totalPenalty: number | null;
  solveDataStatus: string;
  solveDataOptimizationStatus: SolveDataOptimizationStatus;
  solCount: number;
  modelCalculatingTime: string;
  selectedColumnsCount: number;
  timestamp: Date;
}

export interface ModelSummary {
  data: string;
}

export const ColumnTypeNotSet = 'NotSet';
export const ColumnTypeBye = 'Bye';
export const ColumnTypeMatch = 'Match';
export const ColumnTypePenalty = 'BySet';

export type ColumnType =
  | typeof ColumnTypeNotSet
  | typeof ColumnTypeMatch
  | typeof ColumnTypeBye
  | typeof ColumnTypePenalty;

export interface OptimizedColumn {
  columnType: ColumnType;
  columnId: string;
  columnShortId: string;
  homeTeamId: string;
  awayTeamId: string | null;
  slotKey: string;
  isLocked: boolean;
}
export interface Penalty {
  id: string;
  name: string;
  penalty: number;
  penaltyHappenedTimes: number;
  constraintKey: string;
}

export interface OptimizedOption {
  columnShortId: string;
  objectiveX: number;
  isLocked: boolean;
}

export interface ScenarioSlice {
  scenarioKey: string;
  scenarios: ScenarioMetaData[];
  solutionKey: string;
  prevSolutionKey: string;
  solveDataKey: string;
  season: number;
  isLoaded: boolean;
  toBeClonedSolutionLocalKeys: string[];
  clonedSolutionLocalKeyMap: Record<string, string>;
}

export type ScenarioResponsePromiseType = {
  type: string;
  payload: {
    data: Scenario;
  };
  error?: apiErrorType;
};

export type SolutionResponsePromiseType = {
  type: string;
  payload: {
    data: Solution;
  };
  error?: apiErrorType;
};

export type SolutionMetaResponsePromiseType = {
  type: string;
  payload: {
    data: Solution[];
  };
  error?: apiErrorType;
};

export type ScenarioSolveResponsePromiseType = {
  type: string;
  payload: {
    data: Solution | null;
  };
  error?: apiErrorType;
};

export type ScenarioSolveStopResponsePromiseType = {
  type: string;
  payload: {};
  error?: apiErrorType;
};

export type ScenarioConstraintResponsePromiseType = {
  type: string;
  payload: {
    data: Constraint;
  };
  error?: apiErrorType;
};

export type ScenarioSlotTypeResponsePromiseType = {
  type: string;
  payload: {
    data: SlotType;
  };
  error?: apiErrorType;
};

export type ScenarioSetResponsePromiseType = {
  type: string;
  payload: {
    data: Set;
  };
  error?: apiErrorType;
};

export type ScenarioSetLineResponsePromiseType = {
  type: string;
  payload: {
    data: SetLine;
  };
  error?: apiErrorType;
};

export type ScenarioNetworkResponsePromiseType = {
  type: string;
  payload: {
    data: Network;
  };
  error?: apiErrorType;
};

export type ScenarioNetworkCayegoryResponsePromiseType = {
  type: string;
  payload: {
    data: NetworkCategory;
  };
  error?: apiErrorType;
};

export type ScenarioTimeSlotResponsePromiseType = {
  type: string;
  payload: {
    data: TimeSlot;
  };
  error?: apiErrorType;
};

export type NameMap = Record<string | number, string | number>;

export type SolveTableType = 'all' | 'home' | 'away' | 'locked' | 'unlocked';

export type ZoomType = '100' | '90' | '80' | '70' | '60';

export type LogMessageType = 'ApplicationLog' | 'SolverLog';

export type LogMessagesPayload = {
  solutionKey: string;
  logMessageType: LogMessageType;
};

export type LogMessagesResponse = {
  messages: string;
};

export type GamesLockPayload = {
  scenarioKey: string;
  solutionKey: string;
  solveDataKey: string;
  lockType: LockType;
  columnShortIds: string[];
  solutionStatus: string;
};

export type RoundTemplateKeys = {
  parentKey: string;
  childKeys: string[];
};

export type RoundDayKeyMap = {
  roundDayKey: string;
  timeSlots: string[];
};

export type RoundTemplateMapKeys = {
  roundTemplateKey: string;
  roundDays: RoundDayKeyMap[];
};

export type RoundType = {
  startDate: Date;
  endDate: Date;
  repeatType: string;
  count?: number;
  endOn?: Date;
  toBeSyncedKeys?: RoundTemplateKeys[];
  actualData?: RoundTemplate[];
};

export type RoundCreatePayload = {
  data: RoundType;
  scenarioKey: string;
  solutionKey: string;
  toBeSyncedKeys?: RoundTemplateKeys[];
};

export type RoundCreateResponseType = {
  roundTemplates: RoundTemplate[];
};

export type RoundUpdateUnitType = {
  startDate: Date;
  endDate: Date;
  roundTemplateKey: string;
  roundTemplateStatus: string;
  groupBatchId?: string;
  name?: string;
  toBeSyncedKey?: string;
};

export type RoundUpdatePayload = {
  data: RoundUpdateUnitType[];
  scenarioKey: string;
  solutionKey: string;
};

export type RoundWeekStartDayPayload = {
  data: {
    roundTemplateWeekStartDay: number;
  };
  solutionKey: string;
};

export type CreateOrUpdateSlotTypePayload = {
  slotType: SlotType;
  scenarioKey: string;
  solutionKey: string;
};

export interface SlotUpdateUnitType extends QueueItem {
  timeSlotKey: string;
  timeSlotText: string;
  slotTypeId: string;
  networkCategoryId: string;
  networkId: string;
  status: string;
  roundTemplateKey: string;
  roundDayKey: string;
  timeSlotStatus?: EntityStatusType;
  timeSlotId?: string;
}

export type SlotUpdatePayload = {
  data: SlotUpdateUnitType;
  scenarioKey: string;
  solutionKey: string;
};
