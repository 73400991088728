export const SolveTypeRegular = 'Regular';
export const SolveTypeSeeder = 'Seeder';
export const SolveTypeLocalSearch = 'LocalSearch';
export const SolveTypeDistributed = 'Distributed';

export type SolveType =
  typeof SolveTypeRegular
  | typeof SolveTypeSeeder
  | typeof SolveTypeLocalSearch
  | typeof SolveTypeDistributed;
