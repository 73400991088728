import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Venue, VenueResponsePromiseType } from 'Models/Venue';
import {
  QueueCreateUpdateVenuePayloadType,
  QueueUpdateTeamVenuePayloadType,
} from 'Models/Queue';
import {
  saveVenues,
  saveVenue,
  deleteVenue,
  updateVenueTeam,
} from 'store/slices/venueSlice';
import { syncVenue, syncTeamVenueMap } from 'store/slices/scenarioSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { CREATED, DELETED } from 'utils/variables';
import { useRef, useEffect } from 'react';
import { useAppDispatch } from '../hooks';

export type SyncVenueMapPayload = {
  data: {
    venueKey: string;
    teamKey: string;
  };
  toBeUpdatedFields: {
    type: 'update' | 'delete';
  };
  solutionKey: string;
};

export const useVenue = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareVenuePayload = ({
    data,
    toBeUpdatedFields,
  }: QueueCreateUpdateVenuePayloadType): Venue[] => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    if (Array.isArray(newPayload)) {
      newPayload = newPayload.map((venue) => {
        if (queueLocalKeyMapRef.current[venue.venueKey]) {
          venue.venueKey = queueLocalKeyMapRef.current[venue.venueKey];
        }

        return { ...venue, ...toBeUpdatedFields };
      });

      return newPayload;
    }

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    newPayload = newPayload as Venue;

    if (queueLocalKeyMapRef.current[newPayload.venueKey]) {
      newPayload.venueKey = queueLocalKeyMapRef.current[newPayload.venueKey];
    }

    return [newPayload];
  };

  const syncVenueToStore = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
    solutionKey,
  }: QueueCreateUpdateVenuePayloadType): void => {
    const preparedVenue = prepareVenuePayload({ data, toBeUpdatedFields });

    dispatch(
      syncVenue({
        data: preparedVenue,
        solutionKey,
        toBeSyncedKey,
      }),
    );
  };

  const createOrUpdateVenueQueue = ({
    data,
    toBeUpdatedFields,
    actualSolutionKey,
  }: {
    data: Venue[];
    toBeUpdatedFields: {
      [key in keyof Venue]?: Venue[key];
    };
    actualSolutionKey: string;
  }): Promise<VenueResponsePromiseType | null> => {
    const preparedData = prepareVenuePayload({
      data,
      toBeUpdatedFields,
    });

    if (preparedData[0].venueStatus === CREATED) {
      return dispatch(
        saveVenue({
          venuePayload: {
            venue: preparedData[0],
            solutionKey: actualSolutionKey,
          },
          solutionStatus: CREATED,
        }),
      ) as Promise<VenueResponsePromiseType>;
    }
    if (preparedData[0].venueStatus === DELETED) {
      return dispatch(
        deleteVenue({
          venueDeletePayload: {
            venueKey: preparedData[0].venueKey,
            solutionKey: actualSolutionKey,
          },
          solutionStatus: CREATED,
        }),
      ) as Promise<VenueResponsePromiseType>;
    }

    if (preparedData) {
      return dispatch(
        saveVenues({
          venuesPayload: {
            venues: preparedData,
            solutionKey: actualSolutionKey,
          },
          solutionStatus: CREATED,
        }),
      ) as Promise<VenueResponsePromiseType>;
    }

    return Promise.resolve(null);
  };

  const prepareTeamVenueMapPayload = ({
    data,
    toBeUpdatedFields,
  }: QueueUpdateTeamVenuePayloadType): typeof data => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (queueLocalKeyMapRef.current[newPayload.venueKey]) {
      newPayload.venueKey = queueLocalKeyMapRef.current[newPayload.venueKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.teamKey]) {
      newPayload.teamKey = queueLocalKeyMapRef.current[newPayload.teamKey];
    }

    return newPayload;
  };

  const syncTeamVenueMapToStore = ({
    data,
    toBeUpdatedFields,
    solutionKey,
  }: SyncVenueMapPayload): void => {
    const preparedData = prepareTeamVenueMapPayload({
      data,
      toBeUpdatedFields,
    });

    dispatch(
      syncTeamVenueMap({
        venueKey: preparedData.venueKey,
        teamKey: preparedData.teamKey,
        type: toBeUpdatedFields.type,
        solutionKey,
      }),
    );
  };

  const updateTeamVenueMapQueue = ({
    data,
    toBeUpdatedFields,
    actualSolutionKey,
  }: {
    data: {
      venueKey: string;
      teamKey: string;
    };
    toBeUpdatedFields: {
      type: 'update' | 'delete';
    };
    actualSolutionKey: string;
  }): Promise<VenueResponsePromiseType | null> => {
    const preparedData = prepareTeamVenueMapPayload({
      data,
      toBeUpdatedFields,
    });

    if (preparedData) {
      return dispatch(
        updateVenueTeam({
          venueTeamMapUpdatePayload: {
            data: preparedData,
            type: toBeUpdatedFields.type,
            solutionKey: actualSolutionKey,
          },
          solutionStatus: CREATED,
        }),
      ) as Promise<VenueResponsePromiseType>;
    }

    return Promise.resolve(null);
  };

  return {
    prepareVenuePayload,
    syncVenueToStore,
    createOrUpdateVenueQueue,
    prepareTeamVenueMapPayload,
    syncTeamVenueMapToStore,
    updateTeamVenueMapQueue,
  };
};

export default useVenue;
