import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Search, ArrowLeft, ArrowRight } from 'Components/Elements/Icons';
import { getSeasons } from 'Components/Common/TimeUtils';
import Table from 'react-bootstrap/Table';
import useScenario from 'storeHooks/useScenario';
import { ScenarioClone, ScenarioCreateEdit } from 'Components/Modules/Scenario';
import { ScenarioMetaData } from 'Models/Scenario';
import ScenariosTableLoader from './ScenariosTableLoader';
import ScenariosTableBody from './ScenariosTableBody';
import './Scenario.scss';

type MyOption = { label: string; value: number };

const currentYear = new Date().getFullYear();

const baseSeason: MyOption = {
  value: currentYear,
  label: currentYear.toString(),
};

const numberOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
];

const selectStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
};

function ScenariosTable(): JSX.Element {
  const { t } = useTranslation();
  const [loadingScenariosMeta, setLoadingScenariosMeta] = useState(false);
  const {
    selectedSeason,
    setSelectedSeason,
    scenarioMetas,
    getMetas,
    onDeleteScenario,
  } = useScenario();
  const navigate = useNavigate();
  const [scenarioPerPage, setScenarioPerPage] = useState(numberOptions[0]);
  const [scenario, setScenario] = useState<ScenarioMetaData | null>(null);
  const [season, setSeason] = useState(baseSeason);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState('1');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isCloneModalVisible, setIsCloneModalVisible] = useState(false);
  const isLoading = useRef(false);

  useEffect(() => {
    if (selectedSeason) {
      setSeason({
        value: selectedSeason,
        label: selectedSeason.toString(),
      });
    }
  }, [selectedSeason]);

  const openCloneScenarioModal = (item: ScenarioMetaData) => {
    setScenario(item);
    setIsCloneModalVisible(true);
  };

  const openEditScenarioModal = (item: ScenarioMetaData) => {
    setScenario(item);
    setIsEditModalVisible(true);
  };

  const availableSeasons = useMemo(
    () => getSeasons().map((item) => ({ label: item, value: Number(item) })),
    [],
  );
  const validPageNumber = useMemo(() => {
    const pageNumber = Number(page);

    return pageNumber > 0 ? pageNumber : 1;
  }, [page]);
  const filteredScenarios = useMemo(() => {
    const validQuery = query.toLowerCase().trim();

    if (validQuery) {
      return scenarioMetas.filter((item) =>
        item.name.toLowerCase().includes(validQuery),
      );
    }

    return scenarioMetas;
  }, [scenarioMetas, query]);

  const visibleScenarios = useMemo(() => {
    const start = (validPageNumber - 1) * scenarioPerPage.value;
    const end = start + scenarioPerPage.value;

    return filteredScenarios.slice(start, end);
  }, [filteredScenarios, scenarioPerPage, validPageNumber]);

  const totalNumberOfPages = Math.ceil(
    filteredScenarios.length / scenarioPerPage.value,
  );

  const hasNextPage =
    filteredScenarios.length > validPageNumber * scenarioPerPage.value;
  const hasPrevPage =
    scenarioPerPage.value < validPageNumber * scenarioPerPage.value;

  const onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onHandleNextPage = () => {
    setPage((validPageNumber + 1).toString());
  };

  const onHandlePrevPage = () => {
    setPage((validPageNumber - 1).toString());
  };

  const onPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(event.target.value);
  };

  const onShowCurrentPage = () => {
    setPage(validPageNumber.toString());
  };

  const onChangePerPage = (selected?: MyOption | null) => {
    if (selected) {
      setScenarioPerPage(selected);
    }
  };

  const onSeasonPage = (selected?: MyOption | null) => {
    if (selected) {
      setSeason(selected);
      setSelectedSeason(selected.value);
    }
  };

  const goTo = (path: string) => {
    navigate(path);
  };

  const resetScenario = () => {
    setScenario(null);
    setIsCloneModalVisible(false);
    setIsEditModalVisible(false);
  };

  useEffect(() => {
    async function getScenariosMeta() {
      if (selectedSeason && !isLoading.current) {
        setLoadingScenariosMeta(true);
        setPage('1');
        isLoading.current = true;
        await getMetas(selectedSeason);
        setLoadingScenariosMeta(false);
        isLoading.current = false;
      }
    }

    getScenariosMeta();
  }, [selectedSeason]);

  return (
    <div className="scenarios-container">
      <div className="filters-container flex justify-between items-center px-3 pt-3 pb-1">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <Search />
          </div>
          <input
            type="text"
            id="input-group-1"
            className="w-80 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  "
            placeholder={t('GENERAL.SEARCH')}
            value={query}
            onChange={onQueryChange}
          />
        </div>

        <Select
          options={availableSeasons}
          className="w-52"
          styles={selectStyles}
          value={season}
          onChange={onSeasonPage}
        />
      </div>

      <div className="scenarios-table">
        <Table hover className="w-full mb-0">
          <thead>
            <tr>
              <td
                align="left"
                className="table-title"
                data-testid="table-title"
              >
                <strong>{t('GENERAL.SCENARIO.NAME')}</strong>
              </td>
              <td align="left" className="season" data-testid="season-title">
                <strong>{t('GENERAL.SEASON')}</strong>
              </td>
              <td align="left" className="results" data-testid="results-title">
                <strong>{t('GENERAL.RESULTS')}</strong>
              </td>
              <td align="left" className="rounds" data-testid="rounds-title">
                <strong>{t('GENERAL.ROUNDS_DATE')}</strong>
              </td>
              <td
                align="left"
                className="modified-at"
                data-testid="modifiedat-title"
              >
                <strong>{t('GENERAL.MODIFIED_AT')}</strong>
              </td>
              <td align="left" className="action" data-testid="actions-title">
                <strong>{t('GENERAL.ACTIONS')}</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            {loadingScenariosMeta ? (
              <tr>
                <td colSpan={6}>
                  <ScenariosTableLoader loading={loadingScenariosMeta} />
                </td>
              </tr>
            ) : (
              <ScenariosTableBody
                visibleScenarios={visibleScenarios}
                openEditScenarioModal={openEditScenarioModal}
                openCloneScenarioModal={openCloneScenarioModal}
                onDeleteScenario={onDeleteScenario}
                goTo={goTo}
              />
            )}
          </tbody>
        </Table>
      </div>

      <footer>
        <Select
          value={scenarioPerPage}
          options={numberOptions}
          onChange={onChangePerPage}
          className="w-24"
          menuPlacement="top"
          styles={selectStyles}
        />

        <div className="flex gap-2 justify-evenly items-center ">
          <input
            type="text"
            className="w-24 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-1.5  "
            value={page}
            onChange={onPageChange}
            onBlur={onShowCurrentPage}
          />

          <span className="w-24">
            {t('GENERAL.OFF_PAGES', {
              count: totalNumberOfPages,
            })}
          </span>

          <div className="flex justify-center items-center">
            <button
              type="button"
              className="w-12 rounded-l-lg border border-blue-700 flex justify-center items-center p-1"
              onClick={onHandlePrevPage}
              disabled={!hasPrevPage}
            >
              <ArrowLeft color={!hasPrevPage ? '#F1EFEF' : '#808283'} />
            </button>

            <button
              type="button"
              className="w-12 rounded-r-lg border border-blue-700 flex justify-center items-center p-1"
              onClick={onHandleNextPage}
              disabled={!hasNextPage}
            >
              <ArrowRight color={!hasNextPage ? '#F1EFEF' : '#808283'} />
            </button>
          </div>
        </div>
      </footer>

      {scenario && isCloneModalVisible && (
        <ScenarioClone scenario={scenario} onCancel={resetScenario} />
      )}

      {scenario && isEditModalVisible && (
        <ScenarioCreateEdit scenario={scenario} onCancel={resetScenario} />
      )}
    </div>
  );
}

export default ScenariosTable;
