import React, { useRef, useState, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Network, NetworkCategory } from 'Models/Network';
import { NameMap } from 'Models/Scenario';
import { Button, ColorPicker, message, Input } from 'antd';
import type { InputRef } from 'antd';
import { Trash } from 'Components/Elements/Icons';
import { PlusOutlined } from '@ant-design/icons';
import useLocale from 'locales/localeMapGrid';
import { decodeHTML, randomId } from 'utils/ui-helper';
import {
  CREATED,
  DELETED,
  NEW,
  SYNC_NETWORK_CATEGORY_KEY_PREFIX,
  SYNC_NETWORK_KEY_PREFIX,
} from 'utils/variables';
import useRoundNetwork, { DEFAULT_COLOR } from '../useRoundNetwork';

import useNetwork from 'storeHooks/queue/useNetwork';
import useNetworkCategory from 'storeHooks/queue/useNetworkCategory';
import useQueue from 'storeHooks/useQueue';
import useSolution from 'customHooks/useSolution';
import { QueueCreateUpdateNetworkCategoryPayloadType } from 'Models/Queue';
import {
  selectSlotTypes,
  selectSelectedSolution,
} from 'store/slices/scenarioSlice';
import './style.scss';

interface Props {
  className?: string;
  network: Network;
}

interface CellParamType {
  data: NetworkCategory;
}

const validator: Record<string, { maxLength?: number }> = {
  name: {
    maxLength: 200,
  },
};

const validate = (field: string, value: string): string => {
  if (!validator[field]) return value;

  if (validator[field].maxLength) {
    return value.slice(0, validator[field].maxLength);
  }

  return value;
};

function ColorCellRenderer({ data }: CellParamType) {
  const { t } = useTranslation();
  const selectedSolution = useSelector(selectSelectedSolution);
  const selectedSolutionRef = useRef(selectedSolution);
  const { onEnqueue } = useQueue();
  const { syncNetworkCategoryToStore } = useNetworkCategory();
  const { getCurrentOrClonedSolution } = useSolution();
  const [colorHex, setColorHex] = useState<string>(data.color);
  const [open, setOpen] = useState(false);
  const savedColor = useRef(data.color);

  const onClose = () => {
    setColorHex(data.color);
    setOpen(false);
  };

  useEffect(() => {
    if (selectedSolution) {
      selectedSolutionRef.current = selectedSolution;
    }
  }, [selectedSolution]);

  const onApply = async () => {
    setColorHex(savedColor.current);
    setOpen(false);

    if (!selectedSolutionRef.current) return;

    const targetNetwork =
      selectedSolutionRef.current.stateData.roundsDictionaries.networks.find(
        (item) =>
          item.networkCategories
            .map((nc) => nc.networkCategoryKey)
            .findIndex((key) => key === data.networkCategoryKey) !== -1,
      )!;

    const oldData = targetNetwork.networkCategories.find(
      (item) => item.networkCategoryKey === data.networkCategoryKey,
    )!;

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    const toBeUpdatedFields: QueueCreateUpdateNetworkCategoryPayloadType['toBeUpdatedFields'] =
      {};
    toBeUpdatedFields.color = savedColor.current;

    const toBeSyncedKey = `${SYNC_NETWORK_CATEGORY_KEY_PREFIX}${uuidv4()}`;

    syncNetworkCategoryToStore({
      data: oldData,
      toBeUpdatedFields,
      solutionKey: chosenSolution.solutionKey,
      networkKey: targetNetwork.networkKey,
      toBeSyncedKey,
    });

    onEnqueue({
      type: 'CREATE_UPDATE_NETWORK_CATEGORY',
      payload: {
        data: {
          ...oldData,
          solutionKey: chosenSolution.stateData.solutionKey,
        },
        toBeUpdatedFields,
        toBeSyncedKey,
        solutionKey: chosenSolution.solutionKey,
        networkKey: targetNetwork.networkKey,
      },
    });
  };

  // @ts-expect-error
  const panelRender = (__, { components: { Picker, Presets } }) => (
    <div className="custom-panel">
      <Picker />
      <Presets />
      <div className="mt-4 flex items-center gap-1">
        <Button size="small" onClick={onClose}>
          {t('GENERAL.CANCEL')}
        </Button>
        <Button size="small" type="primary" onClick={onApply}>
          {t('GENERAL.APPLY')}
        </Button>
      </div>
    </div>
  );

  return (
    <ColorPicker
      format="hex"
      open={open}
      value={colorHex}
      panelRender={panelRender}
      onChangeComplete={(color) => {
        savedColor.current = color.toHexString();
      }}
      // @ts-expect-error
      onClick={() => setOpen(true)}
    />
  );
}

export default function NetworkCategories({
  className,
  network,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const selectedSolution = useSelector(selectSelectedSolution);
  const selectedSolutionRef = useRef(selectedSolution);
  const { code } = useLocale();
  const { isDeletable } = useRoundNetwork();
  const { onEnqueue } = useQueue();
  const { syncNetworkToStore } = useNetwork();
  const { syncNetworkCategoryToStore } = useNetworkCategory();
  const {
    getCurrentOrClonedSolution,
    canDeleteNetwork,
    canDeleteNetworkCategory,
  } = useSolution();
  const slotTypes = useSelector(selectSlotTypes);
  const gridRef = useRef();
  const inputRef = useRef<InputRef>(null);
  const codeRef = useRef<InputRef>(null);
  const networkName = useRef('');
  const networkCode = useRef('');
  const [isEditingNetwork, setIsEditingNetwork] = useState(false);
  const [isEditingNetworkCode, setIsEditingNetworkCode] = useState(false);

  const slotsMap = useMemo(() => {
    if (slotTypes) {
      return slotTypes.reduce((acc, item) => {
        acc[item.slotTypeId] = item.name;
        return acc;
      }, {} as NameMap);
    }
    return {};
  }, [slotTypes]);

  const onCloneCategory = async (data: NetworkCategory) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    const targetNetwork =
      chosenSolution.stateData.roundsDictionaries.networks.find(
        (item) => item.networkKey === network.networkKey,
      );

    if (!targetNetwork) return;

    const updatedNetworkCategory = targetNetwork.networkCategories.find(
      (category) => category.networkCategoryKey === data.networkCategoryKey,
    );

    if (!updatedNetworkCategory) return;

    const { solutionKey: actionSolutionKey } = chosenSolution.stateData;

    const toBeSyncedKey = `${SYNC_NETWORK_CATEGORY_KEY_PREFIX}${uuidv4()}`;

    const networkCategory: NetworkCategory = {
      color: updatedNetworkCategory.color,
      name: t('GENERAL.CLONE_ITEM', {
        name: updatedNetworkCategory.name,
      }),
      network: null,
      code: `NC${randomId(3)}`,
      slotType: updatedNetworkCategory.slotType
        ? updatedNetworkCategory.slotType
        : null,
      slotTypeId: updatedNetworkCategory.slotTypeId
        ? updatedNetworkCategory.slotTypeId
        : null,
      networkCategoryId: toBeSyncedKey,
      networkCategoryKey: toBeSyncedKey,
      networkCategoryStatus: CREATED,
      toBeSyncedKey,
    };

    syncNetworkCategoryToStore({
      data: networkCategory,
      solutionKey: chosenSolution.solutionKey,
      networkKey: targetNetwork.networkKey,
      toBeSyncedKey,
    });

    onEnqueue({
      type: 'CREATE_UPDATE_NETWORK_CATEGORY',
      payload: {
        data: {
          ...networkCategory,
          networkCategoryStatus: NEW,
          solutionKey: actionSolutionKey,
        },
        toBeSyncedKey,
        solutionKey: chosenSolution.solutionKey,
        networkKey: targetNetwork.networkKey,
      },
    });
  };

  const columnDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('GENERAL.NAME.TITLE'),
        editable: true,
        cellStyle: {
          paddingLeft: '64px',
        },
        flex: 3,
        cellClass: 'pointer-arrow',
      },
      {
        field: 'code',
        headerName: code,
        maxWidth: 180,
        minWidth: 180,
        editable: true,
      },
      {
        field: 'slotTypeId',
        headerName: '',
        maxWidth: 180,
        minWidth: 180,
        editable: true,
        resizable: true,
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams: {
          values: Object.keys(slotsMap),
        },
        refData: slotsMap,
      },
      {
        field: 'color',
        headerName: '',
        editable: false,
        cellRenderer: ColorCellRenderer,
        maxWidth: 130,
        minWidth: 130,
        cellStyle: {
          paddingLeft: '40px',
        },
      },
      {
        headerClass: 'ag-grid-right-header-aligned',
        cellClass: 'trash-icon',
        maxWidth: 50,
        onCellClicked: (event: CellParamType) => {
          if (!selectedSolutionRef.current) return;

          if (!canDeleteNetworkCategory(event.data.networkCategoryId)) return;

          if (
            !isDeletable(
              event.data.networkCategoryKey,
              'network-category',
              selectedSolutionRef.current,
            )
          ) {
            message.warning(
              t('GENERAL.FEEDBACK.NETWORK_CATEGORY.NOT_DELETABLE'),
            );
            return;
          }

          const chosenSolution = getCurrentOrClonedSolution();

          if (!chosenSolution) return;

          const { solutionKey: actionSolutionKey } = chosenSolution.stateData;

          const currentNetwork =
            chosenSolution.stateData.roundsDictionaries.networks.find(
              (n) => n.networkKey === network.networkKey,
            )!;

          const toBeDeletedNetworkCategory =
            currentNetwork.networkCategories.find(
              (item) =>
                item.networkCategoryKey === event.data.networkCategoryKey,
            );

          const toBeSyncedKey = `${SYNC_NETWORK_KEY_PREFIX}${uuidv4()}`;

          const deletedNetworkCategory =
            toBeDeletedNetworkCategory ?? event.data;

          const networkCategory = {
            ...deletedNetworkCategory,
            networkCategoryStatus: DELETED,
          } as NetworkCategory;

          syncNetworkCategoryToStore({
            data: networkCategory,
            solutionKey: chosenSolution.solutionKey,
            networkKey: currentNetwork.networkKey,
            toBeSyncedKey,
          });

          onEnqueue({
            type: 'CREATE_UPDATE_NETWORK_CATEGORY',
            payload: {
              data: {
                ...deletedNetworkCategory,
                networkCategoryStatus: DELETED,
                solutionKey: actionSolutionKey,
              },
              toBeSyncedKey,
              solutionKey: chosenSolution.solutionKey,
              networkKey: currentNetwork.networkKey,
            },
          });
        },
      },
      {
        headerClass: 'ag-grid-right-header-aligned',
        cellClass: 'clone-icon',
        maxWidth: 50,
        onCellClicked: (event: CellParamType) => {
          onCloneCategory(event.data);
        },
      },
    ],
    [slotsMap],
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
    }),
    [],
  );

  const enableEditCodeMode = () => {
    if (isEditingNetworkCode) return;

    networkCode.current = network.code;
    networkName.current = network.name;

    setIsEditingNetworkCode(true);

    setTimeout(() => {
      if (codeRef.current) {
        codeRef.current.focus();
      }
    }, 100);
  };

  const onChangeNetworkCode = (e: React.FormEvent<HTMLInputElement>) => {
    networkCode.current = e.currentTarget.value;
  };

  const enableEditMode = () => {
    if (isEditingNetwork) return;

    networkName.current = network.name;
    networkCode.current = network.code;

    setIsEditingNetwork(true);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  const onChangeNetworkName = (e: React.FormEvent<HTMLInputElement>) => {
    networkName.current = e.currentTarget.value;
  };

  const onUpdateNetworkProperty = (property: 'name' | 'code') => {
    setIsEditingNetwork(false);
    setIsEditingNetworkCode(false);

    const updatedNetworkName = networkName.current.trim();
    const updatedNetworkCode = networkCode.current.trim();
    const invalidName =
      property === 'name' &&
      (updatedNetworkName === '' || updatedNetworkName === network.name);
    const invalidCode =
      property === 'code' &&
      (updatedNetworkCode === '' || updatedNetworkCode === network.code);

    if (invalidName || invalidCode) {
      return;
    }

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    const { solutionKey: actionSolutionKey } = chosenSolution.stateData;

    const toBeSyncedKey = `${SYNC_NETWORK_KEY_PREFIX}${uuidv4()}`;

    const updatedNetwork = {
      ...network,
      name: updatedNetworkName === '' ? network.name : updatedNetworkName,
      code: updatedNetworkCode === '' ? network.code : updatedNetworkCode,
    } as Network;

    syncNetworkToStore({
      data: updatedNetwork,
      solutionKey: chosenSolution.solutionKey,
      toBeSyncedKey,
    });

    onEnqueue({
      type: 'CREATE_UPDATE_NETWORK',
      payload: {
        data: {
          ...updatedNetwork,
          solutionKey: actionSolutionKey,
        },
        toBeSyncedKey,
        solutionKey: chosenSolution.solutionKey,
      },
    });
  };

  const onDeleteNetwork = async () => {
    if (!canDeleteNetwork(network.networkId)) return;

    if (selectedSolution) {
      if (!isDeletable(network.networkKey, 'network', selectedSolution)) {
        message.warning(t('GENERAL.FEEDBACK.NETWORK.NOT_DELETABLE'));
        return;
      }

      const chosenSolution = getCurrentOrClonedSolution();

      if (!chosenSolution) return;

      const { solutionKey: actionSolutionKey } = chosenSolution.stateData;

      const toBeSyncedKey = `${SYNC_NETWORK_KEY_PREFIX}${uuidv4()}`;

      const updatedNetwork = {
        ...network,
        networkStatus: DELETED,
      } as Network;

      syncNetworkToStore({
        data: updatedNetwork,
        solutionKey: chosenSolution.solutionKey,
        toBeSyncedKey,
      });

      onEnqueue({
        type: 'CREATE_UPDATE_NETWORK',
        payload: {
          data: {
            ...updatedNetwork,
            networkStatus: DELETED,
            solutionKey: actionSolutionKey,
            networkCategories: updatedNetwork.networkCategories.map((item) => ({
              ...item,
              networkCategoryStatus: DELETED,
              solutionKey: actionSolutionKey,
            })),
          },
          toBeSyncedKey,
          solutionKey: chosenSolution.solutionKey,
        },
      });
    }
  };

  const onCategoryCreate = async () => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    const targetNetwork =
      chosenSolution.stateData.roundsDictionaries.networks.find(
        (item) => item.networkKey === network.networkKey,
      );

    if (!targetNetwork) return;

    const { solutionKey: actionSolutionKey } = chosenSolution.stateData;

    const toBeSyncedKey = `${SYNC_NETWORK_CATEGORY_KEY_PREFIX}${uuidv4()}`;

    const networkCategoryCount = targetNetwork.networkCategories.length + 1;

    const networkCategory: NetworkCategory = {
      color: network.color ? network.color : DEFAULT_COLOR,
      name: `${t('GENERAL.NETWORK.NEW_EMPTY')} ${networkCategoryCount}`,
      network: null,
      code: `NC${randomId(3)}`,
      networkCategoryId: toBeSyncedKey,
      networkCategoryKey: toBeSyncedKey,
      networkCategoryStatus: CREATED,
      toBeSyncedKey,
    };

    syncNetworkCategoryToStore({
      data: networkCategory,
      solutionKey: chosenSolution.solutionKey,
      networkKey: targetNetwork.networkKey,
      toBeSyncedKey,
    });

    onEnqueue({
      type: 'CREATE_UPDATE_NETWORK_CATEGORY',
      payload: {
        data: {
          ...networkCategory,
          networkCategoryStatus: NEW,
          solutionKey: actionSolutionKey,
        },
        toBeSyncedKey,
        solutionKey: chosenSolution.solutionKey,
        networkKey: targetNetwork.networkKey,
      },
    });
  };

  // @ts-expect-error
  const onCellEditRequest = (event) => {
    const { newValue } = event;
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    if (newValue !== undefined) {
      const targetNetwork =
        chosenSolution.stateData.roundsDictionaries.networks.find(
          (item) => item.networkKey === network.networkKey,
        )!;

      const oldData = targetNetwork.networkCategories.find(
        (item) => item.networkCategoryKey === event.data.networkCategoryKey,
      )!;

      const { field } = event.colDef;

      const newData = { ...oldData };

      const validatedValue = validate(field, newValue);

      // @ts-expect-error
      newData[field] = validatedValue;

      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);

      const toBeUpdatedFields: QueueCreateUpdateNetworkCategoryPayloadType['toBeUpdatedFields'] =
        {};
      // @ts-expect-error
      toBeUpdatedFields[field] = validatedValue;

      const toBeSyncedKey = `${SYNC_NETWORK_CATEGORY_KEY_PREFIX}${uuidv4()}`;

      syncNetworkCategoryToStore({
        data: oldData,
        toBeUpdatedFields,
        solutionKey: chosenSolution.solutionKey,
        networkKey: targetNetwork.networkKey,
        toBeSyncedKey,
      });

      onEnqueue({
        type: 'CREATE_UPDATE_NETWORK_CATEGORY',
        payload: {
          data: {
            ...oldData,
            solutionKey: chosenSolution.stateData.solutionKey,
          },
          toBeUpdatedFields,
          toBeSyncedKey,
          solutionKey: chosenSolution.solutionKey,
          networkKey: targetNetwork.networkKey,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedSolution) {
      selectedSolutionRef.current = selectedSolution;
    }
  }, [selectedSolution]);

  return (
    <div
      className={classNames('network-category-group', className)}
      data-testid="network-category-container"
    >
      <div className="header">
        <div
          className={classNames('title flex items-center', {
            'cursor-pointer': !isEditingNetwork,
          })}
          onClick={enableEditMode}
          data-testid="network-name"
        >
          {isEditingNetwork ? (
            <Input
              ref={inputRef}
              defaultValue={networkName.current}
              onChange={onChangeNetworkName}
              onPressEnter={() => onUpdateNetworkProperty('name')}
              onBlur={() => onUpdateNetworkProperty('name')}
              data-testid="network-name-input"
            />
          ) : (
            <div className="p-1.5">{decodeHTML(network.name)}</div>
          )}
        </div>
        <div
          className={classNames('slot px-2 flex items-center', {
            'cursor-pointer': !isEditingNetworkCode,
          })}
          onClick={enableEditCodeMode}
          data-testid="network-code"
        >
          {isEditingNetworkCode ? (
            <Input
              ref={codeRef}
              defaultValue={networkCode.current}
              onChange={onChangeNetworkCode}
              onPressEnter={() => onUpdateNetworkProperty('code')}
              onBlur={() => onUpdateNetworkProperty('code')}
              data-testid="network-code-input"
            />
          ) : (
            <span>{network.code}</span>
          )}
        </div>
        <div className="slot">{t('GENERAL.SLOT.TYPE')}</div>
        <div className="color">
          <span
            style={{
              background: network.color,
            }}
          >
            &nbsp;
          </span>
        </div>
        <div className="action">
          <div className="flex justify-center items-center gap-2 p-2">
            <span
              className="cursor-pointer"
              onClick={onDeleteNetwork}
              data-testid={`network-${network.networkKey}`}
            >
              <Trash className="w-5" />
            </span>
          </div>
        </div>
      </div>

      <div
        className="ag-theme-alpine ag-grid-headless ag-grid-border"
        data-testid="network-categories-table"
        data-count={network.networkCategories.length}
      >
        {/* @ts-expect-error */}
        <AgGridReact
          ref={gridRef}
          rowData={network.networkCategories.map((c) => ({
            ...c,
            name: decodeHTML(c.name),
          }))}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellEditRequest={onCellEditRequest}
          getRowId={(params) => params.data.networkCategoryKey}
          animateRows
          readOnlyEdit
          rowSelection="multiple"
          domLayout="autoHeight"
          enterNavigatesVerticallyAfterEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>

      <p className="py-2 pl-10">
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={onCategoryCreate}
          data-testid="create-network-category-btn"
        >
          {t('GENERAL.NETWORK.CATEGORY_CREATE')}
        </Button>
      </p>
    </div>
  );
}
