import { replaceStringInObject } from 'utils/ui-helper';
import { createSlice } from '@reduxjs/toolkit';

import { QueueSlice } from 'Models/Queue';

export const selectQueue = ({ queue }: { queue: QueueSlice }) => queue.queue;
export const selectFailedQueue = ({ queue }: { queue: QueueSlice }) =>
  queue.failedQueue;

export const selectQueueLocalKeyMap = ({ queue }: { queue: QueueSlice }) =>
  queue.queueLocalKeyMap;

export const selectProcessingQueueItem = ({ queue }: { queue: QueueSlice }) =>
  queue.processingQueueItem;

const initialState: QueueSlice = {
  processingQueueItem: false,
  queue: [],
  failedQueue: [],
  isLoaded: false,
  queueLocalKeyMap: {},
};

const queueSlice = createSlice({
  name: 'app/queue',
  initialState: {
    ...initialState,
  },
  reducers: {
    enqueue(state, { payload }) {
      state.queue = [...state.queue, payload];
    },
    dequeue(state) {
      state.queue.shift();
    },
    pickFailedQueueItems(state) {
      state.failedQueue = [...state.failedQueue, ...state.queue];
      state.queue = [];
    },
    syncFailedQueueItems(state) {
      state.queue = [...state.queue, ...state.failedQueue];
      state.failedQueue = [];
    },
    setQueueProcessing(state, { payload }) {
      state.processingQueueItem = payload;
    },
    clearFailedQueueItems(state) {
      state.failedQueue = [];
    },
    setQueueLocalKeyMap(
      state,
      {
        payload,
      }: {
        payload: {
          key: string;
          value: string;
        };
      },
    ) {
      const { key, value } = payload;
      if (!state.queueLocalKeyMap[key]) {
        state.queueLocalKeyMap[key] = value;
      }
    },
    updateQueueSolution(
      state,
      {
        payload,
      }: {
        payload: {
          solutionKey: string;
          newSolutionKey: string;
        };
      },
    ) {
      const { solutionKey, newSolutionKey } = payload;
      if (Array.isArray(state.queue)) {
        state.queue = state.queue.map((queueItem) => {
          if (queueItem.payload.solutionKeyUpdated) return queueItem;

          queueItem.payload.solutionKeyUpdated = true;

          const oldSolutionId = solutionKey.split('-')[2];
          const newSolutionId = newSolutionKey.split('-')[2];

          queueItem.payload = replaceStringInObject(
            queueItem.payload,
            oldSolutionId,
            newSolutionId,
          );

          return queueItem;
        });
      }
    },
  },
});

export const {
  enqueue,
  dequeue,
  setQueueProcessing,
  updateQueueSolution,
  setQueueLocalKeyMap,
  pickFailedQueueItems,
  syncFailedQueueItems,
  clearFailedQueueItems,
} = queueSlice.actions;

export default queueSlice.reducer;
