import { Dropdown } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import React from 'react';
import SetDefinition from './SetDefinition';

interface Props {
  setKey: string;
  label: string;
}

function SetLabel({ label, setKey }: Props): JSX.Element {
  return (
    <div className="flex items-center justify-between">
      <span data-test-id="key-name">{label}</span>

      <Dropdown
        menu={{
          items: [
            {
              key: setKey,
              label: <SetDefinition selectedSetKey={setKey} />,
            },
          ],
        }}
        destroyPopupOnHide
      >
        <div>
          <EyeOutlined />
        </div>
      </Dropdown>
    </div>
  );
}

export default SetLabel;
