import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConstraintList from './Constraint/ConstraintList';
import { Divider } from 'antd';
import classNames from 'classnames';
import SetGroup from './SetGroup/SetGroup';
import './style.scss';

type SectionType = 'constraint' | 'set';

export default function ConstraintsGroup(): JSX.Element {
  const { t } = useTranslation();
  const [section, setSection] = useState<SectionType>('constraint');

  return (
    <div className="constraints-group">
      <div className="pills-tab">
        <div
          className={classNames('pill-item', {
            active: section === 'constraint',
          })}
          onClick={() => setSection('constraint')}
          data-testid="constraint-pill-item"
        >
          {t('GENERAL.CONSTRAINT.TITLE')}
        </div>
        <div
          className={classNames('pill-item', {
            active: section === 'set',
          })}
          onClick={() => setSection('set')}
          data-testid="set-pill-item"
        >
          {t('GENERAL.SET.TITLE', {
            count: 2,
          })}
        </div>
      </div>

      <Divider className="divider" />

      <div className="px-3 grow">
        {section === 'constraint' && <ConstraintList />}
        {section === 'set' && <SetGroup />}
      </div>
    </div>
  );
}
