import React, { useState, useMemo, useRef, ChangeEvent } from 'react';
import { Card, Divider, Button, Input, Alert } from 'antd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { StateData } from 'Models/Scenario';
import { RoundInstance } from 'Models/RoundInstance';
import useScenario from 'storeHooks/useScenario';
import useRoundNetwork from 'Components/Elements/Slots/useRoundNetwork';

interface Props {
  value: string;
  round: RoundInstance;
  onClose: () => void;
}

export const CUSTOM_NAME = '$$-$$custom';

function RoundNameEditPopover({ value, round, onClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedSolution } = useScenario();
  const { onUpdateStateData } = useRoundNetwork();
  const [error, setError] = useState('');

  const name = useRef(value);

  const stateData = useMemo(() => {
    if (selectedSolution) {
      return selectedSolution.stateData;
    }

    return null;
  }, [selectedSolution]);

  const onResetError = () => {
    setError('');
  };

  const onSaveRound = () => {
    if (stateData) {
      const updatedStateData: StateData = _.cloneDeep(stateData);

      updatedStateData.roundTemplates = updatedStateData.roundTemplates.map(
        (item) => {
          if (item.roundTemplateKey === round.roundTemplateKey) {
            item.name = `${name.current}${CUSTOM_NAME}`;
          }

          return item;
        },
      );

      onUpdateStateData(updatedStateData);
    }

    onResetError();
    onClose();
  };

  const onChange = (e: ChangeEvent) => {
    name.current = (e.target as HTMLInputElement).value;
  };

  return (
    <Card
      className="default-pop-over secondary-text"
      type="inner"
      title={t('GENERAL.ROUND.EDIT')}
      style={{ width: 400 }}
    >
      <div className="px-3">
        <Input defaultValue={value} onChange={onChange} />
      </div>

      {error && (
        <div className="px-4 mt-1">
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            afterClose={onResetError}
          />
        </div>
      )}

      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-end items-center gap-1 px-3">
        <Button size="small" onClick={onClose} data-testid="btn-cancel">
          {t('GENERAL.CANCEL')}
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={onSaveRound}
          data-testid="edit-round-button"
        >
          {t('GENERAL.UPDATE')}
        </Button>
      </div>
    </Card>
  );
}

export default RoundNameEditPopover;
