import moment from 'moment';

export const getFormattedDate = (date: Date | null | undefined): string =>
  date ? moment(date).format('DD MMM, YYYY') : '';

export const getFormattedDateWithTime = (
  date: Date | null | undefined,
): string => (date ? moment(date).format('DD MMM, YYYY, hh:mm') : '');

export const getCurrentTime = () => {
  const date = new Date();
  const timeWithoutMilliseconds: string = date.toLocaleTimeString('en-US', {
    hour12: false,
  });
  const milliseconds: string = String(date.getMilliseconds()).padStart(3, '0');
  const time: string = `${timeWithoutMilliseconds}.${milliseconds}`;
  return time;
};
