import _ from 'lodash';
import { useSelector } from 'react-redux';
import { QueueCreateUpdateTeamPayloadType } from 'Models/Queue';
import { syncTeam, syncNewTeam } from 'store/slices/scenarioSlice';
import { saveTeam, deleteTeam } from 'store/slices/teamSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { useRef, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { Team } from 'Models/Team';
import { CREATED, DELETED } from 'utils/variables';
import { Venue } from 'Models/Venue';
import { VenueTeam } from 'Models/VenueTeam';

export type SyncNewTeamPayloadType = {
  team: Team;
  venue: Venue;
  venueTeam: VenueTeam;
  solutionKey: string;
};

export const useTeam = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareTeamPayload = ({
    data,
    toBeUpdatedFields,
  }: QueueCreateUpdateTeamPayloadType): Team => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (queueLocalKeyMapRef.current[newPayload.teamKey]) {
      newPayload.teamKey = queueLocalKeyMapRef.current[newPayload.teamKey];
    }

    return newPayload;
  };

  const syncTeamToStore = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
  }: QueueCreateUpdateTeamPayloadType): void => {
    const preparedTeam = prepareTeamPayload({ data, toBeUpdatedFields });

    dispatch(
      syncTeam({
        data: preparedTeam,
        solutionKey: preparedTeam?.solutionKey ?? '',
        toBeSyncedKey,
      }),
    );
  };

  const createOrUpdateTeamQueue = ({
    data,
    toBeUpdatedFields,
  }: {
    data: Team;
    toBeUpdatedFields: {
      [key in keyof Team]?: Team[key];
    };
  }): Promise<unknown | null> => {
    const preparedData = prepareTeamPayload({
      data,
      toBeUpdatedFields,
    });

    if (preparedData.teamStatus === DELETED) {
      return dispatch(
        deleteTeam({
          teamDeletePayload: {
            teamKey: preparedData.teamKey,
            solutionKey: preparedData.solutionKey ?? '',
          },
          solutionStatus: CREATED,
        }),
      );
    }

    return dispatch(
      saveTeam({
        teamPayload: {
          team: preparedData,
          solutionKey: preparedData.solutionKey ?? '',
        },
        solutionStatus: CREATED,
      }),
    );
  };

  const syncNewTeamToStore = ({
    team,
    venue,
    venueTeam,
    solutionKey,
  }: SyncNewTeamPayloadType): void => {
    dispatch(
      syncNewTeam({
        team,
        venue,
        venueTeam,
        solutionKey,
      }),
    );
  };

  return {
    prepareTeamPayload,
    syncTeamToStore,
    createOrUpdateTeamQueue,
    syncNewTeamToStore,
  };
};

export default useTeam;
