import { useState, useLayoutEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export const useSize = (target: HTMLElement | null) => {
  const [size, setSize] = useState({
    height: 0,
  });

  useLayoutEffect(() => {
    if (target) {
      setSize(target.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));

  return size;
};
