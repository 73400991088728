import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import SignalRMessages from 'Components/Scenarios/EditScenario/Solve/Solve/SignalRMessages';
import {
  LogMessagesPayload,
  LogMessagesResponse,
  LogMessageType,
  Solution,
} from 'Models/Scenario';
import { FetchLogMessages } from 'Services/ScenarioService';
import useScenario from 'storeHooks/useScenario';
import { SolutionStatusStopped } from 'Models/SolutionStatuses';
import showMessage from 'Components/Elements/Common/Message';
import i18n from 'plugins/i18next';

interface Props {
  statusMessage: string;
  interactionMessage: string;
}

export const handleFetchLogs = async (
  selectedSolution: Solution | null,
  logMessageType: LogMessageType,
  fetchLogMessages: (
    payload: LogMessagesPayload,
  ) => Promise<LogMessagesResponse | null>,
  setDisplayMessage: (value: string) => void,
) => {
  if (
    !selectedSolution ||
    selectedSolution.solutionStatus !== SolutionStatusStopped
  ) {
    return;
  }

  try {
    const res: LogMessagesResponse | null = await fetchLogMessages({
      solutionKey: selectedSolution.solutionKey,
      logMessageType,
    });

    if (res?.messages) {
      setDisplayMessage(res.messages);
    }
  } catch (err) {
    showMessage({
      key: selectedSolution.solutionKey,
      type: 'error',
      content: i18n.t('GENERAL.FEEDBACK.SOLUTION.FETCH_LOGS_FAILED'),
    });
  }
};

export function ApplicationLog({ message }: { message: string }) {
  const { selectedSolution } = useScenario();
  const [displayMessage, setDisplayMessage] = useState(message);

  useEffect(() => {
    handleFetchLogs(
      selectedSolution,
      'ApplicationLog',
      FetchLogMessages,
      setDisplayMessage,
    );
  }, [selectedSolution]);

  useEffect(() => {
    setDisplayMessage(message);
  }, [message]);

  return (
    <div className="terminal-log" data-testid="application-log">
      <SignalRMessages message={displayMessage} />
    </div>
  );
}

export function SolverLog({ message }: { message: string }) {
  const { selectedSolution } = useScenario();
  const [displayMessage, setDisplayMessage] = useState(message);

  useEffect(() => {
    handleFetchLogs(
      selectedSolution,
      'SolverLog',
      FetchLogMessages,
      setDisplayMessage,
    );
  }, [selectedSolution]);

  useEffect(() => {
    setDisplayMessage(message);
  }, [message]);

  return (
    <div className="terminal-log" data-testid="solver-log">
      <SignalRMessages message={displayMessage} />
    </div>
  );
}

function ViewLog({ statusMessage, interactionMessage }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div data-testid="log-view" className="view-log-section">
      <Tabs
        data-testid="log-view-tab"
        defaultActiveKey="1"
        items={[
          {
            label: t('GENERAL.VIEW_LOG_TAB.APPLICATION_LOG'),
            key: '1',
            children: <ApplicationLog message={statusMessage} />,
          },
          {
            label: t('GENERAL.VIEW_LOG_TAB.SOLVER_LOG'),
            key: '2',
            children: <SolverLog message={interactionMessage} />,
          },
        ]}
      />
    </div>
  );
}

export default ViewLog;
