import _ from 'lodash';
import { useSelector } from 'react-redux';
import { PostGamesLock } from 'Services/ScenarioService';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { useRef, useEffect } from 'react';
import {
  GamesLockPayload,
  ScenarioTimeSlotResponsePromiseType,
} from 'Models/Scenario';
import { getScenarioKey } from 'utils/ui-helper';

export const useLock = () => {
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const preparePayload = ({
    data,
  }: {
    data: GamesLockPayload;
  }): GamesLockPayload => {
    const newPayload = _.cloneDeep(data);

    if (queueLocalKeyMapRef.current[newPayload.solveDataKey]) {
      newPayload.solveDataKey =
        queueLocalKeyMapRef.current[newPayload.solveDataKey];
    }

    return newPayload;
  };

  const updateLockUnlockQueue = ({
    data,
    actualSolutionKey,
  }: {
    data: GamesLockPayload;
    actualSolutionKey: string;
  }): Promise<ScenarioTimeSlotResponsePromiseType | null> => {
    const preparedData = preparePayload({
      data,
    });

    const scenarioKey = getScenarioKey(actualSolutionKey);

    // @ts-expect-error
    return PostGamesLock({
      ...preparedData,
      solutionKey: actualSolutionKey,
      scenarioKey,
    });
  };

  return {
    preparePayload,
    updateLockUnlockQueue,
  };
};

export default useLock;
