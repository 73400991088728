import React, { useEffect, useState, useRef } from 'react';
import { Input, Divider } from 'antd';
import classNames from 'classnames';
import useScenario from 'storeHooks/useScenario';
import useTeam from 'storeHooks/useTeam';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SelectionType } from './OpponentMatchTable';
import { Solution } from 'Models/Scenario';
import { Opponents } from 'Models/Opponent';
import './style.scss';

interface OpponentPenaltyProps {
  selectionType: SelectionType;
  setSelectionType: (selected: SelectionType) => void;
}

const validator: Record<
  string,
  { min: number; max: number; required: boolean }
> = {
  opponentPenalty: {
    min: 0,
    max: 999,
    required: true,
  },
};

const validate = (field: string, value: number) => {
  if (!validator[field]) return value;

  return Math.min(
    Math.max(Math.floor(value), validator[field].min),
    validator[field].max,
  );
};

export const handleUpdateOpponent = async (
  value: number,
  selectedSolution: Solution | null,
  updateOpponent: (
    payload: Opponents,
    clonedSolution?: Solution | null,
  ) => Promise<void>,
) => {
  if (!selectedSolution) return;

  updateOpponent({
    ...selectedSolution.stateData.opponents,
    penalty: value,
  });
};

function OpponentPenalty({
  selectionType = 'min',
  setSelectionType,
}: OpponentPenaltyProps): JSX.Element {
  const { t } = useTranslation();
  const { selectedSolution } = useScenario();
  const { updateOpponent } = useTeam();
  const [count, setCount] = useState(0);
  const canUpdate = useRef(false);
  const inputElement = useRef(null);
  const selectedInitially = useRef(false);

  const updateOpponentPenalty = useRef(
    debounce((validNumber) => {
      handleUpdateOpponent(
        validNumber,
        selectedSolution,
        // @ts-expect-error
        updateOpponent,
      );
    }, 900),
  );

  const onCountChange = (e: React.FormEvent<HTMLInputElement>) => {
    const num = Number(e.currentTarget.value);
    const validNumber = validate('opponentPenalty', num);

    setCount(validNumber);
    updateOpponentPenalty.current(validNumber);
  };

  useEffect(() => {
    if (selectedSolution) {
      setCount(selectedSolution.stateData.opponentsPenalty);

      if (!selectedInitially.current) {
        // @ts-expect-error
        inputElement.current?.focus();
        selectedInitially.current = true;
      }

      setTimeout(() => {
        canUpdate.current = true;
      }, 3000);
    }
  }, [selectedSolution]);

  return (
    <div className="opponent-header-actions">
      <div className="penalty">
        <span>{t('GENERAL.PENALTY.TITLE')}</span>
        <Input
          className="w-24"
          type="number"
          value={count}
          min={0}
          max={999}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            updateOpponentPenalty.current.cancel();
            onCountChange(e);
          }}
          ref={inputElement}
          data-testid="opponent-penalty"
        />
      </div>
      <Divider type="vertical" className="divider" />
      <div className="patterns">
        <span
          className={classNames('item', { active: selectionType === 'min' })}
          onClick={() => setSelectionType('min')}
          data-testid="opponent-min"
        >
          {t('GENERAL.MIN')}
        </span>
        <span
          className={classNames('item', { active: selectionType === 'max' })}
          onClick={() => setSelectionType('max')}
          data-testid="opponent-max"
        >
          {t('GENERAL.MAX')}
        </span>
        <span
          className={classNames('item', { active: selectionType === 'both' })}
          onClick={() => setSelectionType('both')}
          data-testid="opponent-both"
        >
          {t('GENERAL.BOTH')}
        </span>
      </div>
    </div>
  );
}

export default OpponentPenalty;
