import React from 'react';
import classNames from 'classnames';
import './style.scss';

export type MenuItemType =
  | 'application-settings'
  | 'integrations'
  | 'invite-users';

interface Props {
  title: string;
  id: MenuItemType;
  active?: boolean;
  onClick?: (id: MenuItemType) => void;
}

export default function MenuItem({
  title,
  id,
  active,
  onClick,
}: Props): JSX.Element {
  const onMenuClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <div
      className={classNames(
        'cursor-pointer transition-all duration-300 font-medium rounded-md menu',
        {
          active,
        },
      )}
      onClick={onMenuClick}
      data-testid="menu-item"
    >
      {title}
    </div>
  );
}
