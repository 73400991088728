import React, { useEffect, useRef, useState } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { ChevronLeft, Logomark } from 'Components/Elements/Icons';
import { getUserSettings } from 'Services/UserService';
import { useAppDispatch } from 'storeHooks/hooks';
import useScenario from 'storeHooks/useScenario';
import { Navigation } from 'Components/Layout/HeaderElements/UserNavigation';
import useQueue from 'storeHooks/useQueue';
import QueueStatus from 'Components/Elements/Queue/QueueStatus';
import { setShoudDeleteConfirmation } from 'store/slices/userSlice';
import ScenarioSelector from './ScenarioSelector';
import './style.scss';

let timerRef: ReturnType<typeof setInterval>;
const shouldRunQueue = true;

function Header() {
  const loadingUserInfo = useRef(false);
  const matches = useMatches();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleQueueItem } = useQueue();
  const { scenarioMetas, getMetas } = useScenario();
  const [currentSeason, setCurrentSeason] = useState('');
  const [currentScenarioKey, setCurrentScenarioKey] = useState('');

  const goToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    const firstMatch = matches[0];
    if (firstMatch?.params.season && firstMatch?.params.scenarioKey) {
      setCurrentSeason(firstMatch.params.season);
      setCurrentScenarioKey(firstMatch.params.scenarioKey);
    } else {
      setCurrentSeason('');
      setCurrentScenarioKey('');
    }
  }, [matches]);

  useEffect(() => {
    if (currentSeason && scenarioMetas.length === 0) {
      getMetas(Number(currentSeason));
    }
  }, [currentSeason, scenarioMetas]);

  useEffect(() => {
    if (shouldRunQueue) {
      timerRef = setInterval(() => {
        handleQueueItem();
      }, 400);
    }

    return () => {
      clearInterval(timerRef);
    };
  }, []);

  useEffect(() => {
    if (!loadingUserInfo.current) {
      loadingUserInfo.current = true;

      getUserSettings()
        .then((response) => {
          dispatch(
            setShoudDeleteConfirmation(response.shouldShowDeleteConfirmation),
          );
        })
        .finally(() => {
          loadingUserInfo.current = false;
        });
    }
  }, []);

  return (
    <div className="optimal-app-header py-3 px-10">
      <div className="header-left">
        <div className="app-logo">
          <div className="cursor-pointer" onClick={goToHome}>
            <Logomark />
          </div>
        </div>

        {currentScenarioKey && (
          <div className="btn-back" onClick={goToHome}>
            <ChevronLeft />
          </div>
        )}
      </div>
      {currentScenarioKey && (
        <ScenarioSelector
          season={currentSeason}
          scenarioKey={currentScenarioKey}
        />
      )}
      <div className="header-right">
        <div className="queue-holder">
          <QueueStatus />
        </div>
        <div className="user-navigation">
          <Navigation />
        </div>
      </div>
    </div>
  );
}

export default Header;
