import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CloneScenarioWithSolution,
  CloneScenarioWithSolveData,
  CloneSolution,
  CloneSolveDataToNewSolution,
  DeleteSolution,
  DeleteSolveDataFromSolution,
  SolutionApiPayload,
  SolveDataApiPayload,
} from 'Services/ScenarioService';
import { Scenario, ScenarioSlice, Solution, SolveData } from 'Models/Scenario';
import { getScenarioByKey, updateScenriosScenario } from './mutate_helpers';
import { getScenarioMeta } from 'utils/scenario-helper';

export const cloneSolveData = createAsyncThunk(
  'user/cloneSolveData',
  async (payload: SolveDataApiPayload) => {
    const res = await CloneSolveDataToNewSolution(payload);

    return { data: res };
  }
);

export const cloneSolution = createAsyncThunk(
  'user/cloneSolution',
  async (payload: SolutionApiPayload) => {
    const res = await CloneSolution(payload);

    return { data: res };
  }
);

export const cloneScenarioWithSolveData = createAsyncThunk(
  'user/cloneScenarioWithSolveData',
  async (payload: SolveDataApiPayload) => {
    const res = await CloneScenarioWithSolveData(payload);

    return { data: res };
  }
);

export const cloneScenarioWithSolution = createAsyncThunk(
  'user/cloneScenarioWithSolution',
  async (payload: SolutionApiPayload) => {
    const res = await CloneScenarioWithSolution(payload);

    return { data: res };
  }
);

export const deleteSolveData = createAsyncThunk(
  'user/deleteSolveData',
  async (payload: SolveDataApiPayload) => {
    const res = await DeleteSolveDataFromSolution(payload);

    return { data: res };
  }
);

export const deleteSolution = createAsyncThunk(
  'user/deleteSolution',
  async (payload: SolutionApiPayload) => {
    const res = await DeleteSolution(payload);

    return { data: res };
  }
);

export const cloneScenarioWithSolutionReducer = (
  state: ScenarioSlice,
  {
    payload
  }: {
    payload: {
      data: Scenario | null
    }
  }
) => {
  if (payload.data) {
    const scenarioMeta = getScenarioMeta(payload.data);
    state.scenarios = [...state.scenarios, scenarioMeta];
  }
};

export const cloneScenarioWithSolveDataReducer = (
  state: ScenarioSlice,
  {
    payload
  }: {
    payload: {
      data: Scenario | null
    }
  }
) => {
  if (payload.data) {
    const scenarioMeta = getScenarioMeta(payload.data);
    state.scenarios = [...state.scenarios, scenarioMeta];
  }
};

export const cloneSolveDataReducer = (
  state: ScenarioSlice,
  {
    payload
  }: {
    payload: {
      data: Solution | null
    }
  }
) => {
  if (payload.data) {
    const targetScenarioKey = state.scenarioKey;
    const clonedSolution = payload.data;
    const targetScenario = getScenarioByKey(state, targetScenarioKey);

    if (targetScenario) {
      state.solutionKey = clonedSolution.solutionKey;
      state.solveDataKey = clonedSolution.solveDataList[0].solveDataKey;
      targetScenario.optimizationEnvelop.solutions.push(clonedSolution);
      updateScenriosScenario(state, targetScenarioKey, targetScenario);
    }
  }
};

export const deleteSolveDataReducer = (
  state: ScenarioSlice,
  {
    payload
  }: {
    payload: {
      data: SolveData | null
    }
  }
) => {
  if (payload.data) {
    const targetScenarioKey = state.scenarioKey;
    const targetSolutionKey = state.solutionKey;
    const deletedSolveData = payload.data;
    const targetScenario = getScenarioByKey(state, targetScenarioKey);

    if (targetScenario) {
      const targetSolutionIndex =
        targetScenario.optimizationEnvelop.solutions.findIndex(
          (solution) => solution.solutionKey === targetSolutionKey
        );

      if (targetSolutionIndex !== -1) {
        const targetSolution = targetScenario.optimizationEnvelop.solutions[targetSolutionIndex];
        if (deletedSolveData.solveDataStatus === 'Deleted') {
          const solveDataIndex = targetSolution.solveDataList.findIndex(
            (solveData) => solveData.solveDataKey === deletedSolveData.solveDataKey
          );
          state.solveDataKey = targetSolution.solveDataList[solveDataIndex !== 0 ? solveDataIndex - 1 : solveDataIndex + 1].solveDataKey;
          targetSolution.solveDataList = targetSolution.solveDataList.filter(
            (solveData) => solveData.solveDataKey !== deletedSolveData.solveDataKey
          );
        } else {
          targetSolution.solveDataList = targetSolution.solveDataList.map(
            (solveData) => solveData.solveDataKey === deletedSolveData.solveDataKey ? deletedSolveData : solveData
          );
          state.solveDataKey = deletedSolveData.solveDataKey;
        }
        state.solutionKey = targetSolutionKey;
        targetScenario.optimizationEnvelop.solutions[targetSolutionIndex] = targetSolution;
        updateScenriosScenario(state, targetScenarioKey, targetScenario);
      }
    }
  }
};

export const cloneSolutionReducer = (
  state: ScenarioSlice,
  {
    payload
  }: {
    payload: {
      data: Solution | null
    }
  }
) => {
  if (payload.data) {
    const targetScenarioKey = state.scenarioKey;
    const clonedSolution = payload.data;
    const targetScenario = getScenarioByKey(state, targetScenarioKey);

    if (targetScenario) {
      state.solutionKey = clonedSolution.solutionKey;
      state.solveDataKey = clonedSolution.solveDataList[0].solveDataKey;
      targetScenario.optimizationEnvelop.solutions.push(clonedSolution);
      updateScenriosScenario(state, targetScenarioKey, targetScenario);
    }
  }
};

export const deleteSolutionReducer = (
  state: ScenarioSlice,
  {
    payload
  }: {
    payload: {
      data: Solution | null
    }
  }
) => {
  if (payload.data) {
    const targetScenarioKey = state.scenarioKey;
    const updatedSolution = payload.data;
    const targetScenario = getScenarioByKey(state, targetScenarioKey);

    if (targetScenario) {
      const checkStatus = updatedSolution.solutionStatus === 'Deleted';
      if (checkStatus) {
        const updatedSolutionIndex = targetScenario.optimizationEnvelop.solutions.findIndex(
          (solution) => solution.solutionKey === updatedSolution.solutionKey
        );

        const nextSolutionIndex = updatedSolutionIndex !== 0 ? updatedSolutionIndex - 1 : updatedSolutionIndex + 1;
        const nextSolution = targetScenario.optimizationEnvelop.solutions[nextSolutionIndex];

        state.solutionKey = nextSolution.solutionKey;
        state.solveDataKey = nextSolution.solveDataList[0].solveDataKey;

        targetScenario.optimizationEnvelop.solutions = targetScenario.optimizationEnvelop.solutions.filter(
          (solution) => solution.solutionKey !== updatedSolution.solutionKey
        );
      } else {
        targetScenario.optimizationEnvelop.solutions[0] = updatedSolution;
        state.solutionKey = updatedSolution.solutionKey;
        state.solveDataKey = updatedSolution.solveDataList[0].solveDataKey;
      }
      updateScenriosScenario(state, targetScenarioKey, targetScenario);
    }
  }
};
