import React from 'react';
import { Avatar, Flex, Popconfirm } from 'antd';
import { TrophyOutlined } from '@ant-design/icons';
import { getFormattedDate, getFormattedDateWithTime } from 'utils/date-helper';
import { Copy, Edit, Trash } from 'Components/Elements/Icons';
import { ScenarioMetaData } from 'Models/Scenario';
import { useTranslation } from 'react-i18next';
import useQueue from 'storeHooks/useQueue';
import { clearSolutionAndSolveDataKey } from 'store/slices/scenarioSlice';
import { useAppDispatch } from 'storeHooks/hooks';

interface Props {
  visibleScenarios: ScenarioMetaData[];
  openEditScenarioModal: (item: ScenarioMetaData) => void;
  openCloneScenarioModal: (item: ScenarioMetaData) => void;
  onDeleteScenario: (key: string) => void;
  goTo: (key: string) => void;
}

export default function ScenariosTableBody({
  visibleScenarios,
  openEditScenarioModal,
  openCloneScenarioModal,
  onDeleteScenario,
  goTo,
}: Props): React.JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { queue } = useQueue();

  return (
    <>
      {visibleScenarios.map((item) => (
        <tr key={item.scenarioKey}>
          <td align="left" className="table-title">
            <div
              onClick={() => {
                dispatch(clearSolutionAndSolveDataKey());
                goTo(`/scenario/${item.season}/${item.scenarioKey}`);
              }}
              className="cursor-pointer"
            >
              <Flex gap="small" align="center">
                {item.logo ? (
                  <Avatar shape="circle" src={item.logo} />
                ) : (
                  <Avatar shape="circle" icon={<TrophyOutlined />} />
                )}
                {item.name}
              </Flex>
            </div>
          </td>
          <td align="left" className="season">
            {item.season}
          </td>
          <td align="left" className="results">
            {item?.results ?? 0}
          </td>
          <td align="left" className="rounds">
            {getFormattedDate(item?.startDate)}
            {' - '}
            {getFormattedDate(item?.endDate)}
          </td>
          <td align="left" className="modified-at">
            {getFormattedDateWithTime(item?.lastModifiedDate)}
          </td>
          <td align="left" className="action">
            <div className="flex gap-3 justify-center items-center">
              <Popconfirm
                title={t('GENERAL.SCENARIO.DELETE.TITLE')}
                description={t('GENERAL.SCENARIO.DELETE.DESCRIPTION')}
                onConfirm={() => onDeleteScenario(item.scenarioKey)}
                okText={t('GENERAL.YES')}
                cancelText={t('GENERAL.NO')}
              >
                <span
                  className={`cursor-pointer ${
                    queue && queue.length > 0 ? 'cursor-not-allowed' : ''
                  }`}
                >
                  <Trash />
                </span>
              </Popconfirm>

              <span
                className="cursor-pointer"
                onClick={() => openEditScenarioModal(item)}
                data-testid="edit-scenario-icon"
              >
                <Edit />
              </span>
              <span
                className="cursor-pointer"
                onClick={() => openCloneScenarioModal(item)}
                data-testid="clone-scenario-icon"
              >
                <Copy />
              </span>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}
