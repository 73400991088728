// @ts-nocheck
import React, { useMemo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useScenario from 'storeHooks/useScenario';
import { Badge, Button, Tag, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { TimeSlot } from 'Models/Scenario';
import { Draggable } from '@fullcalendar/interaction';
import Empty from 'Components/Elements/Common/Empty/Empty';
import fontColorContrast from 'Library/font-color-contrast';
import useRoundCreate from '../Rounds/useRoundCreate';
import './style.scss';

type SectionType = 'slot' | 'round' | 'network';
interface Props {
  className?: string;
  onTabChange?: (tab: SectionType) => void;
}

type CreateSlotType = (
  timeSlotText: string,
  slotTypeId: string,
  networkCategoryId: string,
) => TimeSlot;

type SaveSlotType = (slot: TimeSlot) => void;

export const attachSlot = (
  slotTypeId: string,
  networkCategoryId: string,
  createNewSlot: CreateSlotType,
  saveSlot: SaveSlotType,
) => {
  saveSlot(createNewSlot('slot ', slotTypeId, networkCategoryId));
};

export default function NetworkList({
  className,
  onTabChange,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const draggableElements = useRef(null);
  const elementRegistered = useRef(false);
  const { stateData } = useScenario();
  const { createNewSlot, saveSlotToAll, saveSlotToSpecificDay } =
    useRoundCreate();

  const contextMenuItems: MenuProps['items'] = [
    {
      label: t('GENERAL.DAY_OPTIONS.FULL.MON'),
      key: 'Mon',
    },
    {
      label: t('GENERAL.DAY_OPTIONS.FULL.TUE'),
      key: 'Tue',
    },
    {
      label: t('GENERAL.DAY_OPTIONS.FULL.WED'),
      key: 'Wed',
    },
    {
      label: t('GENERAL.DAY_OPTIONS.FULL.THU'),
      key: 'Thu',
    },
    {
      label: t('GENERAL.DAY_OPTIONS.FULL.FRI'),
      key: 'Fri',
    },
    {
      label: t('GENERAL.DAY_OPTIONS.FULL.SAT'),
      key: 'Sat',
    },
    {
      label: t('GENERAL.DAY_OPTIONS.FULL.SUN'),
      key: 'Sun',
    },
    {
      type: 'divider',
    },
    {
      label: t('GENERAL.ALL'),
      key: 'all',
    },
  ];

  const networkCategories = useMemo(() => {
    if (stateData) {
      return stateData.roundsDictionaries.networks
        .flatMap((item) => item.networkCategories)
        .filter((item) => item.slotTypeId)
        .map((item) => ({
          ...item,
          title: item.name,
          id: item.networkCategoryId,
        }));
    }

    return [];
  }, [stateData]);

  const slotTypesMap = stateData
    ? stateData.roundsDictionaries.slotTypes.reduce((acc, item) => {
        acc[item.slotTypeId] = item.name;

        return acc;
      }, {})
    : {};

  const onSaveToAll = (networkCategoryId: string, slotTypeId?: string) => {
    if (slotTypeId) {
      attachSlot(slotTypeId, networkCategoryId, createNewSlot, saveSlotToAll);
    }
  };

  const handleMenuItemClick: MenuProps['onClick'] = ({ key }) => {
    const [day, networkCategoryId, slotTypeId] = key.split('-');
    if (day === 'all') {
      onSaveToAll(networkCategoryId, slotTypeId);
    } else {
      saveSlotToSpecificDay(
        createNewSlot('slot ', slotTypeId, networkCategoryId),
        day,
      );
    }
  };

  useEffect(() => {
    const draggableEl = draggableElements.current;

    if (elementRegistered.current) return;

    // eslint-disable-next-line
    new Draggable(draggableEl, {
      itemSelector: '.fc-event',
      // eslint-disable-next-line
      eventData: function eventData({ dataset }) {
        const { id, color, title } = dataset;

        return {
          id,
          title,
          color,
          create: true,
        };
      },
    });

    elementRegistered.current = true;
  }, []);

  return (
    <div
      className={classNames('section-container', className)}
      data-testid="network-list-container"
    >
      <div className="section-header flex-between">
        <div
          className="section-title flex items-center gap-2"
          data-testid="network-section-title"
        >
          {t('GENERAL.NETWORK.TITLE', {
            count: 2,
          })}

          <Badge
            count={networkCategories?.length}
            style={{ backgroundColor: '#d0d5dd', color: '#000000' }}
          />
        </div>
      </div>

      <div className="list-section mt-1">
        <div className="title">
          {t('GENERAL.NETWORK.TITLE', {
            count: 2,
          })}
        </div>
        {networkCategories.length === 0 && (
          <Empty
            title={t('GENERAL.NETWORK.EMPTY')}
            subtitle={t('GENERAL.NETWORK.LET_CREATE')}
            btnText={t('GENERAL.NETWORK.CREATE')}
            onBtnClick={() => onTabChange('network')}
            height={495}
            className="white-background"
          />
        )}

        <div
          className="network-list"
          ref={draggableElements}
          data-testid="network-list"
        >
          {networkCategories.map((item) => (
            <div
              className="network-list-item fc-event flex items-center justify-between"
              title={item.title}
              data-title={item.title}
              data-id={item.id}
              data-color={item.color}
              key={item.id}
            >
              <Tag color={item.color}>
                <span
                  style={{
                    color: fontColorContrast(item.color),
                  }}
                >
                  {item.name} - {slotTypesMap[item.slotTypeId] ?? ''}
                </span>
              </Tag>

              <Dropdown
                menu={{
                  items: contextMenuItems.map((m) => ({
                    ...m,
                    key: `${m?.key}-${item.networkCategoryId}-${item.slotTypeId}`,
                  })),
                  onClick: handleMenuItemClick,
                }}
                trigger={['click']}
                destroyPopupOnHide
              >
                <Button size="small">{t('GENERAL.ADD_TO')}</Button>
              </Dropdown>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
