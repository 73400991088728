import { v4 as uuidv4 } from 'uuid';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Card, Checkbox, Col, Input, Row } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useTranslation } from 'react-i18next';
import { Team } from 'Models/Team';
import { Constraint } from 'Models/Scenario';
import {
  QueueActionType,
  QueueCreateUpdateConstraintPayloadType,
} from 'Models/Queue';
import useScenario from 'storeHooks/useScenario';
import { Search } from 'Components/Elements/Icons';
import useQueue from 'storeHooks/useQueue';
import useConstraint from 'storeHooks/queue/useConstraint';
import useSolution from 'customHooks/useSolution';
import { SYNC_CONSTRAINT_KEY_PREFIX } from 'utils/variables';
import '../style.scss';

interface Props {
  constraintKey: string;
  teamsIds: string[];
}

interface Option {
  label: string;
  value: string;
}

export const handleCheckboxGroupUpdate = async (
  checkedValues: CheckboxValueType[],
  setValue: React.Dispatch<React.SetStateAction<CheckboxValueType[]>>,
  constraint: Constraint,
  onEnqueue: (data: QueueActionType) => void,
  syncConstraintToStore: (data: QueueCreateUpdateConstraintPayloadType) => void,
  localSolutionKey: string,
  solutionKey: string,
) => {
  setValue(checkedValues);

  const teamValues = checkedValues.map((item) => item.toString());

  const toBeSyncedKey = `${SYNC_CONSTRAINT_KEY_PREFIX}${uuidv4()}`;

  syncConstraintToStore({
    data: {
      ...constraint,
      solutionKey: localSolutionKey,
    },
    toBeUpdatedFields: {
      teamsIds: teamValues,
    },
    toBeSyncedKey,
  });
  onEnqueue({
    type: 'CREATE_UPDATE_CONSTRAINT',
    payload: {
      data: {
        ...constraint,
        solutionKey,
      },
      toBeUpdatedFields: {
        teamsIds: teamValues,
      },
      toBeSyncedKey,
      solutionKey: localSolutionKey,
    },
  });
};

function TeamSelectPopOver({ constraintKey, teamsIds }: Props): JSX.Element {
  const { stateData } = useScenario();
  const [value, setValue] = useState<CheckboxValueType[]>(teamsIds);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');
  const { onEnqueue } = useQueue();
  const { syncConstraintToStore } = useConstraint();
  const { getCurrentOrClonedSolution } = useSolution();

  const constraints: Constraint[] = useMemo(
    () => stateData?.constraintsEnvelop.constraints ?? [],
    [stateData],
  );

  const constraint: Constraint | null = useMemo(() => {
    const item = constraints.find((x) => x.constraintKey === constraintKey);
    return item || null;
  }, [constraints]);

  const teams: Team[] = useMemo(() => stateData?.teams ?? [], [stateData]);

  const options: Option[] = teams.map((team) => ({
    label: team.name,
    value: team.teamId,
  }));

  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  const onSearch = (e: ChangeEvent) => {
    setSearchText((e.target as HTMLInputElement).value);
  };

  const handleChange = (checkedValues: CheckboxValueType[]) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution || !constraint) return;
    const { solutionKey } = chosenSolution.stateData;

    handleCheckboxGroupUpdate(
      checkedValues,
      setValue,
      constraint,
      onEnqueue,
      syncConstraintToStore,
      chosenSolution.solutionKey,
      solutionKey,
    );
  };

  useEffect(() => {
    if (searchText.trim().length > 0) {
      const filtered: Option[] = options.filter((item) =>
        item.label.toLowerCase().includes(searchText.trim().toLowerCase()),
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [searchText]);

  useEffect(() => {
    setValue(teamsIds);
  }, [teamsIds]);

  return (
    <Card
      className="default-pop-over flat"
      style={{ width: 250 }}
      actions={[
        <Button
          size="small"
          type="link"
          onClick={() => handleChange(options.map((item) => item.value))}
          data-testid="select-all-btn"
        >
          {t('GENERAL.SELECT_ALL')}
        </Button>,
        <Button
          size="small"
          type="link"
          danger
          onClick={() => handleChange([])}
          data-testid="deselect-all-btn"
        >
          {t('GENERAL.DESELECT_ALL')}
        </Button>,
      ]}
    >
      <Input
        className="search-input my-2"
        size="middle"
        placeholder="Search"
        prefix={<Search />}
        value={searchText}
        onChange={onSearch}
        data-testid="search-input"
      />
      <div className="p-1">
        <Checkbox.Group
          style={{ width: '100%' }}
          value={value}
          onChange={handleChange}
        >
          <span style={{ maxHeight: 120, overflowY: 'auto' }}>
            <Row>
              {filteredOptions.map((option) => (
                <Col className="mt-2" span={24} key={option.value}>
                  <Checkbox value={option.value}> {option.label} </Checkbox>
                </Col>
              ))}
            </Row>
          </span>
        </Checkbox.Group>
      </div>
    </Card>
  );
}

export default TeamSelectPopOver;
