import { message } from 'antd';

interface MessagePayload {
  key?: string | number,
  type?: 'loading' | 'success' | 'error',
  content: string,
  duration?: number,
}

export default function showMessage({ key, type = 'loading', content, duration = 2 }: MessagePayload) {
  return message.open({
    key: key ?? 0,
    type,
    content,
    duration: type === 'loading' ? 0 : duration,
  });
}
