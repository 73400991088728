import React from 'react';
import NetworkList from './Networks/NetworkList';
import Slots from './SlotType/Slots';

type SectionType = 'slot' | 'round' | 'network';
interface Props {
  onTabChange?: (tab: SectionType) => void;
}

export default function RoundsTab({ onTabChange }: Props): JSX.Element {
  return (
    <div className="slots-tab">
      <Slots className="slot-section" onTabChange={onTabChange} />
      <NetworkList className="network-section" onTabChange={onTabChange} />
    </div>
  );
}
