// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Badge } from 'antd';
import { NetworkCategory } from 'Models/Network';
import { SlotType } from 'Models/Slot';
import moment from 'moment';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useTranslation } from 'react-i18next';
import useScenario from 'storeHooks/useScenario';
import Empty from 'Components/Elements/Common/Empty/Empty';
import fontColorContrast from 'Library/font-color-contrast';
import SlotEvent from './SlotEvent';
import useRoundCreate from '../Rounds/useRoundCreate';
import {
  DELETED,
  DATE_FORMAT_CAL,
  WeekDayMap,
  FullCalenderStartDayMap,
} from 'utils/variables';

dayjs.extend(minMax);
dayjs.extend(utc);

type SectionType = 'slot' | 'round' | 'network';

type SlotEventType = {
  title: string;
  start: string;
  color: string;
};

interface Props {
  className?: string;
  onTabChange?: (tab: SectionType) => void;
}

const DATE_FORMAT = 'DD MMM YYYY';

export default function Slots({ className, onTabChange }: Props): JSX.Element {
  const { t } = useTranslation();
  const { stateData } = useScenario();
  const { createNewSlot, saveSlot, deleteSlot } = useRoundCreate();
  const [weekDays, setWeekDays] = useState([]);
  const roundTemplates = useMemo(() => {
    if (stateData) {
      return stateData.roundTemplates.filter(
        (item) => item.roundTemplateStatus !== 'Deleted',
      );
    }

    return [];
  }, [stateData]);

  const roundTemplateDates = useMemo(
    () =>
      roundTemplates.reduce((acc, item) => {
        const activeDays = {};

        const roundStart = dayjs(item.startDate).utc();
        const roundEnd = dayjs(item.endDate).utc();

        activeDays[roundStart.format(DATE_FORMAT_CAL)] = true;
        activeDays[roundEnd.format(DATE_FORMAT_CAL)] = true;

        const differenceBewteenDates = roundEnd.diff(roundStart, 'days');

        for (let i = 0; i < differenceBewteenDates; i++) {
          activeDays[roundStart.add(i, 'days').format(DATE_FORMAT_CAL)] = true;
        }

        acc[item.roundTemplateKey] = activeDays;

        return acc;
      }, {}),
    [roundTemplates],
  );

  const roundWeeksMap = useMemo(() => {
    const weeksMap = {};
    moment.updateLocale('en', {
      week: {
        dow: stateData?.roundTemplateWeekStartDay
          ? Math.abs(stateData?.roundTemplateWeekStartDay)
          : 1, // Monday is the first day of the week.
      },
    });
    roundTemplates.forEach((item) => {
      let weekDayDiff = Math.abs(
        moment(item.startDate).utc().week() - moment(item.endDate).utc().week(),
      );

      // Round coonsist of 2 year
      if (weekDayDiff > 48) {
        weekDayDiff =
          52 -
          Math.abs(
            moment(item.startDate).utc().week() -
              moment(item.endDate).utc().week(),
          );
      }

      // adjust for startweekday variable
      weeksMap[item.roundTemplateKey] = weekDayDiff;
    });

    return weeksMap;
  }, [roundTemplates]);

  const networkCategories: Record<string, NetworkCategory> = useMemo(() => {
    if (stateData) {
      return stateData.roundsDictionaries.networks
        .flatMap((item) => item.networkCategories)
        .filter((item) => item.slotTypeId)
        .reduce((acc, item) => {
          acc[item.networkCategoryId] = item;
          return acc;
        }, {});
    }

    return {};
  }, [stateData]);

  const slotTypes: Record<string, SlotType> = useMemo(() => {
    if (stateData) {
      return stateData.roundsDictionaries.slotTypes
        .filter((item) => item.slotTypeId)
        .reduce((acc, item) => {
          acc[item.slotTypeId] = item;
          return acc;
        }, {});
    }

    return {};
  }, [stateData]);

  const getRoundSlotsDates = (roundTemplate) => {
    const weekDayEventDates: Record<number, string> = {};
    const startDate = dayjs(roundTemplate.startDate).utc();
    const endDate = dayjs(roundTemplate.endDate).utc();

    const differenceBewteenDates = endDate.diff(startDate, 'days');

    for (let start = 0; start <= differenceBewteenDates; start++) {
      const nextDay = startDate.add(start, 'days');

      const weekDay = WeekDayMap[nextDay.format('ddd')] ?? 1;

      if (weekDayEventDates[weekDay]) {
        weekDayEventDates[weekDay].push(nextDay.format(DATE_FORMAT_CAL));
      } else {
        weekDayEventDates[weekDay] = [nextDay.format(DATE_FORMAT_CAL)];
      }
    }

    return weekDayEventDates;
  };

  const roundTemplateSlots = useMemo(() => {
    const roundTemplateEvents: Record<string, SlotEventType[]> = {};

    roundTemplates.forEach((roundTemplate) => {
      const slotsEvents: SlotEventType[] = [];
      const weekDayEventDates = getRoundSlotsDates(roundTemplate);

      roundTemplate.roundDays
        .filter((rd) => rd.isActive)
        .forEach((roundDate) => {
          roundDate.timeSlots
            .filter((x) => x.timeSlotStatus !== DELETED)
            .forEach((slot) => {
              weekDayEventDates[roundDate.weekDay]?.forEach((wDay: string) => {
                const selectednetworkCategory =
                  networkCategories[slot.networkCategoryId];

                const textColor = fontColorContrast(
                  selectednetworkCategory?.color,
                );

                slotsEvents.push({
                  color: selectednetworkCategory?.color,
                  title: `${selectednetworkCategory?.name} - ${
                    slotTypes[slot.slotTypeId]?.name
                  }`,
                  start: wDay,
                  data: {
                    roundTemplateKey: roundTemplate.roundTemplateKey,
                    roundDayKey: roundDate.roundDayKey,
                    timeSlotKey: slot.timeSlotKey,
                    textColor,
                  },
                });
              });
            });
        });

      roundTemplateEvents[roundTemplate.roundTemplateKey] = slotsEvents;
    });

    return roundTemplateEvents;
  }, [roundTemplates, networkCategories]);

  const handleEventReceive = useCallback(
    (eventInfo, roundTemplateKey) => {
      const networkCategoryId = eventInfo.draggedEl.getAttribute('data-id');
      const { slotTypeId } = networkCategories[networkCategoryId];

      const currentDropDate = dayjs(eventInfo.event.start).utc();

      if (
        roundTemplateDates[roundTemplateKey][
          currentDropDate.format(DATE_FORMAT_CAL)
        ]
      ) {
        saveSlot(
          createNewSlot('slot ', slotTypeId, networkCategoryId),
          roundTemplateKey,
          WeekDayMap[currentDropDate.format('ddd')],
        );
      }
    },
    [roundTemplateDates],
  );

  const dayCellCellContent = (arg, type, roundTemplate) => {
    const cellDate = dayjs(arg.date).utc().format(DATE_FORMAT_CAL);

    if (type === 'class') {
      if (roundTemplateDates[roundTemplate.roundTemplateKey][cellDate]) {
        return ['active-round-day'];
      }

      return ['inactive-cell'];
    }

    const currentDate = dayjs(arg.date).utc().format(DATE_FORMAT_CAL);
    const startDate = dayjs(roundTemplate.startDate)
      .utc()
      .format(DATE_FORMAT_CAL);
    const endDate = dayjs(roundTemplate.endDate).utc().format(DATE_FORMAT_CAL);

    const isActiveDay = currentDate >= startDate && currentDate <= endDate;

    return isActiveDay ? dayjs(arg.date).utc().format('DD MMM') : '';
  };

  const eventRender = (arg) => {
    const { data } = arg.event.extendedProps;

    if (!data) return <div className="invalid-event" />;

    return (
      <SlotEvent
        title={arg.event.title}
        roundTemplateKey={data.roundTemplateKey}
        roundDayKey={data.roundDayKey}
        timeSlotKey={data.timeSlotKey}
        textColor={data.textColor}
        deleteSlot={deleteSlot}
      />
    );
  };

  useEffect(() => {
    const tableHeaderRow = document.querySelector('.fc-col-header thead tr');

    if (tableHeaderRow) {
      const columns = tableHeaderRow.children;
      const newWeekDays = [];
      for (let i = 0; i < columns.length; i++) {
        newWeekDays.push(columns[i].innerText);
      }

      setWeekDays(newWeekDays);
    } else {
      setWeekDays(Object.keys(WeekDayMap));
    }
  }, []);

  return (
    <div
      className={classNames('section-container', className)}
      data-testid="slots-container"
    >
      <div className="section-header flex-between">
        <div
          className="section-title flex items-center gap-2"
          data-testid="slot-section-title"
        >
          {t('GENERAL.SLOT.TITLE', {
            count: 2,
          })}

          <Badge
            count={roundTemplates.length}
            style={{ backgroundColor: '#d0d5dd', color: '#000000' }}
          />
        </div>
      </div>

      <div className="slot-list mt-1">
        <div className="slots-summary sticky">
          <div className="slot-round list-section">
            <div className="title">{t('GENERAL.NETWORK.TITLE')}</div>
          </div>

          <div className="calender-columns">
            {weekDays.map((day) => (
              <div key={day} className="week-day">
                {day}
              </div>
            ))}
          </div>
        </div>
        {roundTemplates.length === 0 && (
          <Empty
            title={t('GENERAL.ROUND.EMPTY')}
            subtitle={t('GENERAL.ROUND.LET_CREATE')}
            btnText={t('GENERAL.ROUND.CREATE')}
            onBtnClick={() => onTabChange('round')}
            className="white-background"
          />
        )}

        {roundTemplates.map((roundTemplate, roundIndex) => (
          <div key={roundIndex} className="slots-summary hidden-header">
            <div
              className="slot-round list-section"
              data-testid={`round-key-${roundTemplate.roundTemplateKey}`}
            >
              <div className="name">
                {t('GENERAL.ROUND.TITLE')} {roundIndex + 1}
                <br />
                <span className="date">
                  {moment(roundTemplate.startDate).utc().format(DATE_FORMAT)}
                </span>
              </div>
            </div>
            <div className="calender hide-invalid-event">
              <FullCalendar
                timeZone="UTC"
                key={roundTemplate.roundTemplateKey}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridFourWeek"
                events={
                  roundTemplateSlots[roundTemplate.roundTemplateKey] ?? []
                }
                views={{
                  dayGridFourWeek: {
                    type: 'dayGrid',
                    duration: {
                      week: roundWeeksMap[roundTemplate.roundTemplateKey]
                        ? roundWeeksMap[roundTemplate.roundTemplateKey] + 1
                        : 1,
                    },
                    dayHeaderFormat: {
                      weekday: 'short',
                    },
                    dayCellContent: (arg) =>
                      dayCellCellContent(arg, 'day', roundTemplate),
                  },
                }}
                headerToolbar={{
                  left: '',
                  center: '',
                  right: '',
                }}
                fixedWeekCount={false}
                contentHeight={
                  (roundWeeksMap[roundTemplate.roundTemplateKey] + 1) * 130
                }
                dayCellClassNames={(arg) =>
                  dayCellCellContent(arg, 'class', roundTemplate)
                }
                initialDate={dayjs(roundTemplate.startDate).utc().$d}
                droppable
                eventReceive={(eventInfo) =>
                  handleEventReceive(eventInfo, roundTemplate.roundTemplateKey)
                }
                firstDay={
                  FullCalenderStartDayMap[
                    stateData?.roundTemplateWeekStartDay
                  ] ?? 1
                }
                eventContent={eventRender}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
