import React, { useMemo } from 'react';
import { Badge, Dropdown, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { decodeHTML } from 'utils/ui-helper';
import { NameMap } from 'Models/Scenario';
import { selectTeams, selectVenueTeams } from 'store/slices/scenarioSlice';
import TeamSelectPopOver from './TeamSelectPopover';
import { Team } from 'Models/Team';

export default function HomeVenue({
  data,
}: {
  data: { venueId: string; venueKey: string };
}) {
  const allTeams = useSelector(selectTeams);
  const allVenueTeams = useSelector(selectVenueTeams);

  const teamsMap = useMemo(() => {
    if (allTeams) {
      return allTeams.reduce((acc: NameMap, venue: Team) => {
        acc[venue.teamId] = decodeHTML(venue.name);

        return acc;
      }, {});
    }
    return {};
  }, [allTeams]);

  const homeTeams = useMemo(() => {
    if (allVenueTeams) {
      return (
        allVenueTeams.find((v) => v.venueId === data.venueId)?.teamIds ?? []
      );
    }

    return [];
  }, [allVenueTeams]);

  const venueTeams = useMemo(
    () =>
      homeTeams.map((teamId) => ({
        label: <span>{teamsMap[teamId]}</span>,
        key: teamId,
      })),

    [homeTeams],
  );

  const createSelectPopOver = () => (
    <TeamSelectPopOver venueKey={data.venueKey} teamsIds={homeTeams} />
  );

  let innerContent = null;

  if (venueTeams.length === 1) {
    innerContent = (
      <span className="cursor-pointer" data-testid="home-venue-count">
        <Tag color="#BDBDBD">{teamsMap[venueTeams[0].key]}</Tag>
      </span>
    );
  } else {
    innerContent = (
      <div>
        <Dropdown
          menu={{ items: venueTeams }}
          trigger={['hover']}
          placement="bottomRight"
          destroyPopupOnHide
        >
          <div className="cursor-pointer">
            <Badge
              count={homeTeams.length}
              style={{ backgroundColor: '#BDBDBD' }}
              data-testid="home-venue-count"
            />
          </div>
        </Dropdown>
      </div>
    );
  }

  return (
    <Dropdown
      menu={{ items: [] }}
      trigger={['click']}
      dropdownRender={createSelectPopOver}
      placement="bottomLeft"
      destroyPopupOnHide
    >
      {innerContent}
    </Dropdown>
  );
}
