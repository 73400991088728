import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Set } from 'Models/Scenario';
import { QueueActionType, QueueCreateUpdateSetPayloadType } from 'Models/Queue';
import {
  CREATED,
  DELETED,
  NEW,
  SYNC_SET_KEY_PREFIX,
  SYNC_SETLINE_KEY_PREFIX,
} from 'utils/variables';
import '../../style.scss';

export const copySet = async (
  sets: Set[],
  setKey: string,
  onEnqueue: (data: QueueActionType) => void,
  syncSetToStore: (data: QueueCreateUpdateSetPayloadType) => void,
  localSolutionKey: string,
  solutionKey: string,
) => {
  const toBeCopiedSet = _.cloneDeep(sets.find((set) => set.setKey === setKey));

  if (!toBeCopiedSet) return;

  const toBeSyncedKey = `${SYNC_SET_KEY_PREFIX}${uuidv4()}`;
  const toBeCopiedSetlines = toBeCopiedSet.setLines.map((item) => {
    const syncedKey = `${SYNC_SETLINE_KEY_PREFIX}${uuidv4()}`;
    item.setLineKey = syncedKey;
    item.toBeSyncedKey = syncedKey;
    item.setLineStatus = CREATED;

    return item;
  });

  const setPayload = {
    ...toBeCopiedSet,
    setLines: toBeCopiedSetlines,
    toBeSyncedKey,
    solutionKey: localSolutionKey,
  };

  syncSetToStore({
    data: setPayload,
    toBeUpdatedFields: {
      setKey: toBeSyncedKey,
      setStatus: CREATED,
    },
    toBeSyncedKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_SET',
    payload: {
      data: {
        ...setPayload,
        setStatus: NEW,
        setLines: toBeCopiedSetlines.map((item) => ({
          ...item,
          setLineStatus: NEW,
        })),
        solutionKey,
      },
      toBeUpdatedFields: {
        setKey: '',
        setStatus: NEW,
      },
      toBeSyncedKey,
      solutionKey: localSolutionKey,
    },
  });
};

export const deleteSet = async (
  sets: Set[],
  setKey: string,
  onEnqueue: (data: QueueActionType) => void,
  syncSetToStore: (data: QueueCreateUpdateSetPayloadType) => void,
  localSolutionKey: string,
  solutionKey: string,
) => {
  const toBeDeleted = sets.find((set) => set.setKey === setKey);

  if (!toBeDeleted) return;

  const toBeSyncedKey = `${SYNC_SET_KEY_PREFIX}${uuidv4()}`;

  syncSetToStore({
    data: {
      ...toBeDeleted,
      solutionKey: localSolutionKey,
    },
    toBeUpdatedFields: {
      setStatus: DELETED,
    },
    toBeSyncedKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_SET',
    payload: {
      data: { ...toBeDeleted, solutionKey },
      toBeUpdatedFields: {
        setStatus: DELETED,
      },
      toBeSyncedKey,
      solutionKey: localSolutionKey,
    },
  });
};
