import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  VenuePayload,
  VenuePromiseResponse,
  VenueDeletePayload,
  VenuesPayload,
  VenuesPromiseResponse,
} from '../../Models/Venue';
import {
  VenueTeam,
  VenueTeamMapUpdatePayload,
  VenueTeamMapUpdateResponse,
} from 'Models/VenueTeam';
import { ScenarioSlice } from 'Models/Scenario';
import {
  CreateOrUpdateVenue,
  CreateVenues,
  DeleteVenue,
  TeamVenueMap,
} from 'Services/VenueService';
import {
  updateScenriosScenario,
  getScenarioByKey,
  updateVenue,
} from './mutate_helpers';
import { getScenarioKey, getSolutionKey } from 'utils/ui-helper';

export const saveVenue = createAsyncThunk(
  'user/saveVenue',
  async (payload: {
    venuePayload: VenuePayload;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await CreateOrUpdateVenue(
      payload.venuePayload,
      payload.solutionStatus,
    );

    return {
      data: res,
      toBeSyncedKey: payload.venuePayload.venue.toBeSyncedKey ?? '',
    };
  },
);

export const saveVenues = createAsyncThunk(
  'user/saveVenues',
  async (payload: {
    venuesPayload: VenuesPayload;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await CreateVenues(
      payload.venuesPayload,
      payload.solutionStatus,
    );

    const toBeSyncedKeys = payload.venuesPayload.venues
      .filter((item) => item.toBeSyncedKey)
      .map((item) => item.toBeSyncedKey);

    return { data: res, toBeSyncedKeys };
  },
);

export const deleteVenue = createAsyncThunk(
  'user/deleteVenue',
  async (payload: {
    venueDeletePayload: VenueDeletePayload;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await DeleteVenue(
      payload.venueDeletePayload,
      payload.solutionStatus,
    );

    return { data: res, venueKey: payload.venueDeletePayload.venueKey };
  },
);

export const updateVenueTeam = createAsyncThunk(
  'user/updateVenueTeam',
  async (payload: {
    venueTeamMapUpdatePayload: VenueTeamMapUpdatePayload;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await TeamVenueMap(
      payload.venueTeamMapUpdatePayload,
      payload.solutionStatus,
    );

    return { data: res };
  },
);

export const saveVenueReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: VenuePromiseResponse;
      toBeSyncedKey?: string;
    };
  },
) => {
  if (payload.data) {
    const updatedVenue = payload.data?.venue;

    const targetScenarioKey = getScenarioKey(updatedVenue.venueKey);
    const targetSolutionKey = getSolutionKey(updatedVenue.venueKey);

    const toBeSyncedKey = payload.toBeSyncedKey ?? '';

    updateVenue(targetScenarioKey, targetSolutionKey, [updatedVenue], state, [
      toBeSyncedKey,
    ]);
  }
};

export const saveVenuesReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: VenuesPromiseResponse;
      toBeSyncedKeys?: string[];
    };
  },
) => {
  if (payload.data) {
    const createdVenues = payload.data?.venues;
    const targetScenarioKey = getScenarioKey(createdVenues[0].venueKey);
    const targetSolutionKey = getSolutionKey(createdVenues[0].venueKey);

    const toBeSyncedKeys = payload.toBeSyncedKeys ?? [];

    updateVenue(
      targetScenarioKey,
      targetSolutionKey,
      createdVenues,
      state,
      toBeSyncedKeys,
    );
  }
};

export const deleteVenueReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: object;
      venueKey: string;
    };
  },
) => {
  if (payload.data) {
    const targetScenarioKey = state.scenarioKey;
    const targetSolutionKey = state.solutionKey;

    const deletedVenueKey = payload.venueKey;
    const deletedVenueId = deletedVenueKey.split('-')[2];
    const targetScenario = getScenarioByKey(state, targetScenarioKey);

    if (targetScenario) {
      const targetSolutionIndex =
        targetScenario.optimizationEnvelop.solutions.findIndex(
          (solution) => solution.solutionKey === targetSolutionKey,
        );

      if (targetSolutionIndex !== -1) {
        const targetSolution =
          targetScenario.optimizationEnvelop.solutions[targetSolutionIndex];

        targetSolution.stateData.venues =
          targetSolution.stateData.venues.filter(
            (venue) => venue.venueKey !== deletedVenueKey,
          );

        targetSolution.stateData.venueTeams =
          targetSolution.stateData.venueTeams.filter(
            (item) => item.venueId !== deletedVenueId,
          );

        targetScenario.optimizationEnvelop.solutions[targetSolutionIndex] =
          targetSolution;

        updateScenriosScenario(state, targetScenarioKey, targetScenario);
      }
    }
  }
};

export const updateVenueTeamReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: VenueTeamMapUpdateResponse;
    };
  },
) => {
  if (payload.data) {
    const targetScenarioKey = state.scenarioKey;
    const targetSolutionKey = state.solutionKey;

    const updatedVenueTeam = payload.data.venueTeam;
    const targetScenario = getScenarioByKey(state, targetScenarioKey);

    if (targetScenario) {
      const targetSolutionIndex =
        targetScenario.optimizationEnvelop.solutions.findIndex(
          (solution) => solution.solutionKey === targetSolutionKey,
        );

      if (targetSolutionIndex !== -1) {
        const targetSolution =
          targetScenario.optimizationEnvelop.solutions[targetSolutionIndex];

        const venueTeamIndex = targetSolution.stateData.venueTeams.findIndex(
          (venueTeam) => venueTeam.venueId === updatedVenueTeam.venueId,
        );

        targetSolution.stateData.venueTeams[venueTeamIndex] = updatedVenueTeam;

        targetScenario.optimizationEnvelop.solutions[targetSolutionIndex] =
          targetSolution;
      }
    }

    if (targetScenarioKey && targetScenario) {
      updateScenriosScenario(state, targetScenarioKey, targetScenario);
    }
  }
};
