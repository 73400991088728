import React from 'react';
import { Close } from 'Components/Elements/Icons';

interface Props {
  title: string;
  roundTemplateKey: string;
  roundDayKey: string;
  timeSlotKey: string;
  textColor: string;
  deleteSlot: ({
    roundTemplateKey,
    roundDayKey,
    timeSlotKey,
  }: {
    roundTemplateKey: string;
    roundDayKey: string;
    timeSlotKey: string;
  }) => void;
}

export default function SlotEvent({
  title,
  roundTemplateKey,
  roundDayKey,
  timeSlotKey,
  textColor,
  deleteSlot,
}: Props): JSX.Element {
  const openDeletePopover = () => {
    deleteSlot({ roundTemplateKey, roundDayKey, timeSlotKey });
  };

  return (
    <div className="flex justify-between items-center px-1">
      <span className="slot-event-title" style={{ color: textColor }}>
        {title}
      </span>

      <span
        className="cursor-pointer"
        onClick={openDeletePopover}
        data-testid="delete-event"
      >
        <Close className="w-3 h-3" color={textColor} />
      </span>
    </div>
  );
}
