import { Switch, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { saveUserSettings } from 'Services/UserService';
import { useAppDispatch } from 'storeHooks/hooks';
import {
  setShoudDeleteConfirmation,
  selectShoudDeleteConfirmation,
} from 'store/slices/userSlice';

const ApplicationSetting = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const shouldDeleteConfirmation = useSelector(selectShoudDeleteConfirmation);

  const onChange = (checked) => {
    dispatch(setShoudDeleteConfirmation(checked));

    saveUserSettings({
      shouldShowDeleteConfirmation: checked,
    });
  };

  return (
    <>
      <div>
        <div className="section-title font-semibold pl-0">
          {t('GENERAL.USER.APPLICATION_SETTING')}
        </div>
        <div className="mt-1 section-subtitle">
          {t('GENERAL.SET_PREFERENCES')}
        </div>
      </div>

      <Divider style={{ marginBottom: '16px' }} />

      <div>
        <div className="font-medium my-2">
          {t('GENERAL.APPLICATION_FUNCTION')}
        </div>
        <div className="flex items-center gap-2">
          <Switch
            checked={shouldDeleteConfirmation}
            onChange={onChange}
            className="bg-slate-100"
          />
          <div>{t('GENERAL.USER.SHOW_DELETE_CONFIRMATION')}</div>
        </div>
      </div>
    </>
  );
};

export default ApplicationSetting;
