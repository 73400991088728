import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import i18n from 'plugins/i18next';
import {
  Constraint,
  RoundTemplate,
  Set,
  SetLine,
  Solution,
  TimeSlot,
} from 'Models/Scenario';
import { QueueActionType, QueueKeyMapType } from 'Models/Queue';
import { selectScenarioMetas } from 'store/slices/scenarioSlice';
import {
  enqueue,
  dequeue,
  selectProcessingQueueItem,
  selectQueue,
  setQueueProcessing,
  selectQueueLocalKeyMap,
  setQueueLocalKeyMap,
  pickFailedQueueItems,
  selectFailedQueue,
  syncFailedQueueItems,
  clearFailedQueueItems,
} from 'store/slices/queueSlice';
import useSolveData from 'customHooks/useSolveData';
import { Venue } from 'Models/Venue';
import { SYNC_SETLINE_KEY_PREFIX } from 'utils/variables';
import { useAppDispatch } from './hooks';
import {
  useConstraint,
  useSet,
  useSetLine,
  useOpponent,
  useVenue,
  useTeam,
  useSlotType,
  useNetwork,
  useNetworkCategory,
  useRoundTemplate,
  useTimeSlot,
  useLock,
} from './queue';
import { Team } from 'Models/Team';
import { Network, NetworkCategory } from 'Models/Network';
import { SlotType } from 'Models/Slot';
import { SolutionStatusStopped } from 'Models/SolutionStatuses';

type WaitTimerType = (time: number) => Promise<void>;

export const wait: WaitTimerType = (time) =>
  new Promise((resolve: () => void) => {
    setTimeout(() => {
      resolve();
    }, time);
  });

const feedbackMessageKey = {
  New: 'CREATED',
  Created: 'UPDATED',
  Deleted: 'DELETED',
};

export const useQueue = () => {
  const dispatch = useAppDispatch();
  const queue = useSelector(selectQueue);
  const failedQueueItems = useSelector(selectFailedQueue);
  const { cloneToNewInstance } = useSolveData();
  const scenarioMetas = useSelector(selectScenarioMetas);
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const { createOrUpdateConstraintQueue } = useConstraint();
  const { createOrUpdateSetQueue } = useSet();
  const { createOrUpdateSetLineQueue } = useSetLine();
  const { updateOpponentQueue } = useOpponent();
  const { createOrUpdateVenueQueue, updateTeamVenueMapQueue } = useVenue();
  const { createOrUpdateTeamQueue } = useTeam();
  const { createOrUpdateSlotTypeQueue } = useSlotType();
  const { createOrUpdateNetworkQueue } = useNetwork();
  const { createOrUpdateNetworkCategoryQueue } = useNetworkCategory();
  const {
    createRoundTemplateQueue,
    updateRoundTemplateQueue,
    updateRoundWeekStartDay,
  } = useRoundTemplate();
  const { createOrUpdateTimeSlotQueue } = useTimeSlot();
  const { updateLockUnlockQueue } = useLock();
  const isQueueProcessingItem = useSelector(selectProcessingQueueItem);

  const queueActionMethodMap = {
    NEW_SOLUTION_INSTANCE: cloneToNewInstance,
    CREATE_UPDATE_SET: createOrUpdateSetQueue,
    CREATE_UPDATE_SETLINE: createOrUpdateSetLineQueue,
    CREATE_UPDATE_CONSTRAINT: createOrUpdateConstraintQueue,
    CREATE_UPDATE_OPPONENT: updateOpponentQueue,
    CREATE_UPDATE_VENUE: createOrUpdateVenueQueue,
    UPDATE_TEAM_VENUE: updateTeamVenueMapQueue,
    CREATE_UPDATE_TEAM: createOrUpdateTeamQueue,
    CREATE_UPDATE_SLOT_TYPE: createOrUpdateSlotTypeQueue,
    CREATE_UPDATE_NETWORK: createOrUpdateNetworkQueue,
    CREATE_UPDATE_NETWORK_CATEGORY: createOrUpdateNetworkCategoryQueue,
    CREATE_ROUND_TEMPLATE: createRoundTemplateQueue,
    UPDATE_ROUND_TEMPLATE: updateRoundTemplateQueue,
    CREATE_UPDATE_TIME_SLOT: createOrUpdateTimeSlotQueue,
    UPDATE_LOCK_UNLOCK: updateLockUnlockQueue,
    UPDATE_ROUND_WEEK_START: updateRoundWeekStartDay,
  };

  const currentQueueLength = useRef(Array.isArray(queue) ? queue.length : 0);
  const currentQueue = useRef(queue);
  const isQueueBusy = useRef(isQueueProcessingItem);
  const scenarioMetasRef = useRef(scenarioMetas);

  const onEnqueue = (item: QueueActionType) => dispatch(enqueue(item));

  const setQueueBusy = () => dispatch(setQueueProcessing(true));
  const setQueueFree = () => dispatch(setQueueProcessing(false));
  const restoreFailedQueueItems = () => dispatch(syncFailedQueueItems());
  const storeFailedQueueItem = () => dispatch(pickFailedQueueItems());
  const discardFailedQueueItems = () => {
    dispatch(clearFailedQueueItems());

    setTimeout(() => {
      window.location.reload();
    }, 600);
  };

  const syncQueueLocalKeyMap = (key: string, value: string) => {
    dispatch(
      setQueueLocalKeyMap({
        key,
        value,
      }),
    );
  };

  const setActualKeyForLocalQueueId = (
    result: QueueKeyMapType,
    actionData: QueueActionType,
  ) => {
    const { payload, type } = result;
    const { payload: actionPayload, type: actionType } = actionData;

    if (payload) {
      const { data, toBeSyncedKey, toBeSyncedKeys, toBeSyncedVenueKey } =
        payload;

      switch (type) {
        case 'user/saveConstraint/fulfilled': {
          const given = data as Constraint;
          syncQueueLocalKeyMap(toBeSyncedKey, given.constraintKey);
          break;
        }
        case 'user/saveSet/fulfilled': {
          const given = data as Set;

          syncQueueLocalKeyMap(toBeSyncedKey, given.setKey);

          if (actionType === 'CREATE_UPDATE_SET') {
            let syncSetlineKeys: string[] = [];

            syncSetlineKeys = actionPayload.data.setLines
              .map((item) => item.setLineKey)
              .filter((setLineKey) =>
                setLineKey.includes(SYNC_SETLINE_KEY_PREFIX),
              );

            if (actionPayload.toBeSyncedSetId) {
              syncQueueLocalKeyMap(actionPayload.toBeSyncedSetId, given.setId);
            }

            if (
              syncSetlineKeys.length > 0 &&
              given.setLines?.length === syncSetlineKeys.length
            ) {
              given.setLines.forEach((setLine: SetLine, index: number) =>
                syncQueueLocalKeyMap(
                  syncSetlineKeys[index],
                  setLine.setLineKey,
                ),
              );
            }
          }

          break;
        }
        case 'user/saveSetLine/fulfilled': {
          const given = data as SetLine;

          syncQueueLocalKeyMap(toBeSyncedKey, given.setLineKey);
          break;
        }
        case 'user/saveVenues/fulfilled': {
          // @ts-expect-error
          const savedVenues = data.venues as Venue[];

          if (toBeSyncedKeys) {
            savedVenues.forEach((v, i) =>
              syncQueueLocalKeyMap(toBeSyncedKeys[i], v.venueKey),
            );
          }

          break;
        }
        case 'user/saveVenue/fulfilled': {
          const { venue } = data as {
            venue: Venue;
          };

          syncQueueLocalKeyMap(toBeSyncedKey, venue.venueKey);

          break;
        }
        case 'user/saveTeam/fulfilled': {
          const { team, venue } = data as {
            team: Team;
            venue: Venue;
          };

          if (team?.teamKey) {
            syncQueueLocalKeyMap(toBeSyncedKey, team.teamKey);
          }

          if (toBeSyncedVenueKey && venue) {
            syncQueueLocalKeyMap(toBeSyncedKey, venue.venueKey);
          }

          break;
        }
        case 'user/saveNetwork/fulfilled': {
          const given = data as Network;

          syncQueueLocalKeyMap(toBeSyncedKey, given.networkKey);

          if (actionType === 'CREATE_UPDATE_NETWORK') {
            const syncNetworkCategoryKeys: string[] = [];

            const dataPayload = actionPayload.data;

            if (Array.isArray(dataPayload.networkCategories)) {
              dataPayload.networkCategories
                .filter((nc) => nc.toBeSyncedKey)
                .forEach((v) => {
                  if (
                    v.toBeSyncedKey &&
                    !syncNetworkCategoryKeys.includes(v.toBeSyncedKey)
                  ) {
                    syncNetworkCategoryKeys.push(v.toBeSyncedKey);
                  }
                });
            }

            if (
              syncNetworkCategoryKeys.length > 0 &&
              given.networkCategories?.length === syncNetworkCategoryKeys.length
            ) {
              given.networkCategories.forEach(
                (nc: NetworkCategory, index: number) =>
                  syncQueueLocalKeyMap(
                    syncNetworkCategoryKeys[index],
                    nc.networkCategoryKey,
                  ),
              );
            }
          }

          break;
        }
        case 'user/saveNetworkCategory/fulfilled': {
          const given = data as NetworkCategory;

          syncQueueLocalKeyMap(toBeSyncedKey, given.networkCategoryKey);

          break;
        }
        case 'user/createRround/fulfilled': {
          const { roundTemplates } = data as {
            roundTemplates: RoundTemplate[];
          };

          // @ts-expect-error
          const dataPayload = actionPayload.data.actualData as RoundTemplate[];

          if (Array.isArray(dataPayload) && Array.isArray(roundTemplates)) {
            dataPayload.forEach((v, i) => {
              syncQueueLocalKeyMap(
                v.toBeSyncedKey ?? '',
                roundTemplates[i].roundTemplateKey,
              );

              if (Array.isArray(v.roundDays)) {
                v.roundDays.forEach((rd, rdi) => {
                  syncQueueLocalKeyMap(
                    rd.toBeSyncedKey ?? '',
                    roundTemplates[i].roundDays[rdi].roundDayKey,
                  );
                });
              }
            });
          }

          break;
        }
        case 'user/updateSlot/fulfilled': {
          const given = data as TimeSlot;

          syncQueueLocalKeyMap(toBeSyncedKey, given.timeSlotKey);
          break;
        }
        case 'user/saveSlotType/fulfilled': {
          const { slotType } = data as {
            slotType: SlotType;
          };

          syncQueueLocalKeyMap(toBeSyncedKey, slotType.slotTypeKey);
          break;
        }
        case 'user/updateRound/fulfilled': {
          const { roundTemplates } = data as {
            roundTemplates: RoundTemplate[];
          };

          // @ts-expect-error
          const payloadRoundTemplates = actionPayload.data as RoundTemplate[];

          if (actionType === 'UPDATE_ROUND_TEMPLATE') {
            roundTemplates.forEach((rt, i) => {
              payloadRoundTemplates[i].roundDays.forEach((rd, rdi) => {
                rd.timeSlots.forEach((ts, tsi) => {
                  syncQueueLocalKeyMap(
                    ts.timeSlotKey,
                    rt.roundDays[rdi].timeSlots[tsi].timeSlotKey,
                  );
                });
              });
            });
          }

          break;
        }
        default:
        //
      }
    }
  };

  const showCloneRelatedMessage = (data: QueueActionType) => {
    const { type, payload } = data;
    const actionEntity = type.replace('CREATE_', '').replace('UPDATE_', '');

    switch (type) {
      case 'CREATE_UPDATE_CONSTRAINT': {
        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${
              feedbackMessageKey[payload.data.constraintStatus]
            }_IN_CLONED`,
          ),
        );
        break;
      }
      case 'CREATE_UPDATE_SET': {
        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${
              feedbackMessageKey[payload.data.setStatus]
            }_IN_CLONED`,
          ),
        );
        break;
      }
      case 'CREATE_UPDATE_SETLINE': {
        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${
              feedbackMessageKey[payload.data.setLineStatus]
            }_IN_CLONED`,
          ),
        );
        break;
      }
      case 'CREATE_UPDATE_OPPONENT': {
        message.success(
          i18n.t(`GENERAL.FEEDBACK.${actionEntity}.UPDATED_IN_CLONED`),
        );
        break;
      }
      case 'CREATE_UPDATE_VENUE': {
        const singleEntiry = Array.isArray(payload.data)
          ? payload.data[0]
          : payload.data;

        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${
              feedbackMessageKey[singleEntiry.venueStatus]
            }_IN_CLONED`,
          ),
        );
        break;
      }
      case 'CREATE_UPDATE_TEAM': {
        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${
              feedbackMessageKey[payload.data.teamStatus]
            }_IN_CLONED`,
          ),
        );
        break;
      }
      case 'UPDATE_TEAM_VENUE': {
        message.success(
          i18n.t(`GENERAL.FEEDBACK.VENUE_TEAM.UPDATED_IN_CLONED`),
        );
        break;
      }
      case 'CREATE_UPDATE_SLOT_TYPE': {
        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${
              feedbackMessageKey[payload.data.slotTypeStatus]
            }_IN_CLONED`,
          ),
        );
        break;
      }
      case 'CREATE_UPDATE_NETWORK': {
        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${
              feedbackMessageKey[payload.data.networkStatus]
            }_IN_CLONED`,
          ),
        );
        break;
      }
      case 'CREATE_UPDATE_NETWORK_CATEGORY': {
        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${
              feedbackMessageKey[payload.data.networkCategoryStatus]
            }_IN_CLONED`,
          ),
        );
        break;
      }
      case 'CREATE_ROUND_TEMPLATE': {
        message.success(
          i18n.t(`GENERAL.FEEDBACK.${actionEntity}.CREATED_IN_CLONED`),
        );
        break;
      }
      case 'UPDATE_ROUND_TEMPLATE': {
        const singleEntiry = Array.isArray(payload.data)
          ? payload.data[0]
          : payload.data;

        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${
              feedbackMessageKey[singleEntiry.roundTemplateStatus]
            }_IN_CLONED`,
          ),
        );
        break;
      }
      case 'CREATE_UPDATE_TIME_SLOT': {
        const key = payload.data.status === 'New' ? 'New' : 'Deleted';

        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}_IN_CLONED`,
          ),
        );
        break;
      }
      case 'UPDATE_LOCK_UNLOCK': {
        message.success(
          i18n.t(
            `GENERAL.FEEDBACK.${actionEntity}.${payload.data.lockType.toUpperCase()}ED_IN_CLONED`,
          ),
        );
        break;
      }
      default:
      //
    }
  };

  const canProcessQueueItem = () =>
    !isQueueBusy.current && currentQueueLength.current > 0;

  const handleQueueItem = async () => {
    if (canProcessQueueItem()) {
      setQueueBusy();

      let [firstItemInQueue] = currentQueue.current;

      try {
        if (firstItemInQueue.payload.toBeSyncedKey) {
          syncQueueLocalKeyMap(firstItemInQueue.payload.toBeSyncedKey, '');
        }

        if (firstItemInQueue.payload.toBeSyncedVenueKey) {
          syncQueueLocalKeyMap(firstItemInQueue.payload.toBeSyncedVenueKey, '');
        }

        const allSolutions = scenarioMetasRef.current
          .flatMap((item) => item.scenario?.optimizationEnvelop.solutions)
          .filter((item) => item) as Solution[];

        const targetSolution = allSolutions.find(
          (item) => item.solutionKey === firstItemInQueue.payload.solutionKey,
        );
        const actionSolution = allSolutions.find(
          (item) => item.solutionKey === targetSolution?.stateData.solutionKey,
        );

        if (
          !firstItemInQueue.payload.solutionKeyUpdated &&
          actionSolution?.solutionStatus === SolutionStatusStopped
        ) {
          showCloneRelatedMessage(firstItemInQueue);

          await cloneToNewInstance(
            true,
            firstItemInQueue.type !== 'UPDATE_LOCK_UNLOCK',
          );
          [firstItemInQueue] = currentQueue.current;
        }

        const result = (await queueActionMethodMap[firstItemInQueue.type](
          // @ts-expect-error
          firstItemInQueue.payload,
        )) as QueueKeyMapType;

        setActualKeyForLocalQueueId(result, firstItemInQueue);

        if (firstItemInQueue.payload.onSuccessMessage) {
          message.success(i18n.t(firstItemInQueue.payload.onSuccessMessage));
        }

        if (!result.payload) {
          storeFailedQueueItem();
        }
      } catch (e) {
        if (firstItemInQueue.payload.onError) {
          firstItemInQueue.payload.onError();
        }
      } finally {
        dispatch(dequeue());
        setQueueFree();
      }
    }
  };

  useEffect(() => {
    if (queue) {
      currentQueue.current = queue;
      currentQueueLength.current = queue.length;
    }
  }, [queue]);

  useEffect(() => {
    isQueueBusy.current = isQueueProcessingItem;
  }, [isQueueProcessingItem]);

  useEffect(() => {
    scenarioMetasRef.current = scenarioMetas;
  }, [scenarioMetas]);

  return {
    queue,
    failedQueueItems,
    setQueueBusy,
    setQueueFree,
    isQueueProcessingItem,
    onEnqueue,
    handleQueueItem,
    queueLocalKeyMap,
    syncQueueLocalKeyMap,
    restoreFailedQueueItems,
    storeFailedQueueItem,
    discardFailedQueueItems,
  };
};

export default useQueue;
