import { ScenarioSetResponsePromiseType } from 'Models/Scenario';
import { QueueUpdateOpponentPayloadType } from 'Models/Queue';
import { syncOpponent } from 'store/slices/scenarioSlice';
import { saveOpponent } from 'store/slices/teamSlice';
import { CREATED } from 'utils/variables';
import { Opponents } from 'Models/Opponent';
import { getSolutionKey } from 'utils/ui-helper';
import { useAppDispatch } from '../hooks';

export const useOpponent = () => {
  const dispatch = useAppDispatch();

  const prepareOpponentPayload = ({
    data,
  }: QueueUpdateOpponentPayloadType) => ({
    updatedOpponent: data,
    opponentsPenalty: data.penalty,
  });

  const syncOpponentToStore = ({
    data,
    solutionKey,
  }: QueueUpdateOpponentPayloadType): void => {
    dispatch(
      syncOpponent({
        ...prepareOpponentPayload({
          data,
        }),
        solutionKey,
      }),
    );
  };

  const updateOpponentQueue = ({
    data,
  }: {
    data: Opponents;
  }): Promise<ScenarioSetResponsePromiseType> => {
    const solutionKey = getSolutionKey(data.opponentKey);

    return dispatch(
      saveOpponent({
        opponentPayload: {
          opponents: data,
          solutionKey,
        },
        solutionStatus: CREATED,
      }),
    ) as Promise<ScenarioSetResponsePromiseType>;
  };

  return {
    prepareOpponentPayload,
    syncOpponentToStore,
    updateOpponentQueue,
  };
};

export default useOpponent;
