import {
  TeamPayload,
  TeamPromiseResponse,
  TeamDeletePayload,
  TeamDeletePromiseResponse,
  OpponentPayload,
  OpponentPromiseResponse,
} from 'Models/Team';
import i18n from 'plugins/i18next';
import api from './apiClient';
import { getErrorMsg } from './ServiceHelper';
import { message } from 'antd';
import { SYNC_TEAM_KEY_PREFIX } from 'utils/variables';

export const CreateOrUpdateTeam = async (
  payload: TeamPayload,
  solutionStatus: string | null | undefined,
): Promise<TeamPromiseResponse> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');

    message.error(errMsg);
    throw new Error(errMsg);
  }

  const teamKey = payload.team.teamKey.includes(SYNC_TEAM_KEY_PREFIX)
    ? ''
    : payload.team.teamKey;

  try {
    const targetUrl = `scenario/solution/${payload.solutionKey}/team/${
      teamKey ?? ''
    }`;

    const response = await api[
      payload.team.teamStatus === 'New' ? 'post' : 'put'
    ](targetUrl, {
      ...payload.team,
    });

    return response.data;
  } catch (err: any) {
    throw getErrorMsg(err, 'Create/Update Team');
  }
};

export const DeleteTeam = async (
  payload: TeamDeletePayload,
  solutionStatus: string | null | undefined,
): Promise<TeamDeletePromiseResponse> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }
  try {
    const response = await api.delete(
      `scenario/solution/${payload.solutionKey}/team/${payload.teamKey}`,
    );

    return response.data;
  } catch (err) {
    throw getErrorMsg(err, 'Delete DeleteTeam');
  }
};

export const CreateOrUpdateOpponents = async (
  payload: OpponentPayload,
  solutionStatus: string | null | undefined,
): Promise<OpponentPromiseResponse> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }
  try {
    const response = await api.put(
      `scenario/solution/${payload.solutionKey}/opponents/${payload.opponents.opponentKey}`,
      {
        ...payload.opponents,
      },
    );

    return response.data;
  } catch (err: any) {
    throw getErrorMsg(err, 'Create/Update opponent');
  }
};
