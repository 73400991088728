import { setSaving } from 'store/slices/networkSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useAppDispatch } from './hooks';

export const useNetwork = () => {
  const dispatch = useAppDispatch();

  const isSaving: boolean = useSelector(
    (state: RootState) => state.network.saving,
  );

  const setIsSaving = (payload: boolean) => dispatch(setSaving(payload));

  const goToScenario = (scenarioKey: string) => {
    // @ts-expect-error
    if (document.appNavigate) {
      // @ts-expect-error
      document.appNavigate(
        `/scenario/${scenarioKey.split('-')[0]}/${scenarioKey}`,
      );
    }
  };

  return {
    isSaving,
    setIsSaving,
    goToScenario,
  };
};

export default useNetwork;
