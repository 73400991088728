import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.496 18.9396C22.496 18.6887 22.2942 18.4855 22.0329 18.4855H20.8691C20.6197 18.4855 20.4178 18.6887 20.4178 18.9396V18.9635H20.406V29.8731H3.59023V12.9531H13.5538C13.8032 12.9531 14.0169 12.7499 14.0169 12.499V11.328C14.0169 11.077 13.8151 10.8739 13.5538 10.8739H2.54519C1.96329 10.8739 1.50014 11.3399 1.50014 11.9254V30.9485C1.50014 31.5221 1.96329 32.0001 2.54519 32.0001H21.451C22.0329 32.0001 22.496 31.534 22.496 30.9485V18.9396Z"
        fill="white"
      />
      <path
        d="M26.6481 0.227034C26.6481 0.107542 26.5412 0 26.4224 0H25.3299C25.2111 0 25.1042 0.0955932 25.1042 0.227034V1.32636C25.1042 1.44585 25.1992 1.55339 25.3299 1.55339H26.4224C26.5412 1.55339 26.6481 1.4578 26.6481 1.32636V0.227034Z"
        fill="url(#paint0_linear_1083_50505)"
      />
      <path
        d="M30.5072 3.08302C30.5072 2.86793 30.341 2.70064 30.1272 2.70064H28.3102C28.0965 2.70064 27.9302 2.86793 27.9302 3.08302V4.91124C27.9302 5.12633 28.0965 5.29362 28.3102 5.29362H30.1272C30.341 5.29362 30.5072 5.12633 30.5072 4.91124V3.08302Z"
        fill="url(#paint1_linear_1083_50505)"
      />
      <path
        d="M18.8221 5.67584C19.0834 5.67584 19.2971 5.89093 19.2971 6.15381V8.47195C19.2971 8.73484 19.0834 8.94992 18.8221 8.94992H16.5183C16.257 8.94992 16.0433 8.73484 16.0433 8.47195V6.15381C16.0433 5.89093 16.257 5.67584 16.5183 5.67584H18.8221Z"
        fill="url(#paint2_linear_1083_50505)"
      />
      <path
        d="M15.5324 18.5212C15.5324 18.0911 15.188 17.7445 14.7605 17.7445H11.0197C10.5922 17.7445 10.2478 18.0911 10.2478 18.5212V22.2852C10.2478 22.7154 10.5922 23.0619 11.0197 23.0619H14.7605C15.188 23.0619 15.5324 22.7154 15.5324 22.2852V18.5212Z"
        fill="url(#paint3_linear_1083_50505)"
      />
      <path
        d="M9.79662 24.0775C9.79662 23.6474 9.45223 23.3008 9.02471 23.3008H5.27205C4.84453 23.3008 4.50014 23.6474 4.50014 24.0775V27.8415C4.50014 28.2717 4.84453 28.6182 5.27205 28.6182H9.01284C9.44036 28.6182 9.78475 28.2717 9.78475 27.8415V24.0775H9.79662Z"
        fill="url(#paint4_linear_1083_50505)"
      />
      <path
        d="M25.9117 6.9663C25.9117 6.63173 25.6385 6.35689 25.2941 6.35689H22.3371C22.0046 6.35689 21.7315 6.63173 21.7315 6.9663V9.94165C21.7315 10.2762 22.0046 10.5511 22.3371 10.5511H25.2941C25.6267 10.5511 25.9117 10.2762 25.9117 9.94165V6.9663Z"
        fill="url(#paint5_linear_1083_50505)"
      />
      <path
        d="M14.8792 24.4717C15.2236 24.4717 15.4967 24.7466 15.4967 25.0931V28.0923C15.4967 28.4389 15.2236 28.7137 14.8792 28.7137H11.8984C11.554 28.7137 11.2809 28.4389 11.2809 28.0923V25.0931C11.2809 24.7466 11.554 24.4717 11.8984 24.4717H14.8792Z"
        fill="url(#paint6_linear_1083_50505)"
      />
      <path
        d="M19.9145 12.738C19.9145 12.4273 19.6651 12.1644 19.3444 12.1644H16.6012C16.2924 12.1644 16.0312 12.4154 16.0312 12.738V15.4983C16.0312 15.8089 16.2806 16.0718 16.6012 16.0718H19.3444C19.6532 16.0718 19.9145 15.8209 19.9145 15.4983V12.738Z"
        fill="url(#paint7_linear_1083_50505)"
      />
      <path
        d="M8.02681 17.8043C8.32369 17.8043 8.5612 18.0433 8.5612 18.342V20.9111C8.5612 21.1979 8.32369 21.4369 8.02681 21.4369H5.47357C5.17668 21.4369 4.93917 21.2098 4.93917 20.9111V18.342C4.93917 18.0433 5.17668 17.8043 5.47357 17.8043H8.02681Z"
        fill="url(#paint8_linear_1083_50505)"
      />
      <path
        d="M29.4032 10.2641C29.5576 10.2641 29.6764 10.3836 29.6764 10.539V11.8653C29.6764 12.0207 29.5576 12.1402 29.4032 12.1402H28.0851C27.9307 12.1402 27.8119 12.0207 27.8119 11.8653V10.539C27.8119 10.3836 27.9307 10.2641 28.0851 10.2641H29.4032Z"
        fill="url(#paint9_linear_1083_50505)"
      />
      <path
        d="M23.9399 14.351C23.9399 14.1239 23.7618 13.9447 23.5361 13.9447H21.5648C21.3392 13.9447 21.161 14.1239 21.161 14.351V16.3345C21.161 16.5616 21.3392 16.7408 21.5648 16.7408H23.5361C23.7618 16.7408 23.9399 16.5616 23.9399 16.3345V14.351Z"
        fill="url(#paint10_linear_1083_50505)"
      />
      <path
        d="M18.8815 20.0508C19.119 20.0508 19.309 20.242 19.309 20.481V22.5721C19.309 22.8111 19.119 23.0023 18.8815 23.0023H16.8151C16.5776 23.0023 16.3876 22.8111 16.3876 22.5721V20.481C16.3876 20.242 16.5776 20.0508 16.8151 20.0508H18.8815Z"
        fill="url(#paint11_linear_1083_50505)"
      />
      <path
        d="M13.858 14.4705C14.0124 14.4705 14.1312 14.59 14.1312 14.7453V16.0717C14.1312 16.227 14.0124 16.3465 13.858 16.3465H12.5398C12.3855 16.3465 12.2667 16.227 12.2667 16.0717V14.7453C12.2667 14.59 12.3855 14.4705 12.5398 14.4705H13.858Z"
        fill="url(#paint12_linear_1083_50505)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1083_50505"
          x1="4.52719"
          y1="28.771"
          x2="30.0218"
          y2="-0.340694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0660385" stopColor="#930393" />
          <stop offset="0.389966" stopColor="#0135EC" />
          <stop offset="0.962334" stopColor="#FEFEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
