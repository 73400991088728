import api from './apiClient';
import { getErrorMsg } from './ServiceHelper';
import { SolverParameterSlice } from 'Models/SolverParameter';

export const FetchSolverParameters = async () : Promise<SolverParameterSlice> => {
  const uri = 'solve-parameters';

  try {
    const response = await api.get(uri);

    return response.data as SolverParameterSlice;
  } catch (err: unknown) {
    throw getErrorMsg(err, 'Get SolverParameters');
  }
};
