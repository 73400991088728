import React, { useMemo, useEffect, useState, useRef, ElementRef } from 'react';
import { Table } from 'react-bootstrap';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Team } from 'Models/Team';
import { Venue } from 'Models/Venue';
import { VenueTeam } from 'Models/VenueTeam';
import { Solution } from 'Models/Scenario';
import useQueue from 'storeHooks/useQueue';
import useSolution from 'customHooks/useSolution';
import { useVenue as useVenueQueue } from 'storeHooks/queue';
import { QueueActionType } from 'Models/Queue';
import { SyncVenueMapPayload } from 'storeHooks/queue/useVenue';
import { useSelector } from 'react-redux';
import {
  selectTeams,
  selectVenueTeams,
  selectVenues,
} from 'store/slices/scenarioSlice';
import './style.scss';
import classNames from 'classnames';

type IsConnectedMap = Record<string, boolean>;

export const handleUpdateVenueTeam = async (
  connected: boolean,
  team: Team,
  venue: Venue,
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncTeamVenueMapToStore: (data: SyncVenueMapPayload) => void,
) => {
  if (!selectedSolution) return;

  const { solutionKey } = selectedSolution.stateData;
  const { solutionKey: localSolutionKey } = selectedSolution;

  syncTeamVenueMapToStore({
    data: {
      venueKey: venue.venueKey,
      teamKey: team.teamKey,
    },
    toBeUpdatedFields: {
      type: connected ? 'update' : 'delete',
    },
    solutionKey: selectedSolution.solutionKey,
  });

  onEnqueue({
    type: 'UPDATE_TEAM_VENUE',
    payload: {
      data: {
        venueKey: venue.venueKey,
        teamKey: team.teamKey,
      },
      toBeUpdatedFields: {
        type: connected ? 'update' : 'delete',
      },
      solutionKey: localSolutionKey, // local solution key
      actualSolutionKey: solutionKey,
    },
  });
};

function IsConnected(
  _venueTeams: VenueTeam[],
  _venue: Venue,
  _team: Team,
): boolean {
  return (
    _venueTeams.find(
      (venueTeam) =>
        venueTeam.teamIds.find((t) => t === _team.teamId) &&
        venueTeam.venueId === _venue.venueId,
    ) !== undefined
  );
}

function DrawIsConntectedCheckBox(
  checkIsConnected: IsConnectedMap,
  venue: Venue,
  team: Team,
  onEnqueue: (data: QueueActionType) => void,
  syncTeamVenueMapToStore: (data: SyncVenueMapPayload) => void,
  getCurrentOrClonedSolution: () => Solution | null,
): JSX.Element {
  const isConnected = checkIsConnected[`${venue.venueId}-${team.teamId}`];
  const [value, setValue] = useState(isConnected);
  const valueRef = useRef(isConnected);

  const handleIsConnectedChange = async (connected: boolean) => {
    if (!team || !venue) return;

    setValue(connected);

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    await handleUpdateVenueTeam(
      connected,
      team,
      venue,
      chosenSolution,
      onEnqueue,
      syncTeamVenueMapToStore,
    );
  };
  const handleCellClick = () => {
    handleIsConnectedChange(!valueRef.current);
  };

  useEffect(() => {
    valueRef.current = isConnected;
    setValue(isConnected);
  }, [isConnected]);

  return (
    <td
      key={`${team.teamId}_${venue.venueId}`}
      className={classNames('cursor-pointer checkbox', {
        checked: value,
      })}
      onClick={handleCellClick}
    >
      <div>
        {value ? (
          <CheckOutlined
            style={{
              color: '#ffffff',
              fontSize: 20,
            }}
          />
        ) : (
          <div className="">&nbsp;</div>
        )}
      </div>
    </td>
  );
}

export default function VenueTeamGrid({
  height,
}: {
  height?: number;
}): JSX.Element {
  const { t: translate } = useTranslation();
  const stickyHeader = useRef<ElementRef<'tr'>>(null);
  const allTeams = useSelector(selectTeams);
  const allVenues = useSelector(selectVenues);
  const allVenueTeams = useSelector(selectVenueTeams);
  const { syncTeamVenueMapToStore } = useVenueQueue();
  const { onEnqueue } = useQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const [isConnectedMap, setIsConnectedMap] = useState<IsConnectedMap>({});

  const teams: Team[] = useMemo(() => {
    if (allTeams) {
      return [...allTeams].sort((a, b) =>
        a.code.localeCompare(b.code, 'en', { numeric: true }),
      );
    }
    return [];
  }, [allTeams]);

  const teamsAmount = teams?.length ?? 0;

  const venues: Venue[] = useMemo(() => {
    if (allVenues) {
      return [...allVenues].sort((a, b) =>
        a.code.localeCompare(b.code, 'en', { numeric: true }),
      );
    }
    return [];
  }, [allVenues]);

  const venueTeams: VenueTeam[] = useMemo(() => {
    if (allVenueTeams) {
      return allVenueTeams;
    }
    return [];
  }, [allVenueTeams]);

  useEffect(() => {
    const updatedConnectedMap: IsConnectedMap = {};

    venues.forEach((v) => {
      teams.forEach((t) => {
        updatedConnectedMap[`${v.venueId}-${t.teamId}`] = IsConnected(
          venueTeams,
          v,
          t,
        );
      });
    });

    setIsConnectedMap(() => updatedConnectedMap);
  }, [venueTeams, teams, venues]);

  let body;
  if (teamsAmount) {
    body = (
      <tbody>
        {/* ==== BODY ROWS ==== */}
        <tr className="sticky top-[-1px]" ref={stickyHeader}>
          <td colSpan={teams.length} className="text-left">
            {translate('GENERAL.TEAM.TITLE', { count: 2 })}
          </td>
        </tr>
        <tr
          className="sticky top-[44px]"
          style={{
            borderTop: '1px solid blue',
          }}
          ref={stickyHeader}
        >
          {teams.map((team) => (
            <td
              className="team-list-item"
              key={`top-${team.teamId}_${team.name}`}
            >
              {team.code}
            </td>
          ))}
        </tr>
        {venues.map((venue) => (
          <tr key={venue.venueId}>
            {teams.map((team) =>
              DrawIsConntectedCheckBox(
                isConnectedMap,
                venue,
                team,
                onEnqueue,
                syncTeamVenueMapToStore,
                getCurrentOrClonedSolution,
              ),
            )}
          </tr>
        ))}
      </tbody>
    );
  } else {
    body = (
      <tbody>
        <tr key={1}>
          <td>{translate('GENERAL.NO_RESULT.TEAM_VENUES')}</td>
        </tr>
      </tbody>
    );
  }

  useEffect(() => {
    const tableContainer = document.querySelector('.venueteams-content');

    const resizeEelements = (cellElement: HTMLTableCellElement) => {
      const newHeight = cellElement.getBoundingClientRect().height;

      const headerCells: NodeListOf<HTMLDivElement> =
        document.querySelectorAll('.venue-list-item');
      for (let i = 0; i < headerCells.length; i++) {
        headerCells[i].style.height = `${newHeight - 0.08}px`;
      }
    };

    const handleKeypressInTable = (e: Event) => {
      if (e.type === 'resize' && tableContainer) {
        const cellElement = tableContainer.querySelector('td')!;

        resizeEelements(cellElement);
      }
    };

    window.addEventListener('resize', handleKeypressInTable);

    const firstRenderedCell: HTMLTableCellElement = document.querySelector(
      '.venueteams-content .checkbox',
    )!;

    if (firstRenderedCell) {
      resizeEelements(firstRenderedCell);
    }

    return () => {
      window.removeEventListener('resize', handleKeypressInTable);
    };
  }, []);

  return (
    <div
      className="venueteams-grid flex flex-col"
      style={{
        height: height ? height - 50 : 'auto',
      }}
    >
      <div className="venueteams-content">
        <div className="venue-teams-container">
          <div className="venue-teams-wrapper">
            <div>
              <div className="empty">&nbsp;</div>
              <div className="venue-teams-sidebar">
                <div className="header">
                  <span>{translate('GENERAL.VENUE.TITLE', { count: 2 })}</span>
                </div>
                <div className="venue-list">
                  {venues.map((venue) => (
                    <div key={venue.venueId} className="venue-list-item">
                      {venue.code}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="venue-teams-content">
              <Table bordered>{body}</Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
