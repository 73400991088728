import React, { useMemo, useState, useRef } from 'react';
import useLocale from 'locales/localeMapGrid';
import { AgGridReact } from 'ag-grid-react';
import { NameMap, SetLine } from 'Models/Scenario';
import { ALL, AWAY, HOME, BYE } from 'utils/variables';
import { decodeHTML } from 'utils/ui-helper';
import { useSelector } from 'react-redux';
import {
  selectSets,
  selectVenues,
  selectTeams,
  selectSlotTypes,
  selectNetworks,
  selectRoundTemplates,
} from 'store/slices/scenarioSlice';
import '../../style.scss';
import { Team } from 'Models/Team';
import { Venue } from 'Models/Venue';

interface Props {
  selectedSetKey: string;
}

type CellValueType = { value: string };

function SetDefinition({ selectedSetKey }: Props): JSX.Element {
  const allSets = useSelector(selectSets);
  const allTeams = useSelector(selectTeams);
  const allVenues = useSelector(selectVenues);
  const slotTypes = useSelector(selectSlotTypes);
  const networks = useSelector(selectNetworks);
  const roundTemplates = useSelector(selectRoundTemplates);
  const { setLine, all: allOptions } = useLocale();
  const {
    seqNo,
    gameType,
    teams,
    opponents,
    day,
    slotType,
    network,
    category,
    venue,
    round,
    dayOptions: { mon, tue, wed, thu, fri, sat, sun },
  } = setLine;
  const gridRef = useRef();

  const gameTypeData = {
    all: ALL,
    All: ALL,
    away: AWAY,
    Away: AWAY,
    bye: BYE,
    Bye: BYE,
    home: HOME,
    Home: HOME,
  };

  const weekDayData = {
    null: allOptions,
    1: mon,
    2: tue,
    3: wed,
    4: thu,
    5: fri,
    6: sat,
    7: sun,
  };

  const teamsMap = useMemo(() => {
    if (allTeams) {
      return allTeams.reduce((acc: NameMap, t: Team) => {
        acc[t.teamId] = decodeHTML(t.name);

        return acc;
      }, {});
    }
    return {};
  }, [allTeams]);

  const venuesMap = useMemo(() => {
    if (allVenues) {
      return allVenues.reduce((acc: NameMap, v: Venue) => {
        acc[v.venueId] = decodeHTML(v.name);

        return acc;
      }, {});
    }
    return {};
  }, [allVenues]);

  teamsMap['null'] = allOptions;
  venuesMap['null'] = allOptions;

  const slotsMap = useMemo(() => {
    if (slotTypes) {
      return slotTypes.reduce(
        (acc, item) => {
          acc[item.slotTypeId] = item.name;
          return acc;
        },
        {
          null: allOptions,
        } as NameMap,
      );
    }
    return {};
  }, [slotTypes]);

  const networksMap = useMemo(() => {
    if (networks) {
      return networks.reduce(
        (acc, item) => {
          acc[item.networkId] = item.name;
          return acc;
        },
        { null: allOptions } as NameMap,
      );
    }
    return {};
  }, [networks]);

  const networkCategoryMap = useMemo(() => {
    if (networks) {
      return networks
        .flatMap((item) => item.networkCategories)
        .reduce(
          (acc, item) => {
            acc[item.networkCategoryId] = item.name;
            return acc;
          },
          {
            null: allOptions,
          } as NameMap,
        );
    }
    return {};
  }, [networks]);

  const roundsMap = useMemo(() => {
    if (roundTemplates) {
      return roundTemplates.reduce(
        (acc, v, i) => {
          acc[(i + 1).toString()] = (i + 1).toString();
          return acc;
        },
        { null: allOptions } as NameMap,
      );
    }
    return {};
  }, [roundTemplates]);

  const set = useMemo(() => {
    if (allSets && selectedSetKey) {
      return allSets.find((x) => x.setKey === selectedSetKey);
    }
    return null;
  }, [selectedSetKey, allSets]);

  const setLines: SetLine[] = useMemo(() => {
    if (set && selectedSetKey) {
      return set.setLines.map((x) => ({ ...x, setKey: selectedSetKey }));
    }
    return [];
  }, [selectedSetKey, set]);

  const [columnDefs] = useState([
    {
      field: 'seqNo',
      headerName: seqNo,
      maxWidth: 65,
    },
    {
      field: 'gameType',
      headerName: gameType,
      valueFormatter: (params: CellValueType) =>
        // @ts-expect-error
        gameTypeData[params.value] ?? ALL,
    },
    {
      field: 'teamId',
      headerName: teams,
      valueFormatter: (params: CellValueType) => teamsMap[params.value] ?? ALL,
    },
    {
      field: 'opponentTeamId',
      headerName: opponents,
      valueFormatter: (params: CellValueType) => teamsMap[params.value] ?? ALL,
    },
    {
      field: 'weekDay',
      headerName: day,
      maxWidth: 65,
      valueFormatter: (params: CellValueType) =>
        // @ts-expect-error
        weekDayData[params.value] ?? ALL,
    },
    {
      field: 'slotTypeId',
      headerName: slotType,
      valueFormatter: (params: CellValueType) => slotsMap[params.value] ?? ALL,
    },
    {
      field: 'networkId',
      headerName: network,
      valueFormatter: (params: CellValueType) =>
        networksMap[params.value] ?? ALL,
    },
    {
      field: 'networkCategoryId',
      headerName: category,
      valueFormatter: (params: CellValueType) =>
        networkCategoryMap[params.value] ?? ALL,
    },
    {
      field: 'venueId',
      headerName: venue,
      valueFormatter: (params: CellValueType) => venuesMap[params.value] ?? ALL,
    },
    {
      field: 'roundNumber',
      headerName: round,
      maxWidth: 75,
      valueFormatter: (params: CellValueType) => roundsMap[params.value] ?? ALL,
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: false,
      flex: 1,
      cellClass: 'text-xs pt-2.5 px-1',
      headerClass: 'text-xs px-1',
    }),
    [],
  );

  return (
    <div
      className="set-table set-definition read-only"
      data-testid="setline-readonly-list"
    >
      <div className="ag-theme-alpine mt-2 ag-grid-curve h-full">
        {/* @ts-ignore */}
        <AgGridReact
          ref={gridRef}
          rowData={setLines}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={(params) => params.data.setLineKey}
          animateRows
          readOnlyEdit
          rowSelection="multiple"
          suppressRowClickSelection
          domLayout="autoHeight"
          enterNavigatesVerticallyAfterEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  );
}

export default SetDefinition;
