import React from 'react';
import classNames from 'classnames';
import './Navigation.scss';

interface NavigationItemType {
  label: React.ReactNode | string;
  onClick: () => void;
  className?: string;
}

function NavigationItem({
  label,
  onClick,
  className,
}: NavigationItemType): JSX.Element {
  return (
    <div
      className={classNames('navigation-item', className)}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      {label}
    </div>
  );
}

export default NavigationItem;
