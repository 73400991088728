export const SolveDataOptimizationStatusUnknown = 'UNKNOWN';
export const SolveDataOptimizationStatusOptimal = 'OPTIMAL';
export const SolveDataOptimizationStatusInfeasible = 'INFEASIBLE';
export const SolveDataOptimizationStatusCutoff = 'CUTOFF';
export const SolveDataOptimizationStatusStoppedByUser = 'STOPPED_BY_USER';
export const SolveDataOptimizationStatusError = 'ERROR';
export const SolveDataOptimizationStatusMaxIterationsExceeded = 'MAX_ITERATIONS_EXCEEDED';

export type SolveDataOptimizationStatus =
  typeof SolveDataOptimizationStatusUnknown |
  typeof SolveDataOptimizationStatusOptimal |
  typeof SolveDataOptimizationStatusInfeasible |
  typeof SolveDataOptimizationStatusCutoff |
  typeof SolveDataOptimizationStatusStoppedByUser |
  typeof SolveDataOptimizationStatusError |
  typeof SolveDataOptimizationStatusMaxIterationsExceeded;
