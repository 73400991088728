import React from 'react';
import { ZoomType } from 'Models/Scenario';
import { Select } from 'antd';

type OptionType = { label: string; value: string };

interface Props {
  zoom: ZoomType;
  onChange: (value: ZoomType) => void;
}

export const items: OptionType[] = [
  {
    label: '100%',
    value: '100',
  },
  {
    label: '90%',
    value: '90',
  },
  {
    label: '80%',
    value: '80',
  },
  {
    label: '70%',
    value: '70',
  },
  {
    label: '60%',
    value: '60',
  },
  {
    label: '50%',
    value: '50',
  },
];

function SelectSolveZoom({ zoom, onChange }: Props): JSX.Element {
  return (
    <Select
      value={zoom}
      style={{ width: 180 }}
      options={items}
      onChange={(value) => onChange(value)}
      data-testid="select-solve-zoom"
    />
  );
}

export default SelectSolveZoom;
