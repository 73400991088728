import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PostCreateRound,
  PutUpdateRound,
  PostUpdateSlot,
  CreateOrUpdateSlotType,
  PostUpdateRoundWeekStartDay,
} from 'Services/ScenarioService';
import {
  ScenarioSlice,
  RoundCreatePayload,
  RoundCreateResponseType,
  RoundUpdatePayload,
  RoundUpdateUnitType,
  SlotUpdatePayload,
  TimeSlot,
  CreateOrUpdateSlotTypePayload,
  RoundTemplateKeys,
  RoundTemplateMapKeys,
  RoundTemplate,
  RoundDayKeyMap,
  RoundWeekStartDayPayload,
} from 'Models/Scenario';
import {
  getScenarioByKey,
  updateRoundDay,
  updateRoundTemplate,
  updateScenriosScenario,
  updateSlotType,
  updateTimeslot,
} from './mutate_helpers';
import { getScenarioKey, getSolutionKey } from 'utils/ui-helper';

export const createRround = createAsyncThunk(
  'user/createRround',
  async (payload: {
    data: RoundCreatePayload;
    solutionStatus: string | null | undefined;
  }) => {
    const { scenarioKey, solutionKey } = payload.data;
    const res = await PostCreateRound(payload.data, payload.solutionStatus);

    const toBeSyncedKeys = payload.data.data.toBeSyncedKeys ?? [];

    return {
      data: res,
      scenarioKey,
      solutionKey,
      toBeSyncedKeys,
    };
  },
);

export const updateRound = createAsyncThunk(
  'user/updateRound',
  async (payload: {
    data: RoundUpdatePayload;
    solutionStatus: string | null | undefined;
  }) => {
    const { scenarioKey, solutionKey } = payload.data;
    const res = await PutUpdateRound(payload.data, payload.solutionStatus);

    const payloadRounds = payload.data.data as RoundTemplate[];

    const roundTemplateMapKeys: RoundTemplateMapKeys[] = [];

    payloadRounds.forEach((rt) => {
      const keyMap: RoundTemplateMapKeys = {
        roundTemplateKey: rt.roundTemplateKey,
        roundDays: [],
      };

      rt.roundDays.forEach((rd) => {
        const roundDayKeyMap: RoundDayKeyMap = {
          roundDayKey: rd.roundDayKey,
          timeSlots: [],
        };

        rd.timeSlots.forEach((ts) => {
          roundDayKeyMap.timeSlots.push(ts.timeSlotKey);
        });

        keyMap.roundDays.push(roundDayKeyMap);
      });

      roundTemplateMapKeys.push(keyMap);
    });

    return {
      data: res,
      scenarioKey,
      solutionKey,
      roundTemplateMapKeys,
    };
  },
);

export const deleteRound = createAsyncThunk(
  'user/deleteRound',
  async (payload: {
    data: RoundUpdatePayload;
    solutionStatus: string | null | undefined;
  }) => {
    const { scenarioKey, solutionKey } = payload.data;

    await PutUpdateRound(payload.data, payload.solutionStatus);

    return {
      data: payload.data.data,
      scenarioKey,
      solutionKey,
    };
  },
);

export const createOrUpdateSlotType = createAsyncThunk(
  'user/saveSlotType',
  async (payload: {
    slotTypePayload: CreateOrUpdateSlotTypePayload;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await CreateOrUpdateSlotType(
      payload.slotTypePayload,
      payload.solutionStatus,
    );

    const toBeSyncedKey = payload.slotTypePayload.slotType.toBeSyncedKey ?? '';

    return { data: res, toBeSyncedKey };
  },
);

export const updateSlot = createAsyncThunk(
  'user/updateSlot',
  async (payload: {
    data: SlotUpdatePayload;
    solutionStatus: string | null | undefined;
  }) => {
    const { scenarioKey, solutionKey } = payload.data;
    const res = await PostUpdateSlot(payload.data, payload.solutionStatus);
    const toBeSyncedKey = payload.data.data.toBeSyncedKey ?? '';

    return {
      data: res,
      scenarioKey,
      solutionKey,
      roundTemplateKey: payload.data.data.roundTemplateKey,
      roundDayKey: payload.data.data.roundDayKey,
      toBeSyncedKey,
    };
  },
);

export const updateRoundTemplateWeekStartDay = createAsyncThunk(
  'user/updateRoundWeekStartDay',
  async (payload: {
    data: RoundWeekStartDayPayload;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await PostUpdateRoundWeekStartDay(
      payload.data,
      payload.solutionStatus,
    );

    return {
      data: res,
    };
  },
);

export const createRoundReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: RoundCreateResponseType | null;
      scenarioKey: string;
      solutionKey: string;
      toBeSyncedKeys?: RoundTemplateKeys[];
    };
  },
) => {
  if (payload.data) {
    const { roundTemplates } = payload.data;
    const { roundTemplateKey } = roundTemplates[0];
    const toBeSyncedKeys = payload.toBeSyncedKeys ?? [];

    if (!roundTemplateKey) return;

    const targetScenarioKey = getScenarioKey(roundTemplateKey);
    const solutionKey = getSolutionKey(roundTemplateKey);

    if (toBeSyncedKeys.length > 0) {
      toBeSyncedKeys.forEach((item, i) => {
        updateRoundTemplate(
          targetScenarioKey,
          solutionKey,
          roundTemplates[i],
          state,
          item.parentKey,
        );

        roundTemplates[i].roundDays.forEach((rd, rdIndex) => {
          updateRoundDay(
            targetScenarioKey,
            solutionKey,
            roundTemplates[i].roundTemplateKey,
            rd,
            state,
            item.childKeys[rdIndex],
          );
        });
      });
    }
  }
};

export const updateRoundReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: RoundCreateResponseType | null;
      scenarioKey: string;
      solutionKey: string;
      toBeSyncedKeys?: [];
      roundTemplateMapKeys?: RoundTemplateMapKeys[];
    };
  },
) => {
  if (!payload.data) return;

  const { roundTemplates } = payload.data;
  const { roundTemplateKey } = roundTemplates[0];
  const roundTemplateMapKeys = payload.roundTemplateMapKeys ?? [];

  if (!roundTemplateKey) return;

  const targetScenarioKey = getScenarioKey(roundTemplateKey);
  const solutionKey = getSolutionKey(roundTemplateKey);

  if (roundTemplateMapKeys.length > 0) {
    roundTemplateMapKeys.forEach((item, i) => {
      updateRoundTemplate(
        targetScenarioKey,
        solutionKey,
        roundTemplates[i],
        state,
        item.roundTemplateKey,
      );

      item.roundDays.forEach((rd, rdi) => {
        rd.timeSlots.forEach((ts, tsi) => {
          updateTimeslot(
            targetScenarioKey,
            solutionKey,
            roundTemplates[i].roundTemplateKey,
            rd.roundDayKey,
            roundTemplates[i].roundDays[rdi].timeSlots[tsi],
            state,
            ts,
          );
        });
      });
    });
  }
};

export const deleteRoundReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: RoundUpdateUnitType[] | null;
      scenarioKey: string;
      solutionKey: string;
    };
  },
) => {
  if (payload.data) {
    const targetScenarioKey = state.scenarioKey;
    const targetSolutionKey = payload.solutionKey;
    const deletedRounds = payload.data;
    const targetScenario = getScenarioByKey(state, targetScenarioKey);

    const deletedRoundTemplatesMap: Record<string, boolean> =
      deletedRounds.reduce((acc: Record<string, boolean>, item) => {
        acc[item.roundTemplateKey] = true;
        return acc;
      }, {});

    if (targetScenario) {
      const solutionKeyIndex =
        targetScenario.optimizationEnvelop.solutions.findIndex(
          (item) => item.solutionKey === targetSolutionKey,
        );

      if (solutionKeyIndex !== -1) {
        const currentRoundTemplates =
          targetScenario.optimizationEnvelop.solutions[solutionKeyIndex]
            .stateData.roundTemplates;

        targetScenario.optimizationEnvelop.solutions[
          solutionKeyIndex
        ].stateData.roundTemplates = currentRoundTemplates.filter(
          (rt) => !deletedRoundTemplatesMap[rt.roundTemplateKey],
        );
        updateScenriosScenario(state, targetScenarioKey, targetScenario);
      }
    }
  }
};

export const createOrUpdateSlotTypeReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: CreateOrUpdateSlotTypePayload | null;
      toBeSyncedKey?: string;
    };
  },
) => {
  if (payload.data) {
    const updatedSlotType = payload.data.slotType;
    const toBeSyncedKey = payload.toBeSyncedKey ?? '';

    const targetScenarioKey = getScenarioKey(updatedSlotType.slotTypeKey);
    const targetSolutionKey = getSolutionKey(updatedSlotType.slotTypeKey);

    updateSlotType(
      targetScenarioKey,
      targetSolutionKey,
      updatedSlotType,
      state,
      toBeSyncedKey,
    );
  }
};

export const updateSlotReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: TimeSlot | null;
      scenarioKey: string;
      solutionKey: string;
      roundTemplateKey: string;
      roundDayKey: string;
      toBeSyncedKey?: string;
    };
  },
) => {
  if (payload.data) {
    const targetRoundTemplateKey = payload.roundTemplateKey;
    const targetRoundDayKey = payload.roundDayKey;
    const newTimeSlot = payload.data;

    const toBeSyncedKey = payload.toBeSyncedKey ?? '';

    const targetScenarioKey = getScenarioKey(targetRoundTemplateKey);
    const targetSolutionKey = getSolutionKey(targetRoundTemplateKey);

    updateTimeslot(
      targetScenarioKey,
      targetSolutionKey,
      targetRoundTemplateKey,
      targetRoundDayKey,
      newTimeSlot,
      state,
      toBeSyncedKey,
    );
  }
};
