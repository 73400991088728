import { v4 as uuidv4 } from 'uuid';
import { Constraint } from 'Models/Scenario';
import {
  QueueActionType,
  QueueCreateUpdateConstraintPayloadType,
} from 'Models/Queue';
import {
  CREATED,
  DELETED,
  NEW,
  SYNC_CONSTRAINT_KEY_PREFIX,
} from 'utils/variables';
import '../style.scss';

export const copyConstraint = async (
  constraints: Constraint[],
  constraintKey: string,
  onEnqueue: (data: QueueActionType) => void,
  syncConstraintToStore: (data: QueueCreateUpdateConstraintPayloadType) => void,
  localSolutionKey: string,
  solutionKey: string,
) => {
  const toBeCopiedConstraint = constraints.find(
    (constraint) => constraint.constraintKey === constraintKey,
  );

  if (toBeCopiedConstraint) {
    const toBeSyncedKey = `${SYNC_CONSTRAINT_KEY_PREFIX}${uuidv4()}`;

    const constraintPayload = {
      ...toBeCopiedConstraint,
      toBeSyncedKey,
      solutionKey: localSolutionKey,
    };

    syncConstraintToStore({
      data: constraintPayload,
      toBeUpdatedFields: {
        constraintKey: toBeSyncedKey,
        constraintStatus: CREATED,
      },
      toBeSyncedKey,
    });

    onEnqueue({
      type: 'CREATE_UPDATE_CONSTRAINT',
      payload: {
        data: {
          ...toBeCopiedConstraint,
          solutionKey,
        },
        toBeUpdatedFields: {
          constraintKey: '',
          constraintStatus: NEW,
        },
        toBeSyncedKey,
        solutionKey: localSolutionKey,
      },
    });
  }
};

export const deleteConstraint = async (
  constraints: Constraint[],
  constraintKey: string,
  onEnqueue: (data: QueueActionType) => void,
  syncConstraintToStore: (data: QueueCreateUpdateConstraintPayloadType) => void,
  localSolutionKey: string,
  solutionKey: string,
) => {
  const toBeDeleted = constraints.find(
    (constraint) => constraint.constraintKey === constraintKey,
  );

  if (toBeDeleted) {
    const toBeSyncedKey = `${SYNC_CONSTRAINT_KEY_PREFIX}${uuidv4()}`;

    syncConstraintToStore({
      data: {
        ...toBeDeleted,
        solutionKey: localSolutionKey,
      },
      toBeUpdatedFields: {
        constraintStatus: DELETED,
      },
      toBeSyncedKey,
    });
    onEnqueue({
      type: 'CREATE_UPDATE_CONSTRAINT',
      payload: {
        data: {
          ...toBeDeleted,
          solutionKey,
        },
        toBeUpdatedFields: {
          constraintStatus: DELETED,
        },
        toBeSyncedKey,
        solutionKey: localSolutionKey,
      },
    });
  }
};
