import React, { useState, useMemo, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { useSize } from 'customHooks';
import { Button, Badge } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Empty from 'Components/Elements/Common/Empty/Empty';
import { decodeHTML, randomId } from 'utils/ui-helper';
import useLocale from 'locales/localeMapGrid';
import useRoundNetwork from '../useRoundNetwork';
import {
  CREATED,
  DELETED,
  NEW,
  SYNC_SLOT_TYPE_KEY_PREFIX,
} from 'utils/variables';
import { useSelector } from 'react-redux';
import { selectSelectedSolution } from 'store/slices/scenarioSlice';
import { Solution } from 'Models/Scenario';
import showMessage from 'Components/Elements/Common/Message';
import { SlotType as TSlotType } from 'Models/Slot';
import i18n from 'plugins/i18next';
import useQueue from 'storeHooks/useQueue';
import useSolution from 'customHooks/useSolution';
import { useSlotType } from 'storeHooks/queue';
import {
  QueueActionType,
  QueueCreateUpdateSlotPayloadType,
} from 'Models/Queue';
import DeleteConfirm from 'Components/Elements/Common/DeleteConfirm';
import { selectShoudDeleteConfirmation } from 'store/slices/userSlice';

interface Props {
  className?: string;
}

interface CellParamType {
  data: TSlotType;
}

const validator: Record<string, { maxLength?: number }> = {
  name: {
    maxLength: 200,
  },
};

const validate = (field: string, value: string): string => {
  if (!validator[field]) return value;

  if (validator[field].maxLength) {
    return value.slice(0, validator[field].maxLength);
  }

  return value;
};

export const handleCreateSlotType = async (
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncSlotTypeToStore: (data: QueueCreateUpdateSlotPayloadType) => void,
  currentSlotCount?: number,
) => {
  if (!selectedSolution) return;

  const toBeSyncedKey = `${SYNC_SLOT_TYPE_KEY_PREFIX}${uuidv4()}`;

  const { solutionKey } = selectedSolution.stateData;

  const newSlotCount =
    currentSlotCount === undefined ? 1 : currentSlotCount + 1;

  const newSlotType = {
    name: `${i18n.t('GENERAL.SET_LINE.SLOT_TYPE')} ${newSlotCount}`,
    slotTypeId: toBeSyncedKey,
    slotTypeKey: toBeSyncedKey,
    code: `ST${randomId(3)}`,
    toBeSyncedKey,
    slotTypeStatus: CREATED,
  } as TSlotType;

  syncSlotTypeToStore({
    data: newSlotType,
    solutionKey: selectedSolution.solutionKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_SLOT_TYPE',
    payload: {
      data: {
        ...newSlotType,
        slotTypeStatus: NEW,
      },
      toBeSyncedKey,
      solutionKey: selectedSolution.solutionKey, // local solution key,
      actualSolutionKey: solutionKey,
    },
  });
};

export const handleDeleteSlotType = async (
  selectedSolution: Solution,
  slotTypeKey: string,
  onEnqueue: (data: QueueActionType) => void,
  syncSlotTypeToStore: (data: QueueCreateUpdateSlotPayloadType) => void,
) => {
  const toBeDeletedSlotType =
    selectedSolution.stateData.roundsDictionaries.slotTypes.find(
      (x) => x.slotTypeKey === slotTypeKey,
    );

  if (!toBeDeletedSlotType) return;

  const toBeSyncedKey = `${SYNC_SLOT_TYPE_KEY_PREFIX}${uuidv4()}`;

  syncSlotTypeToStore({
    data: {
      ...toBeDeletedSlotType,
      slotTypeStatus: DELETED,
    },
    solutionKey: selectedSolution.solutionKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_SLOT_TYPE',
    payload: {
      data: {
        ...toBeDeletedSlotType,
        slotTypeStatus: DELETED,
        toBeSyncedKey,
      },
      toBeSyncedKey,
      solutionKey: selectedSolution.solutionKey, // local solution key,
      actualSolutionKey: selectedSolution.stateData.solutionKey,
    },
  });
};

export const handleCellEdit = (
  // @ts-expect-error
  event,
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncSlotTypeToStore: (data: QueueCreateUpdateSlotPayloadType) => void,
) => {
  if (!selectedSolution) return;

  const { slotTypes } = selectedSolution.stateData.roundsDictionaries;
  const oldData = slotTypes.find(
    (item) => item.slotTypeKey === event.data.slotTypeKey,
  );

  if (!oldData) return;

  const toBeUpdatedFields: QueueCreateUpdateSlotPayloadType['toBeUpdatedFields'] =
    {};

  const { field } = event.colDef;
  const { newValue } = event;
  const newData = { ...oldData };

  const validatedValue = validate(field, newValue);

  // @ts-expect-error
  newData[field as keyof TSlotType] = validatedValue;
  // @ts-expect-error
  toBeUpdatedFields[field as keyof TSlotType] = validatedValue;

  if (newValue !== undefined) {
    const tx = {
      update: [newData],
    };
    event.api.applyTransaction(tx);

    const toBeSyncedKey = `${SYNC_SLOT_TYPE_KEY_PREFIX}${uuidv4()}`;

    syncSlotTypeToStore({
      data: {
        ...oldData,
      },
      toBeUpdatedFields,
      solutionKey: selectedSolution.solutionKey,
    });

    onEnqueue({
      type: 'CREATE_UPDATE_SLOT_TYPE',
      payload: {
        data: oldData,
        toBeSyncedKey,
        toBeUpdatedFields,
        solutionKey: selectedSolution.solutionKey, // local solution key,
        actualSolutionKey: selectedSolution.stateData.solutionKey,
      },
    });
  }
};

export default function SlotType({ className }: Props): JSX.Element {
  const { t } = useTranslation();
  const { code } = useLocale();
  const selectedSolution = useSelector(selectSelectedSolution);
  const selectedSolutionRef = useRef(selectedSolution);
  const { onEnqueue } = useQueue();
  const { syncSlotTypeToStore } = useSlotType();
  const { getCurrentOrClonedSolution, canDeleteSlotType } = useSolution();
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const size = useSize(container);
  const gridRef = useRef();
  const showDeleteConfirmation = useSelector(selectShoudDeleteConfirmation);
  const { isDeletable } = useRoundNetwork();

  const agGridStyle = useMemo(() => {
    if (size && size.height) {
      const multiplyBy = size.height < 550 ? 0.8 : 0.9;

      return {
        height: size.height * multiplyBy,
      };
    }

    return {};
  }, [size]);

  const onDelete = (slotTypeKey: string) => {
    if (!selectedSolution) return;

    if (!isDeletable(slotTypeKey, 'slot', selectedSolution)) {
      showMessage({
        key: 'GENERAL.SLOT_TYPE.DELETE',
        type: 'error',
        content: i18n.t('GENERAL.FEEDBACK.SLOT_TYPE.NOT_DELETABLE'),
      });
      return;
    }

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    handleDeleteSlotType(
      chosenSolution,
      slotTypeKey,
      onEnqueue,
      syncSlotTypeToStore,
    );
  };

  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: t('GENERAL.NAME.TITLE'),
      editable: true,
      resizable: true,
    },
    { field: 'code', headerName: code, editable: true, resizable: true },
    {
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: !showDeleteConfirmation ? 'trash-icon' : 'grid-center',
      maxWidth: 80,
      cellRenderer: (event: CellParamType) => {
        if (!showDeleteConfirmation) return null;

        return (
          <DeleteConfirm
            title={i18n.t('GENERAL.FEEDBACK.SLOT_TYPE.DELETE_CONFIRM')}
            onConfirm={() => {
              if (!canDeleteSlotType(event.data.slotTypeId)) return;

              onDelete(event.data.slotTypeKey);
            }}
          />
        );
      },
      onCellClicked: (event: CellParamType) => {
        if (showDeleteConfirmation) return;

        if (!canDeleteSlotType(event.data.slotTypeId)) return;

        onDelete(event.data.slotTypeKey);
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
    }),
    [],
  );

  const slotTypes = selectedSolution?.stateData
    ? selectedSolution.stateData.roundsDictionaries.slotTypes.map((s) => ({
        ...s,
        name: decodeHTML(s.name),
      }))
    : [];

  // @ts-expect-error
  const onCellEditRequest = (event) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    handleCellEdit(event, chosenSolution, onEnqueue, syncSlotTypeToStore);
  };

  const onSlotTypeCreate = () => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    handleCreateSlotType(
      chosenSolution,
      onEnqueue,
      syncSlotTypeToStore,
      slotTypes.length,
    );
  };

  useEffect(() => {
    if (selectedSolution) {
      selectedSolutionRef.current = selectedSolution;
    }
  }, [selectedSolution]);

  return (
    <div
      className={classNames('section-container', className)}
      data-testid="slots-type-container"
      ref={setContainer}
    >
      <div className="section-header flex-between">
        <div
          className="section-title flex items-center gap-2"
          data-testid="slot-title"
        >
          {t('GENERAL.SLOT.TYPE', {
            count: 2,
          })}

          <Badge
            count={slotTypes?.length}
            style={{ backgroundColor: '#d0d5dd', color: '#000000' }}
          />
        </div>

        <Button
          className="white-btn"
          onClick={onSlotTypeCreate}
          icon={<PlusOutlined />}
          data-testid="btn-slot-type-create"
        >
          {t('GENERAL.SLOT.CREATE_TYPE')}
        </Button>
      </div>

      {slotTypes.length === 0 ? (
        <Empty
          title={t('GENERAL.SLOT.EMPTY_TYPE')}
          subtitle={t('GENERAL.SLOT.LET_CREATE')}
          btnText={t('GENERAL.SLOT.CREATE_TYPE')}
          onBtnClick={onSlotTypeCreate}
          height={495}
          className="white-background mt-1"
        />
      ) : (
        <div
          className="ag-theme-alpine mt-2 section-ag-grid ag-grid-border ag-grid-curve"
          style={agGridStyle}
        >
          {/* @ts-expect-error */}
          <AgGridReact
            ref={gridRef}
            rowData={slotTypes}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onCellEditRequest={onCellEditRequest}
            getRowId={(params) => params.data.slotTypeKey}
            animateRows
            readOnlyEdit
            rowSelection="multiple"
            enterNavigatesVerticallyAfterEdit
            stopEditingWhenCellsLoseFocus
          />
        </div>
      )}
    </div>
  );
}
