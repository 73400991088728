const solveTableQuery = '.opponent-table';
const solveTableCellClass = 'opponent';
const solveTableCellQuery = `.${solveTableCellClass}`;
const solveTableHighlightedCellClass = 'highlighted';
const solveTableDisabledCellClass = 'opp-disabled';
const solveTableResultCellClass = 'result-item';
const solevTableHeaderCellQuery = '.team-list-item';
const cellEditTriggerClass = '.trigger-container';

export const lastPressedNumber: {
  value: number | null;
} = {
  value: null,
};
export const isEditModeEnabled: {
  value: boolean;
} = {
  value: false,
};

let handleMouseDown: EventListenerOrEventListenerObject;
let handleMouseOver: EventListenerOrEventListenerObject;
let handleSelectStart: EventListenerOrEventListenerObject;
let handleMouseUp: EventListenerOrEventListenerObject;
let tableGlobal: HTMLTableElement | null;
let allOpponentCells: HTMLTableCellElement[] = [];

export const keyPressed: Record<string, boolean> = {
  meta: false,
};

export const toBeExecutedWhileMultiSelect: Record<string, () => void> = {};
export const toBeExecutedPressEnter: Record<string, () => void> = {};

export const getCurrentFocusedElement: () => HTMLElement | null = () =>
  document.querySelector(':focus');

function getTable(): HTMLTableElement | null {
  return document.querySelector(solveTableQuery);
}

let selectedCells: HTMLTableCellElement[] = [];

const moveToLeftCellInTable = (cellElement: HTMLElement) => {
  const leftElement = cellElement.previousElementSibling;
  if (
    leftElement &&
    leftElement instanceof HTMLElement &&
    !leftElement.classList.contains(solveTableDisabledCellClass)
  ) {
    leftElement.focus();
  } else {
    const anyLeftElement = leftElement?.previousElementSibling;
    if (anyLeftElement instanceof HTMLElement) {
      anyLeftElement.focus();
    } else {
      // try to go to the previous row
      const previousRow = cellElement.parentElement?.previousElementSibling;
      if (previousRow instanceof HTMLElement) {
        // get the last td
        const allCellsCountInRow = previousRow.children.length;
        if (
          !previousRow.children[allCellsCountInRow - 2].classList.contains(
            solveTableDisabledCellClass,
          )
        ) {
          // @ts-expect-error
          previousRow.children[allCellsCountInRow - 2].focus();
        } else {
          // select the second last cell
          // @ts-expect-error
          previousRow.children[allCellsCountInRow - 3].focus();
        }
      }
    }
  }

  Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());
};

const moveToRightCellInTable = (cellElement: HTMLElement) => {
  const rightElement = cellElement.nextElementSibling;
  if (
    rightElement &&
    rightElement instanceof HTMLElement &&
    !rightElement.classList.contains(solveTableDisabledCellClass) &&
    !rightElement.classList.contains(solveTableResultCellClass)
  ) {
    rightElement.focus();
  } else {
    const anyRightElement = rightElement?.nextElementSibling;
    if (
      anyRightElement &&
      anyRightElement instanceof HTMLElement &&
      !anyRightElement.classList.contains(solveTableResultCellClass)
    ) {
      anyRightElement.focus();
    } else {
      // try to go to the next row
      const nextRow = cellElement.parentElement?.nextElementSibling;
      if (nextRow) {
        if (
          nextRow.children[0] instanceof HTMLElement &&
          !nextRow.children[0].classList.contains(solveTableDisabledCellClass)
        ) {
          nextRow.children[0].focus();
        } else if (nextRow.children[1] instanceof HTMLElement) {
          nextRow.children[1].focus();
        }
      }
    }
  }

  Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());
};

export function getAllCells(): NodeListOf<HTMLTableCellElement> {
  return getTable()!.querySelectorAll(
    solveTableCellQuery,
  ) as NodeListOf<HTMLTableCellElement>;
}

export function getSelectedCells(): HTMLTableCellElement[] {
  const allCells = getAllCells();
  const result: HTMLTableCellElement[] = [];

  for (let i = 0; i < allCells.length; i++) {
    const cell: HTMLTableCellElement = allCells[i];

    if (
      !cell.classList.contains(solveTableDisabledCellClass) &&
      cell.classList.contains('highlighted')
    ) {
      result.push(cell);
    }
  }

  return result;
}

export function clearHeaderHighlighting() {
  const allHeaders = document.querySelectorAll(solevTableHeaderCellQuery);

  for (let i = 0; i < allHeaders.length; i++) {
    const cell: Element = allHeaders[i];
    cell.classList.remove('active');
  }
}

export function clearCellsHighlighting() {
  const allCells = getAllCells();

  for (let i = 0; i < allCells.length; i++) {
    const cell: Element = allCells[i];
    cell.classList.remove(solveTableHighlightedCellClass);
  }

  clearHeaderHighlighting();
}

export function setHighlight(element: HTMLTableCellElement) {
  if (!element.classList.contains(solveTableDisabledCellClass)) {
    element.classList.add(solveTableHighlightedCellClass);
  }
}

export function selectAllCells() {
  const allCells = getAllCells();

  for (let i = 0; i < allCells.length; i++) {
    const cell: HTMLTableCellElement = allCells[i];
    setHighlight(cell);
  }
}

function triggerInputCell(target: HTMLDivElement | HTMLTableCellElement) {
  const triggerContainer = target.querySelector(cellEditTriggerClass);

  if (triggerContainer) {
    const homeTeamKey = triggerContainer.getAttribute('data-hometeamkey');
    const awayTeamKey = triggerContainer.getAttribute('data-awayteamkey');
    const compositeKey = `${homeTeamKey}-${awayTeamKey}`;

    const focusedElement =
      document.activeElement && document.activeElement.tagName;

    if (toBeExecutedPressEnter[compositeKey] && focusedElement !== 'INPUT') {
      toBeExecutedPressEnter[compositeKey]();
    }
  }
}

function handleKeyPress(event: KeyboardEvent) {
  if (event.isTrusted && (event.metaKey || event.ctrlKey)) {
    keyPressed.meta = true;
  } else {
    keyPressed.meta = false;
  }
}

function handleKeyUp(event: KeyboardEvent) {
  keyPressed.meta = false;

  const isNumber = Number.isFinite(Number(event.key));
  const allCells = getSelectedCells();

  if (allCells.length === 1 && isNumber) {
    const targetCell = allCells[0];

    lastPressedNumber.value = Number(event.key);
    Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());

    triggerInputCell(targetCell);
  }
}

const handlePressEnter = (event: KeyboardEvent) => {
  const currentTarget = event.target as HTMLDivElement | null;

  if (currentTarget) {
    triggerInputCell(currentTarget);
  }
};

function focusOnHeader(cell: HTMLDivElement) {
  if (cell) {
    const topHeaderTeamKey = cell.getAttribute('data-awayteamkey');
    const leftHeaderTeamKey = cell.getAttribute('data-hometeamkey');

    const topHeaderCell = document.querySelector(
      `[data-tkey="${topHeaderTeamKey}"]`,
    );
    const leftHeaderCell = document.querySelector(
      `[data-lkey="${leftHeaderTeamKey}"]`,
    );

    if (topHeaderCell && leftHeaderCell) {
      const cells = getSelectedCells();
      clearHeaderHighlighting();

      if (cells.length <= 1) {
        topHeaderCell.classList.add('active');
        leftHeaderCell.classList.add('active');
      }
    }
  }
}

function handleOpponentCellFocus(event: FocusEvent | KeyboardEvent) {
  const currentTarget = event.target as HTMLDivElement;

  const triggerContainer = currentTarget.querySelector(
    cellEditTriggerClass,
  ) as HTMLDivElement;

  if (triggerContainer) {
    focusOnHeader(triggerContainer);
  }

  if (currentTarget && isEditModeEnabled.value) {
    triggerInputCell(currentTarget);
  }
}

function handleOpponentKeyPress(event: KeyboardEvent) {
  switch (event.key) {
    case 'Tab':
      lastPressedNumber.value = null;
      clearCellsHighlighting();

      break;
    case 'Enter':
      lastPressedNumber.value = null;
      isEditModeEnabled.value = false;
      handlePressEnter(event);
      break;
    case 'ArrowLeft':
      {
        lastPressedNumber.value = null;

        // Left pressed
        const currentFocusedElement = getCurrentFocusedElement();
        if (currentFocusedElement && currentFocusedElement.tagName === 'TD') {
          clearCellsHighlighting();
          moveToLeftCellInTable(currentFocusedElement);
        }
      }
      break;
    case 'ArrowRight':
      {
        lastPressedNumber.value = null;
        const currentFocusedElement = getCurrentFocusedElement();
        if (currentFocusedElement && currentFocusedElement.tagName === 'TD') {
          clearCellsHighlighting();
          moveToRightCellInTable(currentFocusedElement);
        }
      }
      break;
    case 'ArrowUp':
      {
        lastPressedNumber.value = null;
        const currentFocusedElement = getCurrentFocusedElement();
        if (currentFocusedElement && currentFocusedElement.tagName === 'TD') {
          clearCellsHighlighting();
          const prevRow =
            currentFocusedElement.parentElement?.previousElementSibling;

          if (prevRow) {
            // get the element order
            let order = 0;

            const siblingElements =
              currentFocusedElement.parentElement?.children;

            for (let i = 0; i < siblingElements.length; i++) {
              if (siblingElements[i] === currentFocusedElement) {
                order = i;
                break;
              }
            }

            if (
              !prevRow.children[order].classList.contains(
                solveTableDisabledCellClass,
              )
            ) {
              // @ts-expect-error
              prevRow.children[order].focus();
            } else {
              const nextNextRow = prevRow.previousElementSibling;

              if (nextNextRow) {
                // @ts-expect-error
                nextNextRow.children[order].focus();
              }
            }
          }

          Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());
        }
      }
      break;
    case 'ArrowDown':
      {
        lastPressedNumber.value = null;
        const currentFocusedElement = getCurrentFocusedElement();
        if (currentFocusedElement && currentFocusedElement.tagName === 'TD') {
          clearCellsHighlighting();
          const nextRow =
            currentFocusedElement.parentElement?.nextElementSibling;

          if (nextRow) {
            // get the element order
            let order = 0;

            const siblingElements =
              currentFocusedElement.parentElement?.children;

            for (let i = 0; i < siblingElements.length; i++) {
              if (siblingElements[i] === currentFocusedElement) {
                order = i;
                break;
              }
            }

            if (
              !nextRow.children[order].classList.contains(
                solveTableDisabledCellClass,
              )
            ) {
              // @ts-expect-error
              nextRow.children[order].focus();
            } else {
              const nextNextRow = nextRow.nextElementSibling;

              if (nextNextRow) {
                // @ts-expect-error
                nextNextRow.children[order].focus();
              }
            }
          }
          Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());
        }
      }
      break;
    default: {
      const isNumber = Number.isFinite(Number(event.key));
      if (isNumber) {
        if (isNumber && event.target) {
          lastPressedNumber.value = Number(event.key);
          handlePressEnter(event);
        }
      } else {
        lastPressedNumber.value = null;
      }
    }
  }
}

export function initializeTableSelection() {
  let isMouseDown = false;

  const table = getTable();

  if (!table) return;

  tableGlobal = table;

  let startCell: HTMLTableCellElement | null;

  function tryGetCell(target: EventTarget | null): HTMLTableCellElement | null {
    let element: HTMLTableCellElement | null = null;

    if (
      target &&
      (target as Element)?.classList?.contains(solveTableCellClass)
    ) {
      element = target as HTMLTableCellElement;
    } else if (
      target &&
      (target as Element)?.parentElement?.classList?.contains(
        solveTableCellClass,
      )
    ) {
      element = (target as Element)?.parentElement as HTMLTableCellElement;
    } else {
      element = null;
    }

    return element;
  }

  handleMouseDown = (event: Event) => {
    if (event instanceof MouseEvent && event.button === 2) {
      return; // Do nothing for right-click
    }

    let element: HTMLTableCellElement | null = tryGetCell(event.target);

    // @ts-ignore
    if (!element && event.target?.parentElement) {
      // @ts-ignore
      element = tryGetCell(event.target.parentElement);
    }

    if (element) {
      if (!keyPressed.meta) {
        clearCellsHighlighting();
        focusOnHeader(element.querySelector(cellEditTriggerClass)!);
      }

      isMouseDown = true;
      startCell = element;
      if (!keyPressed.meta) {
        selectedCells = [];
      } else {
        Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());
        selectedCells = getSelectedCells();
      }

      selectedCells.push(startCell);
      setHighlight(startCell);
      event.preventDefault(); // prevent text selection

      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  // Helper function to get all cells within a rectangle
  function getCellsInRectangle(
    currentStartCell: HTMLTableCellElement,
    endCell: HTMLTableCellElement,
  ): HTMLTableCellElement[] {
    const retCells: HTMLTableCellElement[] = [];

    if (
      !currentStartCell ||
      !currentStartCell.parentElement ||
      !endCell ||
      !endCell.parentElement
    ) {
      return retCells;
    }

    const rows = table!.querySelectorAll('tr');
    const rowsArr = Array.from(rows);

    const startRowIndex = rowsArr.indexOf(
      currentStartCell.parentElement as HTMLTableRowElement,
    );
    const endRowIndex = rowsArr.indexOf(
      endCell.parentElement as HTMLTableRowElement,
    );

    const startColumnIndex = Array.from(
      currentStartCell.parentElement.children,
    ).indexOf(currentStartCell);
    const endColumnIndex = Array.from(endCell.parentElement.children).indexOf(
      endCell,
    );

    const rectTopRowInd = Math.min(startRowIndex, endRowIndex);
    const rectBottomRowInd = Math.max(startRowIndex, endRowIndex);

    for (let i = rectTopRowInd; i <= rectBottomRowInd; i++) {
      const curRow = rows[i];

      const rectLeftColInd = Math.min(startColumnIndex, endColumnIndex);
      const rectRightColInd = Math.max(startColumnIndex, endColumnIndex);

      for (let j = rectLeftColInd; j <= rectRightColInd; j++) {
        retCells.push(curRow.cells[j] as HTMLTableCellElement);
      }
    }

    return retCells;
  }

  handleMouseOver = (event: Event) => {
    const element: HTMLTableCellElement | null = tryGetCell(event.target);

    if (isMouseDown && startCell && element) {
      const endCell = element;
      const allCells = getAllCells();

      // Clear previous selection
      for (let i = 0; i < allCells.length; i++) {
        const cell = allCells[i];
        if (cell !== startCell && !keyPressed.meta) {
          cell.classList.remove(solveTableHighlightedCellClass);
        }
      }

      selectedCells = getCellsInRectangle(startCell, endCell);
      selectedCells.forEach((cell) => {
        setHighlight(cell);
      });

      if (selectedCells.length > 1) {
        Object.values(toBeExecutedWhileMultiSelect).forEach((fn) => fn());
      }

      if (selectedCells.length > 1) {
        clearHeaderHighlighting();
      }
    }
  };

  handleSelectStart = (event: Event) => {
    event.preventDefault();
  };

  handleMouseUp = () => {
    isMouseDown = false;
    startCell = null;
  };

  function AddEventListeners() {
    table!.addEventListener('mousedown', handleMouseDown, true);
    table!.addEventListener('mouseover', handleMouseOver, true);

    table!.addEventListener('selectstart', handleSelectStart, true);
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('keydown', handleKeyPress, true);
    document.addEventListener('keyup', handleKeyUp, true);

    // @ts-expect-error
    allOpponentCells = document.querySelectorAll(solveTableCellQuery);

    for (let i = 0; i < allOpponentCells.length; i++) {
      allOpponentCells[i].addEventListener('keydown', handleOpponentKeyPress);
      allOpponentCells[i].addEventListener('focus', handleOpponentCellFocus);
    }
  }

  AddEventListeners();
}

export function unInitializeTableSelection() {
  if (tableGlobal) {
    if (handleMouseDown) {
      tableGlobal.removeEventListener('mousedown', handleMouseDown, true);
    }
    if (handleMouseOver) {
      tableGlobal.removeEventListener('mouseover', handleMouseOver, true);
    }

    if (handleSelectStart) {
      tableGlobal.removeEventListener('selectstart', handleSelectStart, true);
    }
  }

  if (handleMouseUp) {
    document.removeEventListener('mouseup', handleMouseUp, true);
  }

  document.removeEventListener('keydown', handleKeyPress, true);
  document.removeEventListener('keyup', handleKeyUp, true);

  for (let i = 0; i < allOpponentCells.length; i++) {
    allOpponentCells[i].removeEventListener('keydown', handleOpponentKeyPress);
    allOpponentCells[i].removeEventListener('focus', handleOpponentCellFocus);
  }
}
