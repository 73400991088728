import {
  VenueTeamMapUpdatePayload,
  VenueTeamMapUpdateResponse,
} from 'Models/VenueTeam';
import {
  VenuePayload,
  VenuePromiseResponse,
  VenueDeletePayload,
  VenuesPayload,
  VenuesPromiseResponse,
} from 'Models/Venue';
import i18n from 'plugins/i18next';
import api from './apiClient';
import { getErrorMsg } from './ServiceHelper';
import { message } from 'antd';

export const CreateOrUpdateVenue = async (
  payload: VenuePayload,
  solutionStatus: string | null | undefined,
): Promise<VenuePromiseResponse> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }
  try {
    const targetUrl = `scenario/solution/${payload.solutionKey}/venue/${
      payload.venue.venueKey ?? ''
    }`;
    const response = await api[payload.venue.venueId ? 'put' : 'post'](
      targetUrl,
      {
        ...payload.venue,
      },
    );

    return response.data;
  } catch (err: any) {
    throw getErrorMsg(err, 'Create/Update Venue');
  }
};

export const CreateVenues = async (
  payload: VenuesPayload,
  solutionStatus: string | null | undefined,
): Promise<VenuesPromiseResponse> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }
  try {
    const targetUrl = `scenario/solution/${payload.solutionKey}/venues`;
    const response = await api.post(targetUrl, {
      venues: [...payload.venues],
    });

    return response.data;
  } catch (err: any) {
    throw getErrorMsg(err, 'Get Venues');
  }
};

export const DeleteVenue = async (
  payload: VenueDeletePayload,
  solutionStatus: string | null | undefined,
): Promise<object> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }
  try {
    const response = await api.delete(
      `scenario/solution/${payload.solutionKey}/venue/${payload.venueKey}`,
    );

    return response.data;
  } catch (err) {
    throw getErrorMsg(err, 'Delete DeleteVenue');
  }
};

export const TeamVenueMap = async (
  payload: VenueTeamMapUpdatePayload,
  solutionStatus: string | null | undefined,
): Promise<VenueTeamMapUpdateResponse> => {
  //TODO: Improve/Remove after proper validation added in API
  if (solutionStatus === 'Solved') {
    const errMsg = i18n.t('GENERAL.FEEDBACK.INSTANCE.LOCKED');
    message.error(errMsg);
    throw new Error(errMsg);
  }
  try {
    let response;

    if (payload.type === 'delete') {
      response = await api.delete(
        `scenario/solution/${payload.solutionKey}/venueTeam`,
        {
          data: payload.data,
        },
      );
    } else {
      response = await api.post(
        `scenario/solution/${payload.solutionKey}/venueTeam`,
        {
          ...payload.data,
        },
      );
    }

    return response.data;
  } catch (err) {
    throw getErrorMsg(err, 'Delete DeleteVenue');
  }
};
