import { useState } from 'react';
import { Form, Input, Divider, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { inviteUser } from 'Services/UserService';
import classNames from 'classnames';

const { Item } = Form;

const InviteUser = () => {
  const [form] = Form.useForm();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { t } = useTranslation();

  const resetFields = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
  };

  const handleInvite = () => {
    inviteUser(email, firstName, lastName)
      .then(() => {
        resetFields();
        message.success(t('GENERAL.FEEDBACK.USER_INVITATION.SUCCESS'));
      })
      .catch((err) => {
        message.error(
          t(err.message ?? 'GENERAL.FEEDBACK.USER_INVITATION.ERROR'),
        );
      });
  };

  const isValid = email && firstName && lastName;

  return (
    <>
      <div>
        <div className="section-title font-semibold pl-0">
          {t('GENERAL.USER.INVITE_USER')}
        </div>
        <div className="mt-1 section-subtitle">
          {t('GENERAL.USER.INVITE_TEAM_MEMBERS')}
        </div>
      </div>

      <Divider style={{ marginBottom: '16px' }} />

      <Form form={form} layout="vertical" className="px-1">
        <div className="flex items-center gap-2">
          <Item label={t('GENERAL.USER.FIRST_NAME')} className="w-full">
            <Input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </Item>
          <Item label={t('GENERAL.USER.LAST_NAME')} className="w-full">
            <Input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Item>
        </div>

        <Item label={t('GENERAL.USER.EMAIL')}>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Item>

        <Divider style={{ marginBottom: '16px' }} />

        <div className="flex justify-end">
          <button
            type="button"
            className={classNames(
              'bg-slate-100 bg px-3 py-2 text-xs font-medium rounded-lg focus:ring-4 focus:outline-none"',
              {
                '!text-white !bg-blue-500': isValid,
              },
            )}
            onClick={handleInvite}
            disabled={!isValid}
          >
            {t('GENERAL.USER.SEND_INVITATION')}
          </button>
        </div>
      </Form>
    </>
  );
};

export default InviteUser;
