export const ALL = 'All';
export const CREATED = 'Created';
export const HOME = 'Home';
export const AWAY = 'Away';
export const BYE = 'Bye';
export const GAME_TYPE = 'gameType';
export const TEAM_ID = 'teamId';
export const OPPOENENT_TEAM_ID = 'opponentTeamId';
export const WEEKDAY = 'weekDay';
export const SLOT_TYPE_ID = 'slotTypeId';
export const NETWORK_ID = 'networkId';
export const NETWORK_CATEGORY_ID = 'networkCategoryId';
export const VENUE_ID = 'venueId';
export const ROUND_NUMBER = 'roundNumber';
export const IS_ACTIVE = 'isActive';
export const OPERATOR = 'operator';
export const GAMES = 'games';
export const ROUNDS = 'rounds';
export const SET_ID = 'setId';
export const COMMENT = 'comment';
export const CONSTRAINT_KEY = 'constraintKey';
export const TEAMS = 'teams';
export const FAMILY_TYPE = 'familyType';
export const PENALTY = 'penalty';
export const START = 'start';
export const END = 'end';
export const BY_TEAM = 'ByTeam';
export const BY_SET = 'BySet';
export const MATCHING = 'Matching';
export const MIN = 'Min';
export const MAX = 'Max';
export const NEW = 'New';
export const DELETED = 'Deleted';
export const PENALTY_SCORE = 'penalty.penaltyScore';
export const CAP = 'penalty.penaltyCap.penaltyCapThreshold';
export const CAP_SCORE = 'penalty.penaltyCap.penaltyScore';
export const APP_CACHE_KEY = 'scenario_cache';
export const SYNC_CONSTRAINT_KEY_PREFIX = 'constraint---';
export const SYNC_SOLUTION_KEY_PREFIX = 'solution---';
export const SYNC_SET_KEY_PREFIX = 'set---';
export const SYNC_SETLINE_KEY_PREFIX = 'setline---';
export const SYNC_VENUE_KEY_PREFIX = 'venue---';
export const SYNC_VENUE_TEAM_KEY_PREFIX = 'venue-team---';
export const SYNC_TEAM_KEY_PREFIX = 'team---';
export const SYNC_SLOT_TYPE_KEY_PREFIX = 'slotType---';
export const SYNC_NETWORK_KEY_PREFIX = 'network---';
export const SYNC_NETWORK_CATEGORY_KEY_PREFIX = 'network-category---';
export const ROUND_TEMPLATE_KEY_PREFIX = 'round-template---';
export const ROUND_DAY_KEY_PREFIX = 'round-day---';
export const TIME_SLOT_KEY_PREFIX = 'time-slot---';
export const DATE_FORMAT_CAL = 'YYYY-MM-DD';
export const WeekDayMap = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 7,
};

export const FullCalenderStartDayMap = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 0,
};
