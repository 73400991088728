import { RcFile } from 'antd/es/upload';

export const getBase64 = (
  img: RcFile,
  successCallback: (url: string) => void,
  errorCallback: (url: string) => void,
) => {
  const reader = new FileReader();

  if (img instanceof Blob) {
    reader.addEventListener('load', () => successCallback(reader.result as string));
    reader.readAsDataURL(img);
  } else {
    errorCallback('File is of invalid type.');
  }
};
