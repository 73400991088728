import axios, { InternalAxiosRequestConfig } from 'axios';
import {
  isLoggedIn, updateToken, getToken, doLogout,
} from './UserService';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  timeout: parseInt(process.env.REACT_APP_API_TIMEOUT || '', 10),
  headers: {
    Accept: 'application/json',
  },
});

export const keycloakApiClient = axios.create({
  baseURL: process.env.REACT_APP_KC_AUTH_URL,
  timeout: parseInt(process.env.REACT_APP_API_TIMEOUT || '', 10),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const interceptor = async (config: InternalAxiosRequestConfig) => {
  if (isLoggedIn()) {
    try {
      // check token is still valid and refresh the token
      await updateToken();

      config.headers.Authorization = `Bearer ${getToken()}`;
      return await Promise.resolve(config);
    } catch (err) {
      // force logout if token has expired
      doLogout();
    }
  }
  return Promise.resolve(config);
};

apiClient.interceptors.request.use(interceptor);
keycloakApiClient.interceptors.request.use(interceptor);

export default apiClient;
