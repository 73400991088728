import { SignalRDto, SignalREventType } from 'Models/SignalRMessage';
import { SetStateAction } from 'react';
import SignalRService from 'Services/SignalRService';

export const getUseEffect =
  (
    hub: SignalRService | null,
    setConnectionStatus: (value: SetStateAction<string>) => void,
    eventIds: SignalREventType[],
    handleSignalRMsgReceived: (msg: SignalRDto) => void,
  ): (() => void) =>
  () => {
    if (!hub) {
      return () => {};
    }

    // @ts-expect-error
    const handleConnectionStatus = (error) => {
      const msgTxt = `${error.toString()}`;
      setConnectionStatus(msgTxt);
    };

    if (hub) {
      hub.onConnectionStatus(handleConnectionStatus);
      setConnectionStatus(hub.getConnectionStatus);
    }

    const eventListeners = eventIds.map((eventId) => {
      const messageHandler = (msg: SignalRDto) => handleSignalRMsgReceived(msg);

      const hubmethod = eventId;
      hub?.getConnection()?.on(hubmethod, messageHandler);

      return { eventId, messageHandler };
    });

    return () => {
      hub.onConnectionStatus(handleConnectionStatus);

      for (let i = 0; i < eventListeners.length; i++) {
        const { eventId, messageHandler } = eventListeners[i];

        const hubMethod = `${eventId}`;
        hub.getConnection()?.off(hubMethod, messageHandler);
      }
    };
  };
