import { useState, useEffect } from 'react';
import ErrorBoundary from 'Components/Common/ErrorBoundary';
import { Ping } from 'Services/ScenarioService';

export default function AppVersion() {
  const [version, setVersion] = useState(null);

  useEffect(() => {
    let ignore = false;

    const getBackendVersion = async () => {
      let version;

      try {
        version = await Ping();
      } catch (err) {
        version = `Can't connect to the server. ${err.message}`;
      }

      if (!ignore) {
        setVersion(version);
      }
    };

    if (!version) {
      getBackendVersion();
    }

    return () => {
      ignore = true;
    };
  }, [version]);

  return (
    <ErrorBoundary componentName="AppVersion">
      App ver.: [Front-end {process.env.REACT_APP_FRONTEND_APP_VERSION}],
      [Back-end {version}]
    </ErrorBoundary>
  );
}
