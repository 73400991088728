import React from 'react';
import { type DropdownIndicatorProps, components } from 'react-select';
import { SelectSort } from 'Components/Elements/Icons';

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  const onClick = () => {
  };

  return (
    <components.DropdownIndicator {...props}>
      <div className="cursor-pointer" onClick={onClick}>
        <SelectSort />
      </div>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
