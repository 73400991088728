import useScenario from 'storeHooks/useScenario';
import useNetwork from 'storeHooks/useNetwork';
import _ from 'lodash';
import { StateData, Scenario, Solution } from 'Models/Scenario';

export type DeletableType = 'network-category' | 'network' | 'slot';

export const NEW_ITEM = 'NewItem';
export const NEW_ITEM_ID = 'NewItemId';
export const NEW_ITEM_KEY = 'NewItemKey';
export const EMPTY = '';
export const DEFAULT_COLOR = '#175cd3';

export const useRoundNetwork = () => {
  const { selectedSolution, scenario, updateScenario } = useScenario();
  const { setIsSaving } = useNetwork();

  const isDeletable = (
    key: string,
    type: DeletableType,
    solution: Solution,
  ) => {
    if (type === 'network-category') {
      return !solution.stateData.roundTemplates
        .flatMap((item) => item.roundDays)
        .flatMap((item) => item.timeSlots)
        .find((item) => item.networkCategory?.networkCategoryKey === key);
    }
    if (type === 'network') {
      return !solution.stateData.roundTemplates
        .flatMap((item) => item.roundDays)
        .flatMap((item) => item.timeSlots)
        .find((item) => item.networkCategory?.network?.networkKey === key);
    }
    if (type === 'slot') {
      return !solution.stateData.roundTemplates
        .flatMap((item) => item.roundDays)
        .flatMap((item) => item.timeSlots)
        .find((item) => item.slotType?.slotTypeKey === key);
    }

    return false;
  };

  const onUpdateStateData = (data: StateData) => {
    if (selectedSolution && scenario) {
      const updatedStateData: StateData = _.cloneDeep(data);

      const scenarioCopy = _.cloneDeep(scenario) as Scenario;

      const updatingSolutionIndex =
        scenarioCopy.optimizationEnvelop.solutions.findIndex(
          (s) => s.solutionKey === selectedSolution?.solutionKey,
        );

      if (updatingSolutionIndex !== -1) {
        scenarioCopy.optimizationEnvelop.solutions[
          updatingSolutionIndex
        ].stateData = updatedStateData;

        setIsSaving(true);
        updateScenario(scenarioCopy).finally(() => {
          setIsSaving(false);
        });
      }
    }
  };

  return {
    onUpdateStateData,
    isDeletable,
  };
};

export default useRoundNetwork;
