import { v4 as uuidv4 } from 'uuid';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Button } from 'antd';
import { Plus } from 'Components/Elements/Icons';
import useLocale from 'locales/localeMapGrid';
import { AgGridReact } from 'ag-grid-react';
import { Constraint, Set, Solution } from 'Models/Scenario';
import { decodeHTML } from 'utils/ui-helper';
import i18n from 'plugins/i18next';
import showMessage from 'Components/Elements/Common/Message';
import useQueue from 'storeHooks/useQueue';
import useSet from 'storeHooks/queue/useSet';
import useSolution from 'customHooks/useSolution';
import { QueueActionType, QueueCreateUpdateSetPayloadType } from 'Models/Queue';
import DeleteConfirm from 'Components/Elements/Common/DeleteConfirm';
import {
  ALL,
  CREATED,
  NEW,
  SYNC_SET_KEY_PREFIX,
  SYNC_SETLINE_KEY_PREFIX,
} from 'utils/variables';
import { useSelector } from 'react-redux';
import { selectSets, selectConstraints } from 'store/slices/scenarioSlice';
import { selectShoudDeleteConfirmation } from 'store/slices/userSlice';
import { copySet, deleteSet } from './ActionCellRenderer';
import '../../style.scss';

interface Props {
  setSelectedSetKey: (setKey: string) => void;
}

interface CellParamType {
  data: Set;
}

export const handleCreateEmptySet = async (
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncSetToStore: (data: QueueCreateUpdateSetPayloadType) => void,
) => {
  if (!selectedSolution) return;

  const { solutionKey } = selectedSolution;

  const { sets } = selectedSolution.stateData.constraintsEnvelop;

  const toBeSyncedKey = `${SYNC_SET_KEY_PREFIX}${uuidv4()}`;
  const toBeSyncedSetId = `${SYNC_SET_KEY_PREFIX}${uuidv4()}`;
  const toBeSyncedKeyForSetline = `${SYNC_SETLINE_KEY_PREFIX}${uuidv4()}`;

  const setLinePayload = {
    setLineKey: toBeSyncedKeyForSetline,
    gameType: ALL,
    seqNo: 1,
    roundNumber: null,
    setLineStatus: CREATED,
    teamId: null,
    opponentTeamId: null,
    weekDay: null,
    slotTypeId: null,
    networkId: null,
    networkCategoryId: null,
    venueId: null,
    toBeSyncedKey: toBeSyncedKeyForSetline,
  };

  const dataPayload = {
    name: `${i18n.t('GENERAL.SET.TITLE')} ${sets.length + 1}`,
    setKey: toBeSyncedKey,
    setId: toBeSyncedSetId,
    solutionKey,
    setLines: [setLinePayload],
    setStatus: CREATED,
  };

  syncSetToStore({
    data: dataPayload as Set,
    toBeSyncedKey,
  });

  onEnqueue({
    type: 'CREATE_UPDATE_SET',
    payload: {
      data: {
        ...dataPayload,
        setLines: [
          {
            ...setLinePayload,
            setLineStatus: NEW,
          },
        ],
        setStatus: NEW,
        solutionKey: selectedSolution.stateData.solutionKey,
      } as Set,
      toBeSyncedKey,
      solutionKey: selectedSolution.solutionKey,
      toBeSyncedSetId,
    },
  });
};

function SetList({ setSelectedSetKey }: Props): JSX.Element {
  const allConstraints = useSelector(selectConstraints);
  const allSets = useSelector(selectSets);
  const { onEnqueue } = useQueue();
  const { syncSetToStore } = useSet();
  const { getCurrentOrClonedSolution } = useSolution();
  const { name, set } = useLocale();
  const { title, create } = set;
  const gridRef = useRef();
  const setsCount = useRef(0);
  const selectedRowIndex = useRef(0);
  const setsRef = useRef<Set[]>([]);
  const constraintsRef = useRef<Constraint[]>([]);
  const showDeleteConfirmation = useSelector(selectShoudDeleteConfirmation);

  const sets: Set[] = useMemo(() => {
    if (allSets) {
      return allSets.map((s) => ({
        ...s,
        name: decodeHTML(s.name),
      }));
    }
    return [];
  }, [allSets]);

  const constraints: Constraint[] = useMemo(() => {
    if (allConstraints) {
      return allConstraints;
    }
    return [];
  }, [allConstraints]);

  useEffect(() => {
    setsRef.current = sets;

    if (sets.length > 0 && setsCount.current === 0) {
      setTimeout(() => {
        setSelectedSetKey(sets[0].setKey);
        // @ts-expect-error
        document.querySelectorAll('.set-list .ag-row')[0]?.click();
      }, 100);
    } else if (sets.length > 0 && sets.length !== setsCount.current) {
      setTimeout(() => {
        setSelectedSetKey(sets[sets.length - 1].setKey);

        document.querySelectorAll('.set-list .ag-row')[
          sets.length - 1
          // @ts-expect-error
        ]?.click();
      }, 100);
    } else if (sets.length > 0 && sets.length === setsCount.current) {
      setTimeout(() => {
        if (sets[selectedRowIndex.current]) {
          setSelectedSetKey(sets[selectedRowIndex.current].setKey);

          document.querySelectorAll('.set-list .ag-row')[
            selectedRowIndex.current
            // @ts-expect-error
          ]?.click();
        }
      }, 100);
    }

    setsCount.current = sets.length;
  }, [sets]);

  useEffect(() => {
    constraintsRef.current = constraints;
  }, [constraints]);

  const onDeleteSet = (setKey: string) => {
    const currentSet = setsRef.current.find((item) => item.setKey === setKey);
    const constraint = constraintsRef.current.find(
      (item) => item.setId === currentSet?.setId,
    );

    if (constraint) {
      showMessage({
        key: 'GENERAL.SET.DELETE',
        type: 'error',
        content: i18n.t('GENERAL.SET.ERRORS.CANNOT_DELETE_USED_SET'),
      });

      return;
    }

    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;
    const { solutionKey } = chosenSolution.stateData;

    deleteSet(
      chosenSolution.stateData.constraintsEnvelop.sets,
      setKey,
      onEnqueue,
      syncSetToStore,
      chosenSolution.solutionKey,
      solutionKey,
    );
  };

  const [columnDefs] = useState([
    { field: 'name', headerName: name, editable: true },
    {
      headerName: '',
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: 'clone-icon',
      maxWidth: 40,
      onCellClicked: (event: CellParamType) => {
        const chosenSolution = getCurrentOrClonedSolution();

        if (!chosenSolution) return;
        const { solutionKey } = chosenSolution.stateData;

        copySet(
          chosenSolution.stateData.constraintsEnvelop.sets,
          event.data.setKey,
          onEnqueue,
          syncSetToStore,
          chosenSolution.solutionKey,
          solutionKey,
        );
      },
    },
    {
      headerName: '',
      headerClass: 'ag-grid-right-header-aligned',
      cellClass: !showDeleteConfirmation ? 'trash-icon' : 'grid-center',
      maxWidth: 40,
      cellRenderer: (event: CellParamType) => {
        if (!showDeleteConfirmation) return null;

        return (
          <DeleteConfirm
            title={i18n.t('GENERAL.FEEDBACK.SET.DELETE_CONFIRM')}
            onConfirm={() => onDeleteSet(event.data.setKey)}
          />
        );
      },
      onCellClicked: (event: CellParamType) => {
        if (showDeleteConfirmation) return;
        onDeleteSet(event.data.setKey);
      },
    },
  ]);

  const createEmptySet = () => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;
    handleCreateEmptySet(chosenSolution, onEnqueue, syncSetToStore);
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
    }),
    [],
  );

  // @ts-expect-error
  const onCellEditRequest = (event) => {
    const oldData = setsRef.current.find(
      (item) => item.setKey === event.data.setKey,
    )!;
    const { field } = event.colDef;
    const { newValue } = event;

    const toBeUpdatedFields: QueueCreateUpdateSetPayloadType['toBeUpdatedFields'] =
      {};

    const toBeSyncedKey = `${SYNC_SET_KEY_PREFIX}${uuidv4()}`;

    const newData = { ...oldData };
    newData[field as keyof Set] = event.newValue;
    toBeUpdatedFields[field as keyof Set] = event.newValue;

    if (newValue !== undefined) {
      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);

      const chosenSolution = getCurrentOrClonedSolution();

      if (!chosenSolution) return;

      const { solutionKey } = chosenSolution;

      syncSetToStore({
        data: {
          ...oldData,
          solutionKey,
        },
        toBeUpdatedFields,
        toBeSyncedKey,
      });

      onEnqueue({
        type: 'CREATE_UPDATE_SET',
        payload: {
          data: {
            ...oldData,
            solutionKey: chosenSolution.stateData.solutionKey,
          },
          toBeSyncedKey,
          toBeUpdatedFields,
          solutionKey: chosenSolution.solutionKey,
        },
      });
    }
  };

  // @ts-expect-error
  const onRowSelection = (event) => {
    const selectedRows = event.api.getSelectedRows();

    if (selectedRows.length === 0) return;

    selectedRowIndex.current = sets.findIndex(
      (x) => x.setKey === selectedRows[0].setKey,
    );
    setSelectedSetKey(selectedRows[0].setKey);
  };

  return (
    <div className="set-table">
      <div className="flex justify-between items-center">
        <span className="font-semibold ml-4">{title}</span>
        <Button
          className="create-btn"
          onClick={createEmptySet}
          icon={<Plus />}
          data-testid="create-set-btn"
        >
          {create}
        </Button>
      </div>
      <div
        className="ag-theme-alpine mt-2 ag-grid-curve set-list"
        style={{
          height: 680,
        }}
      >
        {/* @ts-ignore */}
        <AgGridReact
          ref={gridRef}
          rowData={sets}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellEditRequest={onCellEditRequest}
          getRowId={(params) => params.data.setKey}
          animateRows
          readOnlyEdit
          rowSelection="single"
          onSelectionChanged={onRowSelection}
          enterNavigatesVerticallyAfterEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  );
}

export default SetList;
