// @ts-nocheck
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import type { DatePickerProps } from 'antd';
import { Card, Divider, Button, DatePicker, Select, Input, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import useRoundCreate from './useRoundCreate';
import './style.scss';

dayjs.extend(utc);

interface Props {
  start?: Dayjs;
  end?: Dayjs;
  onClose: () => void;
}

const DATE_FORMAT = 'DD MMM YY';

function RoundCreatePopover({ onClose, start, end }: Props): JSX.Element {
  const { saveRounds } = useRoundCreate();
  const { t } = useTranslation();
  const [data, setData] = useState({
    start: start ?? dayjs().utc(),
    end: end ?? dayjs().utc(),
    repeat: 'week',
    after: 'after',
    endOn: dayjs().utc(),
  });
  const [count, setCount] = useState(1);
  const [error, setError] = useState('');

  const afterOptions = [
    {
      label: t('GENERAL.AFTER'),
      value: 'after',
    },
    {
      label: t('GENERAL.ON_DATE'),
      value: 'on_date',
    },
  ];
  const repeatOptions = [
    {
      label: t('GENERAL.EVERY_WEEK'),
      value: 'week',
    },
    {
      label: t('GENERAL.EVERY_DAY'),
      value: 'day',
    },
  ];

  const onResetError = () => {
    setError('');
  };

  const onStartChange: DatePickerProps['onChange'] = (date) => {
    setData((prev) => ({
      ...prev,
      start: date,
      end: date,
    }));
  };

  const onEndChange: DatePickerProps['onChange'] = (date) => {
    setData((prev) => ({
      ...prev,
      end: date,
    }));
  };

  const onEndOnChange: DatePickerProps['onChange'] = (date) => {
    setData((prev) => ({
      ...prev,
      endOn: date,
    }));
  };

  const onRepeatChange = (v: string) => {
    setData((prev) => ({
      ...prev,
      repeat: v,
    }));
  };

  const onAfterChange = (v: string) => {
    setData((prev) => ({
      ...prev,
      after: v,
    }));
  };

  const createTeamsWithVenues = () => {
    onResetError();

    const errorMessage = saveRounds(
      data.start,
      data.end,
      data.repeat,
      data.after === 'on_date' ? data.endOn : count,
    );

    if (errorMessage) {
      setError(errorMessage);
    } else {
      onClose();
    }
  };

  const onCountChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCount(Number(e.currentTarget.value));
  };

  const onSave = () => {
    createTeamsWithVenues();
  };

  useEffect(() => {
    if (start && end) {
      setData((prev) => ({
        ...prev,
        start,
        end,
      }));
    }
  }, [start, end]);

  return (
    <Card
      className="default-pop-over secondary-text ant-width"
      type="inner"
      title={t('GENERAL.ROUND.CREATE')}
      style={{ width: 400 }}
    >
      <div className="flex justify-between items-center px-4">
        <span>{t('GENERAL.STARTS')}</span>

        <DatePicker
          defaultValue={data.start.utc()}
          onChange={onStartChange}
          format={DATE_FORMAT}
          allowClear={false}
          value={data.start.utc()}
        />
      </div>
      <div className="flex justify-between items-center px-4 mt-1">
        <span>{t('GENERAL.ENDS')}</span>

        <DatePicker
          defaultValue={data.end.utc()}
          onChange={onEndChange}
          format={DATE_FORMAT}
          allowClear={false}
          value={data.end.utc()}
        />
      </div>

      <div className="flex justify-between items-center px-4 mt-1">
        <span>{t('GENERAL.REPEAT')}</span>

        <Select
          value={data.repeat}
          options={repeatOptions}
          onChange={onRepeatChange}
          className="select-w"
        />
      </div>

      <div className="flex justify-between items-center px-4 mt-1">
        <span>{t('GENERAL.END')}</span>

        <Select
          value={data.after}
          options={afterOptions}
          onChange={onAfterChange}
          className="select-w"
        />
      </div>
      <div className="flex justify-between items-center px-4 mt-1">
        <span>&nbsp;</span>

        {data.after === 'on_date' ? (
          <DatePicker
            defaultValue={data.endOn.utc()}
            onChange={onEndOnChange}
            format={DATE_FORMAT}
            allowClear={false}
          />
        ) : (
          <Input
            type="number"
            min={1}
            value={count}
            onChange={onCountChange}
            className="select-w"
            data-testid="add-round-count"
            suffix="rounds"
          />
        )}
      </div>

      {error && (
        <div className="px-4 mt-1">
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            afterClose={onResetError}
          />
        </div>
      )}

      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-end items-center gap-1 px-4">
        <Button size="small" onClick={onClose}>
          {t('GENERAL.CANCEL')}
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={onSave}
          disabled={count < 1}
          data-testid="add-round-button"
        >
          {t('GENERAL.ROUND.CREATE')}
        </Button>
      </div>
    </Card>
  );
}

export default RoundCreatePopover;
