import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

function Icon({ color, className }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path d="M13.3333 5.5V4.83333C13.3333 3.89991 13.3333 3.4332 13.1517 3.07668C12.9919 2.76308 12.7369 2.50811 12.4233 2.34832C12.0668 2.16667 11.6001 2.16667 10.6667 2.16667H9.33333C8.39991 2.16667 7.9332 2.16667 7.57668 2.34832C7.26308 2.50811 7.00811 2.76308 6.84832 3.07668C6.66667 3.4332 6.66667 3.89991 6.66667 4.83333V5.5M8.33333 10.0833V14.25M11.6667 10.0833V14.25M2.5 5.5H17.5M15.8333 5.5V14.8333C15.8333 16.2335 15.8333 16.9335 15.5608 17.4683C15.3212 17.9387 14.9387 18.3212 14.4683 18.5609C13.9335 18.8333 13.2335 18.8333 11.8333 18.8333H8.16667C6.76654 18.8333 6.06647 18.8333 5.53169 18.5609C5.06129 18.3212 4.67883 17.9387 4.43915 17.4683C4.16667 16.9335 4.16667 16.2335 4.16667 14.8333V5.5" stroke={color ?? '#98A2B3'} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default Icon;
