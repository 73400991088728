import { Scenario, StateData } from './Scenario';

export function getStateData(
  solutionKey: string,
  scenario: Scenario,
): StateData {
  const solution = scenario.optimizationEnvelop.solutions.find(
    (sol) => sol.solutionKey === solutionKey,
  );

  if (!solution) {
    throw Error(
      `A solution '${solutionKey} not found in a scenario '${scenario.scenarioKey}'`,
    );
  }

  return solution!.stateData;
}
