import moment from 'moment';

export const getWeekDayNumber = (date: Date): number =>
  moment(date).isoWeekday();

export const getDateForNextWeekDay = (
  weekDays: number[],
  startDate: Date,
  endDate: Date,
): Date | null => {
  weekDays.sort((a, b) => a - b);

  while (startDate <= endDate) {
    const weekDay = getWeekDayNumber(startDate);

    if (weekDays.includes(weekDay)) {
      return startDate;
    }

    startDate.setDate(startDate.getDate() + 1);
  }

  return null;
};

export const parseToUTCCutDate = (utcTimeStr: string): string => {
  const localTime = new Date(Date.parse(utcTimeStr));

  const utcTime = new Date(
    localTime.getFullYear(),
    localTime.getMonth(),
    localTime.getDate(),
    localTime.getHours(),
  )
    .toISOString()
    .replace(/[T]\d{2}/g, 'T00');

  return utcTime;
};

export const getShortUtcNow = (): Date => {
  const now = new Date();
  const nowNoTime = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const dateUtc = new Date(nowNoTime.getTime());
  return dateUtc;
};

export const getSeasons = (): string[] => {
  let lastFiveYear = new Date().getFullYear() - 5;
  const seasonOptions = [];

  for (let i = 0; i <= 15; i++) {
    seasonOptions.push(lastFiveYear++);
  }

  return seasonOptions.map((year) => year.toString());
};
