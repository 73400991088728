import type { PreloadedState } from '@reduxjs/toolkit';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import scenarioSlice from './slices/scenarioSlice';
import solverParameterSlice from './slices/solverParameterSlice';
import networkSlice from './slices/networkSlice';
import queueSlice from './slices/queueSlice';
import userSlice from './slices/userSlice';
import localforage from 'localforage';

const configureStorage = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: 'ops-gss',
  version: 1.0,
  storeName: 'gss-state',
});

const persistConfig = (key: string) => ({
  key: key,
  version: 1,
  storage: configureStorage,
  debug: false,
  serialize: false,
  deserialize: false,
});

const rootReducer = combineReducers({
  scenario: persistReducer(persistConfig('scenario'), scenarioSlice),
  solverParameters: solverParameterSlice,
  network: networkSlice,
  user: userSlice,
  queue: persistReducer(persistConfig('queue'), queueSlice),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
    devTools: process.env.NODE_ENV === 'development',
    preloadedState,
  });

export const store = setupStore();
export const persistor = persistStore(store);
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export default store;
