import React, { useEffect, useState } from 'react';
import { SolveTableType } from 'Models/Scenario';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

type OptionType = { label: string; value: string };

interface Props {
  onChange: (value: SolveTableType) => void;
}

function SelectSolveDataType({ onChange }: Props): JSX.Element {
  const { t } = useTranslation();
  const [type, setType] = useState<SolveTableType>('all');
  const items: OptionType[] = [
    {
      label: t('GENERAL.GAME_TYPES.ALL'),
      value: 'all',
    },
    {
      label: t('GENERAL.GAME_TYPES.HOME'),
      value: 'home',
    },
    {
      label: t('GENERAL.GAME_TYPES.AWAY'),
      value: 'away',
    },
    {
      label: t('GENERAL.GAME_TYPES.LOCKED'),
      value: 'locked',
    },
    {
      label: t('GENERAL.GAME_TYPES.UNLOCKED'),
      value: 'unlocked',
    },
  ];

  useEffect(() => {
    if (onChange) {
      onChange(type);
    }
  }, [type]);

  return (
    <Select
      value={type}
      style={{ width: 180 }}
      options={items}
      onChange={(value) => setType(value)}
      data-testid="select-solve-type"
    />
  );
}

export default SelectSolveDataType;
