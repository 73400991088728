import React from 'react';
import i18n from 'plugins/i18next';
import { QueueActionType, QueueType } from 'Models/Queue';
import { Button, Badge, Popover, List, Empty } from 'antd';
import {
  SyncOutlined,
  CheckCircleOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import useQueue from 'storeHooks/useQueue';
import classNames from 'classnames';

const { Item } = List;

const feedbackMessageKey = {
  New: 'CREATE',
  Created: 'UPDATE',
  Deleted: 'DELETE',
  Lock: 'LOCK',
  UnLock: 'UNLOCK',
};

export const getQueueItemInfo = (data: QueueActionType) => {
  const { type, payload } = data;
  const actionEntity = type.replace('CREATE_', '').replace('UPDATE_', '');

  const hasFailed = Boolean(data.payload.hasFailed);

  switch (type) {
    case 'CREATE_UPDATE_CONSTRAINT': {
      const key =
        payload.toBeUpdatedFields?.constraintStatus ??
        payload.data.constraintStatus;
      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'CREATE_UPDATE_SET': {
      const key =
        payload.toBeUpdatedFields?.setStatus ?? payload.data.setStatus;
      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'CREATE_UPDATE_SETLINE': {
      const key =
        payload.toBeUpdatedFields?.setLineStatus ?? payload.data.setLineStatus;
      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'CREATE_UPDATE_OPPONENT': {
      return {
        title: i18n.t(`GENERAL.FEEDBACK.${actionEntity}.UPDATE`),
        hasFailed,
      };
    }
    case 'UPDATE_TEAM_VENUE': {
      return {
        title: i18n.t(`GENERAL.FEEDBACK.VENUE_TEAM.UPDATE`),
        hasFailed,
      };
    }
    case 'CREATE_UPDATE_VENUE': {
      const singleEntiry = Array.isArray(payload.data)
        ? payload.data[0]
        : payload.data;

      const key =
        payload.toBeUpdatedFields?.venueStatus ?? singleEntiry.venueStatus;

      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'CREATE_UPDATE_TEAM': {
      const key =
        payload.toBeUpdatedFields?.teamStatus ?? payload.data.teamStatus;

      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'CREATE_UPDATE_SLOT_TYPE': {
      const key =
        payload.toBeUpdatedFields?.slotTypeStatus ??
        payload.data.slotTypeStatus;

      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'CREATE_UPDATE_NETWORK': {
      const key =
        payload.toBeUpdatedFields?.networkStatus ?? payload.data.networkStatus;

      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'CREATE_UPDATE_NETWORK_CATEGORY': {
      const key =
        payload.toBeUpdatedFields?.networkCategoryStatus ??
        payload.data.networkCategoryStatus;

      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'CREATE_ROUND_TEMPLATE': {
      return {
        title: i18n.t(`GENERAL.FEEDBACK.${actionEntity}.CREATE`),
        hasFailed,
      };
    }
    case 'UPDATE_ROUND_TEMPLATE': {
      const singleEntiry = Array.isArray(payload.data)
        ? payload.data[0]
        : payload.data;

      const key =
        payload.toBeUpdatedFields?.roundTemplateStatus ??
        singleEntiry.roundTemplateStatus;

      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'CREATE_UPDATE_TIME_SLOT': {
      const key = payload.data.status === 'New' ? 'New' : 'Deleted';

      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${feedbackMessageKey[key]}`,
        ),
        hasFailed,
      };
    }
    case 'UPDATE_LOCK_UNLOCK': {
      return {
        title: i18n.t(
          `GENERAL.FEEDBACK.${actionEntity}.${
            feedbackMessageKey[payload.data.lockType]
          }`,
        ),
        hasFailed,
      };
    }
    case 'UPDATE_ROUND_WEEK_START': {
      return {
        title: i18n.t('GENERAL.FEEDBACK.ROUND.UPDATE_START_WEEK_DAY'),
        hasFailed,
      };
    }
    default:
      return {
        title: i18n.t('GENERAL.FEEDBACK.UNKNOWN'),
        hasFailed,
      };
  }
};

function QueueStatusIcon({
  queue,
  failedItems,
}: {
  queue: QueueType;
  failedItems: QueueType;
}): JSX.Element {
  if (failedItems.length > 0) return <WarningTwoTone twoToneColor="#b3261e" />;
  if (queue.length > 0) return <SyncOutlined spin />;

  return <CheckCircleOutlined />;
}

export default function QueueStatus(): JSX.Element {
  const {
    queue,
    failedQueueItems,
    restoreFailedQueueItems,
    discardFailedQueueItems,
  } = useQueue();

  const isEmpty = !queue.length && !failedQueueItems.length;

  const allQueueItems = [
    ...queue,
    ...failedQueueItems.map((item) => ({
      ...item,
      payload: {
        ...item.payload,
        hasFailed: true,
      },
    })),
  ];

  return (
    <Popover
      content={
        <div>
          {isEmpty ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={i18n.t('GENERAL.QUEUE.EMPTY')}
            />
          ) : (
            <List
              itemLayout="horizontal"
              size="small"
              // @ts-expect-error
              dataSource={allQueueItems.map((item) => getQueueItemInfo(item))}
              className="max-h-80 overflow-y-auto"
              renderItem={(item, i) => (
                <Item
                  className={classNames('queue-service-item', {
                    active: i === 0 && !item.hasFailed,
                  })}
                >
                  {item.title}

                  {i === 0 && !item.hasFailed && (
                    <span className="processing" />
                  )}
                  {item.hasFailed && <WarningTwoTone twoToneColor="#b3261e" />}
                </Item>
              )}
            />
          )}
          <div className="flex justify-between items-center gap-1">
            {failedQueueItems.length > 0 && (
              <>
                <Button
                  data-testid="btn-retry"
                  className="w-full mt-2"
                  type="primary"
                  onClick={restoreFailedQueueItems}
                >
                  {i18n.t('GENERAL.QUEUE.RETRY')}
                </Button>
                <Button
                  className="w-full mt-2"
                  onClick={discardFailedQueueItems}
                >
                  {i18n.t('GENERAL.QUEUE.DISCARD')}
                </Button>
              </>
            )}
          </div>
        </div>
      }
      title={i18n.t('GENERAL.QUEUE.TITLE')}
      trigger="click"
      placement="leftBottom"
    >
      <Badge count={queue.length}>
        <Button
          size="small"
          icon={
            <QueueStatusIcon queue={queue} failedItems={failedQueueItems} />
          }
          data-testid="queue-info"
        />
      </Badge>
    </Popover>
  );
}
