import React from 'react';
import { Card } from 'antd';

interface Props {
  items: [string, string][];
  message?: string;
}

function PopoverRoundDetail({ items, message }: Props): JSX.Element {
  return (
    <Card
      className="table-cell-popover"
      type="inner"
      title=""
      style={{ minWidth: 150 }}
    >
      {items.map((item) => (
        <div key={item[0]} className="flex items-center p-0 gap-1">
          <span className="w-16">{item[0]}</span>
          <span className="mx-1">:</span>
          <span>{item[1]}</span>
        </div>
      ))}
      {message}
    </Card>
  );
}

export default PopoverRoundDetail;
