import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  TeamPayload,
  TeamPromiseResponse,
  TeamDeletePayload,
  TeamDeletePromiseResponse,
  OpponentPayload,
  OpponentPromiseResponse,
} from 'Models/Team';
import { ScenarioSlice } from 'Models/Scenario';
import {
  CreateOrUpdateTeam,
  DeleteTeam,
  CreateOrUpdateOpponents,
} from 'Services/TeamService';
import { getScenarioKey, getSolutionKey } from 'utils/ui-helper';
import {
  updateScenriosScenario,
  getScenarioByKey,
  clearTeamFromVenueTeams,
  updateOpponent,
  updateNewTeam,
  updateTeam,
} from './mutate_helpers';

export const saveTeam = createAsyncThunk(
  'user/saveTeam',
  async (payload: {
    teamPayload: TeamPayload;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await CreateOrUpdateTeam(
      payload.teamPayload,
      payload.solutionStatus,
    );

    const toBeSyncedKey = payload.teamPayload.team.toBeSyncedKey ?? '';
    const toBeSyncedVenueKey =
      payload.teamPayload.team.toBeSyncedVenueKey ?? '';

    return { data: res, toBeSyncedVenueKey, toBeSyncedKey };
  },
);

export const deleteTeam = createAsyncThunk(
  'user/deleteTeam',
  async (payload: {
    teamDeletePayload: TeamDeletePayload;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await DeleteTeam(
      payload.teamDeletePayload,
      payload.solutionStatus,
    );

    return { data: res, teamKey: payload.teamDeletePayload.teamKey };
  },
);

export const saveOpponent = createAsyncThunk(
  'user/saveOpponent',
  async (payload: {
    opponentPayload: OpponentPayload;
    solutionStatus: string | null | undefined;
  }) => {
    const res = await CreateOrUpdateOpponents(
      payload.opponentPayload,
      payload.solutionStatus,
    );

    return { data: res };
  },
);

export const saveTeamReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: TeamPromiseResponse;
      toBeSyncedVenueKey?: string;
      toBeSyncedKey?: string;
    };
  },
) => {
  if (payload.data) {
    const { opponents, team, venue, venueTeam } = payload.data;
    const { toBeSyncedVenueKey, toBeSyncedKey } = payload;

    const targetScenarioKey = getScenarioKey(team.teamKey);
    const targetSolutionKey = getSolutionKey(team.teamKey);

    if (opponents && team && venue && venueTeam) {
      updateNewTeam(
        targetScenarioKey,
        targetSolutionKey,
        team,
        venue,
        state,
        venueTeam,
        opponents,
        toBeSyncedKey,
        toBeSyncedVenueKey,
      );
    } else {
      updateTeam(
        targetScenarioKey,
        targetSolutionKey,
        team,
        state,
        toBeSyncedKey,
        opponents,
      );
    }
  }
};

export const deleteTeamReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: TeamDeletePromiseResponse;
      teamKey: string;
    };
  },
) => {
  if (payload.data) {
    const deletedTeamKey = payload.teamKey;
    const deletedTeamId = deletedTeamKey.split('-')[2];
    const targetScenarioKey = getScenarioKey(payload.teamKey);
    const targetSolutionKey = getSolutionKey(payload.teamKey);
    const targetScenario = getScenarioByKey(state, targetScenarioKey);
    const updatedOpponents = payload.data?.opponents;
    const deletedVenues = payload.data?.deletedVenues;
    const deletedVenueIds = deletedVenues.map((x) => x.venueId);

    if (targetScenario) {
      const targetSolutionIndex =
        targetScenario.optimizationEnvelop.solutions.findIndex(
          (solution) => solution.solutionKey === targetSolutionKey,
        );

      if (targetSolutionIndex !== -1) {
        const targetSolution =
          targetScenario.optimizationEnvelop.solutions[targetSolutionIndex];

        if (targetSolution && targetSolution.stateData.teams) {
          targetSolution.stateData.teams =
            targetSolution.stateData.teams.filter(
              (item) => item.teamKey !== deletedTeamKey,
            );
        }

        if (targetSolution && updatedOpponents) {
          targetSolution.stateData.opponents = updatedOpponents;
        }

        if (targetSolution && targetSolution.stateData.venues) {
          targetSolution.stateData.venues =
            targetSolution.stateData.venues.filter(
              (x) => !deletedVenueIds.includes(x.venueId),
            );
        }

        if (targetSolution && targetSolution.stateData.venueTeams) {
          targetSolution.stateData.venueTeams = clearTeamFromVenueTeams(
            targetSolution.stateData.venueTeams,
            deletedTeamId,
          );
        }
      }

      updateScenriosScenario(state, targetScenarioKey, targetScenario);
    }
  }
};

export const saveOpponentReducer = (
  state: ScenarioSlice,
  {
    payload,
  }: {
    payload: {
      data: OpponentPromiseResponse;
    };
  },
) => {
  if (payload.data) {
    const { opponents, opponentsPenalty } = payload.data;

    const targetScenarioKey = getScenarioKey(
      payload.data.opponents.opponentKey,
    );
    const targetSolutionKey = getSolutionKey(
      payload.data.opponents.opponentKey,
    );

    updateOpponent(
      targetScenarioKey,
      targetSolutionKey,
      opponents,
      opponentsPenalty,
      state,
    );
  }
};
