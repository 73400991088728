import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ScenarioSetResponsePromiseType, Set } from 'Models/Scenario';
import { QueueCreateUpdateSetPayloadType } from 'Models/Queue';
import { saveSet, syncSet } from 'store/slices/scenarioSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { CREATED, DELETED } from 'utils/variables';
import { useRef, useEffect } from 'react';
import { useAppDispatch } from '../hooks';

export const useSet = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareSetPayload = ({
    data,
    toBeUpdatedFields,
  }: QueueCreateUpdateSetPayloadType): Set => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (queueLocalKeyMapRef.current[newPayload.setKey]) {
      newPayload.setKey = queueLocalKeyMapRef.current[newPayload.setKey];
    }

    // check setline for local Key
    newPayload.setLines = newPayload.setLines.map((setLine) => {
      if (queueLocalKeyMapRef.current[setLine.setLineKey]) {
        setLine.setLineKey = queueLocalKeyMapRef.current[setLine.setLineKey];
      }

      if (toBeUpdatedFields?.setStatus === DELETED) {
        setLine.setLineStatus = DELETED;
      }

      return setLine;
    });

    if (queueLocalKeyMapRef.current[newPayload.setId]) {
      newPayload.setId = queueLocalKeyMapRef.current[newPayload.setId];
    }

    return newPayload;
  };

  const syncSetToStore = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
  }: QueueCreateUpdateSetPayloadType): void => {
    dispatch(
      syncSet({
        ...prepareSetPayload({
          data,
          toBeUpdatedFields,
          solutionKey: data.solutionKey,
        }),
        solutionKey: data.solutionKey,
        toBeSyncedKey,
      }),
    );
  };

  const createOrUpdateSetQueue = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
  }: {
    data: Set;
    toBeUpdatedFields: {
      [key in keyof Set]?: Set[key];
    };
    targetIndex?: number;
    toBeSyncedKey: string;
  }): Promise<ScenarioSetResponsePromiseType | null> => {
    const preparedData = prepareSetPayload({
      data,
      toBeUpdatedFields,
    });

    if (preparedData) {
      return dispatch(
        saveSet({
          set: {
            ...preparedData,
            toBeSyncedKey,
          },
          solutionStatus: CREATED,
        }),
      ) as Promise<ScenarioSetResponsePromiseType>;
    }

    return Promise.resolve(null);
  };

  return {
    prepareSetPayload,
    syncSetToStore,
    createOrUpdateSetQueue,
  };
};

export default useSet;
