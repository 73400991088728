import React, {
  ReactNode,
  useEffect,
  useState,
  useRef,
  DragEvent,
  useMemo,
} from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import {
  Maximize,
  Minimize,
  Close,
  ArrowRight,
  ArrowDown,
  Save,
} from 'Components/Elements/Icons';
import QueueStatus from 'Components/Elements/Queue/QueueStatus';
import './style.scss';

type ValidDirectionType = 'bottom' | 'right';

interface Props {
  direction: ValidDirectionType;
  length?: number;
  children?: ReactNode | null;
  onClose?: () => void;
  onChange?: (value: number) => void;
  onDockRight?: () => void;
  onDockBottom?: () => void;
  isSaving?: boolean;
  fullscreen?: boolean;
}

function Drawer({
  direction,
  length,
  children,
  fullscreen,
  onClose,
  onChange,
  onDockRight,
  onDockBottom,
  isSaving,
}: Props): JSX.Element {
  const [currentLength, setCurrentLength] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const lastDragPosition = useRef<number | null>(null);
  const containerLengthStyle = useMemo(() => {
    if (direction === 'bottom') {
      return { minHeight: isFullScreen ? '100%' : currentLength };
    }

    return { width: isFullScreen ? '100%' : currentLength };
  }, [isFullScreen, direction, currentLength]);

  const IsRightDrawer = direction === 'right';
  const IsBottomDrawer = direction === 'bottom';

  const initial = (e: DragEvent<HTMLDivElement>) => {
    lastDragPosition.current = e.clientY;
  };

  const resize = (e: DragEvent<HTMLDivElement>) => {
    if (lastDragPosition.current) {
      if (onChange) {
        if (IsBottomDrawer) {
          onChange(e.clientY);
        } else {
          onChange(e.clientX);
        }
      }
    }
  };

  const stopDrag = () => {
    lastDragPosition.current = 0;
  };

  useEffect(() => {
    if (length) {
      if (fullscreen) {
        setIsFullScreen(true);
      }
      setCurrentLength(length);
    } else {
      setCurrentLength(0);
      setIsFullScreen(false);
    }
  }, [length, fullscreen]);

  return (
    <div
      className={classNames({
        'dynamic-height bottom-drawer': IsBottomDrawer,
        'dynamic-width right-drawer': IsRightDrawer,
        fullscreen: isFullScreen,
        'no-animation': lastDragPosition.current,
      })}
      style={containerLengthStyle}
      data-testid="scenario-info-drawer"
    >
      {onChange && IsBottomDrawer && !isFullScreen && Number(length) > 0 && (
        <div
          className="vertical-resize"
          draggable
          onDragStart={initial}
          onDrag={resize}
          onDragEnd={stopDrag}
        />
      )}
      {currentLength ? (
        <>
          <div className="drawer-header">
            <QueueStatus />

            <div className="icon no-action">
              {isSaving ? (
                <span data-testid="spinner">
                  <Spin size="small" />
                </span>
              ) : (
                <span data-testid="save-icon">
                  <Save color="#98A2B3" />
                </span>
              )}
            </div>

            {!isFullScreen && (
              <>
                {onDockRight && IsBottomDrawer && (
                  <div className="icon" onClick={onDockRight}>
                    <ArrowRight color="#0F1316" />
                  </div>
                )}

                {onDockBottom && IsRightDrawer && (
                  <div className="icon" onClick={onDockBottom}>
                    <ArrowDown />
                  </div>
                )}

                <div
                  className="icon"
                  onClick={() => setIsFullScreen(true)}
                  data-testid="fullscreen-open"
                >
                  <Maximize />
                </div>
              </>
            )}
            {isFullScreen && (
              <div
                className="icon"
                onClick={() => setIsFullScreen(false)}
                data-testid="fullscreen-close"
              >
                <Minimize />
              </div>
            )}
            {onClose && (
              <div
                className="icon close-drawer"
                onClick={onClose}
                data-testid="close-drawer"
              >
                <Close />
              </div>
            )}
          </div>
          <div
            className={classNames({
              'drawer-content': currentLength > 0,
            })}
          >
            {children}
          </div>
        </>
      ) : null}

      {onChange && IsRightDrawer && !isFullScreen && Number(length) > 0 && (
        <div
          className="horizontal-resize"
          draggable
          onDragStart={initial}
          onDrag={resize}
          onDragEnd={stopDrag}
        />
      )}
    </div>
  );
}

export default Drawer;
