import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ScenarioNetworkCayegoryResponsePromiseType } from 'Models/Scenario';
import { QueueCreateUpdateNetworkCategoryPayloadType } from 'Models/Queue';
import {
  syncNetworkCategory,
  saveNetworkCategory,
} from 'store/slices/scenarioSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { CREATED } from 'utils/variables';
import { useRef, useEffect } from 'react';
import { NetworkCategory } from 'Models/Network';
import { getNthElement, getScenarioKey } from 'utils/ui-helper';
import { useAppDispatch } from '../hooks';

export const useNetworkCategory = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareNetworkCategoryPayload = ({
    data,
    toBeUpdatedFields,
  }: QueueCreateUpdateNetworkCategoryPayloadType): NetworkCategory => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (queueLocalKeyMapRef.current[newPayload.networkCategoryKey]) {
      newPayload.networkCategoryKey =
        queueLocalKeyMapRef.current[newPayload.networkCategoryKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.networkCategoryId]) {
      newPayload.networkCategoryId = getNthElement(
        queueLocalKeyMapRef.current[newPayload.networkCategoryId],
        5,
      );
    }

    if (
      newPayload.slotTypeId &&
      queueLocalKeyMapRef.current[newPayload.slotTypeId]
    ) {
      newPayload.slotTypeId = getNthElement(
        queueLocalKeyMapRef.current[newPayload.slotTypeId],
        4,
      );
    }

    return newPayload;
  };

  const syncNetworkCategoryToStore = ({
    data,
    toBeUpdatedFields,
    solutionKey,
    networkKey,
  }: QueueCreateUpdateNetworkCategoryPayloadType): void => {
    dispatch(
      syncNetworkCategory({
        data: prepareNetworkCategoryPayload({
          data,
          toBeUpdatedFields,
        }),
        solutionKey,
        networkKey,
      }),
    );
  };

  const createOrUpdateNetworkCategoryQueue = ({
    data,
    toBeUpdatedFields,
    networkKey,
  }: {
    data: NetworkCategory;
    toBeUpdatedFields: {
      [key in keyof NetworkCategory]?: NetworkCategory[key];
    };
    toBeSyncedKey: string;
    networkKey: string;
  }): Promise<ScenarioNetworkCayegoryResponsePromiseType | null> => {
    const preparedData = prepareNetworkCategoryPayload({
      data,
      toBeUpdatedFields,
    });

    const scenarioKey = getScenarioKey(preparedData.solutionKey ?? '');

    if (preparedData) {
      return dispatch(
        saveNetworkCategory({
          scenarioKey,
          solutionKey: preparedData.solutionKey ?? '',
          networkCategory: preparedData,
          networkKey: queueLocalKeyMapRef.current[networkKey] ?? networkKey,
          solutionStatus: CREATED,
        }),
      ) as Promise<ScenarioNetworkCayegoryResponsePromiseType>;
    }

    return Promise.resolve(null);
  };

  return {
    prepareNetworkCategoryPayload,
    syncNetworkCategoryToStore,
    createOrUpdateNetworkCategoryQueue,
  };
};

export default useNetworkCategory;
