import React, { useEffect, useRef } from 'react';
import ClassNames from 'classnames';
import { Solution } from 'Models/Scenario';
import useScenario from 'storeHooks/useScenario';
import { ExportSolveData } from 'Services/ScenarioService';
import { FileExport } from 'Models/FileExport';
import { useTranslation } from 'react-i18next';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import useNetwork from 'storeHooks/useNetwork';
import { shadeColor } from 'utils/ui-helper';
import { ThreeDots } from '../Icons';
import { useAppDispatch } from '../../../storeHooks/hooks';
import {
  cloneSolveData,
  cloneScenarioWithSolveData,
  deleteSolveData,
} from '../../../store/slices/solutionSlice';
import showMessage from '../Common/Message';
import './style.scss';
import {
  SolveDataOptimizationStatusOptimal,
  SolveDataOptimizationStatusUnknown,
} from '../../../Models/SolveDataOptimizationStatus';

interface Props {
  solution: Solution;
  onChange: (key: string) => void;
  className?: string;
  backgroundColor?: string;
  color?: string;
}

export default function SolutionItem({
  solution,
  className,
  onChange,
  backgroundColor,
  color,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { currentScenarioKey, solutionKey, solveDataKey } = useScenario();
  const { goToScenario } = useNetwork();
  const dispatch = useAppDispatch();
  const { show } = useContextMenu({
    id: 'solution-item-options',
  });
  const listRef = useRef<HTMLLIElement[]>([]);

  useEffect(() => {
    if (backgroundColor && color && listRef.current) {
      listRef.current.forEach((liElement) => {
        if (liElement.classList.contains('active')) {
          liElement.style.background = shadeColor(backgroundColor, -16);
        } else {
          liElement.style.background = 'inherit';
          liElement.style.border = '';
        }
      });
    }
  }, [backgroundColor, solution, solveDataKey]);

  const handleDeleteSolveData = async () => {
    if (solveDataKey) {
      showMessage({
        key: solveDataKey,
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_DELETING'),
      });

      const res = await dispatch(
        deleteSolveData({
          scenarioKey: currentScenarioKey,
          solutionKey,
          solveDataKey,
        }),
      );

      if (res.payload) {
        showMessage({
          key: solveDataKey,
          type: 'success',
          content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_DELETED'),
        });
      } else {
        showMessage({
          key: solveDataKey,
          type: 'error',
          content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_DELETION_FAILED'),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  const handleCloneSolveData = async () => {
    if (solveDataKey) {
      showMessage({
        key: solveDataKey,
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_COPYING'),
      });

      const res = await dispatch(
        cloneSolveData({
          scenarioKey: currentScenarioKey,
          solutionKey,
          solveDataKey,
        }),
      );

      if (res.payload) {
        showMessage({
          key: solveDataKey,
          type: 'success',
          content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_COPIED'),
        });
      } else {
        showMessage({
          key: solveDataKey,
          type: 'error',
          content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_COPY_FAILED'),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  const handleCloneScenarioWithSolveData = async () => {
    if (solveDataKey) {
      showMessage({
        key: solveDataKey,
        content: t('GENERAL.FEEDBACK.SCENARIO.CREATING'),
      });

      const res = await dispatch(
        cloneScenarioWithSolveData({
          scenarioKey: currentScenarioKey,
          solutionKey,
          solveDataKey,
        }),
      );

      if (res.payload) {
        showMessage({
          key: solveDataKey,
          type: 'success',
          content: t('GENERAL.FEEDBACK.SCENARIO.CREATED'),
        });
      } else {
        showMessage({
          key: solveDataKey,
          type: 'error',
          content: t('GENERAL.FEEDBACK.SCENARIO.CREATION_FAILED'),
        });
      }

      goToScenario(currentScenarioKey);
    }
  };

  const handleExportResult = async () => {
    try {
      showMessage({
        key: solveDataKey,
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_EXPORTING'),
      });

      const response: FileExport = await ExportSolveData(
        currentScenarioKey,
        solutionKey,
        solveDataKey,
      );

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute(
        'href',
        `data:${response.mimeType};charset=utf-8,${response.content}`,
      );
      downloadLink.setAttribute('download', response.fileName);
      downloadLink.click();

      showMessage({
        key: solveDataKey,
        type: 'success',
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_EXPORTED'),
      });
    } catch (err) {
      showMessage({
        key: solveDataKey,
        type: 'error',
        content: t('GENERAL.FEEDBACK.SOLUTION.RESULT_EXPORT_FAILED'),
      });
    }
  };

  function displayMenu(e: React.MouseEvent) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({ event: e, props: { solveDataKey } });
  }

  return (
    <ul className={className} data-testid="solution-items">
      {solution.solveDataList.map((item, i) => (
        <li
          key={item.solveDataKey}
          ref={(el) => {
            if (el) {
              listRef.current[i] = el;
            }
          }}
          className={ClassNames('solution-item', {
            active: item.solveDataKey === solveDataKey,
          })}
          title={`Created at: ${item.timestamp?.toString()}, Status: ${
            item.solveDataOptimizationStatus
          }`}
          onClick={() => onChange(item.solveDataKey)}
        >
          <div className="flex justify-between">
            <span>
              {item.solveDataOptimizationStatus ===
                SolveDataOptimizationStatusOptimal ||
              item.solveDataOptimizationStatus ===
                SolveDataOptimizationStatusUnknown
                ? item.totalPenalty ?? 'No Value '
                : item.solveDataOptimizationStatus}
            </span>

            <span>
              {item.solveDataKey === solution.solveDataList[0].solveDataKey
                ? '[Base]'
                : ''}
            </span>
            <span
              className="flex gap-2"
              onClick={(e) => displayMenu(e)}
              data-testid="menu-click-icon"
            >
              <ThreeDots className="w-4" color={color ?? '#0F1316'} />
              <Menu id="solution-item-options">
                <Item
                  onClick={() => handleCloneSolveData()}
                  data-testid="copy-button"
                >
                  {t('GENERAL.COPY')}
                </Item>
                <Item
                  onClick={() => handleDeleteSolveData()}
                  data-testid="delete-button"
                >
                  {t('GENERAL.DELETE')}
                </Item>
                <Separator />
                <Item
                  onClick={() => handleCloneScenarioWithSolveData()}
                  data-testid="create-scenario-button"
                >
                  {t('GENERAL.SOLUTION.CREATE_SCENARIO_FROM_RESULT')}
                </Item>
                <Item
                  onClick={() => handleExportResult()}
                  data-testid="export-csv-button"
                >
                  {t('GENERAL.SOLUTION.EXPORT_RESULT_INTO_CSV')}
                </Item>
              </Menu>
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
}
