import _ from 'lodash';
import { CellOpponentIndexMap, Opponents } from 'Models/Opponent';
import { useScenario } from './useScenario';
import { SelectionType } from 'Components/Elements/Opponents/OpponentMatchTable';
import useSolution from 'customHooks/useSolution';
import i18n from 'plugins/i18next';
import { useOpponent } from './queue';
import useQueue from './useQueue';
import { message } from 'antd';

export const useTeam = () => {
  const { scenario } = useScenario();
  const { syncOpponentToStore } = useOpponent();
  const { onEnqueue } = useQueue();
  const { getCurrentOrClonedSolution } = useSolution();

  const updateOpponent = (payload: Opponents) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    syncOpponentToStore({
      data: {
        ...payload,
      },
      solutionKey: chosenSolution.solutionKey,
    });

    onEnqueue({
      type: 'CREATE_UPDATE_OPPONENT',
      payload: {
        data: {
          ...payload,
        },
        solutionKey: chosenSolution.solutionKey,
      },
    });
  };

  const formatOpponent = (
    selectedCellIndexMap: CellOpponentIndexMap,
    value: number,
    selectionType: SelectionType,
    shouldTranspose: boolean,
  ) => {
    const invalidCellIndexMap: CellOpponentIndexMap = {};
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    let { stateData } = chosenSolution;

    if (
      stateData &&
      Array.isArray(stateData.opponents.minItemsList) &&
      Array.isArray(stateData.opponents.maxItemsList)
    ) {
      if (selectionType !== 'min' && selectionType !== 'max') return;

      const itemsListInState =
        selectionType === 'min'
          ? stateData.opponents.minItemsList
          : stateData.opponents.maxItemsList;

      let newItemList: number[][] = JSON.parse(
        JSON.stringify(itemsListInState),
      );

      if (shouldTranspose) {
        // @ts-ignore
        newItemList = _.zip(...newItemList);
      }

      newItemList.forEach((row, rowIndex) => {
        row.forEach((col, colIndex) => {
          if (selectedCellIndexMap[`${rowIndex}-${colIndex}`]) {
            if (selectionType === 'min') {
              if (
                value > stateData.opponents.maxItemsList[rowIndex][colIndex]
              ) {
                invalidCellIndexMap[`${rowIndex}-${colIndex}`] = true;
              }
            } else if (
              value < stateData.opponents.minItemsList[rowIndex][colIndex]
            ) {
              invalidCellIndexMap[`${rowIndex}-${colIndex}`] = true;
            }

            newItemList[rowIndex][colIndex] = value;
          }
        });
      });

      if (shouldTranspose) {
        // @ts-ignore
        newItemList = _.zip(...newItemList);
      }

      if (selectionType === 'min') {
        stateData = {
          ...stateData,
          opponents: {
            ...stateData.opponents,
            minItemsList: newItemList,
          },
        };
      } else if (selectionType === 'max') {
        stateData = {
          ...stateData,
          opponents: {
            ...stateData.opponents,
            maxItemsList: newItemList,
          },
        };
      }

      syncOpponentToStore({
        data: {
          ...stateData.opponents,
          penalty: stateData.opponentsPenalty,
        },
        solutionKey: chosenSolution.solutionKey,
      });

      onEnqueue({
        type: 'CREATE_UPDATE_OPPONENT',
        payload: {
          data: {
            ...stateData.opponents,
            penalty: stateData.opponentsPenalty,
          },
          solutionKey: chosenSolution.solutionKey,
        },
      });
    }

    if (Object.keys(invalidCellIndexMap).length > 0) {
      message.warning(
        i18n.t(
          selectionType === 'min'
            ? 'GENERAL.FEEDBACK.OPPONENT.MAX_VALIDATION_ERROR'
            : 'GENERAL.FEEDBACK.OPPONENT.MIN_VALIDATION_ERROR',
        ),
        5, // 5 seconds
      );
    }
  };

  const formatOpponentToOppositeSelectionType = (
    selectedCellIndexMap: Record<string, boolean>,
    selectionType: SelectionType,
    shouldTranspose: boolean,
  ) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    let { stateData } = chosenSolution;

    if (
      stateData &&
      Array.isArray(stateData.opponents.minItemsList) &&
      Array.isArray(stateData.opponents.maxItemsList)
    ) {
      if (selectionType === 'min') {
        const itemsListInState = stateData.opponents.maxItemsList;
        let minListInState: number[][] = stateData.opponents.minItemsList;

        let newItemList: number[][] = JSON.parse(
          JSON.stringify(itemsListInState),
        );

        if (shouldTranspose) {
          // @ts-ignore
          newItemList = _.zip(...newItemList);
          // @ts-ignore
          minListInState = _.zip(...minListInState);
        }

        newItemList.forEach((row, rowIndex) => {
          row.forEach((col, colIndex) => {
            if (selectedCellIndexMap[`${rowIndex}-${colIndex}`]) {
              newItemList[rowIndex][colIndex] =
                minListInState[rowIndex][colIndex];
            }
          });
        });

        if (shouldTranspose) {
          // @ts-ignore
          newItemList = _.zip(...newItemList);
        }

        stateData = {
          ...stateData,
          opponents: {
            ...stateData.opponents,
            maxItemsList: newItemList,
          },
        };

        syncOpponentToStore({
          data: {
            ...stateData.opponents,
            penalty: stateData.opponentsPenalty,
          },
          solutionKey: chosenSolution.solutionKey,
        });

        onEnqueue({
          type: 'CREATE_UPDATE_OPPONENT',
          payload: {
            data: {
              ...stateData.opponents,
              penalty: stateData.opponentsPenalty,
            },
            solutionKey: chosenSolution.solutionKey,
          },
        });
      } else if (selectionType === 'max') {
        const itemsListInState = stateData.opponents.minItemsList;
        let maxListInState: number[][] = stateData.opponents.maxItemsList;

        let newItemList: number[][] = JSON.parse(
          JSON.stringify(itemsListInState),
        );

        if (shouldTranspose) {
          // @ts-ignore
          newItemList = _.zip(...newItemList);
          // @ts-ignore
          maxListInState = _.zip(...maxListInState);
        }

        newItemList.forEach((row, rowIndex) => {
          row.forEach((col, colIndex) => {
            if (selectedCellIndexMap[`${rowIndex}-${colIndex}`]) {
              newItemList[rowIndex][colIndex] =
                maxListInState[rowIndex][colIndex];
            }
          });
        });

        if (shouldTranspose) {
          // @ts-ignore
          newItemList = _.zip(...newItemList);
        }

        stateData = {
          ...stateData,
          opponents: {
            ...stateData.opponents,
            minItemsList: newItemList,
          },
        };

        syncOpponentToStore({
          data: {
            ...stateData.opponents,
            penalty: stateData.opponentsPenalty,
          },
          solutionKey: chosenSolution.solutionKey,
        });

        onEnqueue({
          type: 'CREATE_UPDATE_OPPONENT',
          payload: {
            data: {
              ...stateData.opponents,
              penalty: stateData.opponentsPenalty,
            },
            solutionKey: chosenSolution.solutionKey,
          },
        });
      }
    }
  };

  return {
    scenario,
    updateOpponent,
    formatOpponent,
    formatOpponentToOppositeSelectionType,
  };
};

export default useTeam;
