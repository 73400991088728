import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from 'Components/Pages/NotFound';
import App from 'Components/App/App';
import Scenarios from 'Components/Pages/Scenario/Scenarios';
import Scenario from 'Components/Pages/Scenario/Scenario';
import UserDashboard from 'Components/Pages/Admin/UserDashboard';
import EnsureAuthenticated from 'Components/Guards/EnsureAuthenticated';

const router = createBrowserRouter([
  {
    path: '/settings',
    element: (
      <EnsureAuthenticated>
        <UserDashboard />
      </EnsureAuthenticated>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/old',
    element: (
      <EnsureAuthenticated>
        <App />
      </EnsureAuthenticated>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: 'scenario/:season/:scenarioKey',
    element: (
      <EnsureAuthenticated>
        <Scenario />
      </EnsureAuthenticated>
    ),
  },
  {
    path: '/',
    element: (
      <EnsureAuthenticated>
        <Scenarios />
      </EnsureAuthenticated>
    ),
  },
]);

export default router;
