import React, { useState } from 'react';
import SetLineList from './SetLine/SetLineList';
import SetList from './Set/SetList';
import '../style.scss';

function SetGroup(): JSX.Element {
  const [selectedSetKey, setSelectedSetKey] = useState('');

  return (
    <div className="flex sets-section">
      <div className="flex-none w-1/4 mr-2" data-testid="set-section">
        <SetList setSelectedSetKey={setSelectedSetKey} />
      </div>
      <div className="flex-grow" data-testid="setline-section">
        <SetLineList selectedSetKey={selectedSetKey} />
      </div>
    </div>
  );
}

export default SetGroup;
