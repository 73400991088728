import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'plugins/i18next';

export const getErrorMsg = (err: any, methodName: string): Error => {
  let errMsg = `'${methodName}': `;

  if (isAxiosError(err)) {
    if (i18n.t(err?.response?.data)) {
      errMsg = i18n.t(err?.response?.data);
    } else if (err.response && typeof err?.response?.data === 'string') {
      errMsg += err?.response?.data;
    } else if (err.request) {
      errMsg = 'No connection to the server.';
    } else {
      errMsg += `Error:${err.message}`;
    }
  } else {
    // Handle other types of errors
    errMsg += `Unknown Error:${err.message}`;
  }

  // TODO: https://dev.azure.com/OptimalPlanning/OPS/_workitems/edit/406
  message.error(errMsg);
  return new Error(errMsg);
};
