import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  SignalRDto,
  SignalREventType,
  SignalREventSolutionStopped,
  SignalREventSolutionBase,
  SignalREventSolutionError,
  SignalREventSolutionSaved,
  SignalREventSolutionSolveRequested,
  SignalREventSolutionSolving,
} from 'Models/SignalRMessage';
import { plainToClass } from 'class-transformer';
import useScenario from 'storeHooks/useScenario';
import SignalRService from 'Services/SignalRService';
import { getUseEffect } from 'utils/hub-helper';
import {
  selectCurrentScenarioKey,
  selectScenario,
  selectSelectedSolution,
} from 'store/slices/scenarioSlice';
import { useSelector } from 'react-redux';
import showMessage from 'Components/Elements/Common/Message';
import i18n from 'plugins/i18next';

interface SignalRMessagesProps {
  statusHub: SignalRService | null;
  eventIds: SignalREventType[];
  setIsLogButtonVisible: Dispatch<SetStateAction<boolean>>;
  connectLogHubHandler: (
    solutionKey?: string,
  ) => Promise<SignalRService | null>;
}

export default function useStatusHubMessages({
  statusHub,
  eventIds,
  setIsLogButtonVisible,
  connectLogHubHandler,
}: SignalRMessagesProps) {
  const scenario = useSelector(selectScenario);
  const selectedSolution = useSelector(selectSelectedSolution);
  const currentScenarioKey = useSelector(selectCurrentScenarioKey);
  const { fetchSolution } = useScenario();
  const [connectionStatus, setConnectionStatus] = useState<string>('');
  const [isSolverRunning, setIsSolverRunning] = useState<boolean>(false);
  const [hasSolveStarted, setHasSolveStarted] = useState<boolean>(false);
  const [solvingSolutionIndex, setSolvingSolutionIndex] = useState<number>();

  const selectedSolutionRef = useRef(selectedSolution);

  const notifyUserOnStatusChange = (message: SignalREventSolutionBase) => {
    let key: string | null = null;

    if (message instanceof SignalREventSolutionSolveRequested) {
      key = 'GENERAL.FEEDBACK.SOLVE.REQUESTED';
    } else if (message instanceof SignalREventSolutionSolving) {
      key = 'GENERAL.FEEDBACK.SOLVE.SOLVER_STARTED_SOLVING';
    } else if (message instanceof SignalREventSolutionSaved) {
      // Let's skip that message from a user as it is annoing for many found solutions.
      // key = 'GENERAL.FEEDBACK.SOLVE.SAVED';
    } else if (message instanceof SignalREventSolutionError) {
      key = 'GENERAL.FEEDBACK.SOLVE.ERROR';
    }

    if (key) {
      showMessage({
        key: 'statusChange',
        type: 'success',
        content: i18n.t(key),
      });
    }
  };

  function processSolutionEvents(realMessage: SignalRDto) {
    if (
      !(realMessage.signalRMsgBase instanceof SignalREventSolutionBase) ||
      !scenario ||
      !selectedSolutionRef.current
    ) {
      return;
    }

    if (
      realMessage.signalRMsgBase instanceof SignalREventSolutionSaved ||
      realMessage.signalRMsgBase instanceof SignalREventSolutionStopped ||
      realMessage.signalRMsgBase instanceof SignalREventSolutionError
    ) {
      setIsSolverRunning(() => false);
    } else if (!isSolverRunning) {
      setIsSolverRunning(() => true);
    }

    // eslint-disable-next-line no-console
    console.debug(realMessage.signalRMsgBase.Display());

    setIsLogButtonVisible(() => true);

    if (
      realMessage.signalRMsgBase.solutionKey ===
      selectedSolutionRef.current.solutionKey
    ) {
      setSolvingSolutionIndex(selectedSolutionRef.current.solutionIndex);
      connectLogHubHandler(selectedSolutionRef.current.solutionKey).catch(
        () => {},
      );
    } else {
      setSolvingSolutionIndex(
        scenario.optimizationEnvelop.solutions.find(
          (x) => x.solutionKey === realMessage.signalRMsgBase.solutionKey,
        )?.solutionIndex,
      );
    }

    notifyUserOnStatusChange(realMessage.signalRMsgBase);

    if (
      realMessage.signalRMsgBase instanceof
        SignalREventSolutionSolveRequested ||
      realMessage.signalRMsgBase instanceof SignalREventSolutionSolving ||
      realMessage.signalRMsgBase instanceof SignalREventSolutionSaved ||
      realMessage.signalRMsgBase instanceof SignalREventSolutionStopped ||
      realMessage.signalRMsgBase instanceof SignalREventSolutionError
    ) {
      fetchSolution(
        currentScenarioKey,
        selectedSolutionRef.current.solutionKey,
      );
    }
  }

  const handleSignalRMsgReceived = (msg: SignalRDto) => {
    const realMessage = plainToClass(SignalRDto, msg);

    processSolutionEvents(realMessage);
  };

  useEffect(
    getUseEffect(
      statusHub,
      setConnectionStatus,
      eventIds,
      handleSignalRMsgReceived,
    ),
    [statusHub, currentScenarioKey],
  );

  useEffect(() => {
    if (isSolverRunning) {
      setHasSolveStarted(true);

      if (solvingSolutionIndex) {
        showMessage({
          key: 'solve',
          content: i18n.t('GENERAL.FEEDBACK.SOLVE.SOLVING', {
            index: solvingSolutionIndex,
          }),
        });
      }
    } else if (hasSolveStarted) {
      setHasSolveStarted(false);

      showMessage({
        key: 'solve',
        type: 'success',
        content: i18n.t('GENERAL.FEEDBACK.SOLVE.SUCCESS'),
      });
    }
  }, [isSolverRunning, hasSolveStarted, solvingSolutionIndex]);

  useEffect(() => {
    selectedSolutionRef.current = selectedSolution;
  }, [selectedSolution]);

  return {
    connectionStatus,
    isSolverRunning,
    solvingSolutionIndex,
  };
}
