import { useTranslation } from 'react-i18next';

export const useLocale = () => {
  const { t } = useTranslation();

  return {
    all: t('GENERAL.ALL'),
    active: t('GENERAL.ACTIVE'),
    cancel: t('GENERAL.CANCEL'),
    code: t('GENERAL.CODE'),
    delete: t('GENERAL.DELETE'),
    venue: t('GENERAL.VENUE.NAME'),
    location: t('GENERAL.LOCATION'),
    city: t('GENERAL.CITY'),
    comment: t('GENERAL.COMMENT.TITLE'),
    name: t('GENERAL.NAME.TITLE'),
    type: t('GENERAL.TYPE'),
    homeVenue: t('GENERAL.VENUE.HOME'),
    homeOf: t('GENERAL.VENUE.HOME_OF'),
    action: t('GENERAL.ACTION'),
    team: {
      code: t('GENERAL.TEAM.CODE'),
      create: t('GENERAL.TEAM.CREATE'),
      name: t('GENERAL.TEAM.NAME'),
      title: t('GENERAL.TEAM.TITLE', { count: 2 }),
    },
    opponent: {
      title: t('GENERAL.OPPONENT.TITLE', { count: 2 }),
    },
    constraint: {
      create: t('GENERAL.CONSTRAINT.CREATE'),
      title: t('GENERAL.CONSTRAINT.TITLE', { count: 2 }),
      type: t('GENERAL.CONSTRAINT.TYPE'),
      operator: t('GENERAL.CONSTRAINT.OPERATOR'),
      games: t('GENERAL.CONSTRAINT.GAMES'),
      set: t('GENERAL.CONSTRAINT.SET'),
      rounds: t('GENERAL.CONSTRAINT.ROUNDS'),
      start: t('GENERAL.CONSTRAINT.START'),
      end: t('GENERAL.CONSTRAINT.END'),
      penalty: t('GENERAL.PENALTY.TITLE'),
      cap: t('GENERAL.CONSTRAINT.CAP_TITLE'),
      capScore: t('GENERAL.CONSTRAINT.CAP_SCORE_TITLE'),
      deleteTitle: t('GENERAL.CONSTRAINT.DELETE_TITLE'),
      deleteConfirm: t('GENERAL.CONSTRAINT.DELETE_CONFIRM'),
    },
    set: {
      create: t('GENERAL.SET.CREATE'),
      title: t('GENERAL.SET.TITLE', { count: 2 }),
    },
    setLine: {
      create: t('GENERAL.SET_LINE.CREATE'),
      title: t('GENERAL.SET_LINE.TITLE', { count: 2 }),
      seqNo: t('GENERAL.SET_LINE.SEQ'),
      gameType: t('GENERAL.SET_LINE.GAME_TYPE'),
      teams: t('GENERAL.SET_LINE.TEAMS'),
      opponents: t('GENERAL.SET_LINE.OPPONENTS'),
      day: t('GENERAL.SET_LINE.DAY'),
      slotType: t('GENERAL.SET_LINE.SLOT_TYPE'),
      network: t('GENERAL.SET_LINE.NETWORK'),
      category: t('GENERAL.SET_LINE.CATEGORY'),
      venue: t('GENERAL.SET_LINE.VENUE'),
      round: t('GENERAL.SET_LINE.ROUND'),
      gameTypeOptions: {
        all: t('GENERAL.SET_LINE.GAME_TYPE_OPTIONS.ALL'),
        home: t('GENERAL.SET_LINE.GAME_TYPE_OPTIONS.HOME'),
        away: t('GENERAL.SET_LINE.GAME_TYPE_OPTIONS.AWAY'),
        bye: t('GENERAL.SET_LINE.GAME_TYPE_OPTIONS.BYE'),
      },
      dayOptions: {
        mon: t('GENERAL.SET_LINE.DAY_OPTIONS.MON'),
        tue: t('GENERAL.SET_LINE.DAY_OPTIONS.TUE'),
        wed: t('GENERAL.SET_LINE.DAY_OPTIONS.WED'),
        thu: t('GENERAL.SET_LINE.DAY_OPTIONS.THU'),
        fri: t('GENERAL.SET_LINE.DAY_OPTIONS.FRI'),
        sat: t('GENERAL.SET_LINE.DAY_OPTIONS.SAT'),
        sun: t('GENERAL.SET_LINE.DAY_OPTIONS.SUN'),
      },
    },
    penalty: {
      comment: t('GENERAL.PENALTY.COMMENT'),
      penaltyHappenedTimes: t('GENERAL.PENALTY.PENALTY_HAPPENED_TIMES'),
      number: t('GENERAL.PENALTY.NUMBER'),
      title: t('GENERAL.PENALTY.TITLE', { count: 2 }),
    },
  };
};

export default useLocale;
