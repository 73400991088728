import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Card, Checkbox, Col, Input, Row } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useTranslation } from 'react-i18next';
import { Team } from 'Models/Team';
import { useVenue as useVenueQueue } from 'storeHooks/queue';
import { SyncVenueMapPayload } from 'storeHooks/queue/useVenue';
import { Search } from 'Components/Elements/Icons';
import { Solution } from 'Models/Scenario';
import useQueue from 'storeHooks/useQueue';
import { QueueActionType } from 'Models/Queue';
import useSolution from 'customHooks/useSolution';
import { useSelector } from 'react-redux';
import { selectTeams } from 'store/slices/scenarioSlice';

interface Props {
  venueKey: string;
  teamsIds: string[];
}

interface Option {
  label: string;
  value: string;
}

export const handleSelectTeam = async (
  venueKey: string,
  teamIds: string[],
  checkedValues: CheckboxValueType[],
  selectedSolution: Solution | null,
  onEnqueue: (data: QueueActionType) => void,
  syncTeamVenueMapToStore: (data: SyncVenueMapPayload) => void,
) => {
  if (!selectedSolution) return;
  const { teams } = selectedSolution.stateData;
  const { solutionKey } = selectedSolution;
  const { solutionKey: actualSolutionKey } = selectedSolution.stateData;

  const operationType: {
    type: 'update' | 'delete';
    teamKey: string;
  } = {
    type: 'update',
    teamKey: '',
  };

  const currentTeams = teamIds.reduce(
    (acc: Record<string, boolean>, item: string) => {
      acc[item] = true;

      return acc;
    },
    {},
  );

  const newTeams = checkedValues
    .map((item) => item.toString())
    .reduce((acc: Record<string, boolean>, item: string) => {
      acc[item] = true;

      return acc;
    }, {});

  if (checkedValues.length < teamIds.length) {
    teamIds.forEach((item) => {
      if (!newTeams[item]) {
        operationType.type = 'delete';
        operationType.teamKey = teams.find((team) => team.teamId === item)
          ?.teamKey as string;
      }
    });
  } else {
    checkedValues.forEach((item) => {
      if (!currentTeams[item as string]) {
        operationType.type = 'update';
        operationType.teamKey = teams.find((team) => team.teamId === item)
          ?.teamKey as string;
      }
    });
  }

  const { teamKey } = operationType;

  if (!teamKey || !venueKey) return;

  syncTeamVenueMapToStore({
    data: {
      venueKey,
      teamKey,
    },
    toBeUpdatedFields: {
      type: operationType.type,
    },
    solutionKey,
  });

  onEnqueue({
    type: 'UPDATE_TEAM_VENUE',
    payload: {
      data: {
        venueKey,
        teamKey,
      },
      toBeUpdatedFields: {
        type: operationType.type,
      },
      solutionKey, // local solution key
      actualSolutionKey,
    },
  });
};

function TeamSelectPopOver({ venueKey, teamsIds }: Props): JSX.Element {
  const allTeams = useSelector(selectTeams);
  const { syncTeamVenueMapToStore } = useVenueQueue();
  const { onEnqueue } = useQueue();
  const { getCurrentOrClonedSolution } = useSolution();
  const [value, setValue] = useState<CheckboxValueType[]>(teamsIds);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');

  const teams: Team[] = useMemo(() => {
    if (allTeams) {
      return allTeams;
    }
    return [];
  }, [allTeams]);

  const options: Option[] = teams.map((team) => ({
    label: team.name,
    value: team.teamId,
  }));

  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  const onSearch = (e: ChangeEvent) => {
    setSearchText((e.target as HTMLInputElement).value);
  };

  const handleChange = async (checkedValues: CheckboxValueType[]) => {
    const chosenSolution = getCurrentOrClonedSolution();

    if (!chosenSolution) return;

    await handleSelectTeam(
      venueKey,
      teamsIds,
      checkedValues,
      chosenSolution,
      onEnqueue,
      syncTeamVenueMapToStore,
    );
  };

  useEffect(() => {
    if (searchText.trim().length > 0) {
      const filtered: Option[] = options.filter((item) =>
        item.label.toLowerCase().includes(searchText.trim().toLowerCase()),
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [searchText]);

  useEffect(() => {
    setValue([...teamsIds]);
  }, [teamsIds]);

  return (
    <Card className="default-pop-over flat" style={{ width: 250 }}>
      <Input
        className="search-input my-2"
        size="middle"
        placeholder={t('GENERAL.SEARCH')}
        prefix={<Search />}
        value={searchText}
        onChange={onSearch}
        data-testid="search-input"
      />
      <div className="p-1">
        <Checkbox.Group
          style={{ width: '100%' }}
          value={value}
          onChange={handleChange}
        >
          <span style={{ maxHeight: 120, overflowY: 'auto' }}>
            <Row>
              {filteredOptions.map((option) => (
                <Col
                  className="mt-2 venue-item"
                  dats-testid="venue-item"
                  span={24}
                  key={option.value}
                >
                  <Checkbox value={option.value}> {option.label} </Checkbox>
                </Col>
              ))}
            </Row>
          </span>
        </Checkbox.Group>
      </div>
    </Card>
  );
}

export default TeamSelectPopOver;
