import React, { useState } from 'react';
import { Divider } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import NetworksTab from './NetworksTab';
import RoundsTab from './RoundsTab';
import SlotsTab from './SlotsTab';
import './style.scss';

type SectionType = 'slot' | 'round' | 'network';

export default function SlotsGroup(): JSX.Element {
  const { t } = useTranslation();
  const [section, setSection] = useState<SectionType>('round');

  return (
    <div className="slots-group">
      <div className="pills-tab">
        <div
          className={classNames('pill-item', {
            active: section === 'round',
          })}
          onClick={() => setSection('round')}
        >
          {t('GENERAL.ROUND.TITLE')}
        </div>
        <div
          className={classNames('pill-item', {
            active: section === 'network',
          })}
          onClick={() => setSection('network')}
        >
          {t('GENERAL.NETWORK.TITLE', {
            count: 2,
          })}
        </div>
        <div
          className={classNames('pill-item', {
            active: section === 'slot',
          })}
          onClick={() => setSection('slot')}
        >
          {t('GENERAL.SLOT.TITLE', {
            count: 2,
          })}
        </div>
      </div>
      <Divider className="divider" />

      <div className="my-2 px-3 grow">
        {section === 'network' && <NetworksTab />}
        {section === 'round' && <RoundsTab />}
        {section === 'slot' && <SlotsTab onTabChange={setSection} />}
      </div>
    </div>
  );
}
