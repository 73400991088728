import React, { useMemo, useCallback } from 'react';
import { Tag, Dropdown } from 'antd';
import { Constraint } from 'Models/Scenario';
import { useTranslation } from 'react-i18next';
import { BY_TEAM } from 'utils/variables';
import { useSelector } from 'react-redux';
import { Team } from 'Models/Team';
import { selectTeams, selectConstraints } from 'store/slices/scenarioSlice';
import TeamSelectPopOver from './TeamSelectPopOver';
import '../style.scss';

interface Props {
  data: Constraint;
}

function TeamCellRenderer({ data }: Props) {
  const allTeams = useSelector(selectTeams);
  const allConstraints = useSelector(selectConstraints);
  const { t } = useTranslation();

  const teams: Team[] = useMemo(() => {
    if (allTeams) {
      return allTeams;
    }
    return [];
  }, [allTeams]);

  const validTeamIds: Record<string, boolean> = useMemo(() => {
    if (allTeams) {
      return allTeams.reduce((acc, item) => {
        acc[item.teamId] = true;
        return acc;
      }, {} as Record<string, boolean>);
    }
    return {};
  }, [allTeams]);

  const constraint: Constraint | null = useMemo(() => {
    if (allConstraints) {
      const item = allConstraints.find(
        (x) => x.constraintKey === data.constraintKey,
      );
      return item || null;
    }
    return null;
  }, [allConstraints]);

  const selectedTeamIds: string[] = (constraint?.teamsIds ?? []).filter(
    (id) => validTeamIds[id],
  );

  const createSelectPopOver = useCallback(
    () => (
      <TeamSelectPopOver
        constraintKey={
          constraint ? constraint.constraintKey : data.constraintKey
        }
        teamsIds={selectedTeamIds}
      />
    ),
    [selectedTeamIds],
  );

  if (constraint && constraint.familyType !== BY_TEAM) {
    return <span className="na">{t('GENERAL.NOT_AVAILABLE.SHORT')}</span>;
  }

  return (
    <div data-testid="team-cell-renderer">
      <Dropdown
        menu={{ items: [] }}
        trigger={['click']}
        dropdownRender={createSelectPopOver}
        placement="topLeft"
        destroyPopupOnHide
      >
        <div>
          <Tag className="cursor-pointer">
            {teams?.length === selectedTeamIds.length
              ? t('GENERAL.ALL')
              : t('GENERAL.TEAM.COUNT.TITLE', {
                  count: selectedTeamIds.length,
                })}
          </Tag>
        </div>
      </Dropdown>
    </div>
  );
}

export default TeamCellRenderer;
