import React from 'react';
import { Trash } from 'Components/Elements/Icons';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  onConfirm: () => void;
}

export default function DeleteConfirm({
  title,
  onConfirm,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Popconfirm
      placement="topLeft"
      title={title}
      okText={t('GENERAL.YES')}
      cancelText={t('GENERAL.NO')}
      onConfirm={onConfirm}
    >
      <Button className="no-border-btn" size="small">
        <Trash />
      </Button>
    </Popconfirm>
  );
}
