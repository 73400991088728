import _ from 'lodash';

export const decodeHTML = (encodedHtml: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = encodedHtml;
  return txt.value;
};

export const replaceStringInObject = <T extends object>(
  object: T,
  replace: string,
  replaceWith: string,
): T => JSON.parse(JSON.stringify(object).replaceAll(replace, replaceWith));

export const getSolutionKey = (combinedKey: string) =>
  combinedKey.split('-').slice(0, 3).join('-');

export const getScenarioKey = (combinedKey: string) =>
  combinedKey.split('-').slice(0, 2).join('-');

export const getNthElement = (combinedKey: string, n: number) => {
  const allParts = combinedKey.split('-');

  return allParts[n - 1] ?? '';
};

export const shadeColor = (hex: string, percent: number) => {
  let R = parseInt(
    String((parseInt(hex.substring(1, 3), 16) * (100 + percent)) / 100),
  );
  let G = parseInt(
    String((parseInt(hex.substring(3, 5), 16) * (100 + percent)) / 100),
  );
  let B = parseInt(
    String((parseInt(hex.substring(5, 7), 16) * (100 + percent)) / 100),
  );

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return '#' + RR + GG + BB;
};

export const toggleDrawerCloseIconVisibility = (type: 'show' | 'hide') => {
  const closeIcon = document.querySelector('.close-drawer') as HTMLDivElement;

  if (!closeIcon) return;

  if (type === 'hide') {
    closeIcon.style.display = 'none';
  } else {
    closeIcon.style.display = 'flex';
  }
};

export const randomId = (length = 5) =>
  _.sampleSize('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', length).join('');
