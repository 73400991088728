import { getSolverParameters } from 'store/slices/solverParameterSlice';
import { useAppDispatch } from './hooks';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from 'store/store';
import { SolverParameter } from 'Models/SolverParameter';

export const useSolverParameter = () => {
  const dispatch = useAppDispatch();
  const solverParameters: SolverParameter[] = useSelector((state: RootState) => state.solverParameters.solverParameters);

  useEffect(() => {
    dispatch(getSolverParameters());
  }, [dispatch]);

  return {
    solverParameters,
  };
};
