import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  ScenarioConstraintResponsePromiseType,
  Constraint,
} from 'Models/Scenario';
import { QueueCreateUpdateConstraintPayloadType } from 'Models/Queue';
import { saveConstraint, syncConstraint } from 'store/slices/scenarioSlice';
import { selectQueueLocalKeyMap } from 'store/slices/queueSlice';
import { CREATED } from 'utils/variables';
import { useRef, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { getNthElement } from 'utils/ui-helper';

export const useConstraint = () => {
  const dispatch = useAppDispatch();
  const queueLocalKeyMap = useSelector(selectQueueLocalKeyMap);
  const queueLocalKeyMapRef = useRef(queueLocalKeyMap);

  useEffect(() => {
    queueLocalKeyMapRef.current = queueLocalKeyMap;
  }, [queueLocalKeyMap]);

  const prepareConstraintPayload = ({
    data,
    toBeUpdatedFields,
  }: QueueCreateUpdateConstraintPayloadType): Constraint => {
    let newPayload = _.cloneDeep(data);
    const fieldsToBeUpdated = toBeUpdatedFields || {};

    newPayload = {
      ...newPayload,
      ...fieldsToBeUpdated,
    };

    if (queueLocalKeyMapRef.current[newPayload.constraintKey]) {
      newPayload.constraintKey =
        queueLocalKeyMapRef.current[newPayload.constraintKey];
    }

    if (queueLocalKeyMapRef.current[newPayload.setId]) {
      newPayload.setId = queueLocalKeyMapRef.current[newPayload.setId];
    }

    newPayload.teamsIds = newPayload.teamsIds.map((teamId) => {
      if (queueLocalKeyMapRef.current[teamId]) {
        const actualTeamId = getNthElement(
          queueLocalKeyMapRef.current[teamId],
          4,
        );

        if (actualTeamId) return actualTeamId;
      }

      return teamId;
    });

    return newPayload;
  };

  const syncConstraintToStore = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
  }: QueueCreateUpdateConstraintPayloadType): void => {
    dispatch(
      syncConstraint({
        ...prepareConstraintPayload({
          data,
          toBeUpdatedFields,
          solutionKey: data.solutionKey,
        }),
        solutionKey: data.solutionKey,
        toBeSyncedKey,
      }),
    );
  };

  const createOrUpdateConstraintQueue = ({
    data,
    toBeUpdatedFields,
    toBeSyncedKey,
  }: {
    data: Constraint;
    toBeUpdatedFields: {
      [key in keyof Constraint]?: Constraint[key];
    };
    toBeSyncedKey: string;
  }): Promise<ScenarioConstraintResponsePromiseType | null> => {
    const preparedData = prepareConstraintPayload({
      data,
      toBeUpdatedFields,
    });

    if (preparedData) {
      return dispatch(
        saveConstraint({
          constraint: {
            ...preparedData,
            toBeSyncedKey,
          },
          solutionStatus: CREATED,
        }),
      ) as Promise<ScenarioConstraintResponsePromiseType>;
    }

    return Promise.resolve(null);
  };

  return {
    prepareConstraintPayload,
    syncConstraintToStore,
    createOrUpdateConstraintQueue,
  };
};

export default useConstraint;
