import React, { useMemo } from 'react';
import { Card, Divider, Button, Input, ColorPicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { Network } from 'Models/Network';

interface Props {
  network: Network;
  networkCategoriesNumber: number;
  handleChangeNetworkCategoryNumber: (e: React.FormEvent<HTMLInputElement>) => void;
  handleChangeNetwork: (name: string, value: string | number) => void;
  handleSaveNetwork: () => void;
  onClose: (count: number) => void;
}

function NetworkCreatePopover({ networkCategoriesNumber, handleChangeNetworkCategoryNumber, network, handleChangeNetwork, handleSaveNetwork, onClose }: Props): JSX.Element {
  const { t } = useTranslation();

  const enableButton = useMemo(() => {
    if (network.name && network.name.trim()) {
      return true;
    }

    return false;
  }, [network.name]);

  return (
    <Card
      className="default-pop-over"
      type="inner"
      title={t('GENERAL.NETWORK.CREATE')}
      style={{ width: 400 }}
    >
      <div className="flex justify-between items-center px-4">
        <span>{t('GENERAL.NETWORK.NAME')}</span>

        <Input
          className="w-40"
          value={network.name}
          onChange={(e: React.FormEvent<HTMLInputElement>) => handleChangeNetwork('name', e.currentTarget.value)}
          data-testid="network-name"
        />
      </div>
      <div className="flex justify-between items-center px-4 my-2">
        <span>{t('GENERAL.TOTAL.SUB_CAT')}</span>

        <Input
          className="w-40"
          type="number"
          value={networkCategoriesNumber}
          onChange={handleChangeNetworkCategoryNumber}
          data-testid="category-count"
        />
      </div>
      <div className="flex justify-between items-center px-4 ">
        <span>{t('GENERAL.COLOR')}</span>

        <div className="w-40">
          <ColorPicker
            value={network.color}
            onChangeComplete={(color) => {
              handleChangeNetwork('color', color.toHexString());
            }}
          />
        </div>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-end items-center gap-1 px-4">
        <Button size="small" onClick={() => onClose(0)}>
          {t('GENERAL.CANCEL')}
        </Button>
        <Button
          size="small"
          type="primary"
          disabled={!enableButton}
          onClick={() => handleSaveNetwork()}
          data-testid="add-network"
        >
          {t('GENERAL.NETWORK.CREATE')}
        </Button>
      </div>
    </Card>
  );
}

export default NetworkCreatePopover;
