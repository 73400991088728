/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { Expose, Type } from 'class-transformer';

export enum SignalREventType {
  ServerTime = 'ServerTime',
  GeneralMessage = 'GeneralMessage',
  InteractionMessage = 'InteractionMessage',
  SolutionEvent = 'SolutionEvent',
}

export abstract class SignalRDtoBase {
  @Expose()
  season!: number;

  @Expose()
  scenarioKey!: string;

  @Expose()
  solutionKey!: string;

  @Expose()
  signalREventType!: SignalREventType;

  Display(): string {
    return `WARNING: SignalRMsgBase.Display() need to be overriden in sub class ${this.signalREventType}`;
  }
}

export class SignalRMsgServerTime extends SignalRDtoBase {
  // WARN: add to SignalRDtoBase
  @Expose()
  time!: string;

  Display(): string {
    return `'SignalRMsgServerTime': ${this.time}`;
  }
}

export class SignalRMsgLogBase extends SignalRDtoBase {
  @Expose()
  message!: string;

  @Expose()
  hasAllMessages?: boolean;
}

export abstract class SignalREventSolutionBase extends SignalRDtoBase {
  // WARN: add to SignalRDtoBase
}

export class SignalREventSolutionSolveRequested extends SignalREventSolutionBase {
  // WARN: add to SignalRDtoBase

  Display(): string {
    return "'SignalREventSolutionSolveRequested': \n Solve requested... \n";
  }
}

export class SignalREventSolutionSolving extends SignalREventSolutionBase {
  // WARN: add to SignalRDtoBase

  Display(): string {
    return "'SignalREventSolutionSolving': \n Solving is started... \n";
  }
}

export class SignalREventSolutionFound extends SignalREventSolutionBase {
  // WARN: add to SignalRDtoBase
  Display(): string {
    return "'SignalREventSolutionFound'";
  }
}

export class SignalREventSolutionStopping extends SignalREventSolutionBase {
  // WARN: add to SignalRDtoBase

  Display(): string {
    return "'SignalREventSolutionStopping': \n Stopping by user request... \n";
  }
}

export type SolveDataOptimizationStatus =
  | 'UNKNOWN'
  | 'OPTIMAL'
  | 'TIME_LIMIT'
  | 'INFEASIBLE'
  | 'INTERRUPTED';

export class SignalREventSolutionStopped extends SignalREventSolutionBase {
  // WARN: add to SignalRDtoBase
  @Expose()
  solveDataOptimizationStatus!: SolveDataOptimizationStatus;

  @Expose()
  numSolutions!: number;

  @Expose()
  numSeeds!: number;

  @Expose()
  numInfeasible!: number;

  @Expose()
  numCutOff!: number;

  @Expose()
  bestBound!: number;

  Display(): string {
    return `'SignalREventSolutionStopped' \n solveDataOptimizationStatus: ${this.solveDataOptimizationStatus}, \n numSolutions: ${this.numSolutions}, \n numSeeds: ${this.numSeeds}, \n numInfeasible: ${this.numInfeasible}, \n numCutOff: ${this.numCutOff}, \n bestBound: ${this.bestBound}`;
  }
}

export class SignalREventSolutionError extends SignalREventSolutionBase {
  // WARN: add to SignalRDtoBase
  @Expose()
  message!: string;

  Display(): string {
    return `'SignalREventSolutionError' \n Error: ${this.message}, `;
  }
}

export class SignalREventSolutionSaved extends SignalREventSolutionBase {
  // WARN: add to SignalRDtoBase
  Display(): string {
    return 'SignalREventSolutionSaved';
  }
}

export class SignalRDto {
  @Type(() => SignalRDtoBase, {
    discriminator: {
      property: '$type',
      subTypes: [
        { value: SignalRMsgServerTime, name: 'SignalRMsgServerTime' },
        { value: SignalRMsgLogBase, name: 'SignalRMsgLogBase' },
        {
          value: SignalREventSolutionSolveRequested,
          name: 'SignalREventSolutionSolveRequested',
        },
        {
          value: SignalREventSolutionStopping,
          name: 'SignalREventSolutionStopping',
        },
        {
          value: SignalREventSolutionSolving,
          name: 'SignalREventSolutionSolving',
        },
        { value: SignalREventSolutionFound, name: 'SignalREventSolutionFound' },
        {
          value: SignalREventSolutionStopped,
          name: 'SignalREventSolutionStopped',
        },
        {
          value: SignalREventSolutionError,
          name: 'SignalREventSolutionError',
        },
        { value: SignalREventSolutionSaved, name: 'SignalREventSolutionSaved' },
      ],
    },
  })
  signalRMsgBase!:
    | SignalRMsgServerTime
    | SignalRMsgLogBase
    | SignalREventSolutionSolveRequested
    | SignalREventSolutionSolving
    | SignalREventSolutionStopping
    | SignalREventSolutionStopped
    | SignalREventSolutionError
    | SignalREventSolutionFound
    | SignalREventSolutionSaved;
}
