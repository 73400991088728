import React from 'react';
import classNames from 'classnames';
import { Plus, Empty as EmptyIcon } from 'Components/Elements/Icons';
import './style.scss';

interface Props {
  title: string;
  subtitle: string;
  btnText?: string;
  onBtnClick?: () => void;
  height?: number;
  className?: string;
  ignoreIcon?: boolean;
}

export default function Empty({
  title,
  subtitle,
  btnText,
  height,
  onBtnClick,
  className,
  ignoreIcon,
}: Props): JSX.Element {
  const minHeight = height ?? 500;

  const onClick = () => {
    if (onBtnClick) {
      onBtnClick();
    }
  };

  return (
    <div
      className={classNames(
        'flex flex-column justify-center items-center py-4 empty-data',
        className,
      )}
      style={{
        minHeight,
      }}
    >
      <EmptyIcon />
      <div className="title" data-testid="title">
        {title}
      </div>
      <div className="subtitle" data-testid="sub-title">
        {subtitle}
      </div>

      {btnText && (
        <button
          type="button"
          className="btn-default"
          onClick={onClick}
          data-testid="btn-action"
        >
          {!ignoreIcon && <Plus color="#ffffff" />} {btnText}
        </button>
      )}
    </div>
  );
}
