import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

function Icon({ color, className }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path d="M4.16602 13C3.38945 13 3.00116 13 2.69488 12.8731C2.2865 12.704 1.96204 12.3795 1.79288 11.9711C1.66602 11.6649 1.66602 11.2766 1.66602 10.5V4.83333C1.66602 3.89991 1.66602 3.4332 1.84767 3.07668C2.00746 2.76308 2.26243 2.50811 2.57603 2.34832C2.93255 2.16667 3.39926 2.16667 4.33268 2.16667H9.99935C10.7759 2.16667 11.1642 2.16667 11.4705 2.29353C11.8789 2.46269 12.2033 2.78715 12.3725 3.19553C12.4993 3.50181 12.4993 3.8901 12.4993 4.66667M10.166 18.8333H15.666C16.5994 18.8333 17.0661 18.8333 17.4227 18.6517C17.7363 18.4919 17.9912 18.2369 18.151 17.9233C18.3327 17.5668 18.3327 17.1001 18.3327 16.1667V10.6667C18.3327 9.73325 18.3327 9.26654 18.151 8.91002C17.9912 8.59641 17.7363 8.34145 17.4227 8.18166C17.0661 8 16.5994 8 15.666 8H10.166C9.23259 8 8.76588 8 8.40937 8.18166C8.09576 8.34145 7.84079 8.59641 7.68101 8.91002C7.49935 9.26654 7.49935 9.73325 7.49935 10.6667V16.1667C7.49935 17.1001 7.49935 17.5668 7.68101 17.9233C7.84079 18.2369 8.09576 18.4919 8.40937 18.6517C8.76588 18.8333 9.2326 18.8333 10.166 18.8333Z" stroke={color ?? '#98A2B3'} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default Icon;
