import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Button,
  Input,
  Select,
  Alert,
  Upload,
  Flex,
  Avatar,
  Typography,
} from 'antd';
import { TrophyOutlined } from '@ant-design/icons';
import { getSeasons } from 'Components/Common/TimeUtils';
import useScenario from 'storeHooks/useScenario';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/es/upload';
import { ScenarioMetaData } from 'Models/Scenario';
import { getBase64 } from 'utils/image-upload-handler';

const { Item } = Form;
const { Text } = Typography;

interface Props {
  onCancel: () => void;
  scenario?: ScenarioMetaData;
}

function ScenarioCreateEdit({ onCancel, scenario }: Props): JSX.Element {
  const { t } = useTranslation();
  const { createScenario, updateScenario, selectedSeason } = useScenario();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpen] = useState(true);
  const [scenarioError, setScenarioError] = useState('');
  const [name, setName] = useState<string>(scenario?.name || '');
  const [season, setSeason] = useState<string>(scenario && scenario.season ? scenario.season :
    selectedSeason.toString() ?? new Date().getFullYear().toString(),
  );
  const [logoUrl, setLogoUrl] = useState<string>(scenario?.logo || '');
  const [logoError, setLogoError] = useState<string>();

  const availableSeasons = useMemo(
    () => getSeasons().map((item) => ({ label: item, value: item })),
    [],
  );

  const enableButton = useMemo(() => {
    if (name) {
      return true;
    }

    return false;
  }, [name]);

  const onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const beforeUpload = (file: RcFile) => {
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/x-icon'];
    const isAllowedLogoFormat = allowedImageTypes.includes(file.type);

    if (!isAllowedLogoFormat) {
      setLogoError(t('GENERAL.ERRORS.INVALID_LOGO_FILE_TYPE'));
    }

    const doesNotExceedSizeLimit = file.size / 1024 <= 200;

    if (!doesNotExceedSizeLimit) {
      setLogoError(t('GENERAL.ERRORS.LOGO_MAX_SIZE_EXCEEDED'));
    } else {
      setLogoError('');
    }

    return isAllowedLogoFormat && doesNotExceedSizeLimit;
  };

  const onLogoChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>,
  ) => {
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj as RcFile, (url: string) => {
        setLogoUrl(url);
        setLogoError('');
      }, (error: string) => {
        setLogoError(error);
      });
    }
  };

  const onDeleteLogo = () => {
    setLogoUrl('');
    setLogoError('');
  };

  const closeModal = () => {
    setTimeout(() => {
      onCancel();
    }, 800);
  };

  const onClose = () => {
    setOpen(false);
    closeModal();
  };

  const onResetError = () => {
    setScenarioError('');
  };

  const onSave = async () => {
    setIsSaving(true);

    if (scenarioError) {
      onResetError();
    }

    const result = scenario
      ? await updateScenario({
        id: scenario.id,
        name,
        season,
        logo: logoUrl,
        scenarioKey: scenario.scenarioKey,
      })
      : await createScenario({
        id: '',
        name,
        season,
        logo: logoUrl,
        scenarioKey: '',
      });

    if (result.payload) {
      onClose();
    } else if (result.error) {
      if (result.error.message) {
        setScenarioError(result.error.message);
      }
      setIsSaving(false);
    }
  };

  return (
    <Modal
      title={scenario ? t('GENERAL.SCENARIO.EDIT') : t('GENERAL.SCENARIO.CREATE')}
      centered
      open={open}
      onCancel={onClose}
      width={720}
      footer={[
        <Button key="back" onClick={onClose} data-testid="cancel-button">
          {t('GENERAL.CLOSE')}
        </Button>,
        <Button
          key="submit"
          onClick={onSave}
          disabled={!enableButton || isSaving}
          loading={isSaving}
          className="btn-submit"
          data-testid="scenario-save-button"
        >
          {t('GENERAL.SAVE')}
        </Button>,
      ]}
    >
      {scenarioError && (
        <Alert
          message={scenarioError}
          type="error"
          className="my-2"
          closable
          onClose={onResetError}
        />
      )}
      <Form form={form} layout="vertical">
        <Item label={t('GENERAL.SCENARIO.TITLE')}>
          <Input
            value={name}
            onChange={onNameChange}
            data-testid="scenario-name"
          />
        </Item>

        <Item label={t('GENERAL.SEASON')}>
          <Select
            value={season}
            options={availableSeasons}
            onChange={setSeason}
            data-testid="season-select"
          />
        </Item>
        <Item
          label={`${t('GENERAL.SCENARIO.LOGO')} (${t('GENERAL.OPTIONAL')})`}
        >
          <Flex gap="small" align="center">
            {logoUrl ? (
              <Avatar size={40} src={logoUrl} />
            ) : (
              <Avatar size={60} icon={<TrophyOutlined />} />
            )}
            <Upload
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={onLogoChange}
              data-testid="logo-upload-component"
            >
              <Button
                data-testid="logo-upload-button"
              >
                {t('GENERAL.UPLOAD')}
              </Button>
            </Upload>
            <Button
              type="primary"
              onClick={onDeleteLogo}
              danger
              disabled={!logoUrl}
              data-testid="logo-delete-button"
            >
              {t('GENERAL.DELETE')}
            </Button>
            <Text type="danger">{logoError}</Text>
          </Flex>
        </Item>
      </Form>
    </Modal>
  );
}

export default ScenarioCreateEdit;
